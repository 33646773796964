import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Alert, Button, Form, Input, message, notification } from "antd";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Spinner } from "reactstrap";
import API_PATH from "../../../Constants/api-path";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

function LoginForm() {
  const history = useNavigate();
  const [form] = Form.useForm();
  const [isAuth, setIsAuth] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [userData, setValue] = useState({
    password: "",
    email: "",
  });
  const [error, setError] = useState("");
  const [, forceUpdate] = useState({});

  useEffect(() => {
    const logoutUser = () => {
      localStorage.removeItem("expertData");
      localStorage.removeItem("isExpertAuth");
      history("/expert-login");
    };

    const lastExpertLoginTime = localStorage.getItem("lastExpertLoginTime");

    if (lastExpertLoginTime) {
      const elapsed = Date.now() - parseInt(lastExpertLoginTime, 10);
      if (elapsed >= 8 * 60 * 60 * 1000) {
        // 8 hours in milliseconds
        logoutUser();
      }
    }

    return () => {
      // Clear any cleanup you might have here
    };
  }, [history]);

  const emailRules = [
    {
      type: "email",
      message: "Please enter a valid email address!",
    },
    {
      required: true,
      message: "Email is required!",
    },
  ];

  // To disable submit button at the beginning.
  useEffect(() => {
    forceUpdate({});
  }, []);

  const handleInputs = (e) => {
    const key = e.target.name;
    let value = e.target.value;

    if (key === "email") {
      value = value?.toLowerCase();
    }

    setValue({ ...userData, [key]: value });
  };

  useEffect(() => {
    if (isAuth) {
      let redirectPath = localStorage.getItem("redirectPath");
      if (redirectPath) {
        localStorage.removeItem("redirectPath");
        history(redirectPath);
      } else {
        history("/expert/dashboard");
        setTimeout(() => {
          notification.success({
            message: "Login Successful",
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#2ecc71",
              color: "#fff !important",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          });
        }, 100);
      }
    }
  }, [isAuth]);

  const loginUser = async (e) => {
    e.preventDefault();
    setIsAuth(false);
    setSpinner(true);
    try {
      const res = await fetch(`${API_PATH.EXPERT_LOGIN}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userData),
      });

      const result = await res.json();
      if (result) {
        setSpinner(false);
        if (result?.status === 200 || result?.status === true) {
          localStorage.setItem("expertData", JSON.stringify(result));

          if (result?.token) {
            setIsAuth(true);
            localStorage.setItem("isExpertAuth", true);
            localStorage.setItem(
              "ChangePassword",
              JSON.stringify(userData.password)
            );

            localStorage.setItem("lastExpertActiveTime", new Date().toString()); // Set the last active time when the user logs in

            localStorage.setItem(
              "lastExpertLoginTime",
              new Date().getTime().toString()
            ); // Set the last login time

            history("/expert/dashboard");
            notification.success({
              message: "Login Successful",
              icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
              style: {
                backgroundColor: "#2ecc71",
                color: "#fff !important",
                border: "1px solid #52c41a",
              },
              duration: 5,
              placement: "topRight",
            });
          }
        } else {
          setSpinner(false);
          setError("Invalid email or password");
        }
      } else {
        console.error("result:", result);
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      console.error("Expert Login", error);
      setError("An error occurred while logging in");
    }
  };

  const ForgotClick = () => {
    history("/expert-forgot-password");
  };

  useEffect(() => {
    if (isAuth) {
      history("/expert/dashboard");
      setIsAuth(false);
    }
  }, [isAuth]);

  const something = (event) => {
    if (event.keyCode === 13) {
      loginUser(event);
    }
  };

  return (
    <>
      {error && <Alert message={error} type="error" />}
      <Form form={form} layout="vertical" autoComplete="off">
        <Form.Item name="email" label="Email Address" rules={emailRules}>
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            type="text"
            name="email"
            value={userData?.email}
            onChange={handleInputs}
            autoComplete="nope"
            placeholder="Enter Your Email Address"
            size="large"
          />
        </Form.Item>
        <div>
          <Form.Item
            name="password"
            className="mb-0"
            label="Password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              name="password"
              onChange={handleInputs}
              value={userData?.password}
              id="password-field"
              size="large"
              autoComplete="nope"
              onKeyDown={(e) => something(e)}
              placeholder="Password"
            />
          </Form.Item>

          <Form.Item className="d-flex justify-content-end">
            <Button
              type="link"
              onClick={ForgotClick}
              style={{
                textDecoration: "none",
                color: "#523EE8",
                border: "none",
                background: "none",
                cursor: "pointer",
              }}
            >
              Forgot Password?
            </Button>
          </Form.Item>
        </div>

        <div className="form-group">
          <Form.Item shouldUpdate>
            {() => (
              <Button
                onClick={loginUser}
                className="btn login-btn"
                type="primary"
                htmlType="submit"
                disabled={
                  !form.isFieldsTouched(true) ||
                  !!form.getFieldsError().filter(({ errors }) => errors.length)
                    .length
                }
              >
                {spinner ? (
                  <>
                    <Spinner
                      size="sm"
                      style={{
                        width: "1rem",
                        height: "1rem",
                        marginRight: "5px",
                      }}
                    />
                    Login
                  </>
                ) : (
                  "Login"
                )}
              </Button>
            )}
          </Form.Item>
        </div>
      </Form>

      <div className="form-group">
        <p className="text-center">
          <NavLink to="/expert-register" className="register-btn px-2">
            Register
          </NavLink>
        </p>
      </div>
    </>
  );
}
export default LoginForm;
