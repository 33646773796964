import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import API_PATH from "../Constants/api-path";
import { history } from './history';
import { CheckCircleOutlined } from "@ant-design/icons";
import mydecodedTokenFunction from './DecodedToken';
import { notification } from 'antd';

const name = 'auth';
const initialState = {
    user: JSON.parse(localStorage.getItem('user')),
    error: null,
    loading: false,
    success: false,
    utm: null,
};

export const email_login = createAsyncThunk(
    `${name}/email_login`,
    async ({ email, password }, { rejectWithValue }) => {
        const response = await fetch(`${API_PATH.ADMINLOGIN}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: email,
                password: password
            }),
        });
        let login_response = await response.json();
        if (login_response?.status) {
            return login_response
        } else {
            return rejectWithValue(login_response);
        }
    },
);

const slice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        logout: (state) => {
            state.user = null;
            localStorage.removeItem('userData');
            localStorage.removeItem("token")
            localStorage.removeItem("auth")
            localStorage.removeItem("lastActiveTime")
            localStorage.removeItem("changePassword")
            localStorage.removeItem("lastLoginTime")
            return history.navigate('/admin-login');
        },
        resetState: (state) => {
            state.user = null;
            state.error = null;
            state.loading = false;
            state.success = false;
        },
    },
    extraReducers: (builder) => {
        const { fulfilled, pending, rejected } = email_login
        builder.addCase(fulfilled, (state, action) => {
            // console.log('action: ', action.payload);
            const user = action.payload;
            // console.log('user: FROM EXTRA REDUCER', user);
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('token', user?.token);
            localStorage.setItem('userData', JSON.stringify(user));
            // localStorage.setItem("ChangePassword", JSON.stringify(user?.data?.password));
            localStorage.setItem('lastActiveTime', new Date().toString()); // Set the last active time when the user logs in
            localStorage.setItem('lastLoginTime', new Date().getTime().toString()); // Set the last login time
            const userData = mydecodedTokenFunction(user?.token);
            // console.log('userData: ', userData);
            state.user = userData;
            state.loading = false;
            state.success = true;
            // get return url from location state or default to home page
            if (!user) {
                return history.navigate("/admin-login");
            } else {
                notification.success({
                    message: "Login Successful",
                    icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
                    style: {
                        backgroundColor: "#2ecc71",
                        color: "#fff !important",
                        border: "1px solid #52c41a",
                    },
                    duration: 5,
                    placement: "topRight",
                });
                return history.navigate("/dashboard");
            }
        })
        builder.addCase(pending, (state,) => {
            state.error = null;
            state.loading = true;
        })
        builder.addCase(rejected, (state, action) => {
            console.log("REJECTED", action);
            state.error = action.payload.message;
            state.loading = false;
        })
    }
});

// exports

export const authActions = { ...slice.actions };
export const authReducer = slice.reducer;

