import React, { useState, useRef, useCallback } from "react";
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from "react-icons/ai";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { MdOutlineClose } from "react-icons/md";
import { Input, Select, DatePicker, Form, notification, Spin, AutoComplete } from "antd";
import { Col, Modal, Row } from "react-bootstrap";
import API_PATH from "../../Constants/api-path";
import axios from "axios";
import { useEffect } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"; // Import plugin for UTC handling
import customParseFormat from "dayjs/plugin/customParseFormat";
import DOMPurify from "dompurify";
import debounce from 'lodash/debounce'

import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined
} from "@ant-design/icons";
import { countryOptions } from "../../Constants/CountryOptions";
import DebounceSelect from "../CloudFeatures/InternalMeetings/Components/DebounceSelect";
import moment from "moment";
const { Option } = Select;
dayjs.extend(customParseFormat);
dayjs.extend(utc); // Extend Day.js with the UTC plugin

function AddProjectModal({
  show,
  handleClose,
  teamIdEdit,
  managerList,
  selectedOptions,
  currentPage,
  GetLiveProjectList = () => { },
  GetCompleteProjectList = () => { },
}) {
  const editorRef = useRef();
  const [loading, setLoading] = useState(false);
  const [addData, setAddData] = useState({
    project_title: "",
    project_start: null,
    project_deadline: null,
    client_name: "",
    overview: "",
    status: "",
    client_email: "",
    client_team_email: "",
    linkedin_account: "",
    platform: "",
    manager_id: "",
    no_of_calls_expected: "",
    no_of_profiles_sent: "",
    no_of_calls_completed: "",
    remarks: ""
  });
  console.log('addData: ', addData);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [inputs, setInputs] = useState([{ key: 1, value: '' }]);
  const [geographies, setGeographies] = useState([{ key: 1, value: '' }])

  const handleAddInput = () => {
    const newKey = inputs.length + 1;
    setInputs([...inputs, { key: newKey, value: "" }]);
  };

  const [allClient, setAllClient] = useState([]);

  const getExpertsOptions = useCallback((selectedOptions = "") => {
    setLoading(true);
    axios
      .get(`${API_PATH.TEAM_CLIENT_NAME}?client_name=${selectedOptions}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
      })
      .then((res) => {
        setAllClient(res?.data?.data);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
    setLoading(false);
  }, [userData?.token]);

  const debounceFetcher = React.useMemo(() => {
    let loadOptions = (value) => {
      getExpertsOptions(value)
    };

    return debounce(loadOptions, 1000);
  }, [getExpertsOptions]);


  const handleRemoveInput = (keyToRemove) => {
    if (inputs.length > 1) {
      const updatedInputs = inputs.filter((input) => input.key !== keyToRemove);
      setInputs(updatedInputs);
    }
  };

  const handleAddGeography = () => {
    const newKey = geographies.length + 1;
    setGeographies([...geographies, { key: newKey, value: [] }]);
  };

  const handleRemoveGeography = useCallback((keyToRemove) => {
    if (geographies.length > 1) {
      const updatedGeographies = geographies.filter(
        (geo) => geo.key !== keyToRemove
      );
      setGeographies(updatedGeographies);
    }
  }, [geographies]);

  const handleChange = useCallback((value, field) => {
    setAddData((prevData) => ({
      ...prevData,
      [field]:
        (field === "project_start" || field === "project_deadline") && value
          ? dayjs(value)?.format("YYYY-MM-DD")
          : value,
    }));
  }, []);


  const fetchData = useCallback(async (teamIdEdit) => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${API_PATH?.TEAM_PROJECT_ADD}/${teamIdEdit}`
      );
      const projectData = res?.data?.data;
      if (projectData) {
        setAddData({
          project_title: projectData?.project_title,
          project_start: projectData?.project_start ? dayjs(projectData?.project_start).utc() : null, // Parse and handle UTC
          project_deadline: projectData?.project_deadline ? dayjs(projectData?.project_deadline).utc() : null, // Parse and handle UTC
          client_name: projectData?.client_name,
          overview: projectData?.overview,
          status: projectData?.status === 1 ? "active" : projectData?.status === 2 ? "closed" : "hold",
          client_email: projectData?.client_email,
          client_team_email: projectData?.client_team_email,
          linkedin_account: projectData?.linkedin_account,
          platform: projectData?.platform,
          manager_id: managerList?.filter((manager) => manager?._id === projectData?.manager_id)?.[0] ? {
            "label": managerList?.filter((manager) => manager?._id === projectData?.manager_id)?.[0]?.email,
            "value": managerList?.filter((manager) => manager?._id === projectData?.manager_id)?.[0]?._id,
            "key": managerList?.filter((manager) => manager?._id === projectData?.manager_id)?.[0]?._id
          } : undefined,
          no_of_calls_expected: projectData?.no_of_calls_expected,
          no_of_profiles_sent: projectData?.no_of_profiles_sent,
          no_of_calls_completed: projectData?.no_of_calls_completed,
          remarks: projectData?.remarks
        });

        if (editorRef?.current) {
          editorRef?.current?.setData(projectData?.overview);
        }
        const geographiesArray = projectData?.geography.split(",");
        const inviteTeamArray = projectData?.invite_team_dashboard
          ?.split(",")
          ?.filter(
            (item) => item.toLowerCase() !== userData?.data?.name?.toLowerCase()
          );
        setGeographies(
          geographiesArray?.map((geo, index) => ({
            key: index + 1,
            value: geo?.trim(),
          }))
        );

        setInputs(
          inviteTeamArray?.map((input, index) => ({
            key: index + 1,
            value: input?.trim(),
          }))
        );
      } else {
        setAddData({
          project_title: "",
          project_start: null,
          project_deadline: null,
          client_name: "",
          overview: "",
        });
        setGeographies([{ key: 1, value: "" }]);
        setInputs([{ key: 1, value: "" }]);
      }

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }, [managerList, userData?.data?.name]);

  const AddTeamProject = useCallback(async () => {
    setLoading(true);

    try {
      let formattedInviteTeam = [];
      inputs.forEach((input) => formattedInviteTeam.push(input.value));
      const formattedGeography = geographies.map((geo) => geo.value).join(",");
      // const user = userData?.data?.email?.split("@")[0];
      // if (user) {
      //   if (!formattedInviteTeam.includes(user)) {
      //     formattedInviteTeam.push(user);
      //   }
      // }

      const inviteTeamString = formattedInviteTeam?.toString();

      const requestData = {
        project_title: addData?.project_title,
        overview: addData?.overview,
        project_start: addData?.project_start ? dayjs(addData?.project_start).format("YYYY-MM-DD") : null,
        project_deadline: addData?.project_deadline ? dayjs(addData?.project_deadline).format("YYYY-MM-DD") : null,
        admin_id: userData?.data?._id,
        invite_team_dashboard: inviteTeamString,
        client_name: addData?.client_name,
        geography: formattedGeography,
        status: addData?.status === "active" ? 1 : addData?.status === "closed" ? 2 : 3,
        client_email: addData?.client_email,
        client_team_email: addData?.client_team_email,
        linkedin_account: addData?.linkedin_account,
        platform: addData?.platform,
        manager_id: addData?.manager_id?.key || addData?.manager_id,
        no_of_calls_expected: addData?.no_of_calls_expected,
        no_of_profiles_sent: addData?.no_of_profiles_sent,
        no_of_calls_completed: addData?.no_of_calls_completed,
        remarks: addData?.remarks
      };

      const requestDataEdit = {
        project_title: addData?.project_title,
        overview: addData?.overview,
        project_start: addData?.project_start ? dayjs(addData?.project_start).format("YYYY-MM-DD") : null,
        project_deadline: addData?.project_deadline ? dayjs(addData?.project_deadline).format("YYYY-MM-DD") : null,
        project_id: teamIdEdit,
        invite_team_dashboard: inviteTeamString,
        client_name: addData?.client_name,
        geography: formattedGeography,
        status: addData?.status === "active" ? 1 : addData?.status === "closed" ? 2 : 3,
        client_email: addData?.client_email,
        client_team_email: addData?.client_team_email,
        linkedin_account: addData?.linkedin_account,
        platform: addData?.platform,
        manager_id: addData?.manager_id?.key || addData?.manager_id,
        no_of_calls_expected: addData?.no_of_calls_expected?.toString(),
        no_of_profiles_sent: addData?.no_of_profiles_sent?.toString(),
        no_of_calls_completed: addData?.no_of_calls_completed?.toString(),
        remarks: addData?.remarks
      };

      if (teamIdEdit) {
        const res = await fetch(`${API_PATH.TEAM_PROJECT_ADD}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          },
          body: JSON.stringify(requestDataEdit),
        });

        const result = await res.json();
        if (result) {
          if (result && (res.status === 200 || res.status === true)) {
            handleClose();
            GetLiveProjectList(currentPage, selectedOptions);
            GetCompleteProjectList(currentPage, selectedOptions);
            notification.success({
              message: "Project has been updated",
              icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
              style: {
                backgroundColor: "#2ecc71",
                color: "#fff !important",
                border: "1px solid #52c41a",
              },
              duration: 5,
              placement: "topRight",
            });
          }
        }
      } else {
        const res = await fetch(`${API_PATH.TEAM_PROJECT_ADD}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          },
          body: JSON.stringify(requestData),
        });

        const result = await res.json();
        if (result) {
          if (result && (res.status === 200 || res.status === true)) {
            handleClose();
            GetLiveProjectList();
            GetCompleteProjectList();
            // setTimeout(() => {
            //   window.location.reload();
            // }, 1000);
            notification.success({
              message: "Project has been created",
              icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
              style: {
                backgroundColor: "#2ecc71",
                color: "#fff !important",
                border: "1px solid #52c41a",
              },
              duration: 5,
              placement: "topRight",
            });
          }
        }
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }

    setLoading(false);
  }, [GetCompleteProjectList, GetLiveProjectList, addData?.client_email, addData?.client_name, addData?.client_team_email, addData?.linkedin_account, addData?.manager_id, addData?.no_of_calls_completed, addData?.no_of_calls_expected, addData?.no_of_profiles_sent, addData?.overview, addData?.platform, addData?.project_deadline, addData?.project_start, addData?.project_title, addData?.remarks, addData?.status, currentPage, geographies, handleClose, inputs, selectedOptions, teamIdEdit, userData?.data?._id, userData?.token]);


  useEffect(() => {
    if (teamIdEdit) {
      fetchData(teamIdEdit);
      setLoading(false);
    } else {
      setAddData({
        project_title: "",
        project_start: null,
        project_deadline: null,
        client_name: "",
        overview: "",
      });
      setGeographies([{ key: 1, value: "" }]);
      setInputs([{ key: 1, value: "" }]);
      getExpertsOptions()
    }
  }, [fetchData, getExpertsOptions, teamIdEdit]);

  return (
    <Modal
      show={show}
      onHide={() => {
        handleClose()
      }}
      backdrop="static"
      keyboard={false}
      centered
      size={"lg"}
      scrollable={true}
      dialogClassName={"bootStrapModal"}
      contentClassName={"bootStrapModalContent"}
    >
      <Modal.Body className="position-relative">
        <MdOutlineClose
          className="position-absolute"
          style={{ right: "15px", top: "15px", fontSize: "25px" }}
          onClick={() => {
            setAddData({
              project_title: "",
              project_start: null,
              project_deadline: null,
              client_name: "",
              overview: "",
            });
            setGeographies([{ key: 1, value: "" }]);
            setInputs([{ key: 1, value: "" }]);
            handleClose()

          }}
        />
        <div className="text-center">
          <h4 className="modalHeader">Add New Project</h4>
        </div>

        <Form layout="vertical" autoComplete="off">
          <Row>
            <Col md={8}>
              <Form.Item label="Project Title" rules={[{ required: true }]}>
                <Input
                  type="text"
                  size="large"
                  // name="project_title"
                  value={addData?.project_title}
                  onChange={(event) =>
                    handleChange(event.target.value, "project_title")
                  }
                />
              </Form.Item>
            </Col>
            <Col md={4}>
              <div className=" mb-1" >
                <Form.Item
                  className="mb-0"
                  label="Project Status"
                  rules={[{ required: true }]}
                >
                  <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <Select
                      value={addData?.status}
                      style={{ flex: 1, marginRight: '8px' }}
                      // name={`geography${geo?.key}`}
                      size="large"
                      virtual={false}
                      allowClear
                      onChange={(selectedValue) => {
                        handleChange(selectedValue, "status")
                      }}
                      filterOption={(input, option) =>
                        option?.props?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {["Closed", "Active", "Hold"]?.map((status, index) => (
                        <Select.Option
                          key={index}
                          value={status?.toLowerCase()}
                        >
                          {status}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              {/* <Form.Item label="Client Name" rules={[{ required: true }]}>
                <Input
                  type="text"
                  size="large"
                  name="client_name"
                  value={addData?.client_name}
                  onChange={(event) =>
                    handleChange(event.target.value, "client_name")
                  }
                />
              </Form.Item> */}

              <Form.Item label="Client Name" className="mb-0">
                <AutoComplete
                  showSearch
                  onSearch={debounceFetcher}
                  name="client_name"
                  value={addData?.client_name}
                  placeholder="Select a client"
                  size="large"
                  className="input-select"
                  optionFilterProp="children"
                  onChange={(value) => handleChange(value, "client_name")}
                  virtual={false}
                  allowClear
                  filterOption={(input, option) =>
                    option?.props?.children
                      ?.toLowerCase()
                      .indexOf(input?.toLowerCase()) !== -1
                  }
                >
                  {allClient?.map((option) => (
                    <Option key={option?._id} value={option?._id}>
                      {option?._id}
                    </Option>
                  ))}
                </AutoComplete>

              </Form.Item>

            </Col>
            <Col>
              <Form.Item label="Client POC Email" rules={[{ required: true }]}>
                <Input
                  type="text"
                  size="large"
                  // name="project_title"
                  value={addData?.client_email}
                  onChange={(event) =>
                    handleChange(event.target.value, "client_email")
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item label="Client Team Emails" rules={[{ required: true }]}>
                <Input
                  type="text"
                  size="large"
                  value={addData?.client_team_email}
                  onChange={(event) => {
                    // const cleanInput = DOMPurify.sanitize(event.target.value);
                    handleChange(event.target.value, "client_team_email")
                  }
                  }
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label="Linkedin Account (Reachouts)" rules={[{ required: true }]}>
                <Input
                  type="text"
                  size="large"
                  value={addData?.linkedin_account}
                  onChange={(event) =>
                    handleChange(event.target.value, "linkedin_account")
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Item
                // name="project_start"
                label="Project Start Date"
                rules={[{ required: true }]}
              >

                <DatePicker
                  size="large"
                  style={{ width: "100%", zIndex: "1100" }}
                  value={
                    addData?.project_start
                      ? dayjs(addData?.project_start, "YYYY-MM-DD")
                      : null
                  }
                  selected={addData?.project_start}
                  disabledDate={(current) =>
                    current && current?.isAfter(dayjs(), "D")
                  }
                  format="YYYY-MM-DD"
                  name="project_start"
                  onChange={(value) => handleChange(value, "project_start")}
                />
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item
                // name="project_deadline"
                label="Project Deadline"
                rules={[{ required: true }]}
              >
                <DatePicker
                  size="large"
                  style={{ width: "100%", zIndex: "1100" }}
                  value={
                    addData?.project_deadline
                      ? dayjs(addData?.project_deadline, "YYYY-MM-DD")
                      : null
                  }
                  disabledDate={(current) => {
                    return addData?.project_start && current && current.isBefore(dayjs(addData?.project_start, "YYYY-MM-DD"), 'day')
                  }
                  }
                  format="YYYY-MM-DD"
                  name="project_deadline"
                  selected={addData?.project_deadline}
                  onChange={(value) => handleChange(value, "project_deadline")}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col className="projectEditor">
              <CKEditor
                editor={Editor}
                data={addData?.overview || ""} // Prefill CKEditor with fetched overview data
                onReady={(editor) => {
                  editorRef.current = editor;
                }}
                config={{
                  toolbar: [
                    "bold",
                    "italic",
                    "link",
                    "bulletedList",
                    "numberedList",
                  ],
                }}
                onChange={(event, editor) => {
                  const newData = editor.getData();
                  handleChange(newData, "overview");
                }}
              // onBlur={(editor) => {
              // }}
              // onFocus={(editor) => {
              // }}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col xs={12} md={6}>
              {geographies?.map((geo, index) => (
                <div className=" mb-1" key={geo?.key}>
                  <Form.Item
                    className="mb-0"
                    label="Geography of experts"
                    rules={[{ required: true }]}
                  >
                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                      <Select
                        showSearch
                        value={geo?.value}
                        style={{ flex: 1, marginRight: '8px' }}
                        name={`geography${geo?.key}`}
                        size="large"
                        virtual={false}
                        allowClear
                        onChange={(selectedValue) => {
                          const updatedGeographies = geographies?.map((g) =>
                            g.key === geo.key ? { ...g, value: selectedValue } : g
                          );
                          setGeographies(updatedGeographies);
                        }}
                        filterOption={(input, option) =>
                          option?.props?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {countryOptions?.map((country, index) => (
                          <Select.Option
                            key={index}
                            value={country?.name}
                            label={country?.name}
                          >
                            {`${country?.name}`}
                          </Select.Option>
                        ))}
                      </Select>

                      {index === 0 ? (
                        <AiOutlinePlusCircle
                          style={{
                            fontSize: '22px',
                            color: '#5746EB',
                            cursor: 'pointer',
                          }}
                          onClick={handleAddGeography}
                        />
                      ) : (
                        <AiOutlineMinusCircle
                          style={{
                            fontSize: '22px',
                            color: 'red',
                            cursor: 'pointer',
                          }}
                          onClick={() => handleRemoveGeography(geo.key)}
                        />
                      )}
                    </div>
                  </Form.Item>
                </div>
              ))}
            </Col>
            <Col xs={12} md={6}>
              {inputs?.map((input, index) => (
                <div className="mb-1" key={input?.key}>
                  <Form.Item
                    className="mb-0"
                    label="Invite Team"
                    rules={[{ required: true }]}
                  >
                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                      <Select
                        size="large"
                        name={`team${input?.key}`}
                        value={input?.value}
                        showSearch
                        onChange={(selectedValue) => {
                          const updatedInputs = inputs?.map((i) =>
                            i.key === input.key ? { ...i, value: selectedValue } : i
                          );
                          setInputs(updatedInputs);
                        }}
                        style={{ flex: 1, marginRight: '8px' }}
                        filterOption={(input, option) =>
                          option?.props?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        virtual={false}
                        allowClear
                      >
                        {managerList?.map((item, index) => (
                          <Select.Option
                            key={index}
                            value={item?.email?.split('@')[0]}
                            label={item?.email?.split('@')[0]}
                          >
                            {`${item?.email}`}
                          </Select.Option>
                        ))}
                      </Select>

                      {index === 0 ? (
                        <AiOutlinePlusCircle
                          style={{
                            fontSize: '22px',
                            color: '#5746EB',
                            cursor: 'pointer',
                          }}
                          onClick={handleAddInput}
                        />
                      ) : (
                        <AiOutlineMinusCircle
                          style={{
                            fontSize: '22px',
                            color: 'red',
                            cursor: 'pointer',
                          }}
                          onClick={() => handleRemoveInput(input.key)}
                        />
                      )}
                    </div>
                  </Form.Item>
                </div>
              ))}
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Item label="Platform used" rules={[{ required: true }]}>
                <Select
                  value={addData.platform}
                  style={{ flex: 1, marginRight: '8px' }}
                  // name={`geography${geo?.key}`}
                  size="large"
                  virtual={false}
                  allowClear
                  onChange={(selectedValue) => {
                    handleChange(selectedValue, "platform")

                  }}
                  filterOption={(input, option) =>
                    option?.props?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {["Dashboard", "Email"]?.map((status, index) => (
                    <Select.Option
                      key={index}
                      value={status?.toLowerCase()}
                    >
                      {status}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item label="Project Manager" rules={[{ required: true }]}>
                <DebounceSelect
                  type="manager"
                  optionType="email"
                  size="large"
                  name={`project_manager`}
                  value={addData?.manager_id}
                  placeholder="Select Managers"
                  style={{ flex: 1, marginRight: '8px' }}
                  virtual={false}
                  onChange={(selectedValue) => {
                    handleChange(selectedValue, "manager_id")
                  }}
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Form.Item label="No. of calls expected" rules={[{ required: true }]}>
                <Input
                  type="number"
                  size="large"
                  name="expected_calls"
                  value={addData?.no_of_calls_expected}
                  onChange={(event) =>
                    handleChange(event.target.value, "no_of_calls_expected")
                  }
                />
              </Form.Item>
            </Col> <Col md={4}>
              <Form.Item label="No. of profiles sent" rules={[{ required: true }]}>
                <Input
                  type="number"
                  size="large"
                  name="profile_sent"
                  value={addData?.no_of_profiles_sent}
                  onChange={(event) =>
                    handleChange(event.target.value, "no_of_profiles_sent")
                  }
                />
              </Form.Item>
            </Col> <Col md={4}>
              <Form.Item label="No. of calls completed" rules={[{ required: true }]}>
                <Input
                  type="number"
                  size="large"
                  name="calls_done"
                  value={addData?.no_of_calls_completed}
                  onChange={(event) =>
                    handleChange(event.target.value, "no_of_calls_completed")
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Form.Item label="Remarks" rules={[{ required: true }]}>
                <Input
                  type="text"
                  size="large"
                  // name="project_title"
                  value={addData?.remarks}
                  onChange={(event) =>
                    handleChange(event.target.value, "remarks")
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <div className="d-flex justify-content-end mt-3">
          <button
            className="secondaryBttn btn"
            onClick={() => {
              setAddData({
                project_title: "",
                project_start: null,
                project_deadline: null,
                client_name: "",
                overview: "",
              });
              setGeographies([{ key: 1, value: "" }]);
              setInputs([{ key: 1, value: "" }]);
              handleClose();
            }}
          >
            Close
          </button>
          <button
            className="viewButton btn ms-2"
            onClick={() => AddTeamProject()}
          >
            Submit {loading && <Spin size="small" indicator={<LoadingOutlined style={{
              color: "white",
            }} />} />}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default AddProjectModal;
