import $ from "jquery";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Helmet from "react-helmet";
import "react-toastify/dist/ReactToastify.css";
import API_PATH from "../../Constants/api-path";
import Sidebar from "../../components/Sidebar";
import ComonProjectTable from "./ComonProjectTable";
import { Segmented } from "antd";
import { Input } from "antd";
import queryString from "query-string";
import { useNavigate } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";

function Projects() {
  let queryParams = queryString.parse(window.location.search);
  const [projectListObj, setProjectListObj] = useState([]);
  const [holdprojectListObj, setHoldProjectListObj] = useState([]);
  const [completeprojectListObj, setCompleteProjectListObj] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(
    queryParams?.page !== undefined ? parseInt(queryParams.page) : 1
  );
  const [selectedOption, setSelectedOption] = useState("Live Projects");
  console.log("selectedOption: ", selectedOption);
  const [selectedOptions, setSelectedOptions] = useState({
    key: queryParams?.key !== undefined ? queryParams.key : "",
  });

  let history = useNavigate();
  document.title = "Nextyn -  Projects";
  const userData = JSON.parse(localStorage.getItem("userData"));
  const getliveProjectList = useCallback(
    async (page = null) => {
      setLoading(true);
      try {
        let object = {};
        if (selectedOptions?.key) {
          object.key = selectedOptions?.key;
        }
        const queryParams = new URLSearchParams(object);

        const res = await fetch(
          `${API_PATH.PROJECT_LIVE}/${userData?.data?.email}?page=${
            page === null ? currentPage : page
          }&limit=10&${queryParams?.toString()}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userData?.token}`,
            },
          }
        );

        const result = await res.json();
        if (result) {
          setProjectListObj(result);
          setLoading(false);
          //setAlldatas(result.count);
          console.log("result", result);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
    [currentPage, selectedOptions?.key, userData?.data?.email, userData?.token]
  );

  const getarchievedProjectList = useCallback(
    async (page = null) => {
      setLoading(true);
      try {
        let object = {};
        if (selectedOptions?.key) {
          object.key = selectedOptions?.key;
        }
        const queryParams = new URLSearchParams(object);

        const res = await fetch(
          `${API_PATH.PROJECT_ARCHIVED}/${userData?.data?.email}?page=${
            page === null ? currentPage : page
          }&limit=10&${queryParams?.toString()}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userData?.token}`,
            },
          }
        );

        const result = await res.json();
        if (result) {
          setHoldProjectListObj(result);
          setLoading(false);
          console.log(result);
          //setAlldatas(result.count);
          // console.log(projectListObj);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
    [currentPage, selectedOptions?.key, userData?.data?.email, userData?.token]
  );

  const getcompleteProjectList = useCallback(
    async (page = null) => {
      setLoading(true);
      try {
        let object = {};
        if (selectedOptions?.key) {
          object.key = selectedOptions?.key;
        }
        const queryParams = new URLSearchParams(object);

        const res = await fetch(
          `${API_PATH.PROJECT_COMPLETE}/${userData?.data?.email}?page=${
            page === null ? currentPage : page
          }&limit=10&${queryParams?.toString()}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userData?.token}`,
            },
          }
        );

        const result = await res.json();
        if (result) {
          setCompleteProjectListObj(result);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    },
    [currentPage, selectedOptions?.key, userData?.data?.email, userData?.token]
  );

  const constructUrl = React.useCallback(
    (options = null, pageNumber = null) => {
      let projectParam;
      let page_no;
      if (options === null) {
        const { key } = selectedOptions;
        projectParam = Array.isArray(key) ? key.join(", ") : key || "";
      } else {
        const { key } = options;
        projectParam = Array.isArray(key) ? key.join(", ") : key || "";
      }
      if (pageNumber === null) {
        page_no = currentPage;
      } else {
        page_no = pageNumber;
      }
      const clientPage = Array.isArray(page_no)
        ? page_no.join(", ")
        : page_no || 1;

      const urlParams = [
        `key=${encodeURIComponent(projectParam)}`,
        `page=${encodeURIComponent(clientPage)}`,
      ];

      return history({
        pathname: `/dashboard/projects`,
        search: `?${urlParams.join("&")}`,
      });
    },
    [currentPage, history, selectedOptions]
  );

  const handleOptionChange = useCallback(
    (option) => {
      setSelectedOption(option);
      setCurrentPage(1);
      constructUrl(null, 1);
      if (option === "Completed Projects") {
        getcompleteProjectList();
      } else if (option === "Live Projects") {
        getliveProjectList();
      } else {
        getarchievedProjectList();
      }
    },
    [
      constructUrl,
      getarchievedProjectList,
      getcompleteProjectList,
      getliveProjectList,
    ]
  );

  useEffect(() => {
    document.body.style.overflow = "";
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  $(window).scroll(function () {
    var scroll = $(window).scrollTop();
    if (scroll > 0) {
      $("#header").addClass("active");
    } else {
      $("#header").removeClass("active");
    }
  });

  const handlePageChange = useCallback(
    (pageNumber) => {
      setCurrentPage(pageNumber);
      constructUrl(selectedOptions, pageNumber);
      if (selectedOption === "Completed Projects") {
        getcompleteProjectList(pageNumber);
      } else if (selectedOption === "Live Projects") {
        getliveProjectList(pageNumber);
      } else {
        getarchievedProjectList(pageNumber);
      }
    },
    [
      constructUrl,
      getarchievedProjectList,
      getcompleteProjectList,
      getliveProjectList,
      selectedOption,
      selectedOptions,
    ]
  );

  useEffect(() => {
    let queryParams = queryString.parse(window.location.search);
    if (queryParams.page && !isNaN(parseInt(queryParams.page))) {
      setCurrentPage(parseInt(queryParams.page));
    } else {
      setCurrentPage(1);
    }
  }, []);

  const callOnce = useRef(false);
  useEffect(() => {
    if (!callOnce.current) {
      callOnce.current = true;
      getliveProjectList();
      // getarchievedProjectList();
      // getcompleteProjectList();
      if (selectedOptions) {
        constructUrl(selectedOptions, currentPage);
      }
    }
  }, [
    constructUrl,
    currentPage,
    getarchievedProjectList,
    getcompleteProjectList,
    getliveProjectList,
    selectedOptions,
  ]);

  const LiveProjectsPage = useCallback(() => {
    return (
      <>
        <div>
          <ComonProjectTable
            projectList={projectListObj?.data}
            load={loading}
            selectedOptions={selectedOptions}
            setSelectedOption={setSelectedOptions}
            currentPage={currentPage}
            countData={projectListObj?.countData}
            handlePageChange={handlePageChange}
          />
        </div>
      </>
    );
  }, [
    currentPage,
    handlePageChange,
    loading,
    projectListObj?.countData,
    projectListObj?.data,
    selectedOptions,
  ]);

  const CompletedProjectsPage = useCallback(() => {
    return (
      <>
        <div>
          <ComonProjectTable
            projectList={completeprojectListObj?.data}
            load={loading}
            selectedOptions={selectedOptions}
            setSelectedOption={setSelectedOptions}
            currentPage={currentPage}
            countData={completeprojectListObj?.countData}
            handlePageChange={handlePageChange}
          />
        </div>
      </>
    );
  }, [
    completeprojectListObj?.countData,
    completeprojectListObj?.data,
    currentPage,
    handlePageChange,
    loading,
    selectedOptions,
  ]);

  const ArchivedProjectsPage = useCallback(() => {
    return (
      <>
        <div>
          <ComonProjectTable
            projectList={holdprojectListObj?.data}
            load={loading}
            selectedOptions={selectedOptions}
            setSelectedOption={setSelectedOptions}
            currentPage={currentPage}
            countData={holdprojectListObj?.countData}
            handlePageChange={handlePageChange}
          />
        </div>
      </>
    );
  }, [
    currentPage,
    handlePageChange,
    holdprojectListObj?.countData,
    holdprojectListObj?.data,
    loading,
    selectedOptions,
  ]);

  const handleInputChange = useCallback((e) => {
    const inputValue = e.target.value;
    setSelectedOptions({
      key: inputValue,
    });
    setCurrentPage(1);
  }, []);

  return (
    <>
      {/* <MobileMenu /> */}
      {/* <AdminDashboard /> */}
      <Helmet>
        <title>{"Projects | Nextyn Platform"}</title>

        {/* <script src="//in.fw-cdn.com/30634560/343565.js" chat="true"></script> */}
      </Helmet>
      <div className="page-wrapper chiller-theme toggled">
        <div className="main-sec d-lg-flex">
          <div className="sidebar-menu" id="navAccordion">
            <Sidebar />
          </div>
        </div>
        <main className="body-total content-wrapper float-start w-100">
          <div>
            {/* <div className="d-block d-lg-block">
              <div className="top-bar-menu float-start" id="header">
                <TopMenu2 />
              </div>
            </div> */}

            <div className="bk-div float-start w-100">
              <div className="body-expart-div projects-pages">
                <div className="d-md-flex project-sec-1 top-ps-seection justify-content-between align-items-center m-queries">
                  <h2> My Projects </h2>

                  {/* <ul
                    className="nav mx-auto nav-pills mb-3 mt-4"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active"
                        id="pills-home-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-home"
                        type="button"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                      >
                        Live Projects
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="pills-profile-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-profile"
                        type="button"
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected="false"
                      >
                        Completed Projects
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="pills-contact-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-contact"
                        type="button"
                        role="tab"
                        aria-controls="pills-contact"
                        aria-selected="false"
                      >
                        Archived Projects
                      </button>
                    </li>
                  </ul> */}

                  <Segmented
                    size="large"
                    options={[
                      "Live Projects",
                      "Completed Projects",
                      "Archived Projects",
                    ]}
                    value={selectedOption}
                    onChange={handleOptionChange}
                  />

                  {/* <div className="create-btn">
                    <CreateProjectModal />
                  </div> */}

                  {/* <Change_Password /> */}
                </div>

                <div className="d-md-flex project-sec-2 top-ps-seection justify-content-between align-items-center m-queries d-md-none">
                  <div className="d-flex justify-content-between align-items-center">
                    <h2> My Projects </h2>

                    {/* <div className="create-btn">
                      <CreateProjectModal />
                    </div> */}
                  </div>

                  <Segmented
                    size="large"
                    options={[
                      "Live Projects",
                      "Completed Projects",
                      "Archived Projects",
                    ]}
                    value={selectedOption}
                    onChange={handleOptionChange}
                  />

                  {/* <Change_Password /> */}
                </div>
                <div>
                  <Input
                    placeholder="Search"
                    value={selectedOptions?.key}
                    onChange={handleInputChange}
                    prefix={<SearchOutlined />}
                    style={{ marginBottom: 16 }}
                    size="large"
                  />
                </div>

                <div className="tab-content" id="pills-tabContent">
                  {selectedOption === "Live Projects" && <LiveProjectsPage />}
                  {selectedOption === "Completed Projects" && (
                    <CompletedProjectsPage />
                  )}
                  {selectedOption === "Archived Projects" && (
                    <ArchivedProjectsPage />
                  )}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default Projects;
