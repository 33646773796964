import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import React, { useState } from 'react'
import API_PATH from "./Constants/api-path";
import axios from 'axios'
import moment from 'moment';
function CsvUpload() {
    const [file, setFile] = useState();
    const [array, setArray] = useState([]);
    console.log('array: ', array);

    const fileReader = new FileReader();

    const handleOnChange = (e) => {
        setFile(e.target.files[0]);
    };

    const userData = JSON.parse(localStorage.getItem("userData"));
    console.log('userData: ', userData);

    const uploadCsvData = async (body) => {
        console.log('body: ', body);
        await axios
            .post(API_PATH.CSV_UPLOAD_MEETING, body, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userData?.token}`,
                }
            }).then((res) => {
                setTimeout(() => {
                    notification.success({
                        message: "Meeting Data Uploaded Successfully",
                        icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
                        style: {
                            backgroundColor: "#2ecc71",
                            color: "#fff !important",
                            border: "1px solid #52c41a",
                        },
                        duration: 5,
                        placement: "topRight",
                    });
                }, 100);
            }).catch((err) => {
                console.log('err: ', err);

                setTimeout(() => {
                    notification.error({
                        message: err?.response?.data?.message,
                        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
                        style: {
                            backgroundColor: "#e74c3c",
                            color: "#fff",
                            border: "1px solid #c0392b",
                        },
                        duration: 5,
                        placement: "topRight",
                    });
                }, 100);
            });
    }

    const csvFileToArray = string => {
        // console.log('string: ', string);
        const csvHeader = string?.trim()?.slice(0, string.indexOf("\n")).split(",");
        const removeR = csvHeader?.join(',')?.split("\r")
        const csvHeaderNew = removeR[0]?.trim()?.slice(0, string.indexOf("\n")).split(",");
        const csvRows = string?.trim()?.slice(string.indexOf("\n") + 1).split("\n");
        // console.log('csvRows: ', csvRows);
        const array = csvRows?.filter((rows) => rows !== "")?.map(i => {
            const values = i.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);
            console.log('values: ', values);
            const obj = csvHeaderNew?.reduce((object, header, index) => {
                object[header] = values[index]?.split("\r")?.[0];
                return object;
            }, {});
            return obj;
        });
        //change date format
        let arrayWithModifiedData = array?.map((obj) => {
            console.log('obj: ', obj?.zoom_meeting_start_date, typeof obj?.zoom_meeting_start_date);
            return {
                ...obj,
                zoom_meeting_start_date: moment(obj?.zoom_meeting_start_date, 'DD-MM-YYYY').format("MM/DD/YYYY")
            }
        })
        setArray(arrayWithModifiedData);
        uploadCsvData(arrayWithModifiedData)
    };

    const handleOnSubmit = (e) => {
        e.preventDefault();

        if (file) {
            fileReader.onload = function (event) {
                const text = event.target.result;
                csvFileToArray(text);
            };

            fileReader.readAsText(file);
        }
    };

    const headerKeys = Object.keys(Object.assign({}, ...array));

    return (
        <div style={{ textAlign: "center" }}>
            <h1>INTERNAL MEETING</h1>
            <form>
                <input
                    type={"file"}
                    id={"csvFileInput"}
                    accept={".csv"}
                    onChange={handleOnChange}
                />

                <button
                    onClick={(e) => {
                        handleOnSubmit(e);
                    }}
                >
                    IMPORT CSV
                </button>
            </form>

            <br />

            <table>
                <thead>
                    <tr key={"header"}>
                        {headerKeys.map((key) => (
                            <th>{key}</th>
                        ))}
                    </tr>
                </thead>

                <tbody>
                    {array.map((item) => (
                        <tr key={item.id} className=''>
                            {Object.values(item).map((val) => (
                                <td style={{ padding: 10 }}>{val}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default CsvUpload