import {
  DatePicker,
  Drawer,
  Dropdown,
  Form,
  Input,
  Modal,
  Pagination,
  Space,
  Table,
  Tooltip,
} from "antd";
import React, { useMemo, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import {
  MdAdd,
  MdBorderColor,
  MdDehaze,
  MdDelete,
  MdOutlineCreditCard,
  MdSyncAlt,
} from "react-icons/md";
import { IoSettingsOutline } from "../../../Utils/Icons";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  setAddCreditModal,
  setCreditHistory,
  setDeleteModal,
  setSearch,
  setSelectedCompany,
  setSettingDrawer,
  setTransactionsDrawer,
} from "./Slice/creditManagement.slice";
import useCreditManagement from "./hooks/useCreditManagement";
import PaymentHistoryDrawer from "./Components/PaymentHistoryDrawer";
import AddCreditsModal from "./Components/AddCreditsModal";
import CreditsUsageSettingsDrawer from "./Components/CreditsUsageSettingsDrawer";
import TransactionDrawer from "./Components/TransactionDrawer";
import _ from "lodash";
function ClientTable() {
  const {
    deleteModal,
    company_list,
    selectedCompany,
    companyPagination,
    searchValue,
  } = useSelector((state) => state.creditManagement);
  const {
    getPaymentHistoryById,
    fetchCompanies,
    getCreditSettingById,
    getTransactionHistoryById,
  } = useCreditManagement();
  const dispatch = useDispatch();



  const items = [
    {
      label: (
        <div href="#" onClick={() => dispatch(setAddCreditModal(true))}>
          <Space>
            <MdAdd />
            Add Credits
          </Space>
        </div>
      ),
      key: "0",
    },
    {
      label: (
        <div
          href="#"
          onClick={() => {
            getPaymentHistoryById({
              company_id: selectedCompany?.company_details?._id,
            });
            dispatch(setCreditHistory(true));
          }}
        >
          <Space>
            <MdOutlineCreditCard />
            Payment History
          </Space>
        </div>
      ),
      key: "1",
    },
    {
      label: (
        <div
          href="#"
          onClick={() => {
            getTransactionHistoryById({
              company_id: selectedCompany?.company_details?._id,
            });
            dispatch(setTransactionsDrawer(true));
          }}
        >
          <Space>
            <MdSyncAlt />
            Transactions
          </Space>
        </div>
      ),
      key: "2",
    },
    {
      label: (
        <div
          href="#"
          onClick={() => {
            getCreditSettingById({
              company_id: selectedCompany?.company_details?._id,
            });
            dispatch(setSettingDrawer(true));
          }}
        >
          <Space>
            <IoSettingsOutline />
            Setting
          </Space>
        </div>
      ),
      key: "3",
    },
  ];

  const dataSource = company_list?.data?.map((data) => ({
    company: (
      <Tooltip title={data?.company_details?.companyName} color="#5746ec">
        {_.truncate(data?.company_details?.companyName, {
          length: 40,
          omission: "...",
        })}
      </Tooltip>
    ),
    total_users: data?.user_count,
    credits: data?.credits_data?.total ? data?.credits_data?.total : "NA", //"25/50",
    action: data,
  }));

  const columns = [
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
    },
    {
      title: "Total Users",
      dataIndex: "total_users",
      key: "total_users",
      width: "50px",
    },
    {
      title: "Credits",
      dataIndex: "credits",
      key: "credits",
      width: "150px",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "100px",
      render: (data) => (
        <div className="d-flex align-items-center gap-2">
          <Tooltip placement="top" title={"Add Credits"} color="#5746EB">
            <button className="viewBttnExpert" href="#" onClick={() => {
              dispatch(setSelectedCompany(data));
              dispatch(setAddCreditModal(true))
            }}>
              <Space>
                <MdAdd />
              </Space>
            </button>
          </Tooltip>
          <Tooltip placement="top" title={"Payment History"} color="#5746EB">

            <button
              className="viewBttnExpert"
              href="#"
              onClick={() => {
                dispatch(setSelectedCompany(data));
                getPaymentHistoryById({
                  company_id: data?.company_details?._id,
                });
                dispatch(setCreditHistory(true));
              }}
            >
              <Space>
                <MdOutlineCreditCard />
              </Space>
            </button>
          </Tooltip>
          <Tooltip placement="top" title={"Transactions"} color="#5746EB">

            <button
              className="viewBttnExpert"
              href="#"
              onClick={() => {
                dispatch(setSelectedCompany(data));
                getTransactionHistoryById({
                  company_id: data?.company_details?._id,
                });
                dispatch(setTransactionsDrawer(true));
              }}
            >
              <Space>
                <MdSyncAlt />
              </Space>
            </button>
          </Tooltip>
          <Tooltip placement="top" title={"Setting"} color="#5746EB">

            <button
              className="viewBttnExpert"
              href="#"
              onClick={() => {
                dispatch(setSelectedCompany(data));
                getCreditSettingById({
                  company_id: data?.company_details?._id,
                });
                dispatch(setSettingDrawer(true));
              }}
            >
              <Space>
                <IoSettingsOutline />
              </Space>
            </button>
          </Tooltip>
        </div>
        // <Dropdown
        //   menu={{
        //     items,
        //   }}
        //   trigger={["click"]}
        // >
        //   <span
        //     onClick={(e) => {
        //       e.preventDefault();
        //       dispatch(setSelectedCompany(data));
        //     }}
        //   >
        //     <Space size="middle">
        //       <MdDehaze style={{ cursor: "pointer" }} />
        //     </Space>
        //   </span>
        // </Dropdown>
      ),
    },
  ];

  const paginationSettings = useMemo(() => {
    return {
      current: companyPagination?.page,
      onChange: (index) => fetchCompanies({ page_no: index }),
      pageSize: companyPagination?.limit,
      total: company_list?.count || 0,
    };
  }, [
    companyPagination.limit,
    companyPagination.page,
    company_list?.count,
    fetchCompanies,
  ]);
  return (
    <div>
      <div className="bk-div float-start w-100">
        {" "}
        {/* <hr className="sp-hr" /> */}
        <div className="body-expart-div">
          <div className="d-flex justify-content-between align-items-center">
            <h2>Credit Management</h2>
          </div>
          <Input
            placeholder="Search"
            prefix={<SearchOutlined />}
            value={searchValue}
            onChange={(e) => {
              dispatch(setSearch(e.target.value));
              fetchCompanies({ search: e.target.value });
            }}
            style={{ marginBottom: 16 }}
            size="large"
          />
          <Table
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            scroll={{ x: 800 }}
            size="large"
          />
          <Pagination
            {...paginationSettings}
            showSizeChanger={false}
            showTotal={(callPage, range) =>
              `${range[0]}-${range[1]} of ${callPage || 0} items`
            }
            style={{ marginTop: 16, marginBottom: 16, textAlign: "right" }}
          />
        </div>
        {/* Create Meeting Modal */}
        {/* Delete Modal */}
        <Modal
          open={deleteModal}
          onOk={() => dispatch(setDeleteModal(false))}
          onCancel={() => dispatch(setDeleteModal(false))}
          width={800}
          centered
        >
          <Row>
            <Col>
              <div className="position-relative">
                <div className="text-left">
                  <h4 className="modalHeader">Delete Meeting</h4>
                </div>
                <div>
                  This action cannot be undone. Are you sure you want to delete
                  this meeting ?
                </div>
              </div>
            </Col>
          </Row>
        </Modal>
        {/* ADD CREDITS MODAL */}
        <AddCreditsModal />
        {/* PAYMENT HISTORY DRAWER */}
        <PaymentHistoryDrawer />
        {/* TRANSACTION DRAWER */}
        <TransactionDrawer />
        {/* SETTINGS DRAWER */}
        <CreditsUsageSettingsDrawer />
      </div>
    </div>
  );
}

export default ClientTable;
