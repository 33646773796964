import { Button, Form, Input, Select, Spin, notification } from "antd";
import React, { useRef, useState } from "react";
import { MdOutlineClose } from "react-icons/md";
import PhoneInput from "react-phone-input-2";
import { countryOptions } from "../../Constants/CountryOptions";
import { LoadingOutlined } from "@ant-design/icons";
import { Modal } from "react-bootstrap";
import { useEffect } from "react";
import API_PATH from "../../Constants/api-path";
import {
  ExclamationCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
function AddUserModal({
  userModal,
  setUserModal,
  clientView,
  GetClientList,
  viewClientModal,
}) {
  console.log("clientView: ", clientView);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [addUser, setAddUser] = useState({
    firstNmae: "",
    email: "",
    contact_code: "",
    contact_number: "",
    country: "",
    companyName: clientView?.[0]?.company,
    contact_number_full: "",
  });
  const [form] = Form.useForm();
  const [phone, setPhone] = useState("");
  const validateEmailDomain = (_, value) => {
    // Check if value is a valid email address
    let isValidEmail = value?.includes("@");

    if (value && isValidEmail) {
      return Promise.reject(`please provide the username only.`);
    }
    // Email is valid
    return Promise.resolve();
  };
  const callOnce = useRef(false);

  useEffect(() => {
    if (!callOnce.current && clientView?.length > 0) {
      callOnce.current = true;
      setAddUser({
        ...addUser,
        companyName: clientView?.[0]?.company,
      });
      form.setFieldsValue({
        ...addUser,
        companyName: clientView?.[0]?.company,
      });
    }
  }, [addUser, clientView, form]);

  useEffect(() => {
    form.setFieldsValue(addUser);
  }, [addUser, form]);
  console.log("FORM VALUES", form.getFieldsValue());

  const AddClientData = async () => {
    setLoading(true);

    let fullEmail = `${addUser?.email?.toLowerCase()?.split("@")?.[0]}@${
      clientView?.[0]?.companyDomain
    }`;
    console.log("fullEmail: ", fullEmail);

    const regEx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!regEx.test(fullEmail)) {
      return setMessage("Email is invalid");
    } else {
      setMessage("");
    }

    const clienData = {
      name: addUser?.firstNmae,
      email: fullEmail,
      comapnyName: addUser?.companyName,
      contact_number: addUser?.contact_number,
      country: addUser?.country,
      contact_code: addUser?.contact_code,
      // role_id:addUser?.firstNmae,
      registrationType: clientView?.[0]?.registrationType,
    };

    try {
      const res = await fetch(`${API_PATH.CLIENT_CREATE}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
        body: JSON.stringify(clienData),
      });

      const result = await res.json();
      if (result && (result.status === 200 || result.status === true)) {
        viewClientModal();
        GetClientList();
        notification.success({
          message: "Client created",
          icon: <CheckCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#2ecc71",
            color: "#fff",
            border: "1px solid #52c41a",
          },
          duration: 5,
          placement: "topRight",
        });
        setUserModal(false);
      } else if (result && result.message === "Email is already exists.") {
        notification.error({
          message: "Email is already exists.",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      console.log("Error when creating client", error);
      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  };
  return (
    <Modal
      show={userModal}
      onHide={() => {
        setUserModal(false);
        form.resetFields();
        setAddUser({
          firstNmae: "",
          email: "",
          contact_code: "",
          contact_number: "",
          country: "",
          companyName: clientView?.[0]?.company,
          contact_number_full: "",
        });
      }}
      backdrop="static"
      keyboard={false}
      centered
      size={"lg"}
      scrollable={true}
      dialogClassName={"bootStrapModal"}
      contentClassName={"bootStrapModalContent"}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={addUser}
        onFinish={async () => {
          await AddClientData();
          form.resetFields();
        }}
      >
        <Modal.Header>
          <MdOutlineClose
            className="position-absolute"
            style={{ right: "15px", top: "15px", fontSize: "25px" }}
            onClick={() => setUserModal(false)}
          />
          <div className="text-left">
            <h4 className="modalHeader">Add Users</h4>
          </div>
        </Modal.Header>
        <Modal.Body className="position-relative ">
          <div>
            <div className="projects-modal-divs UserModal">
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <Form.Item
                      label="Name"
                      name={"firstNmae"}
                      rules={[{ required: true, message: "name is required!" }]}
                    >
                      <Input
                        type="text"
                        name="firstNmae"
                        maxLength={40}
                        size="large"
                        width={"95%"}
                        placeholder="Name"
                        // value={addUser.firstNmae}
                        // onChange={handleInputs}
                        onChange={(e) => {
                          let obj = {
                            ...addUser,
                            firstNmae: e.target.value,
                          };
                          setAddUser(obj);
                          form.setFieldValue("firstNmae", e.target.value);
                        }}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group addRightInput">
                    <Form.Item
                      label="Email"
                      name="email"
                      maxLength={50}
                      rules={[
                        {
                          required: true,
                          message: "email is required!",
                        },
                        {
                          validator: validateEmailDomain, // Custom validator function
                        },
                      ]}
                    >
                      <Input
                        // type="email"
                        name="email"
                        size="large"
                        maxLength={50}
                        width={"95%"}
                        // value={addUser.email}
                        placeholder="Enter email address"
                        // onChange={handleInputs}
                        addonAfter={"@" + clientView?.[0]?.companyDomain}
                        onChange={(e) => {
                          let obj = {
                            ...addUser,
                            email: e.target.value,
                          };
                          setAddUser(obj);
                          form.setFieldValue("email", e.target.value);
                        }}
                      />
                    </Form.Item>
                  </div>
                  {message === "" ? (
                    <></>
                  ) : (
                    <p className="text-danger">{message}</p>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <Form.Item
                      label="Company Name"
                      name={"companyName"}
                      rules={[
                        {
                          required: true,
                          message: "Company name is required!",
                        },
                      ]}
                    >
                      <Input
                        type="text"
                        name="companyName"
                        maxLength={40}
                        size="large"
                        width={"95%"}
                        disabled
                        placeholder="Company Name"
                        // value={addUser.firstNmae}
                        // onChange={handleInputs}
                        onChange={(e) => {
                          let obj = {
                            ...addUser,
                            companyName: e.target.value,
                          };
                          setAddUser(obj);
                          form.setFieldValue("companyName", e.target.value);
                        }}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    {/* <label> Contact Number * </label> */}
                    <Form.Item
                      label="Contact Number"
                      name="contact_number_full"
                      rules={[
                        {
                          required: true,
                          message: "contact number is required!",
                        },
                      ]}
                    >
                      <PhoneInput
                        country={"us"}
                        name="contact_number_full"
                        enableSearch={true}
                        // value={phone}
                        onChange={(value, country) => {
                          setPhone(value);
                          const selectedCountry = countryOptions?.find(
                            (option) => option?.code === `+${country?.dialCode}`
                          );
                          if (selectedCountry) {
                            setAddUser((prevStep3) => ({
                              ...prevStep3,
                              contact_code: country?.dialCode,
                              contact_number: value?.replace(
                                country?.dialCode,
                                ""
                              ),
                              country: selectedCountry?.name,
                              contact_number_full: value,
                            }));
                          }
                          form.setFieldValue("contact_number_full", value);
                        }}
                        // onChange={(value, country) => {
                        //   handlePhoneChange(value, country);
                        // }}
                        inputStyle={{
                          width: "100%",
                        }}
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-end align-items-center">
          <Form.Item style={{ marginBottom: 0 }}>
            <Button
              key="back"
              type="button"
              onClick={() => {
                console.log("cancelling");
                setUserModal(false);
                form.resetFields();
                setAddUser({
                  firstNmae: "",
                  email: "",
                  contact_code: "",
                  contact_number: "",
                  country: "",
                  companyName: clientView?.[0]?.company,
                  contact_number_full: "",
                });
              }}
            >
              Cancel
            </Button>
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <Button
              type="submit"
              htmlType="submit"
              style={{
                backgroundColor: "rgb(76, 187, 23)",
                borderColor: "rgb(76, 187, 23)",
                color: "#fff",
              }}
              className=" ms-2"
            >
              Submit{" "}
              {loading && <Spin size="small" indicator={<LoadingOutlined />} />}
            </Button>
          </Form.Item>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default AddUserModal;
