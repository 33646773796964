import React from "react";
import { Helmet } from "react-helmet";
import Sidebar from "../../../components/Sidebar";
import InternalMeetingTable from "./InternalMeetingTable";
import InternalMeetingDrawer from "./Components/InternalMeetingDrawer";
function InternalMeeting() {
  return (
    <>
      {/* <MobileMenu /> */}
      <Helmet>
        <title>{"Nextyn | Internal Meeting"}</title>

        {/* <script src="//in.fw-cdn.com/30634560/343565.js" chat="true"></script> */}
      </Helmet>
      <div className="page-wrapper chiller-theme toggled">
        <div className="main-sec d-lg-flex">
          <Sidebar />
        </div>
        <main className="body-total content-wrapper float-start w-100">
          <div>
            <div className="d-block d-lg-block">
              <InternalMeetingTable />

            </div>
          </div>
        </main>
      </div>

    </>
  );
}

export default InternalMeeting;
