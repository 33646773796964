import React from "react";
import {
    makeStyles,
    Slider,
    withStyles,
} from "@material-ui/core";
import {
    VolumeUp,
    VolumeOff
} from "@material-ui/icons";
import "./Control.css";
import { BsFillFastForwardFill, BsFillRewindFill, BsPauseCircleFill, BsPlayCircleFill } from "react-icons/bs";

const useStyles = makeStyles({
    volumeSlider: {
        width: "100px",
        color: "#5746ec",
    },

    bottomIcons: {
        color: "#999",
        padding: "12px 8px",
        "&:hover": {
            color: "#5746ec",
        },
    },
});

const PrettoSlider = withStyles({
    root: {
        height: "20px",
        color: "#5746ec",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    thumb: {
        height: 20,
        width: 20,
        backgroundColor: "#5746ec",
        border: "2px solid currentColor",
        marginTop: -3,
        marginLeft: -12,
        "&:focus, &:hover, &$active": {
            boxShadow: "inherit",
        },
    },
    active: {},
    valueLabel: {
        left: "calc(-50% + 4px)",
    },
    track: {
        height: 5,
        borderRadius: 4,
        width: "100%",
    },
    rail: {
        height: 5,
        borderRadius: 4,
        backgroundColor: "grey"
    },
})(Slider);

const Control = ({
    onPlayPause,
    playing,
    onRewind,
    onForward,
    played,
    onSeek,
    onSeekMouseUp,
    onVolumeChangeHandler,
    onVolumeSeekUp,
    volume,
    mute,
    onMute,
    duration,
    currentTime,
    onMouseSeekDown,
    controlRef
}) => {
    const classes = useStyles();


    return (
        <div className="control_Container" ref={controlRef}>
            <div className="bottom__container">
                <div className="slider__container gap-3">
                    <span className="timing">{currentTime}</span>
                    <PrettoSlider
                        min={0}
                        max={100}
                        value={played * 100}
                        onChange={onSeek}
                        onChangeCommitted={onSeekMouseUp}
                        onMouseDown={onMouseSeekDown}
                    />
                    <span className="timing"> {duration}</span>
                </div>
                <div className="control__box">
                    <div className="inner__controls">
                        <div className="icon__btn" onClick={onRewind}>
                            <BsFillRewindFill fontSize="large" />
                        </div>
                        <div className="icon__btn" onClick={onPlayPause}>
                            {playing ? (
                                <BsPauseCircleFill fontSize="medium" />
                            ) : (
                                <BsPlayCircleFill fontSize="medium" />
                            )}{" "}
                        </div>

                        <div className="icon__btn" onClick={onForward} >
                            <BsFillFastForwardFill fontSize="medium" />
                        </div>

                        <div className="icon__btn" onClick={onMute}>
                            {mute ? (
                                <VolumeOff fontSize="medium" />
                            ) : (
                                <VolumeUp fontSize="medium" />
                            )}
                        </div>

                        <div className="flex__slider">

                            <span className="slider__color">
                                <Slider
                                    className={`${classes.volumeSlider}`}
                                    onChange={onVolumeChangeHandler}
                                    value={volume * 100}
                                    onChangeCommitted={onVolumeSeekUp}
                                />
                            </span>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default Control;