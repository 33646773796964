import React from "react";
import { Modal } from "react-bootstrap";
import { MdOutlineClose } from "react-icons/md";
import { notification } from "antd";
import API_PATH from "../../Constants/api-path";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";


function DeleteModal({ show, setShow, type, teamId, GetLiveProjectList = () => { }, GetCompleteProjectList = () => { } }) {
  const userData = JSON.parse(localStorage.getItem("userData"));



  const DeleteManager = async () => {
    // e.preventDefault();
    try {
      const res = await fetch(`${API_PATH.TEAM_PROJECT_ADD}/${teamId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },

      });

      const result = await res.json();
      if (result) {
        if (res.status === 200 || res.status === true) {
          GetLiveProjectList();
          GetCompleteProjectList();
          setShow(false)
          notification.success({
            message: "Project has been deleted",
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#2ecc71",
              color: "#fff !important",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          });
        }
      }
    } catch (error) {
      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      backdrop="static"
      keyboard={false}
      centered
      size={"lg"}
      scrollable={true}
      dialogClassName={"bootStrapModal"}
      contentClassName={"bootStrapModalContent"}
    >
      <Modal.Body className="position-relative">
        <MdOutlineClose
          className="position-absolute"
          style={{ right: "15px", top: "15px", fontSize: "25px" }}
          onClick={() => setShow(false)}
        />
        <div className="text-left">
          <h4 className="modalHeader">Delete {type}</h4>
        </div>
        <div>
          This action cannot be undone. Are you sure you want to delete this{" "}
          {type.toLowerCase()} ?
        </div>
        <div className="d-flex justify-content-end mt-3">
          <button className="secondaryBttn btn" onClick={() => setShow(false)}>
            No
          </button>
          <button className="viewButton btn ms-2" onClick={() => DeleteManager()}>Yes</button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default DeleteModal;
