import { Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import API_PATH from "../../Constants/api-path";
import AvatarGroup from "react-avatar-group";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import RingVolumeOutlinedIcon from "@mui/icons-material/RingVolumeOutlined";
import PersonAddAlt1OutlinedIcon from "@mui/icons-material/PersonAddAlt1Outlined";
import _ from "lodash";
import { MdCalendarToday } from "react-icons/md";

const onChange = (pagination, filters, sorter, extra) => {
  // console.log("params", pagination, filters, sorter, extra);
};
const CurrentProjects = () => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  // console.log(userData?.data?.email, "userEmail");
  const [loading, setLoading] = useState(false);
  const [projectListObj, setProjectListObj] = useState("");

  const getliveProjectList = async () => {
    setLoading(false);
    try {
      const res = await fetch(
        `${API_PATH.PROJECT_LIVE}/${userData?.data?.email}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          },
        }
      );

      const result = await res.json();
      if (result) {
        setLoading(true);
        setProjectListObj(result?.data);
        //setAlldatas(result.count);
        // console.log(result, "resultData");
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    getliveProjectList();
  }, []);

  function CalculatePercentage(total, percentage) {
    const completedCalls = parseFloat(total);

    return parseInt((percentage / completedCalls) * 100);
  }

  const convertDate = (date) => {
    //console.log(date)
    if (date !== null && date !== "") {
      var d = new Date(date);
      // Add 1 day to the date
      d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);

      var day = d.getDate();
      var x = d.toDateString().substr(4, 3);
      var year = d.getFullYear();
      let p = `${x} ${day}, ${year}`;
      //console.log(p)
      return p;
    } else {
      return "---------";
    }
  };

  function Calculate_days(date1) {
    var currentDate = new Date(date1);
    currentDate.setDate(currentDate.getDate() + 1); // Add 1 day to the current date

    var diff = new Date().getTime() - currentDate.getTime();
    var days = Math.floor(diff / (1000 * 60 * 60 * 24));

    if (days !== 0) {
      if (days < 0) {
        return Math.abs(days) + " " + "days left";
      } else {
        return days + " " + "days ago";
      }
    } else {
      return "Today";
    }
  }

  function getTeam(arr) {
    var arr1 = [];
    for (var x in arr) {
      arr1.push(arr[x]);
    }
    // console.log(arr1, "arr1");
    return arr1;
  }

  const columns = [
    {
      title: "SNo",
      render: (text, record, rowIndex) => rowIndex + 1,
      width: "50px",
    },
    {
      title: "Client",
      dataIndex: "companyName",
      key: "companyName",
      width: "100px",
      render: (text, record) => (
        <>
          <Tooltip
            title={record?.client?.companyData[0]?.name}
            color="rgb(87, 70, 236)"
          >
            <p className="company_Title">
              {_.truncate(record?.client?.companyData[0]?.name || "-", {
                length: 25,
                omission: "...",
              })}
            </p>
          </Tooltip>
        </>
      ),
    },
    {
      title: "PROJECT NAME",
      dataIndex: "project_title",
      key: "name",
      render: (text, record) => {
        // Truncate the project title to 6 words

        return (
          <>
            <Tooltip title={record?.project_title} color="#5746EB">
              <p
                className="projectTitle"
                title={record?.project_title}
                style={{ cursor: "pointer" }}
              >
                {_.truncate(record?.project_title, {
                  length: 40,
                  omission: "...",
                }) || "-"}
              </p>
            </Tooltip>
            <div className="d-flex">
              <span
                className="text-muted createdDate"
                style={{ fontSize: "12px" }}
              >
                <MdCalendarToday className="me-2" />
                {convertDate(record?.created_at)}
              </span>
            </div>
          </>
        );
      },
    },
    {
      title: "PROGRESS",
      // dataIndex: "percentage",
      key: "name",
      width: "150px",
      render: (render, record) => (
        <>
          <div className="progress" style={{ cursor: "pointer" }}>
            <div
              className="progress-bar"
              style={{
                width: `${CalculatePercentage(
                  render?.expected_calls,
                  render?.completed_Calls
                )}%`,
              }}
              role="progressbar"
              aria-valuenow="0"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
          <ul className="d-flex justify-content-center align-items-center list-unstyled mt-1">
            <li className="me-3">
              <span>
                <LocalPhoneOutlinedIcon style={{ fontSize: "18px" }} /> :
              </span>
              {render?.expected_calls ? render?.expected_calls : 0}
            </li>

            <li className="me-3">
              <span>
                <PersonAddAlt1OutlinedIcon style={{ fontSize: "18px" }} /> :
              </span>
              {render?.expected_Submitted ? render?.expected_Submitted : 0}
            </li>

            <li>
              <span>
                <RingVolumeOutlinedIcon style={{ fontSize: "18px" }} /> :
              </span>

              {render?.completed_Calls
                ? render?.completed_Calls
                : render?.completed_add_call
                  ? render?.completed_add_call
                  : 0}
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "TEAM",
      // dataIndex: "team_count",
      key: "name",
      width: "100px",
      render: (record) => (
        <>
          {/* {console.log("team", record.invite_team_dashboard)} */}
          {/* {record?.invite_team_dashboard?.split(",")?.map} */}
          <AvatarGroup
            avatars={record?.invite_team_dashboard?.split(",")}
            uppercase={true}
            initialCharacters={2}
            fontColor="#f33636"
            max={2}
            size={30}
            fontSize=".38"
            displayAllOnHover={false}
            backgroundColor="#EFEFEF"
          />
        </>
      ),
    },
    {
      title: "DEADLINE",
      // dataIndex: "team_count",
      key: "name",
      width: "100px",
      render: (record) => (
        <>
          <div style={{ cursor: "pointer" }}>
            <p>{convertDate(record?.project_deadline)}</p>
            <p className="text-muted" style={{ fontSize: "12px" }}>
              {" "}
              {Calculate_days(record?.project_deadline)}{" "}
            </p>
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="dasb-div bk-div float-start w-100">
        <div className="body-expart-div">
          <p className="fs-5 mb-3">
            <strong style={{ color: "#5746ec" }}>Current/Live Projects</strong>
          </p>
          <Table
            columns={columns}
            dataSource={projectListObj?.slice(0, 5)}
            onChange={onChange}
            className="paddTable"
            pagination={false}
            scroll={{ x: 800 }}
            loading={loading}
          />
        </div>
      </div>
    </>
  );
};
export default CurrentProjects;
