import { Form, Input, Select, Radio } from "antd";
import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import dayjs from "dayjs";
import customParseFormat from "dayjs/esm/plugin/customParseFormat";
import { projectSpecific, projectSubType } from "../../Constants/ProjectTypeOptions";
import ExpertAuth from "../../expertDashboard/components/ExpertAuth";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";

dayjs.extend(customParseFormat);


function ProjectDetails({ projectDetails }) {
    const [selectedIndustOptions,] = useState([]);
    const [form] = Form.useForm();

    const [step3, setStep3] = useState([{
        project_name: null,
        project_scope: null,
        project_description: null,
        industry: [],
        project_type: null,
        project_sub_type: null,
        project_specific: null,
        project_duration: null,
        size_of_organization: null,
        country: {},
        city: "",
        function: ""

    }]);

    const [projectSubTypeOptions, setProjectSubTypeOptions] = useState([])
    const [projectSpecificOptions, setProjectSpecificOptions] = useState([])

    const experience = [
        { key: 1, label: 1, value: 1 },
        { key: 2, label: 2, value: 2 },
    ];

    for (let i = 3; i <= 70; i++) {
        experience.push({ key: i, label: i, value: i });
    }

    useEffect(() => {
        let data = projectDetails?.map((project) => {
            return {
                project_name: project?.project_name,
                project_scope: project?.project_scope,
                project_description: project?.project_description,
                project_duration: project?.project_duration,
                industry: project?.project_industry,
                project_type: project?.project_type,
                project_sub_type: project?.project_sub_type,
                project_specific: project?.project_specific,
                size_of_organization: project?.size_of_organization,
                country: project?.project_country || {},
                city: project?.project_city || "",
                function: project?.function || ""
            }
        })
        setStep3(data);
        let projectSubTypeArr = []
        let projectSpecificArr = []
        projectDetails?.forEach((proj, index) => {
            let newArrSub = [...projectSubTypeArr, [index] = projectSubType[proj?.project_type]]
            let newArrSpecific = [...projectSpecificArr, [index] = projectSpecific[proj?.project_sub_type]]
            projectSpecificArr = newArrSpecific
            projectSubTypeArr = newArrSub
        })
        setProjectSubTypeOptions(projectSubTypeArr)
        setProjectSpecificOptions(projectSpecificArr)

        const initialValues = projectDetails?.reduce((acc, input, index) => {
            acc[index] = {
                project_name: input?.project_name,
                project_scope: input?.project_scope,
                project_description: input?.project_description,
                project_duration: input?.project_duration,
                industry: input?.project_industry,
                project_type: input?.project_type,
                project_sub_type: input?.project_sub_type,
                project_specific: input?.project_specific,
                size_of_organization: input?.size_of_organization,
                country: input?.project_country || {},
                city: input?.project_city || "",
                function: input?.function || ""
            };
            return acc
        }, {})

        form.setFieldsValue({
            ...initialValues,
        });
    }, [form, projectDetails]);

    return (
        <React.Fragment>
            <ExpertAuth />

            <div className="expertRegistration step1">
                <div className="">
                    <Form layout="vertical" autoComplete="off" form={form}>
                        {step3?.map((step3input, index) => {
                            return <div key={index} className="project-details-container">
                                <h3>Project {index + 1}</h3>
                                <Row>
                                    <Col xs={12} md={12}>
                                        <Form.Item
                                            name={[index, "project_type"]}
                                            label="Project Type"

                                            shouldUpdate={(prevValues, currentValues) => {
                                                return prevValues[index]?.project_type !== currentValues[index]?.project_type
                                            }}
                                        >
                                            <Input
                                                size="large"
                                                allowClear
                                                readOnly
                                                value={step3input.project_type} />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                {projectSubTypeOptions[index]?.length > 0 && <Row>
                                    <Col xs={12} md={12}>
                                        <Form.Item
                                            // name="project_sub_type"
                                            name={[index, "project_sub_type"]}
                                            label="Project Sub Type"
                                            shouldUpdate={(prevValues, currentValues) => {
                                                return prevValues[index]?.project_sub_type !== currentValues[index]?.project_sub_type
                                            }}

                                        >
                                            <Input
                                                size="large"
                                                name="project_sub_type"
                                                allowClear
                                                readOnly
                                                placeholder="Select Sub Type"
                                                value={step3input.project_sub_type} />
                                        </Form.Item>
                                    </Col>
                                </Row>}
                                {projectSpecificOptions[index]?.length > 0 && <Row>
                                    <Col xs={12} md={12}>
                                        <Form.Item
                                            // name="project_specific"
                                            name={[index, "project_specific"]}
                                            label="Project Specific"
                                            shouldUpdate={(prevValues, currentValues) => {
                                                return prevValues[index]?.project_specific !== currentValues[index]?.project_specific
                                            }}

                                        >
                                            <Input
                                                size="large"
                                                name="project_specific"
                                                allowClear
                                                readOnly
                                                placeholder="Select Specific"
                                                value={step3input.project_specific} />
                                        </Form.Item>
                                    </Col>
                                </Row>}

                                <Row>
                                    <Col xs={12} md={12}>
                                        <Form.Item
                                            // name="industry"
                                            name={[index, "industry"]}
                                            shouldUpdate={(prevValues, currentValues) => {
                                                console.log("came here inspite doing nothing", prevValues[index]?.industry !== currentValues[index]?.industry);
                                                return prevValues[index]?.industry !== currentValues[index]?.industry
                                            }}
                                            label="Industry of the Project"

                                        >
                                            <Input
                                                style={{
                                                    width: "100%",
                                                }}
                                                name="industry"
                                                readOnly
                                                size="large"
                                                mode="tags"
                                                value={selectedIndustOptions[index]} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={12}>
                                        <Form.Item
                                            // name="project_name"
                                            name={[index, "project_name"]}
                                            shouldUpdate={(prevValues, currentValues) => {
                                                return prevValues[index]?.project_name !== currentValues[index]?.project_name
                                            }}
                                            label="Project Title"

                                        >
                                            <Input
                                                name="project_name"
                                                placeholder="Project Title"
                                                size="large"
                                                readOnly
                                                type="text"
                                                value={step3input?.project_name}
                                            />
                                        </Form.Item>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <Form.Item
                                            name={[index, "project_description"]}
                                            shouldUpdate={(prevValues, currentValues) => {
                                                console.log("came here also");
                                                return prevValues[index]?.project_description !== currentValues[index]?.project_description
                                            }
                                            }
                                            label="Project Summary"
                                        >

                                            <Col className="projectEditor mt-2 min-h-150">
                                                <CKEditor
                                                    editor={Editor}
                                                    data={step3input?.project_description}
                                                    disabled
                                                    config={{
                                                        toolbar: [
                                                            "bold",
                                                            "italic",
                                                            "link",
                                                            "bulletedList",
                                                            "numberedList",
                                                        ],
                                                        placeholder:
                                                            "Please describe in as much detail what the project entailed and what your specific roles and responsibilities in achieving the project adjective",
                                                        // extraPlugins: 'autogrow',
                                                        // autoGrow_bottomSpace: 0,
                                                        autoGrow_minHeight: 800, // Set a fixed minimum height
                                                        autoGrow_maxHeight: 500, // Set a fixed maximum height
                                                    }}


                                                />

                                            </Col>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <Form.Item
                                            name={[index, "project_duration"]}
                                            label="Project Duration"

                                        >
                                            <Radio.Group name="project_duration" value={step3input.project_duration}  >
                                                <Radio.Button disabled={step3input.project_duration !== "0-6"} className="experianceButton" value="0-6">0 - 6 Months</Radio.Button>
                                                <Radio.Button disabled={step3input.project_duration !== "6-12"} className="experianceButton" value="6-12">6 - 12 Months</Radio.Button>
                                                <Radio.Button disabled={step3input.project_duration !== "12-24"} className="experianceButton" value="12-24">12 - 24 Months</Radio.Button>
                                                <Radio.Button disabled={step3input.project_duration !== "24"} className="experianceButton" value="24">24 + Months</Radio.Button>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={12}>
                                        <Form.Item
                                            name={[index, "size_of_organization"]}
                                            shouldUpdate={(prevValues, currentValues) => {
                                                return prevValues[index]?.size_of_organization !== currentValues[index]?.size_of_organization
                                            }}
                                            label="Size of Organization"

                                        >
                                            <Input
                                                size="large"
                                                name="size_of_organization"
                                                readOnly
                                                value={step3input.size_of_organization} />
                                        </Form.Item>
                                    </Col>
                                </Row>


                                <Form.Item
                                    label={
                                        <span className="">Project Location</span>
                                    }

                                >
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <Form.Item
                                                // name="country"
                                                name={[index, "country"]}
                                                shouldUpdate={(prevValues, currentValues) => {
                                                    return prevValues[index]?.country !== currentValues[index]?.country
                                                }}
                                                label={
                                                    <span className="">Country</span>
                                                }

                                            >
                                                <Input
                                                    name="country"
                                                    size="large"
                                                    labelInValue
                                                    readOnly
                                                    value={step3input?.country}

                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Item
                                                name={[index, "city"]}
                                                shouldUpdate={(prevValues, currentValues) => {
                                                    return prevValues[index]?.city !== currentValues[index]?.city
                                                }}
                                                label={
                                                    <span className="">City</span>
                                                }

                                            >
                                                <Input
                                                    name="city"
                                                    size="large"
                                                    labelInValue
                                                    readOnly
                                                    value={step3input?.city}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </div>
                        })}
                    </Form>
                </div>
            </div>
        </React.Fragment >
    );
}

export default ProjectDetails;
