import Aos from "aos";
import "aos/dist/aos.css";
import React from "react";
import { NavLink } from "react-router-dom";
import nextyn_logo from "../../assests/images/NX19.1.png";
import "./Login.css";
import LoginForm from "./LoginForm";
import nextyn_mob_logo from "../../assests/images/NX19.png";
import { Col, Row } from "react-bootstrap";

function Login() {
  Aos.init({
    offset: 100,
    easing: "ease",
    delay: 0,
    once: true,
    duration: 800,
  });

  document.title = "Login";
  return (
    <>
      <div className="login-page">
        <div className="row row-cols-1 row-cols-lg-2">
          <div className="col d-none d-lg-block">
            <div className="login-left-part">
              <Row className="justify-content-center">
                <Col xs={11} className="mt-3">
                  <NavLink to="/admin-login" className="logo">
                    <img
                      src={nextyn_logo}
                      alt="logo"
                      style={{ maxWidth: "30%" }}
                    />
                  </NavLink>
                </Col>
                <Col lg={8} className="logo-in-div">
                  <h1
                    className="text-left text-white loginText"
                    data-aos="fade-down"
                  >
                    The easiest way for businesses to collaborate with the
                    world's leading experts.
                  </h1>
                </Col>

                <Col lg={8}>
                  <div className="img-box-1 mt-4">
                    <img src="images/Login.gif" alt="login_gif"></img>
                  </div>
                </Col>
              </Row>
            </div>
          </div>

          <div className="position-relative col">
            <div className="row justify-content-center align-items-lg-center scrollableCls">
              <div className="comon-fild col-lg-8">
                <div className="inside-login registerPage">
                  <NavLink to="/admin-login" className="logo d-block d-lg-none">
                    <img src={nextyn_mob_logo} alt="logo" />
                  </NavLink>
                  <h3>Login</h3>
                  <p style={{ color: "#5746ec" }}>You're just one step away!</p>
                  <LoginForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Login;
