import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isOpen: false,
    searchTextQC: "",
    selectedEmails: [],
    selectedRowKeys: [],
    qcExpertList: [],
    callPage: 0,
    formValues: { phantomKey: "" },
    errorProfileList: { data: [], count: 0 },
    errorProfilePagination: { page: 1, limit: 10 }
}

const qcexpertSlice = createSlice({
    name: "qcexpert",
    initialState: initialState,
    reducers: {
        setIsOpen: (state, { payload }) => {
            state.isOpen = payload
        },
        setErrorProfileList: (state, { payload }) => {
            state.errorProfileList = payload
        },
        setErrorProfilePagination: (state, { payload }) => {
            state.errorProfilePagination = payload
        },
        setSearchTextQC: (state, { payload }) => {
            state.searchTextQC = payload
        },
        setSelectedEmails: (state, { payload }) => {
            state.selectedEmails = payload
        },
        setSelectedRowKeys: (state, { payload }) => {
            state.selectedRowKeys = payload
        },
        setFormValues: (state, { payload }) => {
            state.formValues = payload
        },
        setQcExpertList: (state, { payload }) => {
            state.qcExpertList = payload
        },
        setCallPage: (state, { payload }) => {
            state.callPage = payload
        }
    }
})

export const qcexpertReducer = qcexpertSlice.reducer
export const { setIsOpen, setErrorProfileList, setErrorProfilePagination, setSearchTextQC, setSelectedEmails, setSelectedRowKeys, setFormValues, setQcExpertList, setCallPage } = qcexpertSlice.actions