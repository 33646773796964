import React, { useState } from "react";

export const UserContext = React.createContext(null);

export const UserProvider = ({ children }) => {
  const [currentStep, setCurrentStep] = useState(null);
  const [registrationType, setRegistrationType] = useState(1);
  const [currentStepConsultant, setCurrentStepConsultant] = useState(null);
  const values = {
    currentStep,
    currentStepConsultant,
    registrationType,
    setCurrentStepConsultant,
    setCurrentStep,
    setRegistrationType
  };
  return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
};

export default function useStep() {
  const context = React.useContext(UserContext);

  if (context === undefined) {
    throw new Error("something went wrong");
  }

  return context;
}
