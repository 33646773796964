import React from 'react'
import { formatNumber } from '../../../Utils/util'

const StatisticContainer = ({ record }) => {
    return (
        <span className="d-flex flex-column gap-1">
            <span style={{ fontSize: "12px" }} className='stats-container'>
                <span>Profiles Sent </span>
                <span><strong>{formatNumber(record?.no_of_profiles_sent)}</strong></span>
            </span>
            <span style={{ fontSize: "12px" }} className='stats-container'>
                <span>Calls Completed </span>
                <span>
                    <strong>{formatNumber(record?.no_of_calls_completed)}</strong>
                </span>
            </span>
            <span style={{ fontSize: "12px" }} className='stats-container'>
                <span>
                    Expected Calls
                </span>
                <span>
                    <strong>{formatNumber(record?.no_of_calls_expected)}</strong>
                </span>
            </span>
            <span style={{ fontSize: "12px" }} className='stats-container'>
                <span>
                    Fill Rate
                </span>
                <span>
                    <strong>
                        {formatNumber(
                            ((record?.no_of_profiles_sent || 0) /
                                (record?.no_of_calls_expected || 0)) *
                            100
                        )}
                        %
                    </strong>
                </span>
            </span>
            <span style={{ fontSize: "12px" }} className='stats-container'>
                <span className='me-2'>
                    Expected Calls Completed
                </span>
                <span>
                    <strong>
                        {formatNumber(
                            ((record?.no_of_calls_completed || 0) /
                                (record?.no_of_calls_expected || 0)) *
                            100
                        )}
                        %
                    </strong>
                </span>
            </span>
        </span>
    )
}

export default StatisticContainer