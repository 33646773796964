import {
  CheckOutlined,
  DeleteOutlined,
  EllipsisOutlined,
  SearchOutlined,
  UploadOutlined,
  ExclamationCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Dropdown,
  Input,
  Menu,
  Modal,
  Pagination,
  Select,
  Space,
  Table,
  Upload,
  message,
  DatePicker,
  Tooltip,
  notification,
} from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import API_PATH from "../../Constants/api-path";
import "./CallRecords.css";
import SelectionOption from "./SelectionOption";
import moment from "moment";
import dayjs from "dayjs";
import { MdDelete } from "react-icons/md";
import { LuPlus } from "react-icons/lu";

const { Option } = Select;

const items = [
  {
    key: "1",
    label: (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.antgroup.com"
      >
        15 min.
      </a>
    ),
  },
  {
    key: "2",
    label: (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.aliyun.com"
      >
        30 min.
      </a>
    ),
  },
  {
    key: "3",
    label: (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.luohanacademy.com"
      >
        45 min.
      </a>
    ),
  },
  {
    key: "4",
    label: (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.luohanacademy.com"
      >
        60 min.
      </a>
    ),
  },
  {
    key: "5",
    label: (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.luohanacademy.com"
      >
        75 min.
      </a>
    ),
  },
  {
    key: "6",
    label: (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.luohanacademy.com"
      >
        90 min.
      </a>
    ),
  },
  {
    key: "7",
    label: (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.luohanacademy.com"
      >
        105 min.
      </a>
    ),
  },
  {
    key: "8",
    label: (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.luohanacademy.com"
      >
        120 min.
      </a>
    ),
  },
  {
    key: "9",
    label: (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.luohanacademy.com"
      >
        135 min.
      </a>
    ),
  },
];

const dataSource = [
  {
    key: "1",
    sr: "1",
    date: "10 April, 2023",
    project_name: "Teachers' employment & teacher rights",
    expert_name: "Nextyn Advisory Private Limited",
    company_name: "Norasazly Mohd Taha",
    client_name: "Demo Account",
    client_rate: "475",
    expert_rate: "238",
    call_duration: (
      <>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
        >
          <Button
            style={{
              color: "#5748f6",
              background: "white",
              border: "1px solid",
            }}
          >
            60 min.
          </Button>
        </Dropdown>
      </>
    ),
    invoice: (
      <>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
        >
          <Button
            style={{
              color: "#8a2227",
              background: "white",
              border: "1px solid",
            }}
          >
            Not Sent
          </Button>
        </Dropdown>
      </>
    ),
    client_payment: (
      <>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
        >
          <Button
            style={{
              color: "#8a2227",
              background: "white",
              border: "1px solid",
            }}
          >
            No
          </Button>
        </Dropdown>
      </>
    ),
    expert_payment: (
      <>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
        >
          <Button
            style={{
              color: "#8a2227",
              background: "white",
              border: "1px solid",
            }}
          >
            No
          </Button>
        </Dropdown>
      </>
    ),
    payment_mode: (
      <>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
        >
          <Button
            style={{
              color: "#c40a36",
              background: "white",
              border: "1px solid",
            }}
          >
            Select Payment
          </Button>
        </Dropdown>
      </>
    ),
    payment_date: "01 January, 1970",
    comment: "100% Advance payment done",
    team: (
      <>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
        >
          <Button
            style={{
              color: "#c40a36",
              background: "white",
              border: "1px solid",
            }}
          >
            Select Team
          </Button>
        </Dropdown>
      </>
    ),
  },
  {
    key: "2",
    sr: "3",
    date: "10 April, 2023",
    project_name: "Teachers' employment & teacher rights",
    expert_name: "Nextyn Advisory Private Limited",
    company_name: "Norasazly Mohd Taha",
    client_name: "Demo Account",
    client_rate: "475",
    expert_rate: "238",
    call_duration: (
      <>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
        >
          <Button
            style={{
              color: "#5748f6",
              background: "white",
              border: "1px solid",
            }}
          >
            60 min.
          </Button>
        </Dropdown>
      </>
    ),
    invoice: (
      <>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
        >
          <Button
            style={{
              color: "#8a2227",
              background: "white",
              border: "1px solid",
            }}
          >
            Not Sent
          </Button>
        </Dropdown>
      </>
    ),
    client_payment: (
      <>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
        >
          <Button
            style={{
              color: "#8a2227",
              background: "white",
              border: "1px solid",
            }}
          >
            No
          </Button>
        </Dropdown>
      </>
    ),
    expert_payment: (
      <>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
        >
          <Button
            style={{
              color: "#8a2227",
              background: "white",
              border: "1px solid",
            }}
          >
            No
          </Button>
        </Dropdown>
      </>
    ),
    payment_mode: (
      <>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
        >
          <Button
            style={{
              color: "#c40a36",
              background: "white",
              border: "1px solid",
            }}
          >
            Select Payment
          </Button>
        </Dropdown>
      </>
    ),
    payment_date: "01 January, 1970",
    comment: "100% Advance payment done",
    team: (
      <>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
        >
          <Button
            style={{
              color: "#c40a36",
              background: "white",
              border: "1px solid",
            }}
          >
            Select Team
          </Button>
        </Dropdown>
      </>
    ),
  },
  {
    key: "3",
    sr: "3",
    date: "10 April, 2023",
    project_name: "Teachers' employment & teacher rights",
    expert_name: "Nextyn Advisory Private Limited",
    company_name: "Norasazly Mohd Taha",
    client_name: "Demo Account",
    client_rate: "475",
    expert_rate: "238",
    call_duration: (
      <>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
        >
          <Button
            style={{
              color: "#5748f6",
              background: "white",
              border: "1px solid",
            }}
          >
            60 min.
          </Button>
        </Dropdown>
      </>
    ),
    invoice: (
      <>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
        >
          <Button
            style={{
              color: "#8a2227",
              background: "white",
              border: "1px solid",
            }}
          >
            Not Sent
          </Button>
        </Dropdown>
      </>
    ),
    client_payment: (
      <>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
        >
          <Button
            style={{
              color: "#8a2227",
              background: "white",
              border: "1px solid",
            }}
          >
            No
          </Button>
        </Dropdown>
      </>
    ),
    expert_payment: (
      <>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
        >
          <Button
            style={{
              color: "#8a2227",
              background: "white",
              border: "1px solid",
            }}
          >
            No
          </Button>
        </Dropdown>
      </>
    ),
    payment_mode: (
      <>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
        >
          <Button
            style={{
              color: "#c40a36",
              background: "white",
              border: "1px solid",
            }}
          >
            Select Payment
          </Button>
        </Dropdown>
      </>
    ),
    payment_date: "01 January, 1970",
    comment: "100% Advance payment done",
    team: (
      <>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
        >
          <Button
            style={{
              color: "#c40a36",
              background: "white",
              border: "1px solid",
            }}
          >
            Select Team
          </Button>
        </Dropdown>
      </>
    ),
  },
  {
    key: "4",
    sr: "4",
    date: "10 April, 2023",
    project_name: "Teachers' employment & teacher rights",
    expert_name: "Nextyn Advisory Private Limited",
    company_name: "Norasazly Mohd Taha",
    client_name: "Demo Account",
    client_rate: "475",
    expert_rate: "238",
    call_duration: (
      <>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
        >
          <Button
            style={{
              color: "#5748f6",
              background: "white",
              border: "1px solid",
            }}
          >
            60 min.
          </Button>
        </Dropdown>
      </>
    ),
    invoice: (
      <>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
        >
          <Button
            style={{
              color: "#8a2227",
              background: "white",
              border: "1px solid",
            }}
          >
            Not Sent
          </Button>
        </Dropdown>
      </>
    ),
    client_payment: (
      <>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
        >
          <Button
            style={{
              color: "#8a2227",
              background: "white",
              border: "1px solid",
            }}
          >
            No
          </Button>
        </Dropdown>
      </>
    ),
    expert_payment: (
      <>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
        >
          <Button
            style={{
              color: "#8a2227",
              background: "white",
              border: "1px solid",
            }}
          >
            No
          </Button>
        </Dropdown>
      </>
    ),
    payment_mode: (
      <>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
        >
          <Button
            style={{
              color: "#c40a36",
              background: "white",
              border: "1px solid",
            }}
          >
            Select Payment
          </Button>
        </Dropdown>
      </>
    ),
    payment_date: "01 January, 1970",
    comment: "100% Advance payment done",
    team: (
      <>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
        >
          <Button
            style={{
              color: "#c40a36",
              background: "white",
              border: "1px solid",
            }}
          >
            Select Team
          </Button>
        </Dropdown>
      </>
    ),
  },
  {
    key: "5",
    sr: "5",
    date: "10 April, 2023",
    project_name: "Teachers' employment & teacher rights",
    expert_name: "Nextyn Advisory Private Limited",
    company_name: "Norasazly Mohd Taha",
    client_name: "Demo Account",
    client_rate: "475",
    expert_rate: "238",
    call_duration: (
      <>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
        >
          <Button
            style={{
              color: "#5748f6",
              background: "white",
              border: "1px solid",
            }}
          >
            60 min.
          </Button>
        </Dropdown>
      </>
    ),
    invoice: (
      <>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
        >
          <Button
            style={{
              color: "#8a2227",
              background: "white",
              border: "1px solid",
            }}
          >
            Not Sent
          </Button>
        </Dropdown>
      </>
    ),
    client_payment: (
      <>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
        >
          <Button
            style={{
              color: "#8a2227",
              background: "white",
              border: "1px solid",
            }}
          >
            No
          </Button>
        </Dropdown>
      </>
    ),
    expert_payment: (
      <>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
        >
          <Button
            style={{
              color: "#8a2227",
              background: "white",
              border: "1px solid",
            }}
          >
            No
          </Button>
        </Dropdown>
      </>
    ),
    payment_mode: (
      <>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
        >
          <Button
            style={{
              color: "#c40a36",
              background: "white",
              border: "1px solid",
            }}
          >
            Select Payment
          </Button>
        </Dropdown>
      </>
    ),
    payment_date: "01 January, 1970",
    comment: "100% Advance payment done",
    team: (
      <>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
        >
          <Button
            style={{
              color: "#c40a36",
              background: "white",
              border: "1px solid",
            }}
          >
            Select Team
          </Button>
        </Dropdown>
      </>
    ),
  },
  {
    key: "6",
    sr: "6",
    date: "10 April, 2023",
    project_name: "Teachers' employment & teacher rights",
    expert_name: "Nextyn Advisory Private Limited",
    company_name: "Norasazly Mohd Taha",
    client_name: "Demo Account",
    client_rate: "475",
    expert_rate: "238",
    call_duration: (
      <>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
        >
          <Button
            style={{
              color: "#5748f6",
              background: "white",
              border: "1px solid",
            }}
          >
            60 min.
          </Button>
        </Dropdown>
      </>
    ),
    invoice: (
      <>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
        >
          <Button
            style={{
              color: "#8a2227",
              background: "white",
              border: "1px solid",
            }}
          >
            Not Sent
          </Button>
        </Dropdown>
      </>
    ),
    client_payment: (
      <>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
        >
          <Button
            style={{
              color: "#8a2227",
              background: "white",
              border: "1px solid",
            }}
          >
            No
          </Button>
        </Dropdown>
      </>
    ),
    expert_payment: (
      <>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
        >
          <Button
            style={{
              color: "#8a2227",
              background: "white",
              border: "1px solid",
            }}
          >
            No
          </Button>
        </Dropdown>
      </>
    ),
    payment_mode: (
      <>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
        >
          <Button
            style={{
              color: "#c40a36",
              background: "white",
              border: "1px solid",
            }}
          >
            Select Payment
          </Button>
        </Dropdown>
      </>
    ),
    payment_date: "01 January, 1970",
    comment: "100% Advance payment done",
    team: (
      <>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
        >
          <Button
            style={{
              color: "#c40a36",
              background: "white",
              border: "1px solid",
            }}
          >
            Select Team
          </Button>
        </Dropdown>
      </>
    ),
  },
  {
    key: "7",
    sr: "7",
    date: "10 April, 2023",
    project_name: "Teachers' employment & teacher rights",
    expert_name: "Nextyn Advisory Private Limited",
    company_name: "Norasazly Mohd Taha",
    client_name: "Demo Account",
    client_rate: "475",
    expert_rate: "238",
    call_duration: (
      <>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
        >
          <Button
            style={{
              color: "#5748f6",
              background: "white",
              border: "1px solid",
            }}
          >
            60 min.
          </Button>
        </Dropdown>
      </>
    ),
    invoice: (
      <>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
        >
          <Button
            style={{
              color: "#8a2227",
              background: "white",
              border: "1px solid",
            }}
          >
            Not Sent
          </Button>
        </Dropdown>
      </>
    ),
    client_payment: (
      <>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
        >
          <Button
            style={{
              color: "#8a2227",
              background: "white",
              border: "1px solid",
            }}
          >
            No
          </Button>
        </Dropdown>
      </>
    ),
    expert_payment: (
      <>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
        >
          <Button
            style={{
              color: "#8a2227",
              background: "white",
              border: "1px solid",
            }}
          >
            No
          </Button>
        </Dropdown>
      </>
    ),
    payment_mode: (
      <>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
        >
          <Button
            style={{
              color: "#c40a36",
              background: "white",
              border: "1px solid",
            }}
          >
            Select Payment
          </Button>
        </Dropdown>
      </>
    ),
    payment_date: "01 January, 1970",
    comment: "100% Advance payment done",
    team: (
      <>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
        >
          <Button
            style={{
              color: "#c40a36",
              background: "white",
              border: "1px solid",
            }}
          >
            Select Team
          </Button>
        </Dropdown>
      </>
    ),
  },
  {
    key: "8",
    sr: "8",
    date: "10 April, 2023",
    project_name: "Teachers' employment & teacher rights",
    expert_name: "Nextyn Advisory Private Limited",
    company_name: "Norasazly Mohd Taha",
    client_name: "Demo Account",
    client_rate: "475",
    expert_rate: "238",
    call_duration: (
      <>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
        >
          <Button
            style={{
              color: "#5748f6",
              background: "white",
              border: "1px solid",
            }}
          >
            60 min.
          </Button>
        </Dropdown>
      </>
    ),
    invoice: (
      <>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
        >
          <Button
            style={{
              color: "#8a2227",
              background: "white",
              border: "1px solid",
            }}
          >
            Not Sent
          </Button>
        </Dropdown>
      </>
    ),
    client_payment: (
      <>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
        >
          <Button
            style={{
              color: "#8a2227",
              background: "white",
              border: "1px solid",
            }}
          >
            No
          </Button>
        </Dropdown>
      </>
    ),
    expert_payment: (
      <>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
        >
          <Button
            style={{
              color: "#8a2227",
              background: "white",
              border: "1px solid",
            }}
          >
            No
          </Button>
        </Dropdown>
      </>
    ),
    payment_mode: (
      <>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
        >
          <Button
            style={{
              color: "#c40a36",
              background: "white",
              border: "1px solid",
            }}
          >
            Select Payment
          </Button>
        </Dropdown>
      </>
    ),
    payment_date: "01 January, 1970",
    comment: "100% Advance payment done",
    team: (
      <>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
        >
          <Button
            style={{
              color: "#c40a36",
              background: "white",
              border: "1px solid",
            }}
          >
            Select Team
          </Button>
        </Dropdown>
      </>
    ),
  },
  {
    key: "9",
    sr: "9",
    date: "10 April, 2023",
    project_name: "Teachers' employment & teacher rights",
    expert_name: "Nextyn Advisory Private Limited",
    company_name: "Norasazly Mohd Taha",
    client_name: "Demo Account",
    client_rate: "475",
    expert_rate: "238",
    call_duration: (
      <>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
        >
          <Button
            style={{
              color: "#5748f6",
              background: "white",
              border: "1px solid",
            }}
          >
            60 min.
          </Button>
        </Dropdown>
      </>
    ),
    invoice: (
      <>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
        >
          <Button
            style={{
              color: "#8a2227",
              background: "white",
              border: "1px solid",
            }}
          >
            Not Sent
          </Button>
        </Dropdown>
      </>
    ),
    client_payment: (
      <>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
        >
          <Button
            style={{
              color: "#8a2227",
              background: "white",
              border: "1px solid",
            }}
          >
            No
          </Button>
        </Dropdown>
      </>
    ),
    expert_payment: (
      <>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
        >
          <Button
            style={{
              color: "#8a2227",
              background: "white",
              border: "1px solid",
            }}
          >
            No
          </Button>
        </Dropdown>
      </>
    ),
    payment_mode: (
      <>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
        >
          <Button
            style={{
              color: "#c40a36",
              background: "white",
              border: "1px solid",
            }}
          >
            Select Payment
          </Button>
        </Dropdown>
      </>
    ),
    payment_date: "01 January, 1970",
    comment: "100% Advance payment done",
    team: (
      <>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
        >
          <Button
            style={{
              color: "#c40a36",
              background: "white",
              border: "1px solid",
            }}
          >
            Select Team
          </Button>
        </Dropdown>
      </>
    ),
  },
  {
    key: "10",
    sr: "10",
    date: "10 April, 2023",
    project_name: "Teachers' employment & teacher rights",
    expert_name: "Nextyn Advisory Private Limited",
    company_name: "Norasazly Mohd Taha",
    client_name: "Demo Account",
    client_rate: "475",
    expert_rate: "238",
    call_duration: (
      <>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
        >
          <Button
            style={{
              color: "#5748f6",
              background: "white",
              border: "1px solid",
            }}
          >
            60 min.
          </Button>
        </Dropdown>
      </>
    ),
    invoice: (
      <>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
        >
          <Button
            style={{
              color: "#8a2227",
              background: "white",
              border: "1px solid",
            }}
          >
            Not Sent
          </Button>
        </Dropdown>
      </>
    ),
    client_payment: (
      <>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
        >
          <Button
            style={{
              color: "#8a2227",
              background: "white",
              border: "1px solid",
            }}
          >
            No
          </Button>
        </Dropdown>
      </>
    ),
    expert_payment: (
      <>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
        >
          <Button
            style={{
              color: "#8a2227",
              background: "white",
              border: "1px solid",
            }}
          >
            No
          </Button>
        </Dropdown>
      </>
    ),
    payment_mode: (
      <>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
        >
          <Button
            style={{
              color: "#c40a36",
              background: "white",
              border: "1px solid",
            }}
          >
            Select Payment
          </Button>
        </Dropdown>
      </>
    ),
    payment_date: "01 January, 1970",
    comment: "100% Advance payment done",
    team: (
      <>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
        >
          <Button
            style={{
              color: "#c40a36",
              background: "white",
              border: "1px solid",
            }}
          >
            Select Team
          </Button>
        </Dropdown>
      </>
    ),
  },
];

const CallRecordTable = () => {
  let history = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(dataSource);
  const [modal1Open, setModal1Open] = useState(false);
  const [modal2Open, setModal2Open] = useState(false);
  const [filter, setFilter] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [hideFilter, setHideFilter] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [callList, setCallList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [callPage, setCallPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const [pagination, setPagination] = useState({
    current: currentPage,
    onChange: handlePageChange,
    pageSize: 10,
    total: callPage,
  });

  const paginationSettings = {
    current: currentPage,
    onChange: handlePageChange,
    pageSize: 10,
    total: callPage,
  };

  const [managerList, setManagerList] = useState([]);
  const [callHistoryId, setCallHistoryId] = useState("");

  const [selectedOptions, setSelectedOptions] = useState({
    project: "",
    company: "",
    expert: "",
    client: "",
    invoice: "",
    sdate: "",
    edate: "",
  });

  const [filteredClientList, setFilteredClientList] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY", "DD-MM-YYYY", "DD-MM-YY"];

  const convertDate = (date) => {
    //console.log(date)
    if (date !== null && date !== "") {
      var d = new Date(date);
      var day = d.getDate();
      var x = d.toDateString().substr(4, 3);
      var year = d.getFullYear();
      let p = `${x} ${day}, ${year}`;
      //console.log(p)
      return p;
    } else {
      return "---------";
    }
  };

  console.log(selectedOptions, "selectedOptionsList");

  const GetCallList = async (page, selectedOptions) => {
    setLoading(true);
    console.log(selectedOptions, "selectedOne");

    try {
      let object = {};

      console.log(object, "object");

      if (selectedOptions?.project) {
        object.project = `"${selectedOptions?.project}"`;
      }

      if (selectedOptions?.company) {
        object.company = `"${selectedOptions?.company}"`;
      }

      if (selectedOptions?.expert) {
        object.expert = `"${selectedOptions?.expert}"`;
      }

      if (selectedOptions?.client) {
        object.client = `"${selectedOptions?.client}"`;
      }

      if (selectedOptions?.invoice) {
        object.invoice = `"${selectedOptions.invoice}"`;
      }

      if (selectedOptions?.sdate) {
        object.sdate = `"${selectedOptions?.sdate}"`;
      }

      if (selectedOptions?.edate) {
        object.edate = `"${selectedOptions?.edate}"`;
      }

      const queryParams = new URLSearchParams(object); // Construct query parameters from the object properties

      const res = await fetch(
        `${
          API_PATH?.CALL_LIST
        }?page=${page}&limit=10&${queryParams?.toString()}`,
        {
          method: "GET",
          // params: {
          //   selectedOptions
          // },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          },
        }
      );

      const result = await res.json();
      if (result) {
        console.log(result.data);
        setCallHistoryId(result?.data[0]?._id);
        setCallList(result?.data);
        setCallPage(result?.countData);
        console.log(result?.countData, "result?.data?.countData");
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);
      console.log(error, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadCSV = () => {
    // Extract specific properties and convert to CSV format
    const csvData = callList?.map((item) => {
      return [
        item?.date ? dayjs(item?.date)?.format("YYYY-MM-DD") : "",
        item?.project_title || "",
        item?.client_company || "",
        item?.client_email || "",
        item?.expert_email || "",
        item?.client_name || "",
        item?.expert_name || "",
        item?.client_rate || "",
        item?.expert_rate || "",
        item?.call_duration || "",
        item?.invoice_status || "",
        item?.client_payment_status || "",
        item?.expert_payment_status,
        item?.payment_date
          ? dayjs(item?.payment_date)?.format("YYYY-MM-DD")
          : "",
        item?.payment_mode || "",
        item?.comment || "",
        item?.lead_by || "",
        item?.expert_email || "",
        item?.client_email || "",
      ].join(",");
    });

    // Add headers for each column
    const csvContent = [
      [
        "Date",
        "Project Title",
        "Client Company",
        "Client Email",
        "Expert Email",
        "Client Name",
        "Expert Name",
        "Client Rate",
        "Expert Rate",
        "Call Duration",
        "Invoice Status",
        "Client Payment Status",
        "Expert Payment Status",
        "Payment Date",
        "Payment Mode",
        "Comment",
        "Lead By",
        "Expert Email",
        "Client Email",
      ]?.join(","),
      ...csvData,
    ]?.join("\n");

    // Create a Blob object and initiate a download
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "call_list.csv");
    document.body.appendChild(link);
    link.click();

    // Cleanup
    URL.revokeObjectURL(url);
    document.body.removeChild(link);
  };

  const constructUrl = (selectedOptions) => {
    const { project, company, expert, client, invoice, sdate, edate } =
      selectedOptions || {};

    const projectParam = Array.isArray(project) ? project.join(", ") : project;
    const companyParam = Array.isArray(company) ? company.join(", ") : company;
    const expertParam = Array.isArray(expert) ? expert.join(", ") : expert;
    const clientParam = Array.isArray(client) ? client.join(", ") : client;
    const invoiceParam = Array.isArray(invoice) ? invoice.join(", ") : invoice;

    const sanitizedProject = projectParam
      ? projectParam.replace(/%20/g, " ")
      : "";
    const sanitizedCompany = companyParam
      ? decodeURIComponent(companyParam)
      : "";
    const sanitizedExpert = expertParam ? decodeURIComponent(expertParam) : "";
    const sanitizedClient = clientParam ? decodeURIComponent(clientParam) : "";
    const sanitizedInvoice = invoiceParam
      ? decodeURIComponent(invoiceParam)
      : "";

    const urlParams = [
      `project="${sanitizedProject}"`,
      `company="${sanitizedCompany}"`,
      `expert="${sanitizedExpert}"`,
      `client="${sanitizedClient}"`,
      `invoice="${sanitizedInvoice}"`,
      `sdate=${sdate}`,
      `edate=${edate}`,
    ];

    return history({
      pathname: `/dashboard/call_records`,
      search: `?${urlParams.join("&")}`,
    });
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const GetManagerList = async () => {
    setLoading(true);
    try {
      const res = await fetch(`${API_PATH.MANAGER_LIST}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
      });

      const result = await res.json();
      if (result) {
        console.log(result?.data, "resultDatamanager");
        setManagerList(result?.data);
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    GetCallList(currentPage, selectedOptions);
    GetManagerList();
    const url = constructUrl(selectedOptions);
    console.log(url); // Output the constructed UR
  }, [currentPage, selectedOptions]);

  const AddCallList = async () => {
    setLoading(true);
    try {
      const res = await fetch(`${API_PATH.CALL_CREATE}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
        body: JSON.stringify({
          company_id: "",
          date: "",
          project_title: "",
          project_id: "",
          user_id: "",
          client_company: "",
          expert_id: "",
          expert_email: "",
          client_email: "",
          client_rate: "",
          expert_rate: "",
          call_duration: "",
          invoice_status: "",
          client_payment_status: "",
          expert_payment_status: "",
          payment_mode: "",
          comment: "",
          lead_by: "",
          extra: "",
          payment_date: "",
        }),
      });

      const result = await res.json();
      if (result) {
        if (res.status === 200 || res.status === true) {
          await GetCallList();
          notification.success({
            message: "New row added",
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#2ecc71",
              color: "#fff !important",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          });
        }
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  };

  const DeleteCallList = async () => {
    setLoading(true);
    try {
      const res = await fetch(`${API_PATH.CALL_DELETE}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
        body: JSON.stringify({
          call_history_id: [callHistoryId],
        }),
      });

      const result = await res.json();
      if (result) {
        GetCallList();
        if (res.status === 200 || res.status === true) {
          notification.success({
            message: "Call records has been deleted",
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#2ecc71",
              color: "#fff !important",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          });
        }
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  };

  // console.log(callList[0]?.call_history_id, "callList");

  const UpdateCallList = async (record) => {
    setLoading(true);
    try {
      const res = await fetch(`${API_PATH?.CALL_UPDATE}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
        body: JSON.stringify({
          // company_id: record?.company_id || "",
          date: record?.date || "",
          project_title: record?.project_title || "",
          project_id: record?.project_id || "",
          user_id: record?.user_id || "",
          client_company: record?.client_company || "",
          // expert_id: record?._id || "",
          expert_email: record?.expert_email || "",
          expert_name: record?.expert_name || "",
          client_email: record?.client_email || "",
          client_name: record?.client_name || "",
          client_rate: record?.client_rate || "",
          expert_rate: record?.expert_rate || "",
          call_duration: record?.call_duration || "",
          invoice_status: record?.invoice_status || "",
          client_payment_status: record?.client_payment_status || "",
          expert_payment_status: record?.expert_payment_status || "",
          payment_mode: record?.payment_mode || "",
          comment: record?.comment || "",
          lead_by: record?.lead_by || "",
          payment_date: record?.payment_date || "",
          // call_history_id: callHistoryId,
          call_history_id: record?._id,
        }),
      });

      const result = await res?.json();
      if (result) {
        GetCallList();
        if (result?.status === 200 || result?.status === true) {
          // window.location.reload();
          notification.success({
            message: "Call Record Updated",
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#2ecc71",
              color: "#fff !important",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          });
        }
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);
      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  };

  const handleDownload = async () => {
    try {
      const response = await fetch(API_PATH.CALL_UPLOAD_CSV, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
        body: JSON.stringify({ call_csv: "CALL_RECORDS.csv" }),
      });

      if (response.ok) {
        // Extract the CSV data from the response
        const csvData = await response.text();

        // Create a Blob object from the CSV data
        const blob = new Blob([csvData], { type: "text/csv" });

        // Create a URL for the Blob
        const url = window.URL.createObjectURL(blob);

        // Create a link element and trigger the download
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = "CALL_RECORDS.csv";
        document.body.appendChild(a);
        a.click();

        // Cleanup
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } else {
        console.error("Failed to fetch CSV file:", response.status);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleItemClick = (item) => {
    if (item.key === "1") {
      setFilter(!filter);
    }
  };

  const items = [
    {
      label: "Download As CSV",
      key: "0",
    },
    {
      label: "Hide Filter",
      key: "1",
    },
  ];

  const menu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          AddCallList();
        }}
      >
        <Space>
          <span>Add New Record</span>
        </Space>
      </Menu.Item>
      <Menu.Item onClick={() => setShowUpload(!showUpload)}>
        <Space>
          <span>Upload CSV</span>
        </Space>
      </Menu.Item>
      <Menu.Item
        onClick={() => handleDownloadCSV()}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Space align="center" size={8}>
          <span>Download As CSV</span>
        </Space>
      </Menu.Item>

      <Menu.Item
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={() => setHideFilter(!hideFilter)}
      >
        <Space align="center" size={8}>
          <span>{!hideFilter ? "Hide Filter" : "Filter"}</span>
        </Space>
      </Menu.Item>
    </Menu>
  );

  const menuItems = [
    { key: 1, label: "15 min", value: "15 min" },
    { key: 2, label: "30 min", value: "30 min" },
    { key: 3, label: "45 min", value: "45 min" },
    { key: 4, label: "60 min", value: "60 min" },
    { key: 5, label: "75 min", value: "75 min" },
    { key: 6, label: "90 min", value: "90 min" },
    { key: 7, label: "105 min", value: "105 min" },
    { key: 8, label: "120 min", value: "120 min" },
    { key: 9, label: "135 min", value: "135 min" },
    { key: 10, label: "150 min", value: "150 min" },
    { key: 11, label: "165 min", value: "165 min" },
    { key: 12, label: "180 min", value: "180 min" },
  ];

  const inStatus = [
    { key: 1, label: "Sent", value: "Sent" },
    { key: 2, label: "Not Sent", value: "Not Sent" },
  ];

  const client_pay = [
    { key: 1, label: "Yes", value: "Yes" },
    { key: 2, label: "No", value: "No" },
  ];

  const expert_pay = [
    { key: 1, label: "Yes", value: "Yes" },
    { key: 2, label: "No", value: "No" },
  ];

  const pay_mode = [
    { key: 1, label: "Select Payment Mode", value: "Select Payment Mode" },
    { key: 2, label: "Bank Transfer", value: "Bank Transfer" },
    { key: 3, label: "Amazon Gift Card", value: "Amazon Gift Card" },
    { key: 4, label: "PayPal", value: "PayPal" },
    { key: 5, label: "Charity", value: "Charity" },
  ];

  const columns = [
    {
      title: "SNo.",
      dataIndex: "sr",
      key: "sr",
      render: (text, record, rowIndex) => rowIndex + 1,
      width: "50px",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: "50px",
      render: (date, record) => {
        const handleChange = (value) => {
          record.date = value;
          setCallList([...callList]);
        };

        // Check if selectedDate is null or undefined, then initialize it with the value from the API
        if (!selectedDate && date) {
          setSelectedDate(moment(date, ["DD-MMM-YY", "YYYY-MM-DD"]));
        }

        return (
          <>
            <Space direction="vertical" size={24} width="100%">
              <DatePicker
                value={date ? dayjs(date, ["DD-MMM-YY", "YYYY-MM-DD"]) : null}
                format="YYYY-MM-DD"
                onChange={(date, dateString) =>
                  handleChange(dateString, record, "created_at")
                }
                className="custom-datepicker"
              />
            </Space>
          </>
        );
      },
    },
    {
      title: "Project Name",
      dataIndex: "project_title",
      key: "project_title",
      render: (project_title, record) => {
        const handleChange = (value) => {
          record.project_title = value;
          setCallList([...callList]);
        };

        return (
          <>
            <div
              contentEditable={true}
              suppressContentEditableWarning={true}
              onBlur={(e) =>
                handleChange(e.target.textContent, record, "project_title")
              }
              style={{ cursor: "pointer" }}
            >
              {project_title}
            </div>
          </>
        );
      },
    },
    {
      title: "Project ID",
      dataIndex: "project_id",
      key: "project_id",
      render: (project_id, record) => {
        const handleChange = (value) => {
          record.project_id = value;
          setCallList([...callList]);
        };

        return (
          <>
            <Input
              value={project_id}
              onChange={(e) =>
                handleChange(e.target.value, record, "project_id")
              }
              style={{ width: "200px" }}
            />
          </>
        );
      },
    },
    {
      title: "Company Name",
      dataIndex: "client_company",
      key: "client_company",
      render: (client_company, record) => {
        const handleChange = (value) => {
          record.client_company = value;
          setCallList([...callList]);
        };
        return (
          <div
            contentEditable={true}
            suppressContentEditableWarning={true}
            onBlur={(e) =>
              handleChange(e.target.textContent, record, "client_company")
            }
            style={{ cursor: "pointer" }}
          >
            {client_company}
          </div>
        );
      },
    },
    {
      title: "Expert Name",
      dataIndex: "expert_name",
      key: "expert_name",
      render: (expert_name, record) => {
        const handleChange = (value) => {
          record.expert_name = value;
          setCallList([...callList]);
        };
        return (
          <div
            contentEditable={true}
            suppressContentEditableWarning={true}
            onBlur={(e) =>
              handleChange(e.target.textContent, record, "expert_name")
            }
            style={{ cursor: "pointer" }}
          >
            {expert_name}
          </div>
        );
      },
    },
    {
      title: "Expert Email",
      dataIndex: "expert_email",
      key: "expert_email",
      render: (expert_email, record) => {
        const handleChange = (value) => {
          record.expert_email = value;
          setCallList([...callList]);
        };
        return (
          <div
            contentEditable={true}
            suppressContentEditableWarning={true}
            onBlur={(e) =>
              handleChange(e.target.textContent, record, "expert_email")
            }
            style={{ cursor: "pointer" }}
          >
            {expert_email}
          </div>
        );
      },
    },
    {
      title: "Client Name",
      dataIndex: "client_name",
      key: "client_name",
      render: (client_name, record) => {
        const handleChange = (value) => {
          record.client_name = value;
          setCallList([...callList]);
        };

        return (
          <div
            contentEditable={true}
            suppressContentEditableWarning={true}
            onBlur={(e) =>
              handleChange(e.target.textContent, record, "client_name")
            }
            style={{ cursor: "pointer" }}
          >
            {client_name}
          </div>
        );
      },
    },
    {
      title: "Client Email",
      dataIndex: "client_email",
      key: "client_email",
      render: (client_email, record) => {
        const handleChange = (value) => {
          record.client_email = value;
          setCallList([...callList]);
        };

        return (
          <div
            contentEditable={true}
            suppressContentEditableWarning={true}
            onBlur={(e) =>
              handleChange(e.target.textContent, record, "client_email")
            }
            style={{ cursor: "pointer" }}
          >
            {client_email}
          </div>
        );
      },
    },
    {
      title: "Client Rate (USD)",
      dataIndex: "client_rate",
      key: "client_rate",
      render: (text, record) => {
        const handleChange = (value) => {
          record.client_rate = value;
          setCallList([...callList]);
        };

        return (
          <div
            contentEditable={true}
            suppressContentEditableWarning={true}
            onBlur={(e) =>
              handleChange(e.target.textContent, record, "client_rate")
            }
            style={{ cursor: "pointer" }}
          >
            {text}
          </div>
        );
      },
    },
    {
      title: "Expert Rate (USD)",
      dataIndex: "expert_rate",
      key: "expert_rate",
      render: (text, record) => {
        const handleChange = (value) => {
          record.expert_rate = value;
          setCallList([...callList]);
        };
        return (
          <div
            contentEditable={true}
            suppressContentEditableWarning={true}
            onBlur={(e) =>
              handleChange(e.target.textContent, record, "expert_rate")
            }
            style={{ cursor: "pointer" }}
          >
            {text}
          </div>
        );
      },
    },
    {
      title: "Call Duration",
      dataIndex: "call_duration",
      key: "call_duration",
      render: (call_duration, record) => {
        const handleChange = (value) => {
          record.call_duration = value;
          setCallList([...callList]);
        };

        return (
          <Select
            value={call_duration}
            onChange={handleChange}
            style={{
              width: 120,
            }}
            size="large"
          >
            {menuItems.map((item) => (
              <Option key={item.key} value={item.value}>
                {item.label}
              </Option>
            ))}
          </Select>
        );
      },
    },
    {
      title: "Invoice Status",
      dataIndex: "invoice_status",
      key: "invoice_status",
      render: (invoice_status, record) => {
        const handleChange = (value) => {
          record.invoice_status = value;
          setCallList([...callList]);
        };

        return (
          <Select
            value={invoice_status}
            onChange={handleChange}
            style={{
              width: 120,
            }}
            size="large"
          >
            {inStatus.map((item) => (
              <Option key={item.key} value={item.value}>
                {item.label}
              </Option>
            ))}
          </Select>
        );
      },
    },
    {
      title: "Client Payment Received",
      dataIndex: "client_payment_status",
      key: "client_payment_status",
      render: (client_payment_status, record) => {
        const handleChange = (value) => {
          record.client_payment_status = value;
          setCallList([...callList]);
        };

        return (
          <Select
            value={client_payment_status}
            onChange={handleChange}
            style={{
              width: 120,
            }}
            size="large"
          >
            {client_pay.map((item) => (
              <Option key={item.key} value={item.value}>
                {item.label}
              </Option>
            ))}
          </Select>
        );
      },
    },
    {
      title: "Expert Payment Mode",
      dataIndex: "expert_payment_status",
      key: "expert_payment_status",
      render: (expert_payment_status, record) => {
        const handleChange = (value) => {
          record.expert_payment_status = value;
          setCallList([...callList]);
        };

        return (
          <Select
            value={expert_payment_status}
            onChange={handleChange}
            style={{
              width: 120,
            }}
            size="large"
          >
            {expert_pay.map((item) => (
              <Option key={item.key} value={item.value}>
                {item.label}
              </Option>
            ))}
          </Select>
        );
      },
    },
    {
      title: "Payment Mode",
      dataIndex: "payment_mode",
      key: "payment_mode",
      render: (payment_mode, record) => {
        const handleChange = (value) => {
          record.payment_mode = value;
          setCallList([...callList]);
        };

        return (
          <Select
            placeholder="Select"
            value={payment_mode ? payment_mode : "Select Payment Mode"}
            onChange={handleChange}
            style={{
              width: 180,
            }}
            size="large"
            virtual={false}
            allowClear
          >
            {pay_mode && pay_mode.length > 0 ? (
              pay_mode.map((item, index) => (
                <Option value={item?.value} key={index}>
                  {item?.label}
                </Option>
              ))
            ) : (
              <option value="">No data found</option>
            )}
          </Select>
        );
      },
    },
    {
      title: "Payment Date",
      dataIndex: "payment_date",
      key: "payment_date",
      render: (payment_date, record) => {
        const handleChange = (value) => {
          record.payment_date = value;
          setCallList([...callList]);
        };

        const supportedFormats = ["YYYY-MM-DD", "DD-MM-YYYY"];

        if (!selectedDate && payment_date) {
          setSelectedDate(dayjs(payment_date, supportedFormats));
        }

        return (
          <>
            <Space direction="vertical" size={24} width="100%">
              <DatePicker
                value={payment_date && dayjs(payment_date, supportedFormats)}
                format="YYYY-MM-DD"
                onChange={(date, dateString) =>
                  handleChange(dateString, record, "payment_date")
                }
                className="custom-datepicker"
              />
            </Space>
          </>
        );
      },
    },
    {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
      render: (text, record) => {
        const handleChange = (value) => {
          record.comment = value;
          setCallList([...callList]);
        };

        const truncatedTitle = record?.comment
          ? record.comment.split(" ").slice(0, 5).join(" ")
          : "";

        // Add three dots at the end if the title was truncated
        const displayTitle =
          truncatedTitle !== record?.comment
            ? truncatedTitle + "..."
            : truncatedTitle;

        return (
          <Tooltip color="#5746ec" title={text}>
            <div
              contentEditable={true}
              suppressContentEditableWarning={true}
              onBlur={(e) =>
                handleChange(e.target.textContent, record, "comment")
              }
              style={{ cursor: "pointer" }}
            >
              {displayTitle || "---------"}
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: "Team",
      dataIndex: "lead_by",
      key: "lead_by",
      render: (lead_by, record) => {
        const handleChange = (value) => {
          record.lead_by = value;
          setCallList([...callList]);
        };

        return (
          <Select
            defaultValue="Select"
            value={lead_by}
            onChange={handleChange}
            style={{
              width: 180,
            }}
            size="large"
          >
            {managerList && managerList.length > 0 ? (
              managerList.map((option, index) => (
                <Option value={option?.name} key={index}>
                  {option?.name}
                </Option>
              ))
            ) : (
              <option value="">No data found</option>
            )}
          </Select>
        );
      },
    },
    {
      title: "Action",
      render: (text, record) => (
        <>
          <div className="d-flex expertActions align-items-center gap-1">
            <Tooltip placement="top" title="Add/Updated Data" color="#5746EB">
              <span>
                <button
                  size="large"
                  className="viewBttnExpert"
                  style={{
                    cursor: "not-allowed",
                  }}
                  onClick={() => UpdateCallList(record)}
                >
                  <LuPlus />
                </button>
              </span>
            </Tooltip>
            <Tooltip placement="top" title="Delete" color="#5746EB">
              <span>
                <button
                  size="large"
                  className="viewBttnExpert"
                  style={{
                    cursor: "not-allowed",
                  }}
                  onClick={() => {
                    setCallHistoryId(record?._id);
                    setModal2Open(true);
                  }}
                >
                  <MdDelete />
                </button>
              </span>
            </Tooltip>
          </div>
        </>
      ),
    },
  ];

  const footer = (
    <div>
      <button
        key="cancel"
        className="secondaryBttn btn"
        onClick={() => setModal2Open(false)}
      >
        No
      </button>
      <button
        key="submit"
        className="viewButton btn ms-2"
        onClick={() => {
          DeleteCallList();
          setModal2Open(false);
        }}
      >
        Yes
      </button>
    </div>
  );

  useEffect(() => {
    const filteredData = callList?.filter((record) =>
      Object.values(record)
        .join("")
        .toLowerCase()
        .includes(searchText.toLowerCase())
    );
    setFilteredClientList(filteredData);

    const filteredItemsCount = filteredData.length;
    if (filteredItemsCount <= 10) {
      paginationSettings.total = filteredItemsCount;
    } else {
      paginationSettings.total = callPage;
    }

    // If the current page is greater than the updated total pages,
    // set the current page to the last page
    if (currentPage > paginationSettings.total) {
      paginationSettings.current = paginationSettings.total;
    }
  }, [callList, searchText, currentPage, callPage]);

  const handleSearch = (value) => {
    setSearchText(value);
  };
  const handleTableChange = (pagination, filters, sorter) => {
    setPagination(pagination);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("call_csv", file);
    });
    setUploading(true);

    // You can use any AJAX library you like

    try {
      await fetch(`${API_PATH.CALL_UPLOAD_CSV}`, {
        method: "POST",
        body: formData,
      })
        .then((res) => res.json())
        .then(() => {
          setFileList([]);
          notification.success({
            message: "Call records has been updated",
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#2ecc71",
              color: "#fff !important",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          });
        })
        .catch(() => {
          notification.error({
            message: "Error: Ppload failed",
            icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
            style: {
              backgroundColor: "#e74c3c",
              color: "#fff",
              border: "1px solid #c0392b",
            },
            duration: 5,
            placement: "topRight",
          });
        })
        .finally(() => {
          setUploading(false);
        });
    } catch (error) {
      setLoading(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
  };

  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };

  return (
    <>
      <div>
        <div className="bk-div float-start w-100">
          {" "}
          {/* <hr className="sp-hr" /> */}
          <div className="body-expart-div">
            <div className="d-md-flex justify-content-between align-items-center">
              <h2>Add Record</h2>
              <div className="d-flex justify-content-end align-items-center">
                <Dropdown overlay={menu}>
                  <a
                    onClick={(e) => e.preventDefault()}
                    style={{
                      fontSize: "20px",
                      cursor: "pointer",
                      color: "rgb(87, 70, 235)",
                    }}
                  >
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 24 24"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill="none"
                        stroke-width="2"
                        d="M3,13 L3,11 L5,11 L5,13 L3,13 Z M11,12.9995001 L11,11 L12.9995001,11 L12.9995001,12.9995001 L11,12.9995001 Z M19,12.9995001 L19,11 L20.9995001,11 L20.9995001,12.9995001 L19,12.9995001 Z"
                      ></path>
                    </svg>
                  </a>
                </Dropdown>
              </div>
            </div>
            {showUpload && (
              <div>
                <div className="showUploadFunction mb-2">
                  <Button className="downloadBtn" onClick={handleDownload}>
                    Download
                  </Button>
                  <div>
                    <Upload {...props}>
                      <Button icon={<UploadOutlined />}>Select File</Button>
                    </Upload>
                    <Button
                      type="primary"
                      onClick={handleUpload}
                      disabled={fileList.length === 0}
                      loading={uploading}
                      style={{ marginTop: 16 }}
                    >
                      {uploading ? "Uploading" : "Start Upload"}
                    </Button>
                    <p style={{ fontSize: "11px", color: "red" }}>
                      Only CSV format Supported
                    </p>
                  </div>
                </div>
              </div>
            )}

            {!hideFilter ? (
              <div>
                <SelectionOption
                  setSelectedOptions={setSelectedOptions}
                  selectedOptions={selectedOptions}
                />
              </div>
            ) : null}

            <div className="mt-4">
              <Table
                dataSource={filteredClientList}
                columns={columns}
                className="paddTable"
                pagination={false}
                onChange={handleTableChange}
                scroll={{ x: 800 }}
                loading={loading}
              />
              <Pagination
                {...paginationSettings}
                showSizeChanger={false}
                style={{ marginTop: 16, marginBottom: 16, textAlign: "right" }}
              />
            </div>
          </div>
        </div>
      </div>

      <Modal
        title={<span className="modalHeader">Delete this Call Record.</span>}
        open={modal2Open}
        onOk={() => setModal2Open(false)}
        onCancel={() => setModal2Open(false)}
        visible={true}
        footer={footer}
        width={800}
        centered
      >
        <div className="mt-4" style={{ width: "100%", textAlign: "left" }}>
          <p>
            This action cannot be undone. If you delete a call record then this
            call record will not be longer with client and expert dashboard. Are
            you sure you want to delete this call record?
          </p>{" "}
        </div>
      </Modal>
    </>
  );
};

export default CallRecordTable;
