import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect } from "react";

function TopMenu1({ setSearchRgx, searchRgx, onlyFirstTimeAndSearch, clearInput, wordEntered, setWordEntered }) {


  const handleFilter = (event) => {
    onlyFirstTimeAndSearch.current = false
    const searchWord = event.target.value;
    setWordEntered(searchWord);
    setSearchRgx(searchWord);
  };

  useEffect(() => {
    setWordEntered(searchRgx);
  }, [searchRgx]);


  return (
    <>
      <div className="d-flex serch-sec-div position-relative form-control align-items-center ais-SearchBox-form">
        <input
          name="transcript"
          type="text"
          className="form-control dropdown-toggle"
          id="dropdownMenuButton"
          placeholder="Search.."
          value={wordEntered}
          onChange={handleFilter}
        />
        {wordEntered === "" ? (
          <SearchIcon />
        ) : (
          <CloseIcon id="clearBtn" onClick={clearInput} />
        )}
      </div>
    </>
  );
}

export default TopMenu1;
