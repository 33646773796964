import {Navigate} from "react-router-dom";
import useDecodedToken from "../../Utils/useDecodedToken";

const PrivateRouteTwo = ({children}) => {
  const decodedToken = useDecodedToken();
  if (decodedToken) {
    return <Navigate to="/dashboard" replace />;
  }
  return children;
};

export default PrivateRouteTwo;
