// privateRoute
import React from 'react';
import { Navigate } from 'react-router-dom';
// import {isExpired,decodeToken} from "react-jwt";
// // import { connect } from 'react-redux';
// const userData = JSON.parse(localStorage.getItem("userData"));
// const token = userData?.access_token;
const ExpertPrivateRoute = ({children,token}) => {
    // console.log(token, "token");
    // console.log(children, "children")
  let isExpertAuth = JSON.parse(localStorage.getItem("isExpertAuth"));
//   const myDecodeToken = decodeToken(token);
// console.log("hgdfshdcyuddc",myDecodeToken);
    if(!isExpertAuth){
        return <Navigate to="/expert-login" replace/>
    }
    return children;
    // if(myDecodeToken === null){
    //   return <Navigate to="/" replace/>
    // }
    // return children;
}

export default ExpertPrivateRoute;    