import React, { useState, useEffect } from "react";
import Sidebar from "../Sidebar/Sidebar";
import { Col, Row } from "react-bootstrap";
import "./Pages.css";
import {
  AmazonIcon,
  BankIcon,
  PaypalIcon,
  CharityIcon,
} from "../../../Utils/Icons";
import {
  Dropdown,
  Form,
  Input,
  Select,
  Switch,
  Table,
  message,
  Menu,
  Button,
  Modal,
  notification,
} from "antd";
import API_PATH from "../../../Constants/api-path";
import axios from "axios";
import { Modal as BootstrapModal } from "react-bootstrap";
import ExpertDashboard from "../../components/ExpertDashboard";
import { useNavigate } from "react-router-dom";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { currencyList } from "../../../Constants/Currency";

function Payment() {
  const navigate = useNavigate();

  const userData = JSON.parse(localStorage.getItem("expertData"));
  console.log(userData?.data?._id, "userData");

  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [method, setMethod] = useState("");
  const [values, setValues] = useState({
    name_of_charity: "",
    country: "",
    contact_person_name: "",
    contact_number: "",
    email: "",
    website_link: "",
    donation_link: "",
    account_holder_name: "",
    bank_name: "",
    bank_branch_address: "",
    account_number: "",
    iban_ifsc: "",
    branch_code: "",
    bic_or_swift: "",
    ach_routing_code: "",
    micr_code: "",
    paypal_email_id: "",
    expert_id: userData?.data?._id,
    amazn_email_id: "",
    expert_address: "",
    pan_number: "",
    pan_adhar_link_status: "",
    currency: ""
  });
  const [amazonId, setAmazonId] = useState("");
  const [paypalId, setPaypalId] = useState("");
  const [bankId, setBankId] = useState("");
  const [charityId, setCharityId] = useState("");
  const [modal3Open, setModal3Open] = useState(false);
  const [account, setAccount] = useState(false);
  const [accountBnk, setAccountBnk] = useState(false);
  const [accountPypl, setAccountPypl] = useState(false);
  const [accountChr, setAccountChr] = useState(false);

  const [amazonDate, setAmazonDate] = useState("");
  const [paypalDate, setPaypalDate] = useState("");
  const [bankDate, setBankDate] = useState("");
  const [charityDate, setCharityDate] = useState("");

  console.log(amazonId.length, "amazonIdlength");
  console.log(paypalId.length, "paypalIdlength");

  const [viewId, setViewId] = useState("");
  const [deleteId, setdeleteId] = useState("");

  const [form] = Form.useForm();

  const [formErrors, setFormErrors] = useState({});

  const handleInputs = (e) => {
    const key = e.target.name;
    const value = e.target.value;

    setValues({ ...values, [key]: value });
  };

  const handleCountry = (value) => {
    console.log(`selected ${value}`);
    const selectValue = value; // Set "USD" if the value is falsy
    setValues({ ...values, country: selectValue });
  };

  console.log(viewId, "viewId");

  const emailRules = [
    {
      type: "email",
      message: "Please enter a valid email address!",
    },
    {
      required: true,
      message: "Email is required!",
    },
  ];

  const handleSubmit = async (method) => {
    // e.preventDefault();
    setLoading(true);
    let {
      emailError,
      branchNameError,
      account_holder_nameError,
      accountNumberError,
      bank_nameError,
      bank_branch_addressError,
      iban_ifscError,
      expert_addressError,
      paypal_email_idError,
      name_of_charityError,
      countryError,
      contact_person_nameError,
      contact_numberError,
      emailpaypalError,
      website_linkError,
      donation_linkError, pan_adhar_link_status_error
    } = "";

    if (method === "amazon") {
      emailError = !values?.amazn_email_id ? emailRules : "";
    } else if (method === "bank") {
      branchNameError = !values?.bank_name ? "Field is required" : "";
      account_holder_nameError = !values?.account_holder_name
        ? "Field is required"
        : "";
      accountNumberError = !values?.account_number ? "Field is required" : "";
      bank_nameError = !values?.bank_name ? "Field is required" : "";
      bank_branch_addressError = !values?.bank_branch_address
        ? "Field is required"
        : "";
      // iban_ifscError = !values?.iban_ifsc ? "Field is required" : "";
      expert_addressError = !values?.expert_address ? "Field is required" : "";
      pan_adhar_link_status_error = !values?.pan_adhar_link_status && (userData?.data?.country?.toLowerCase() === "india" ||
        userData?.data?.contact_code === "91" ||
        userData?.data?.contact_code === "+91") ? "Field is requilred" : ""
    } else if (method === "paypal") {
      paypal_email_idError = !values?.paypal_email_id
        ? "Field is required"
        : "";
    } else if (method === "charity") {
      name_of_charityError = !values?.name_of_charity
        ? "Field is required"
        : "";
      countryError = !values?.country ? "Field is required" : "";
      contact_person_nameError = !values?.contact_person_name
        ? "Field is required"
        : "";
      contact_numberError = !values?.contact_number ? "Field is required" : "";
      emailpaypalError = !values?.email ? "Field is required" : "";
      website_linkError = !values?.website_link ? "Field is required" : "";
      donation_linkError = !values?.donation_link ? "Field is required" : "";
    }

    if (emailError) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        amazn_email_id: emailError,
      }));
      setLoading(false);
      return;
    }

    if (
      account_holder_nameError ||
      branchNameError ||
      accountNumberError ||
      bank_nameError ||
      bank_branch_addressError ||
      iban_ifscError ||
      expert_addressError ||
      paypal_email_idError ||
      countryError ||
      contact_person_nameError ||
      contact_numberError ||
      emailpaypalError ||
      website_linkError ||
      donation_linkError || pan_adhar_link_status_error
    ) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        account_holder_name: account_holder_nameError,
        branch_name: branchNameError,
        account_number: accountNumberError,
        bank_branch_address: bank_branch_addressError,
        iban_ifsc: iban_ifscError,
        expert_address: expert_addressError,
        paypal_email_id: paypal_email_idError,
        name_of_charity: name_of_charityError,
        country: countryError,
        contact_person_name: contact_person_nameError,
        contact_number: contact_numberError,
        email: emailpaypalError,
        website_link: website_linkError,
        donation_link: donation_linkError,
        pan_adhar_link_status_error: pan_adhar_link_status_error
      }));
      setLoading(false);
      return;
    }

    try {
      if (viewId) {
        const res = await fetch(`${API_PATH.EXPERT_PAYMENT_UPDATE}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          },
          body: JSON.stringify({
            payment_method: method,
            payment_id: viewId,
            ...values,
          }),
        });

        const result = await res.json();
        console.log(result, "result");
        if (result) {
          fetchData();
          if (result.status === 200 || result.status === true) {
            // console.log(res.status);
            notification.success({
              message: "Payment details has been updated",
              icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
              style: {
                backgroundColor: "#2ecc71",
                color: "#fff !important",
                border: "1px solid #52c41a",
              },
              duration: 5,
              placement: "topRight",
            });
            // window.location.reload();
            setShow(false);
          }
        } else {
          notification.error({
            message: "Error: Something went wrong server error",
            icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
            style: {
              backgroundColor: "#e74c3c",
              color: "#fff",
              border: "1px solid #c0392b",
            },
            duration: 5,
            placement: "topRight",
          });
        }
      } else {
        const res = await fetch(`${API_PATH.EXPERT_PAYMENT_ADD}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          },
          body: JSON.stringify({
            payment_method: method,
            ...values,
          }),
        });

        const result = await res.json();
        console.log(result, "result");
        if (result) {
          fetchData();
          if (result.status === 200 || result.status === true) {
            // console.log(res.status);
            fetchData();
            setShow(false);

            notification.success({
              message: "Payment method has been updated",
              icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
              style: {
                backgroundColor: "#2ecc71",
                color: "#fff !important",
                border: "1px solid #52c41a",
              },
              duration: 5,
              placement: "topRight",
            });

            // window.location.reload();
          }
        } else {
          notification.error({
            message: "Error: Something went wrong server error",
            icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
            style: {
              backgroundColor: "#e74c3c",
              color: "#fff",
              border: "1px solid #c0392b",
            },
            duration: 5,
            placement: "topRight",
          });
        }
      }
    } catch (error) {
      setLoading(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  };

  const fetchData = async () => {
    try {
      const res = await axios?.get(
        `${API_PATH?.EXPERT_PAYMENT_LIST}/${userData?.data?._id}`
      );
      console.log(res?.data, "resPaymentData");
      const paymentData = res?.data?.data; // Assuming the API response is stored in a variable called paymentData

      if (paymentData && paymentData?.length > 0) {
        const desiredPaymentMethods = ["amazon", "bank", "paypal", "charity"];

        const prefillData = {};

        desiredPaymentMethods?.forEach((desiredPaymentMethod) => {
          const desiredPayment = paymentData?.find(
            (payment) => payment?.payment_method === desiredPaymentMethod
          );

          if (desiredPayment) {
            if (desiredPaymentMethod === "amazon") {
              prefillData.amazn_email_id = desiredPayment?.amazn_email_id || "";
              console.log(desiredPayment?.createdAt, "createdAt");
              setAmazonDate(desiredPayment?.createdAt);
              console.log(desiredPayment?.status, "status");
              setAccount(desiredPayment?.status);
              setAmazonId(desiredPayment?._id);
            } else if (desiredPaymentMethod === "bank") {
              prefillData.account_holder_name =
                desiredPayment?.account_holder_name || "";
              prefillData.bank_name = desiredPayment?.bank_name || "";
              prefillData.bank_branch_address =
                desiredPayment?.bank_branch_address || "";
              prefillData.account_number = desiredPayment?.account_number || "";
              prefillData.currency = desiredPayment?.currency || "";

              prefillData.iban_ifsc = desiredPayment?.iban_ifsc || "";
              prefillData.branch_code = desiredPayment?.branch_code || "";
              prefillData.bic_or_swift = desiredPayment?.bic_or_swift || "";
              prefillData.ach_routing_code = desiredPayment?.ach_routing_code || "";
              prefillData.micr_code = desiredPayment?.micr_code || "";
              prefillData.pan_number = desiredPayment?.pan_number || "";
              prefillData.expert_address = desiredPayment?.expert_address || "";
              console.log(
                desiredPayment?.pan_adhar_link_status,
                "pan_adhar_link_status"
              );
              prefillData.pan_adhar_link_status =
                desiredPayment?.pan_adhar_link_status === "yes" ? "yes" : "no";
              console.log(desiredPayment?.status, "statusbank");
              setBankDate(desiredPayment?.createdAt);
              setAccountBnk(desiredPayment?.status);
              setBankId(desiredPayment?._id);
            } else if (desiredPaymentMethod === "paypal") {
              prefillData.paypal_email_id =
                desiredPayment?.paypal_email_id || "";
              console.log(desiredPayment?.status, "statuspaypal");
              setPaypalDate(desiredPayment?.createdAt);
              setAccountPypl(desiredPayment?.status);
              setPaypalId(desiredPayment?._id);
            } else if (desiredPaymentMethod === "charity") {
              prefillData.name_of_charity =
                desiredPayment?.name_of_charity || "";
              prefillData.country = desiredPayment?.country || "";
              prefillData.contact_person_name =
                desiredPayment?.contact_person_name || "";
              prefillData.contact_number = desiredPayment?.contact_number || "";
              prefillData.email = desiredPayment?.email || "";
              prefillData.website_link = desiredPayment?.website_link || "";
              prefillData.donation_link = desiredPayment?.donation_link || "";
              console.log(desiredPayment?.status, "statuschar");
              setCharityDate(desiredPayment?.createdAt);
              setAccountChr(desiredPayment?.status);
              setCharityId(desiredPayment?._id);
            }
          }
        });

        // Set the expert ID
        prefillData.expert_id = userData?.data?._id || "";
        setValues((prevValues) => ({
          ...prevValues,
          ...prefillData,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleIconClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const DeletePayment = async () => {
    console.log(deleteId, "deleteId");
    // e.preventDefault();
    setLoading(true);

    try {
      const res = await fetch(`${API_PATH.EXPERT_PAYMENT_DELETE}/${deleteId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
      });

      const result = await res.json();
      if (result) {
        setTimeout(() => {
          fetchData();
        }, 1000);
        window.location.reload();
        if (res.status === 200 || res.status === true) {
          setModal3Open(false);

          notification.success({
            message: "Payment method has been deleted",
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#2ecc71",
              color: "#fff !important",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          });

          // window.location.reload();
        } else {
          notification.error({
            message: "Error: Something went wrong server error",
            icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
            style: {
              backgroundColor: "#e74c3c",
              color: "#fff",
              border: "1px solid #c0392b",
            },
            duration: 5,
            placement: "topRight",
          });
        }
      }
    } catch (error) {
      setLoading(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  };

  const getMangerId = (_id) => {
    setViewId(_id);
    console.log(_id);
  };

  const getpayId = (_id) => {
    setdeleteId(_id);
    console.log(_id);
  };

  const items = [
    {
      key: "1",
      label: (
        <a
          rel="noopener noreferrer"
          onClick={() => openModal("amazon")}
          className="d-flex align-items-center"
        >
          <svg
            stroke="currentColor"
            fill="currentColor"
            stroke-width="0"
            viewBox="0 0 16 16"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M13.23 1h-1.46L3.52 9.25l-.16.22L1 13.59 2.41 15l4.12-2.36.22-.16L15 4.23V2.77L13.23 1zM2.41 13.59l1.51-3 1.45 1.45-2.96 1.55zm3.83-2.06L4.47 9.76l8-8 1.77 1.77-8 8z"></path>
          </svg>
          <span className="ps-2">Edit</span>
        </a>
      ),
    },
    {
      key: "3",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.luohanacademy.com"
          className="d-flex align-items-center"
        >
          <svg
            stroke="currentColor"
            fill="currentColor"
            stroke-width="0"
            viewBox="0 0 1024 1024"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z"></path>
          </svg>
          <span className="ps-2">Delete</span>
        </a>
      ),
    },
  ];

  const getDropdownItems = (action, _id) => {
    // console.log(_id, "paymentid");
    let dropdownItems = [];

    // Customize the dropdown items based on the action parameter
    if (action === "amazon") {
      dropdownItems = [
        {
          key: "edit",
          label: "Edit",
          onClick: () => {
            openModal("amazon");
            getMangerId(_id);
          },
        },
        {
          key: "delete",
          label: "Delete",
          onClick: () => {
            setModal3Open(true);
            getpayId(_id);
          },
        },
        // Add more items specific to the "amazon" action if needed
      ];
    } else if (action === "bank") {
      dropdownItems = [
        {
          key: "edit",
          label: "Edit",
          onClick: () => {
            openModal("bank");
            getMangerId(_id);
          },
        },
        {
          key: "delete",
          label: "Delete",
          onClick: () => {
            setModal3Open(true);
            getpayId(_id);
          },
        },
        // Add more items specific to the "paypal" action if needed
      ];
    } else if (action === "paypal") {
      dropdownItems = [
        {
          key: "edit",
          label: "Edit",
          onClick: () => {
            openModal("paypal");
            getMangerId(_id);
          },
        },
        {
          key: "delete",
          label: "Delete",
          onClick: () => {
            setModal3Open(true);
            getpayId(_id);
          },
        },
        // Add more items specific to the "bank" action if needed
      ];
    } else if (action === "charity") {
      dropdownItems = [
        {
          key: "edit",
          label: "Edit",
          onClick: () => {
            openModal("charity");
            getMangerId(_id);
          },
        },
        {
          key: "delete",
          label: "Delete",
          onClick: () => {
            setModal3Open(true);
            getpayId(_id);
          },
        },
        // Add more items specific to the "charity" action if needed
      ];
    }

    return dropdownItems;
  };

  const convertDate = (date) => {
    //console.log(date)
    if (date !== null && date !== "") {
      var d = new Date(date);
      var day = d.getDate();
      var x = d.toDateString().substr(4, 3);
      var year = d.getFullYear();
      let p = `${x} ${day}, ${year}`;
      //console.log(p)
      return p;
    } else {
      return "---------";
    }
  };

  function handleSwitchChange(checked, actionName, actionId) {
    console.log(`selected ${checked}`);
    console.log(actionId, "actionId");
    console.log(actionName, "actionName");
    switch (actionName) {
      case "amazon":
        setAccount(checked);
        setAmazonId(actionId);
        break;
      case "bank":
        setAccountBnk(checked);
        setBankId(actionId);
        break;
      case "paypal":
        setAccountPypl(checked);
        setPaypalId(actionId);
        break;
      case "charity":
        setAccountChr(checked);
        setCharityId(actionId);
        break;
      default:
        break;
    }

    if (checked) {
      console.log("checked");
      const requestBody = {
        payment_id: actionId,
        expert_id: userData?.data?._id,
      };
      fetch(`${API_PATH.EXPERT_PAYMENT_PRIMATY_SET}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
        body: JSON.stringify(requestBody),
      })
        .then((response) => response.json())
        .then((data) => {
          // Handle the response if needed
          console.log(data);
          fetchData();
        })
        .catch((error) => {
          // Handle any errors
          console.error("Error submitting data:", error);
          notification.error({
            message: "Error: Something went wrong server error",
            icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
            style: {
              backgroundColor: "#e74c3c",
              color: "#fff",
              border: "1px solid #c0392b",
            },
            duration: 5,
            placement: "topRight",
          });
        });
    }
  }

  const columns = [
    {
      title: "Project Name",
      dataIndex: "projectName",
      key: "projectName",
      render: (_, { projectName }) => (
        <>
          {console.log(projectName?.length, "projectName")}

          {projectName?.map((data) => {
            return (
              <div className="d-flex align-items-center">
                <div className="me-3">{data?.icon}</div>
                <div>
                  <h5
                    className="mt-0 mb-1"
                    style={{ fontSize: "16px", fontWeight: 500 }}
                  >
                    {data?.method}
                  </h5>
                  <div className="d-flex">
                    <div className="text-muted">
                      <span>Added on {data?.date}</span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      ),
    },
    {
      title: "status",
      dataIndex: "status",
      key: "status",
      width: "280px",
      render: (_, { status, action }) => (
        <>
          {status?.map((data) => {
            return (
              <div
                className="d-flex align-items-center"
                style={{ color: data.stts && "#5746EB" }}
              >
                <span className="pe-2" style={{ fontWeight: 500 }}>
                  Primary Method
                </span>
                <Switch
                  className="themedChecked"
                  checked={data?.stts}
                  onChange={(checked) =>
                    handleSwitchChange(checked, action?.name, action?._id)
                  }
                />
              </div>
            );
          })}
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      width: "20px",
      render: (_, record) => {
        const { action } = record;
        // {console.log(action?._id, "_id")}

        const dropdownItems = getDropdownItems(action?.name, action?._id);

        return (
          <>
            {dropdownItems.length > 0 && (
              <Dropdown
                trigger={["click"]}
                overlay={
                  <Menu>
                    {dropdownItems.map((item) => (
                      <Menu.Item key={item.key} onClick={item.onClick}>
                        {item.label}
                      </Menu.Item>
                    ))}
                  </Menu>
                }
              >
                <a onClick={(e) => e.preventDefault()}>
                  <svg
                    stroke="currentColor"
                    fill="none"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="12" cy="12" r="1"></circle>
                    <circle cx="12" cy="5" r="1"></circle>
                    <circle cx="12" cy="19" r="1"></circle>
                  </svg>
                </a>
              </Dropdown>
            )}
          </>
        );
      },
    },
  ];

  // console.log(amazonId, "amazonId");

  const data = [
    ...(amazonId?.length > 0
      ? [
        {
          key: "1",
          projectName: [
            {
              icon: <AmazonIcon className="tableIcon" color="5545E7" />,
              method: "Amazon",
              date: convertDate(amazonDate),
            },
          ],
          status: [
            {
              stts: account,
            },
          ],
          action: {
            _id: amazonId,
            name: "amazon",
          },
        },
      ]
      : []),
    ...(bankId?.length > 0
      ? [
        {
          key: "2",
          projectName: [
            {
              icon: <BankIcon className="tableIcon" color="5545E7" />,
              method: "Bank",
              date: convertDate(bankDate),
            },
          ],
          status: [
            {
              stts: accountBnk,
            },
          ],
          action: {
            _id: bankId,
            name: "bank",
          },
        },
      ]
      : []),
    ...(paypalId?.length > 0
      ? [
        {
          key: "3",
          projectName: [
            {
              icon: <PaypalIcon className="tableIcon" color="5545E7" />,
              method: "Paypal",
              date: convertDate(paypalDate),
            },
          ],
          status: [
            {
              stts: accountPypl,
            },
          ],
          action: {
            _id: paypalId,
            name: "paypal",
          },
        },
      ]
      : []),
    ...(charityId?.length > 0
      ? [
        {
          key: "4",
          projectName: [
            {
              icon: <CharityIcon className="tableIcon" color="5545E7" />,
              method: "Charity",
              date: convertDate(charityDate),
            },
          ],
          status: [
            {
              stts: accountChr,
            },
          ],
          action: {
            _id: charityId,
            name: "charity",
          },
        },
      ]
      : []),
  ];

  function openModal(item) {
    setShow(true);
    setMethod(item);
  }

  const footer = (
    <div>
      <Button key="cancel" onClick={() => setModal3Open(false)}>
        No stay
      </Button>
      <Button
        key="submit"
        onClick={() => {
          DeletePayment();
          setModal3Open(false);
        }}
      >
        Yes Delete
      </Button>
    </div>
  );

  const onChange = (checked) => {
    console.log(`switch to ${checked}`);
    const selectValue = checked;
    setValues({
      ...values,
      pan_adhar_link_status: selectValue ? "yes" : "no",
    });
  };

  const validatePAN = (_, value) => {
    if (!value) {
      return Promise.reject("Please enter PAN number");
    }

    const panRegex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
    if (!panRegex.test(value)) {
      return Promise.reject("Invalid PAN number");
    }

    return Promise.resolve();
  };

  document.title = "Expert-Payments";

  return (
    <>
      <ExpertDashboard />
      <div className="page-wrapper chiller-theme toggled">
        <div className="main-sec d-lg-flex">
          <Sidebar />
        </div>
        <main className="body-total content-wrapper float-start w-100">
          <div className="container-fluid">
            <Row className="justify-content-center marginT">
              <Col xs={11}>
                <Row>
                  <Col>
                    <h5
                      className="d-flex align-items-center mt-0"
                      style={{ fontWeight: 600, fontSize: "26px" }}
                      onClick={handleIconClick}
                    >
                      <span
                        className="pe-3 bckIcon"
                        style={{ cursor: "pointer" }}
                      >
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          stroke-width="0"
                          viewBox="0 0 320 512"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"></path>
                        </svg>
                      </span>

                      <span>Payment Details</span>
                    </h5>
                  </Col>
                </Row>
                <Row className="row row-cols-1 row-cols-md-3 g-3">
                  <Col>
                    {amazonId?.length > 0 ? (
                      <div className="bg-white pb-4 ps-4 pe-4 expertBox responsivePaymentBox">
                        <AmazonIcon
                          className="paymentIconSize"
                          color="03C4DC"
                        />
                        <h4 className="paymentTitle">Amazon Gift Card</h4>
                        <p className="paymentSubTitle text-muted">
                          Receive an Amazon Gift Card
                        </p>
                      </div>
                    ) : (
                      <div
                        className="bg-white pb-4 ps-4 pe-4 expertBox responsivePaymentBox"
                        style={{ cursor: "pointer" }}
                        onClick={() => openModal("amazon")}
                      >
                        <AmazonIcon
                          className="paymentIconSize"
                          color="03C4DC"
                        />
                        <h4 className="paymentTitle">Amazon Gift Card</h4>
                        <p className="paymentSubTitle text-muted">
                          Receive an Amazon Gift Card
                        </p>
                      </div>
                    )}
                  </Col>

                  <Col>
                    {bankId?.length > 0 ? (
                      <div className="bg-white pb-4 ps-4 pe-4 expertBox responsivePaymentBox">
                        <BankIcon className="paymentIconSize" color="03C4DC" />
                        <h4 className="paymentTitle">Add Bank Account</h4>
                        <p className="paymentSubTitle text-muted">
                          Receive a Direct Bank Transfer
                        </p>
                      </div>
                    ) : (
                      <div
                        className="bg-white pb-4 ps-4 pe-4 expertBox responsivePaymentBox"
                        style={{ cursor: "pointer" }}
                        onClick={() => openModal("bank")}
                      >
                        <BankIcon className="paymentIconSize" color="03C4DC" />
                        <h4 className="paymentTitle">Add Bank Account</h4>
                        <p className="paymentSubTitle text-muted">
                          Receive a Direct Bank Transfer
                        </p>
                      </div>
                    )}
                  </Col>

                  {/* <Col>
                    {paypalId?.length > 0 ? (
                      <div className="bg-white pb-4 ps-4 pe-4 expertBox responsivePaymentBox">
                        <PaypalIcon
                          className="paymentIconSize"
                          color="03C4DC"
                        />
                        <h4 className="paymentTitle">Add Paypal Account</h4>
                        <p className="paymentSubTitle text-muted">
                          Receive using a PayPal ID
                        </p>
                      </div>
                    ) : (
                      <div
                        className="bg-white pb-4 ps-4 pe-4 expertBox responsivePaymentBox"
                        style={{ cursor: "pointer" }}
                        onClick={() => openModal("paypal")}
                      >
                        <PaypalIcon
                          className="paymentIconSize"
                          color="03C4DC"
                        />
                        <h4 className="paymentTitle">Add Paypal Account</h4>
                        <p className="paymentSubTitle text-muted">
                          Receive using a PayPal ID
                        </p>
                      </div>
                    )}
                  </Col> */}

                  <Col>
                    {charityId?.length > 0 ? (
                      <div className="bg-white pb-4 ps-4 pe-4 expertBox responsivePaymentBox">
                        <CharityIcon
                          className="paymentIconSize"
                          color="03C4DC"
                        />
                        <h4 className="paymentTitle">Charitable Donation</h4>
                        <p className="paymentSubTitle text-muted">
                          Donate to a Charity
                        </p>
                      </div>
                    ) : (
                      <div
                        className="bg-white pb-4 ps-4 pe-4 expertBox responsivePaymentBox"
                        style={{ cursor: "pointer" }}
                        onClick={() => openModal("charity")}
                      >
                        <CharityIcon
                          className="paymentIconSize"
                          color="03C4DC"
                        />
                        <h4 className="paymentTitle">Charitable Donation</h4>
                        <p className="paymentSubTitle text-muted">
                          Donate to a Charity
                        </p>
                      </div>
                    )}
                  </Col>
                </Row>

                <Row className="mt-5">
                  <Col>
                    <h5
                      className="d-flex align-items-center mt-0"
                      style={{ fontWeight: 600, fontSize: "26px" }}
                    >
                      <span>Added Payment Methods</span>
                    </h5>
                  </Col>

                  <Col xs={12}>
                    <Table
                      columns={columns}
                      dataSource={data}
                      showHeader={false}
                      scroll={{ x: 800 }}
                    ></Table>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </main>

        <BootstrapModal
          show={show}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          keyboard={false}
        >
          <BootstrapModal.Body className="expertEditProfile">
            <div
              className="position-absolute closeIcons"
              onClick={() => setShow(false)}
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 24 24"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill="none"
                  stroke="#000"
                  stroke-width="2"
                  d="M3,3 L21,21 M3,21 L21,3"
                ></path>
              </svg>
            </div>
            <div className="text-center">
              <h5 className="">
                {method === "amazon"
                  ? "Amazon Gift Card"
                  : method === "bank"
                    ? "Add Bank Details"
                    : method === "paypal"
                      ? "Add Paypal details"
                      : "Add Charity Details"}
              </h5>
            </div>
            <Form
              layout="vertical"
              autoComplete="off"
              initialValues={values}
              form={form}
            >
              {method === "amazon" && (
                <Row>
                  <Col xs={12}>
                    <Form.Item
                      name="amazn_email_id"
                      label="Please enter your registered Email ID"
                      rules={emailRules}
                    >
                      <Input
                        type="text"
                        autoComplete="nope"
                        size="large"
                        placeholder="xyz@example.com"
                        name="amazn_email_id"
                        value={values?.amazn_email_id}
                        onChange={handleInputs}
                      ></Input>
                    </Form.Item>
                  </Col>
                </Row>
              )}

              {method === "bank" && (
                <Row>
                  <Col xs={12} md={6}>
                    <Form.Item
                      name="account_holder_name"
                      label="Account Holder Name"
                      rules={[
                        {
                          required: true,
                          message: "Field is required",
                        },
                      ]}
                    >
                      <Input
                        type="text"
                        autoComplete="nope"
                        size="large"
                        placeholder=""
                        name="account_holder_name"
                        value={values?.account_holder_name}
                        onChange={handleInputs}
                      ></Input>
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Item
                      name="account_number"
                      label="Account Number"
                      rules={[
                        {
                          required: true,
                          message: "Field is required",
                        },
                      ]}
                    >
                      <Input
                        type="text"
                        autoComplete="nope"
                        size="large"
                        placeholder="316977XXXX"
                        name="account_number"
                        value={values?.account_number}
                        onChange={handleInputs}
                      ></Input>
                    </Form.Item>
                  </Col>

                  <Col xs={12} md={6}>
                    <Form.Item
                      name="bank_name"
                      label="Bank Name"
                      rules={[
                        {
                          required: true,
                          message: "Field is required",
                        },
                      ]}
                    >
                      <Input
                        type="text"
                        autoComplete="nope"
                        size="large"
                        placeholder=""
                        name="bank_name"
                        value={values?.bank_name}
                        onChange={handleInputs}
                      ></Input>
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Item
                      name="bank_branch_address"
                      label="Bank Branch Address"
                      rules={[
                        {
                          required: true,
                          message: "Field is required",
                        },
                      ]}
                    >
                      <Input
                        type="text"
                        autoComplete="nope"
                        size="large"
                        placeholder=""
                        name="bank_branch_address"
                        value={values?.bank_branch_address}
                        onChange={handleInputs}
                      ></Input>
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={4}>
                    <Form.Item
                      name="iban_ifsc"
                      label="IFSC/IBAN"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Field is required",
                    //   },
                    // ]}
                    >
                      <Input
                        type="text"
                        autoComplete="nope"
                        size="large"
                        placeholder=""
                        name="iban_ifsc"
                        value={values?.iban_ifsc}
                        onChange={handleInputs}
                      ></Input>
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={4}>
                    <Form.Item
                      name="bic_or_swift"
                      label="BIC/SWIFT"
                    >
                      <Input
                        type="text"
                        autoComplete="nope"
                        size="large"
                        placeholder=""
                        name="bic_or_swift"
                        value={values?.bic_or_swift}
                        onChange={handleInputs}
                      ></Input>
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={4}>
                    <Form.Item
                      name="branch_code"
                      label="Branch Code/BSB Code"
                    >
                      <Input
                        type="number"
                        autoComplete="nope"
                        size="large"
                        placeholder=""
                        name="branch_code"
                        value={values?.branch_code}
                        onChange={handleInputs}
                      ></Input>
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Item
                      name="ach_routing_code"
                      label="ACH Routing Code"
                    >
                      <Input
                        type="number"
                        autoComplete="nope"
                        size="large"
                        placeholder=""
                        name="ach_routing_code"
                        value={values?.ach_routing_code}
                        onChange={handleInputs}
                      ></Input>
                    </Form.Item>
                  </Col>

                  <Col xs={12} md={6}>
                    <Form.Item name="micr_code" label="MICR/Sort Code">
                      <Input
                        type="text"
                        autoComplete="nope"
                        size="large"
                        placeholder=""
                        name="micr_code"
                        value={values?.micr_code}
                        onChange={handleInputs}
                      ></Input>
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={12}>
                    <Form.Item name="currency" label="Payment Currency (Please select the currency in which you would prefer to receive the consultation fee)"
                      rules={[
                        {
                          required: true,
                          message: "Field is required",
                        },
                      ]}>
                      <Select
                        size="large"
                        placeholder="Select Preferred Currency"
                        name="currency"
                        showSearch
                        // onSearch={setFilteredIndust}
                        filterOption={(input, option) => option?.value
                          ?.toLowerCase()
                          ?.indexOf(input?.toLowerCase()) >= 0

                        }
                        onChange={(newValue, option) => {
                          setValues({ ...values, currency: newValue });
                          form.setFieldValue("currency", newValue);
                        }}
                        // value={zoomData?.client_user}
                        options={currencyList?.map((item) => ({
                          label: <div className="d-flex flex-direction-row align-items-center justify-content-start gap-2"> <span>{item?.code}</span> <span>({item?.name})</span></div>,
                          value: item?.code,
                          key: item?.code,
                        }))}
                        virtual={false}
                        allowClear
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={12}>
                    <Form.Item
                      name="expert_address"
                      label="Address (with postal code)"
                      rules={[
                        {
                          required: true,
                          message: "Field is required",
                        },
                      ]}
                    >
                      <Input
                        type="text"
                        autoComplete="nope"
                        size="large"
                        placeholder=""
                        name="expert_address"
                        value={values?.expert_address}
                        onChange={handleInputs}
                      ></Input>
                    </Form.Item>

                  </Col>

                  {userData?.data?.country?.toLowerCase() === "india" ||
                    userData?.data?.contact_code === "91" ||
                    userData?.data?.contact_code === "+91" ? (
                    <>
                      <Col xs={12} md={6}>
                        <Form.Item
                          name="pan_number"
                          label="PAN (of the account holder)"
                          rules={[{ validator: validatePAN }]}
                        >
                          <Input
                            type="text"
                            autoComplete="nope"
                            className="text-uppercase"
                            size="large"
                            placeholder=""
                            name="pan_number"
                            value={values?.pan_number}
                            onChange={handleInputs}
                          ></Input>
                        </Form.Item>
                      </Col>
                      <Col xs={12} md={6}>
                        <Form.Item
                          name="pan_adhar_link_status"
                          label="PAN Link with Aadhar"
                          rules={[
                            {
                              required: true,
                              message: "Field is required",
                            },
                          ]}
                        >
                          <Select
                            size="large"
                            placeholder="PAN Link with Aadhar"
                            name="pan_adhar_link_status"
                            onChange={(newValue, option) => {
                              console.log('newValue: ', newValue);
                              setValues({
                                ...values,
                                pan_adhar_link_status: newValue,
                              });
                            }}
                            value={values?.pan_adhar_link_status}
                            options={[{
                              label: <div className="d-flex flex-direction-row align-items-center justify-content-start gap-2"> <span>Yes</span></div>,
                              value: "yes",
                              key: "yes",
                            }, {
                              label: <div className="d-flex flex-direction-row align-items-center justify-content-start gap-2"> <span>No</span></div>,
                              value: "no",
                              key: "no",
                            }]}

                            virtual={false}
                            allowClear
                          />
                          {/* <Switch
                            checked={values?.pan_adhar_link_status === "yes"}
                            onChange={onChange}
                            style={{ width: "15%" }}
                          />
                          <p className="text-muted fs-6">No/Yes</p> */}
                        </Form.Item>
                      </Col>
                    </>
                  ) : null}
                </Row>
              )}

              {method === "paypal" && (
                <Row>
                  <Col xs={12}>
                    <Form.Item
                      name="paypal_email_id"
                      label="Please enter your registered PayPal ID"
                      rules={[
                        {
                          required: true,
                          message: "Field is required",
                        },
                      ]}
                    >
                      <Input
                        type="text"
                        autoComplete="nope"
                        size="large"
                        placeholder="xyz@example.com"
                        name="paypal_email_id"
                        value={values?.paypal_email_id}
                        onChange={handleInputs}
                      ></Input>
                    </Form.Item>
                  </Col>
                </Row>
              )}

              {method === "charity" && (
                <Row>
                  <Col xs={12} md={4}>
                    <Form.Item
                      name="name_of_charity"
                      label="Name of Charity"
                      rules={[
                        {
                          required: true,
                          message: "Field is required",
                        },
                      ]}
                    >
                      <Input
                        type="text"
                        autoComplete="nope"
                        size="large"
                        placeholder=""
                        name="name_of_charity"
                        value={values?.name_of_charity}
                        onChange={handleInputs}
                      ></Input>
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={4}>
                    <Form.Item
                      name="contact_person_name"
                      label="Contact Person Name"
                      rules={[
                        {
                          required: true,
                          message: "Field is required",
                        },
                      ]}
                    >
                      <Input
                        type="text"
                        autoComplete="nope"
                        size="large"
                        placeholder=""
                        name="contact_person_name"
                        value={values?.contact_person_name}
                        onChange={handleInputs}
                      ></Input>
                    </Form.Item>
                  </Col>

                  <Col xs={12} md={4}>
                    <Form.Item
                      name="country"
                      label="Country"
                      rules={[
                        {
                          required: true,
                          message: "Field is required",
                        },
                      ]}
                    >
                      <Select
                        size="large"
                        style={{ width: "100%" }}
                        name="country"
                        value={values?.country}
                        onChange={handleCountry}
                        options={[
                          {
                            value: "India",
                            label: "India",
                          },
                          {
                            value: "Foreign",
                            label: "Foreign",
                          },
                        ]}
                      ></Select>
                    </Form.Item>
                  </Col>

                  <Col xs={12} md={6}>
                    <Form.Item
                      name="contact_number"
                      label="Contact Number With Country Code"
                      rules={[
                        {
                          required: true,
                          message: "Field is required",
                        },
                      ]}
                    >
                      <Input
                        type="text"
                        autoComplete="nope"
                        size="large"
                        placeholder="+91 XXXXX XXXXX"
                        name="contact_number"
                        value={values?.contact_number}
                        onChange={handleInputs}
                      ></Input>
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Item name="email" label="Email" rules={emailRules}>
                      <Input
                        type="text"
                        autoComplete="nope"
                        size="large"
                        placeholder=""
                        name="email"
                        value={values?.email}
                        onChange={handleInputs}
                      ></Input>
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Item
                      name="website_link"
                      label="Website Link"
                      rules={[
                        {
                          required: true,
                          message: "Field is required",
                        },
                      ]}
                    >
                      <Input
                        type="text"
                        autoComplete="nope"
                        size="large"
                        placeholder=""
                        name="website_link"
                        value={values?.website_link}
                        onChange={handleInputs}
                      ></Input>
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Item
                      name="donation_link"
                      label="Donation Link"
                      rules={[
                        {
                          required: true,
                          message: "Field is required",
                        },
                      ]}
                    >
                      <Input
                        type="text"
                        autoComplete="nope"
                        size="large"
                        placeholder=""
                        name="donation_link"
                        value={values?.donation_link}
                        onChange={handleInputs}
                      ></Input>
                    </Form.Item>
                  </Col>
                </Row>
              )}

              {method === "amazon" && (
                <Row>
                  <Col className="text-end">
                    <a
                      className="btn btn-responsive expertButtonNext me-3"
                      onClick={() => setShow(false)}
                    >
                      Close
                    </a>
                    <button
                      className="btn btn-responsive viewButton"
                      onClick={() => handleSubmit("amazon")}
                    >
                      Submit
                    </button>
                  </Col>
                </Row>
              )}

              {method === "bank" && (
                <Row>
                  <Col className="text-end">
                    <a
                      className="btn btn-responsive expertButtonNext me-3"
                      onClick={() => setShow(false)}
                    >
                      Close
                    </a>
                    <button
                      className="btn btn-responsive viewButton"
                      onClick={() => handleSubmit("bank")}
                    >
                      Submit
                    </button>
                  </Col>
                </Row>
              )}

              {method === "paypal" && (
                <Row>
                  <Col className="text-end">
                    <a
                      className="btn btn-responsive expertButtonNext me-3"
                      onClick={() => setShow(false)}
                    >
                      Close
                    </a>
                    <button
                      className="btn btn-responsive viewButton"
                      onClick={() => handleSubmit("paypal")}
                    >
                      Submit
                    </button>
                  </Col>
                </Row>
              )}

              {method === "charity" && (
                <Row>
                  <Col className="text-end">
                    <a
                      className="btn btn-responsive expertButtonNext me-3"
                      onClick={() => setShow(false)}
                    >
                      Close
                    </a>
                    <button
                      className="btn btn-responsive viewButton"
                      onClick={() => handleSubmit("charity")}
                    >
                      Submit
                    </button>
                  </Col>
                </Row>
              )}
            </Form>
          </BootstrapModal.Body>
        </BootstrapModal>

        <Modal
          title="Delete."
          open={modal3Open}
          onOk={() => setModal3Open(false)}
          onCancel={() => setModal3Open(false)}
          visible={true}
          footer={footer}
        >
          <div className="mt-4" style={{ width: "100%", textAlign: "left" }}>
            <p>
              This action cannot be undone. Are you sure you want to delete this
              payment method ?
            </p>{" "}
          </div>
        </Modal>
      </div>
    </>
  );
}

export default Payment;
