export const currencyList = [{
    "code": "AED",
    "numeric": "784",
    "name": "UAE Dirham",
    "symbol": "Ø¯.Ø¥",
    "decimal": 2
},
{
    "code": "AFN",
    "numeric": "971",
    "name": "Afghani",
    "symbol": "Ø‹",
    "decimal": 2
},
{
    "code": "ALL",
    "numeric": "008",
    "name": "Lek",
    "symbol": "L",
    "decimal": 2
},
{
    "code": "AMD",
    "numeric": "051",
    "name": "Armenian Dram",
    "symbol": "Õ¤Ö€.",
    "decimal": 2
},
{
    "code": "ANG",
    "numeric": "532",
    "name": "Netherlands Antillean Guilder",
    "symbol": "ƒ",
    "decimal": 2
},
{
    "code": "AOA",
    "numeric": "973",
    "name": "Kwanza",
    "symbol": "Kz",
    "decimal": 2
},
{
    "code": "ARS",
    "numeric": "032",
    "name": "Argentine Peso",
    "symbol": "$",
    "decimal": 2
},
{
    "code": "AUD",
    "numeric": "036",
    "name": "Australian Dollar",
    "symbol": "$",
    "decimal": 2
},
{
    "code": "AWG",
    "numeric": "533",
    "name": "Aruban Florin",
    "symbol": "ƒ",
    "decimal": 2
},
{
    "code": "AZN",
    "numeric": "944",
    "name": "Azerbaijan Manat",
    "symbol": "m",
    "decimal": 2
},
{
    "code": "BAM",
    "numeric": "977",
    "name": "Convertible Mark",
    "symbol": "ÐšÐœ",
    "decimal": 2
},
{
    "code": "BBD",
    "numeric": "052",
    "name": "Barbados Dollar",
    "symbol": "$",
    "decimal": 2
},
{
    "code": "BDT",
    "numeric": "050",
    "name": "Taka",
    "symbol": "à§³",
    "decimal": 2
},
{
    "code": "BGN",
    "numeric": "975",
    "name": "Bulgarian Lev",
    "symbol": "Ð»Ð²",
    "decimal": 2
},
{
    "code": "BHD",
    "numeric": "048",
    "name": "Bahraini Dinar",
    "symbol": ".Ø¯.Ø¨",
    "decimal": 3
},
{
    "code": "BIF",
    "numeric": "108",
    "name": "Burundi Franc",
    "symbol": "Fr",
    "decimal": 0
},
{
    "code": "BMD",
    "numeric": "060",
    "name": "Bermudian Dollar",
    "symbol": "$",
    "decimal": 2
},
{
    "code": "BND",
    "numeric": "096",
    "name": "Brunei Dollar",
    "symbol": "$",
    "decimal": 2
},
{
    "code": "BOB",
    "numeric": "068",
    "name": "Boliviano",
    "symbol": "Bs.",
    "decimal": 2
},
{
    "code": "BOV",
    "numeric": "984",
    "name": "Mvdol",
    "symbol": "",
    "decimal": 2
},
{
    "code": "BRL",
    "numeric": "986",
    "name": "Brazilian Real",
    "symbol": "R$",
    "decimal": 2
},
{
    "code": "BSD",
    "numeric": "044",
    "name": "Bahamian Dollar",
    "symbol": "$",
    "decimal": 2
},
{
    "code": "BTN",
    "numeric": "064",
    "name": "Ngultrum",
    "symbol": "Nu.",
    "decimal": 2
},
{
    "code": "BWP",
    "numeric": "072",
    "name": "Pula",
    "symbol": "P",
    "decimal": 2
},
{
    "code": "BYN",
    "numeric": "933",
    "name": "Belarusian Ruble",
    "symbol": "Br",
    "decimal": 2
},
{
    "code": "BZD",
    "numeric": "084",
    "name": "Belize Dollar",
    "symbol": "$",
    "decimal": 2
},
{
    "code": "CAD",
    "numeric": "124",
    "name": "Canadian Dollar",
    "symbol": "$",
    "decimal": 2
},
{
    "code": "CDF",
    "numeric": "976",
    "name": "Congolese Franc",
    "symbol": "Fr",
    "decimal": 2
},
{
    "code": "CHE",
    "numeric": "947",
    "name": "WIR Euro",
    "symbol": "",
    "decimal": 2
},
{
    "code": "CHF",
    "numeric": "756",
    "name": "Swiss Franc",
    "symbol": "Fr",
    "decimal": 2
},
{
    "code": "CHW",
    "numeric": "948",
    "name": "WIR Franc",
    "symbol": "",
    "decimal": 2
},
{
    "code": "CLF",
    "numeric": "990",
    "name": "Unidad de Fomento",
    "symbol": "",
    "decimal": 4
},
{
    "code": "CLP",
    "numeric": "152",
    "name": "Chilean Peso",
    "symbol": "$",
    "decimal": 0
},
{
    "code": "CNY",
    "numeric": "156",
    "name": "Yuan Renminbi",
    "symbol": "¥",
    "decimal": 2
},
{
    "code": "COP",
    "numeric": "170",
    "name": "Colombian Peso",
    "symbol": "$",
    "decimal": 2
},
{
    "code": "COU",
    "numeric": "970",
    "name": "Unidad de Valor Real",
    "symbol": "",
    "decimal": 2
},
{
    "code": "CRC",
    "numeric": "188",
    "name": "Costa Rican Colon",
    "symbol": "â‚¡",
    "decimal": 2
},
{
    "code": "CUC",
    "numeric": "931",
    "name": "Peso Convertible",
    "symbol": "$",
    "decimal": 2
},
{
    "code": "CUP",
    "numeric": "192",
    "name": "Cuban Peso",
    "symbol": "$",
    "decimal": 2
},
{
    "code": "CVE",
    "numeric": "132",
    "name": "Cabo Verde Escudo",
    "symbol": "Esc or $",
    "decimal": 2
},
{
    "code": "CZK",
    "numeric": "203",
    "name": "Czech Koruna",
    "symbol": "KÄ",
    "decimal": 2
},
{
    "code": "DJF",
    "numeric": "262",
    "name": "Djibouti Franc",
    "symbol": "Fr",
    "decimal": 0
},
{
    "code": "DKK",
    "numeric": "208",
    "name": "Danish Krone",
    "symbol": "kr",
    "decimal": 2
},
{
    "code": "DOP",
    "numeric": "214",
    "name": "Dominican Peso",
    "symbol": "$",
    "decimal": 2
},
{
    "code": "DZD",
    "numeric": "012",
    "name": "Algerian Dinar",
    "symbol": "Ø¯.Ø¬",
    "decimal": 2
},
{
    "code": "EGP",
    "numeric": "818",
    "name": "Egyptian Pound",
    "symbol": "Ø¬.Ù…",
    "decimal": 2
},
{
    "code": "ERN",
    "numeric": "232",
    "name": "Nakfa",
    "symbol": "Nfk",
    "decimal": 2
},
{
    "code": "ETB",
    "numeric": "230",
    "name": "Ethiopian Birr",
    "symbol": "Br",
    "decimal": 2
},
{
    "code": "EUR",
    "numeric": "978",
    "name": "Euro",
    "symbol": "€",
    "decimal": 2
},
{
    "code": "FJD",
    "numeric": "242",
    "name": "Fiji Dollar",
    "symbol": "$",
    "decimal": 2
},
{
    "code": "FKP",
    "numeric": "238",
    "name": "Falkland Islands Pound",
    "symbol": "£",
    "decimal": 2
},
{
    "code": "GBP",
    "numeric": "826",
    "name": "Pound Sterling",
    "symbol": "£",
    "decimal": 2
},
{
    "code": "GEL",
    "numeric": "981",
    "name": "Lari",
    "symbol": "áƒš",
    "decimal": 2
},
{
    "code": "GHS",
    "numeric": "936",
    "name": "Ghana Cedi",
    "symbol": "â‚µ",
    "decimal": 2
},
{
    "code": "GIP",
    "numeric": "292",
    "name": "Gibraltar Pound",
    "symbol": "£",
    "decimal": 2
},
{
    "code": "GMD",
    "numeric": "270",
    "name": "Dalasi",
    "symbol": "D",
    "decimal": 2
},
{
    "code": "GNF",
    "numeric": "324",
    "name": "Guinean Franc",
    "symbol": "Fr",
    "decimal": 0
},
{
    "code": "GTQ",
    "numeric": "320",
    "name": "Quetzal",
    "symbol": "Q",
    "decimal": 2
},
{
    "code": "GYD",
    "numeric": "328",
    "name": "Guyana Dollar",
    "symbol": "$",
    "decimal": 2
},
{
    "code": "HKD",
    "numeric": "344",
    "name": "Hong Kong Dollar",
    "symbol": "$",
    "decimal": 2
},
{
    "code": "HNL",
    "numeric": "340",
    "name": "Lempira",
    "symbol": "L",
    "decimal": 2
},
{
    "code": "HRK",
    "numeric": "191",
    "name": "Kuna",
    "symbol": "kn",
    "decimal": 2
},
{
    "code": "HTG",
    "numeric": "332",
    "name": "Gourde",
    "symbol": "G",
    "decimal": 2
},
{
    "code": "HUF",
    "numeric": "348",
    "name": "Forint",
    "symbol": "Ft",
    "decimal": 2
},
{
    "code": "IDR",
    "numeric": "360",
    "name": "Rupiah",
    "symbol": "Rp",
    "decimal": 2
},
{
    "code": "ILS",
    "numeric": "376",
    "name": "New Israeli Sheqel",
    "symbol": "â‚ª",
    "decimal": 2
},
{
    "code": "INR",
    "numeric": "356",
    "name": "Indian Rupee",
    "symbol": "â‚¹",
    "decimal": 2
},
{
    "code": "IQD",
    "numeric": "368",
    "name": "Iraqi Dinar",
    "symbol": "Ø¹.Ø¯",
    "decimal": 3
},
{
    "code": "IRR",
    "numeric": "364",
    "name": "Iranian Rial",
    "symbol": "ï·¼",
    "decimal": 2
},
{
    "code": "ISK",
    "numeric": "352",
    "name": "Iceland Krona",
    "symbol": "kr",
    "decimal": 0
},
{
    "code": "JMD",
    "numeric": "388",
    "name": "Jamaican Dollar",
    "symbol": "$",
    "decimal": 2
},
{
    "code": "JOD",
    "numeric": "400",
    "name": "Jordanian Dinar",
    "symbol": "Ø¯.Ø§",
    "decimal": 3
},
{
    "code": "JPY",
    "numeric": "392",
    "name": "Yen",
    "symbol": "¥",
    "decimal": 0
},
{
    "code": "KES",
    "numeric": "404",
    "name": "Kenyan Shilling",
    "symbol": "Sh",
    "decimal": 2
},
{
    "code": "KGS",
    "numeric": "417",
    "name": "Som",
    "symbol": "Ð»Ð²",
    "decimal": 2
},
{
    "code": "KHR",
    "numeric": "116",
    "name": "Riel",
    "symbol": "áŸ›",
    "decimal": 2
},
{
    "code": "KMF",
    "numeric": "174",
    "name": "Comorian Franc ",
    "symbol": "Fr",
    "decimal": 0
},
{
    "code": "KPW",
    "numeric": "408",
    "name": "North Korean Won",
    "symbol": "â‚©",
    "decimal": 2
},
{
    "code": "KRW",
    "numeric": "410",
    "name": "Won",
    "symbol": "â‚©",
    "decimal": 0
},
{
    "code": "KWD",
    "numeric": "414",
    "name": "Kuwaiti Dinar",
    "symbol": "Ø¯.Ùƒ",
    "decimal": 3
},
{
    "code": "KYD",
    "numeric": "136",
    "name": "Cayman Islands Dollar",
    "symbol": "$",
    "decimal": 2
},
{
    "code": "KZT",
    "numeric": "398",
    "name": "Tenge",
    "symbol": "â‚¸",
    "decimal": 2
},
{
    "code": "LAK",
    "numeric": "418",
    "name": "Lao Kip",
    "symbol": "â‚­",
    "decimal": 2
},
{
    "code": "LBP",
    "numeric": "422",
    "name": "Lebanese Pound",
    "symbol": "Ù„.Ù„",
    "decimal": 2
},
{
    "code": "LKR",
    "numeric": "144",
    "name": "Sri Lanka Rupee",
    "symbol": "Rs",
    "decimal": 2
},
{
    "code": "LRD",
    "numeric": "430",
    "name": "Liberian Dollar",
    "symbol": "$",
    "decimal": 2
},
{
    "code": "LSL",
    "numeric": "426",
    "name": "Loti",
    "symbol": "L",
    "decimal": 2
},
{
    "code": "LYD",
    "numeric": "434",
    "name": "Libyan Dinar",
    "symbol": "Ù„.Ø¯",
    "decimal": 3
},
{
    "code": "MAD",
    "numeric": "504",
    "name": "Moroccan Dirham",
    "symbol": "Ø¯.Ù….",
    "decimal": 2
},
{
    "code": "MDL",
    "numeric": "498",
    "name": "Moldovan Leu",
    "symbol": "L",
    "decimal": 2
},
{
    "code": "MGA",
    "numeric": "969",
    "name": "Malagasy Ariary",
    "symbol": "Ar",
    "decimal": 2
},
{
    "code": "MKD",
    "numeric": "807",
    "name": "Denar",
    "symbol": "Ð´ÐµÐ½",
    "decimal": 2
},
{
    "code": "MMK",
    "numeric": "104",
    "name": "Kyat",
    "symbol": "Ks",
    "decimal": 2
},
{
    "code": "MNT",
    "numeric": "496",
    "name": "Tugrik",
    "symbol": "â‚®",
    "decimal": 2
},
{
    "code": "MOP",
    "numeric": "446",
    "name": "Pataca",
    "symbol": "P",
    "decimal": 2
},
{
    "code": "MRU",
    "numeric": "929",
    "name": "Ouguiya",
    "symbol": "UM",
    "decimal": 2
},
{
    "code": "MUR",
    "numeric": "480",
    "name": "Mauritius Rupee",
    "symbol": "â‚¨",
    "decimal": 2
},
{
    "code": "MVR",
    "numeric": "462",
    "name": "Rufiyaa",
    "symbol": ".Þƒ",
    "decimal": 2
},
{
    "code": "MWK",
    "numeric": "454",
    "name": "Malawi Kwacha",
    "symbol": "MK",
    "decimal": 2
},
{
    "code": "MXN",
    "numeric": "484",
    "name": "Mexican Peso",
    "symbol": "$",
    "decimal": 2
},
{
    "code": "MXV",
    "numeric": "979",
    "name": "Mexican Unidad de Inversion (UDI)",
    "symbol": "",
    "decimal": 2
},
{
    "code": "MYR",
    "numeric": "458",
    "name": "Malaysian Ringgit",
    "symbol": "RM",
    "decimal": 2
},
{
    "code": "MZN",
    "numeric": "943",
    "name": "Mozambique Metical",
    "symbol": "MT",
    "decimal": 2
},
{
    "code": "NAD",
    "numeric": "516",
    "name": "Namibia Dollar",
    "symbol": "$",
    "decimal": 2
},
{
    "code": "NGN",
    "numeric": "566",
    "name": "Naira",
    "symbol": "â‚¦",
    "decimal": 2
},
{
    "code": "NIO",
    "numeric": "558",
    "name": "Cordoba Oro",
    "symbol": "C$",
    "decimal": 2
},
{
    "code": "NOK",
    "numeric": "578",
    "name": "Norwegian Krone",
    "symbol": "kr",
    "decimal": 2
},
{
    "code": "NPR",
    "numeric": "524",
    "name": "Nepalese Rupee",
    "symbol": "â‚¨",
    "decimal": 2
},
{
    "code": "NZD",
    "numeric": "554",
    "name": "New Zealand Dollar",
    "symbol": "$",
    "decimal": 2
},
{
    "code": "OMR",
    "numeric": "512",
    "name": "Rial Omani",
    "symbol": "Ø±.Ø¹.",
    "decimal": 3
},
{
    "code": "PAB",
    "numeric": "590",
    "name": "Balboa",
    "symbol": "B/.",
    "decimal": 2
},
{
    "code": "PEN",
    "numeric": "604",
    "name": "Sol",
    "symbol": "S/.",
    "decimal": 2
},
{
    "code": "PGK",
    "numeric": "598",
    "name": "Kina",
    "symbol": "K",
    "decimal": 2
},
{
    "code": "PHP",
    "numeric": "608",
    "name": "Philippine Peso",
    "symbol": "â‚±",
    "decimal": 2
},
{
    "code": "PKR",
    "numeric": "586",
    "name": "Pakistan Rupee",
    "symbol": "â‚¨",
    "decimal": 2
},
{
    "code": "PLN",
    "numeric": "985",
    "name": "Zloty",
    "symbol": "zÅ‚",
    "decimal": 2
},
{
    "code": "PYG",
    "numeric": "600",
    "name": "Guarani",
    "symbol": "â‚²",
    "decimal": 0
},
{
    "code": "QAR",
    "numeric": "634",
    "name": "Qatari Rial",
    "symbol": "Ø±.Ù‚",
    "decimal": 2
},
{
    "code": "RON",
    "numeric": "946",
    "name": "Romanian Leu",
    "symbol": "L",
    "decimal": 2
},
{
    "code": "RSD",
    "numeric": "941",
    "name": "Serbian Dinar",
    "symbol": "Ð´Ð¸Ð½.",
    "decimal": 2
},
{
    "code": "RUB",
    "numeric": "643",
    "name": "Russian Ruble",
    "symbol": "Ñ€ÑƒÐ±.",
    "decimal": 2
},
{
    "code": "RWF",
    "numeric": "646",
    "name": "Rwanda Franc",
    "symbol": "Fr",
    "decimal": 0
},
{
    "code": "SAR",
    "numeric": "682",
    "name": "Saudi Riyal",
    "symbol": "Ø±.Ø³",
    "decimal": 2
},
{
    "code": "SBD",
    "numeric": "090",
    "name": "Solomon Islands Dollar",
    "symbol": "$",
    "decimal": 2
},
{
    "code": "SCR",
    "numeric": "690",
    "name": "Seychelles Rupee",
    "symbol": "â‚¨",
    "decimal": 2
},
{
    "code": "SDG",
    "numeric": "938",
    "name": "Sudanese Pound",
    "symbol": "£",
    "decimal": 2
},
{
    "code": "SEK",
    "numeric": "752",
    "name": "Swedish Krona",
    "symbol": "kr",
    "decimal": 2
},
{
    "code": "SGD",
    "numeric": "702",
    "name": "Singapore Dollar",
    "symbol": "$",
    "decimal": 2
},
{
    "code": "SHP",
    "numeric": "654",
    "name": "Saint Helena Pound",
    "symbol": "£",
    "decimal": 2
},
{
    "code": "SLL",
    "numeric": "694",
    "name": "Leone",
    "symbol": "Le",
    "decimal": 2
},
{
    "code": "SOS",
    "numeric": "706",
    "name": "Somali Shilling",
    "symbol": "Sh",
    "decimal": 2
},
{
    "code": "SRD",
    "numeric": "968",
    "name": "Surinam Dollar",
    "symbol": "$",
    "decimal": 2
},
{
    "code": "SSP",
    "numeric": "728",
    "name": "South Sudanese Pound",
    "symbol": "£",
    "decimal": 2
},
{
    "code": "STN",
    "numeric": "930",
    "name": "Dobra",
    "symbol": "",
    "decimal": 2
},
{
    "code": "SVC",
    "numeric": "222",
    "name": "El Salvador Colon",
    "symbol": "$",
    "decimal": 2
},
{
    "code": "SYP",
    "numeric": "760",
    "name": "Syrian Pound",
    "symbol": "Ù„.Ø³",
    "decimal": 2
},
{
    "code": "SZL",
    "numeric": "748",
    "name": "Lilangeni",
    "symbol": "L",
    "decimal": 2
},
{
    "code": "THB",
    "numeric": "764",
    "name": "Baht",
    "symbol": "à¸¿",
    "decimal": 2
},
{
    "code": "TJS",
    "numeric": "972",
    "name": "Somoni",
    "symbol": "Ð…Ðœ",
    "decimal": 2
},
{
    "code": "TMT",
    "numeric": "934",
    "name": "Turkmenistan New Manat",
    "symbol": "m",
    "decimal": 2
},
{
    "code": "TND",
    "numeric": "788",
    "name": "Tunisian Dinar",
    "symbol": "Ø¯.Øª",
    "decimal": 3
},
{
    "code": "TOP",
    "numeric": "776",
    "name": "Pa’anga",
    "symbol": "T$",
    "decimal": 2
},
{
    "code": "TRY",
    "numeric": "949",
    "name": "Turkish Lira",
    "symbol": "â‚º",
    "decimal": 2
},
{
    "code": "TTD",
    "numeric": "780",
    "name": "Trinidad and Tobago Dollar",
    "symbol": "$",
    "decimal": 2
},
{
    "code": "TWD",
    "numeric": "901",
    "name": "New Taiwan Dollar",
    "symbol": "$",
    "decimal": 2
},
{
    "code": "TZS",
    "numeric": "834",
    "name": "Tanzanian Shilling",
    "symbol": "Sh",
    "decimal": 2
},
{
    "code": "UAH",
    "numeric": "980",
    "name": "Hryvnia",
    "symbol": "â‚´",
    "decimal": 2
},
{
    "code": "UGX",
    "numeric": "800",
    "name": "Uganda Shilling",
    "symbol": "Sh",
    "decimal": 0
},
{
    "code": "USD",
    "numeric": "840",
    "name": "US Dollar",
    "symbol": "$",
    "decimal": 2
},
{
    "code": "USN",
    "numeric": "997",
    "name": "US Dollar (Next day)",
    "symbol": "",
    "decimal": 2
},
{
    "code": "UYI",
    "numeric": "940",
    "name": "Uruguay Peso en Unidades Indexadas (UI)",
    "symbol": "",
    "decimal": 0
},
{
    "code": "UYU",
    "numeric": "858",
    "name": "Peso Uruguayo",
    "symbol": "$",
    "decimal": 2
},
{
    "code": "UYW",
    "numeric": "927",
    "name": "Unidad Previsional",
    "symbol": "",
    "decimal": 4
},
{
    "code": "UZS",
    "numeric": "860",
    "name": "Uzbekistan Sum",
    "symbol": "Ð»Ð²",
    "decimal": 2
},
{
    "code": "VED",
    "numeric": "926",
    "name": "Bolívar Soberano",
    "symbol": "",
    "decimal": 2
},
{
    "code": "VES",
    "numeric": "928",
    "name": "Bolívar Soberano",
    "symbol": "",
    "decimal": 2
},
{
    "code": "VND",
    "numeric": "704",
    "name": "Dong",
    "symbol": "â‚«",
    "decimal": 0
},
{
    "code": "VUV",
    "numeric": "548",
    "name": "Vatu",
    "symbol": "Vt",
    "decimal": 0
},
{
    "code": "WST",
    "numeric": "882",
    "name": "Tala",
    "symbol": "T",
    "decimal": 2
},
{
    "code": "XAF",
    "numeric": "950",
    "name": "CFA Franc BEAC",
    "symbol": "Fr",
    "decimal": 0
},
{
    "code": "XCD",
    "numeric": "951",
    "name": "East Caribbean Dollar",
    "symbol": "$",
    "decimal": 2
},
{
    "code": "XOF",
    "numeric": "952",
    "name": "CFA Franc BCEAO",
    "symbol": "Fr",
    "decimal": 0
},
{
    "code": "XPF",
    "numeric": "953",
    "name": "CFP Franc",
    "symbol": "Fr",
    "decimal": 0
},
{
    "code": "YER",
    "numeric": "886",
    "name": "Yemeni Rial",
    "symbol": "ï·¼",
    "decimal": 2
},
{
    "code": "ZAR",
    "numeric": "710",
    "name": "Rand",
    "symbol": "R",
    "decimal": 2
},
{
    "code": "ZMW",
    "numeric": "967",
    "name": "Zambian Kwacha",
    "symbol": "ZK",
    "decimal": 2
},
{
    "code": "ZWL",
    "numeric": "932",
    "name": "Zimbabwe Dollar",
    "symbol": "Z$",
    "decimal": 2
}
]