import { Navigate } from "react-router-dom";

const ExpertPrivateRouteTwo = ({ children }) => {
  let isExpertAuth = JSON.parse(localStorage.getItem("isExpertAuth"));
  if (isExpertAuth) {
    return <Navigate to="/expert/dashboard" replace />;
  } else
    return children;
};



export default ExpertPrivateRouteTwo;
