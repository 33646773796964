import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import Helmet from "react-helmet";
import Sidebar from "../../components/Sidebar";
import ManagersTable from "./ManagersTable";
import { Spin } from "antd";

const ExotelSection = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 100);
  }, []);

  return (
    <>
      {/* <MobileMenu /> */}
      {/* <AdminDashboard /> */}
      <Helmet>
        <title>{"Nextyn | Managers"}</title>

        {/* <script src="//in.fw-cdn.com/30634560/343565.js" chat="true"></script> */}
      </Helmet>
      <div className="page-wrapper chiller-theme toggled">
        <div className="main-sec d-lg-flex">
          <Sidebar />
        </div>
        <main className="body-total content-wrapper float-start w-100">
          <div>
            <div className="d-block d-lg-block">
              {open ? (
                <ManagersTable />
              ) : (
                <>
                  <div
                    className="d-flex justify-content-center align-items-center text-center"
                    style={{ minHeight: "100vh" }}
                  >
                    <Spin size="large" />
                  </div>
                </>
              )}
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default ExotelSection;
