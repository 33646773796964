import React, { useState, useEffect } from "react";
import Sidebar from "../Sidebar/Sidebar";
import { Col, Row } from "react-bootstrap";
import "./Pages.css";
import { Link } from "react-router-dom";
import { Table, message, Button, notification, Spin } from "antd";
import API_PATH from "../../../Constants/api-path";
import company_icon3 from "../../../assests/images/company_icons3.png";
import {
  AmazonIcon,
  BankIcon,
  CharityIcon,
  PaypalIcon,
} from "../../../Utils/Icons";
import ExpertDashboard from "../../components/ExpertDashboard";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

function Dashboard() {
  const [getData, setGetData] = useState([]);
  const [expertDetails, setExpertDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  };

  const formatJoinDate = (dateTimeString) => {
    const date = new Date(dateTimeString);
    return date.toLocaleDateString(undefined, {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  };

  const GetExpertProfile = async () => {
    setLoading(true);
    try {
      const res = await fetch(
        `${API_PATH.EXPERT_PROFILE_LIST}/${userData?.data?._id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          },
        }
      );

      const result = await res.json();
      if (result) {
        setLoading(true);
        setExpertDetails(result?.data);
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    GetExpertProfile();
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  const GetCompData = async () => {
    setLoading(true);
    try {
      const res = await fetch(
        `${API_PATH.COMPLIANCE_GET}/${userData?.data?._id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          },
        }
      );

      const result = await res.json();
      if (result) {
        console.log(result, "resultData");
        setGetData(result?.data);
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    GetCompData();
  }, []);

  const columns = [
    {
      title: "Project Name",
      // dataIndex: "project_title",
      // key: "project_title",
      render: (record) => (
        <>
          <div>
            <h5
              className="mt-0 mb-1"
              style={{ fontSize: "16px", fontWeight: 500 }}
            >
              {record?.project_title}
            </h5>
            <div className="d-flex">
              <div className="text-muted">
                <svg
                  style={{ fontSize: "16px" }}
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 1024 1024"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M880 184H712v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H384v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H144c-17.7 0-32 14.3-32 32v664c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V216c0-17.7-14.3-32-32-32zm-40 656H184V460h656v380zM184 392V256h128v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h256v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h128v136H184z"></path>
                </svg>

                <span className="ps-2">{convertDate(record?.created_at)}</span>
              </div>

              <div className="text-muted ps-5">
                <svg
                  style={{ fontSize: "18px" }}
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  t="1569683618210"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <defs></defs>
                  <path d="M945 412H689c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h256c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zM811 548H689c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h122c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zM477.3 322.5H434c-6.2 0-11.2 5-11.2 11.2v248c0 3.6 1.7 6.9 4.6 9l148.9 108.6c5 3.6 12 2.6 15.6-2.4l25.7-35.1v-0.1c3.6-5 2.5-12-2.5-15.6l-126.7-91.6V333.7c0.1-6.2-5-11.2-11.1-11.2z"></path>
                  <path d="M804.8 673.9H747c-5.6 0-10.9 2.9-13.9 7.7-12.7 20.1-27.5 38.7-44.5 55.7-29.3 29.3-63.4 52.3-101.3 68.3-39.3 16.6-81 25-124 25-43.1 0-84.8-8.4-124-25-37.9-16-72-39-101.3-68.3s-52.3-63.4-68.3-101.3c-16.6-39.2-25-80.9-25-124 0-43.1 8.4-84.7 25-124 16-37.9 39-72 68.3-101.3 29.3-29.3 63.4-52.3 101.3-68.3 39.2-16.6 81-25 124-25 43.1 0 84.8 8.4 124 25 37.9 16 72 39 101.3 68.3 17 17 31.8 35.6 44.5 55.7 3 4.8 8.3 7.7 13.9 7.7h57.8c6.9 0 11.3-7.2 8.2-13.3-65.2-129.7-197.4-214-345-215.7-216.1-2.7-395.6 174.2-396 390.1C71.6 727.5 246.9 903 463.2 903c149.5 0 283.9-84.6 349.8-215.8 3.1-6.1-1.4-13.3-8.2-13.3z"></path>
                </svg>

                <span className="ps-2">{record?.call_duration}</span>
              </div>
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Rate",
      dataIndex: "expert_rate",
      key: "expert_rate",
      render: (record) => (
        <>
          <div>{userData?.data?.hourly_currency + " " + record}</div>
        </>
      ),
    },
    {
      title: "Payment Status",
      dataIndex: "expert_payment_status",
      key: "expert_payment_status",
      render: (record) => (
        <div>{record === "No" ? "Not Processed" : "Processed"}</div>
      ),
    },
    {
      title: "Payment Date",
      key: "payment_date",
      dataIndex: "payment_date",
      render: (record) => <div>{record ? convertDate(record) : "-"}</div>,
    },
  ];

  const userData = JSON.parse(localStorage.getItem("expertData"));
  console.log("userData ----->", userData);
  const [loading, setLoading] = useState(false);
  const [callList, setCallList] = useState([]);

  const convertDate = (date) => {
    //console.log(date)
    if (date !== null && date !== "") {
      var d = new Date(date);
      var day = d.getDate();
      var x = d.toDateString().substr(4, 3);
      var year = d.getFullYear();
      let p = `${x} ${day}, ${year}`;
      //console.log(p)
      return p;
    } else {
      return "---------";
    }
  };

  const GetCallList = async () => {
    setLoading(true);
    try {
      const res = await fetch(
        `${API_PATH.EXPERT_CALL_LIST}/${userData?.data?._id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          },
        }
      );

      const result = await res.json();
      if (result) {
        console.log("resultData", result);
        setCallList(result?.data);
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    GetCallList();
  }, []);

  const addDefaultSrc = (ev) => {
    ev.target.src = company_icon3;
  };

  document.title = "Expert-Dashboard";

  return (
    <>
      <ExpertDashboard />
      <div className="page-wrapper chiller-theme toggled">
        <div className="main-sec d-lg-flex">
          <Sidebar />
        </div>
        {isLoading ? (
          <div
            className="d-flex justify-content-center align-items-center text-center"
            style={{ minHeight: "100vh" }}
          >
            <Spin size="large" />
          </div>
        ) : (
          <>
            <main className="body-total content-wrapper float-start w-100">
              <div className="container-fluid">
                <Row className="justify-content-center marginT">
                  <Col xl={11}>
                    <div className="bg-white p-4 d-xl-flex justify-content-between align-items-center expertBox">
                      <div>
                        <Row className="g-0">
                          <Col className="fixedWidthCol">
                            {userData?.data?.image !== null &&
                            userData?.data?.image !== undefined &&
                            userData?.data?.image !== "" ? (
                              <img
                                loading="lazy"
                                className="me-2"
                                style={{
                                  width: "95px",
                                  borderRadius: "5px",
                                  maxHeight: "95px",
                                }}
                                src={userData?.data?.image}
                                onError={addDefaultSrc}
                                alt={userData?.data?.image}
                              />
                            ) : (
                              <img
                                loading="lazy"
                                className="me-2"
                                style={{
                                  width: "95px",
                                  borderRadius: "5px",
                                  maxHeight: "95px",
                                }}
                                src={company_icon3}
                                alt={userData?.data?.image}
                              />
                            )}
                          </Col>
                          <Col>
                            <div className="px-3">
                              <h5 className="card-title mt-0 text-wrap-afte-three-line">
                                {expertDetails[0]?.name
                                  ? expertDetails[0]?.name
                                  : `+${expertDetails[0]?.first_name}${expertDetails[0]?.last_name}`}
                              </h5>
                              <p className="card-text">
                                <small className="text-muted">
                                  Registered on -{" "}
                                  {convertDate(expertDetails[0]?.createdAt)}
                                </small>
                              </p>
                            </div>
                          </Col>
                        </Row>
                        <Row className="g-0">
                          <Col className="fixedWidthCol d-none d-lg-block"></Col>
                          <Col>
                            <div className="pb-2 px-lg-3 px-0 expert_Card">
                              {expertDetails[0]?.email !== null &&
                              expertDetails[0]?.email !== undefined &&
                              expertDetails[0]?.email !== "" ? (
                                <div className="text-muted pe-4">
                                  <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    stroke-width="0"
                                    viewBox="0 0 512 512"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="32"
                                      d="M320 96H88a40 40 0 00-40 40v240a40 40 0 0040 40h334.73a40 40 0 0040-40V239"
                                    ></path>
                                    <path
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="32"
                                      d="M112 160l144 112 87-65.67"
                                    ></path>
                                    <circle
                                      cx="431.95"
                                      cy="128.05"
                                      r="47.95"
                                    ></circle>
                                    <path d="M432 192a63.95 63.95 0 1163.95-63.95A64 64 0 01432 192zm0-95.9a32 32 0 1031.95 32 32 32 0 00-31.95-32z"></path>
                                  </svg>
                                  <span className="card-text ps-2">
                                    <small className="text-muted">
                                      {expertDetails[0]?.email}
                                    </small>
                                  </span>
                                </div>
                              ) : null}

                              {expertDetails[0]?.linkedin_url !== null &&
                              expertDetails[0]?.linkedin_url !== undefined &&
                              expertDetails[0]?.linkedin_url !== "" ? (
                                <div className="text-muted pe-4">
                                  <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    stroke-width="0"
                                    version="1.2"
                                    baseProfile="tiny"
                                    viewBox="0 0 24 24"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M8 19h-3v-10h3v10zm11 0h-3v-5.342c0-1.392-.496-2.085-1.479-2.085-.779 0-1.273.388-1.521 1.165v6.262h-3s.04-9 0-10h2.368l.183 2h.062c.615-1 1.598-1.678 2.946-1.678 1.025 0 1.854.285 2.487 1.001.637.717.954 1.679.954 3.03v5.647z"></path>
                                    <ellipse
                                      cx="6.5"
                                      cy="6.5"
                                      rx="1.55"
                                      ry="1.5"
                                    ></ellipse>
                                  </svg>
                                  <span className="card-text ps-2">
                                    <small className="text-muted">
                                      <a
                                        href={expertDetails[0]?.linkedin_url}
                                        alt="url"
                                        target="_blank"
                                      >
                                        LinkedIn Url
                                      </a>
                                    </small>
                                  </span>
                                </div>
                              ) : null}

                              {expertDetails[0]?.contact_number !== null &&
                              expertDetails[0]?.contact_number !== undefined &&
                              expertDetails[0]?.contact_number !== "" ? (
                                <div className="text-muted pe-4">
                                  <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    stroke-width="0"
                                    viewBox="0 0 24 24"
                                    height="1em"
                                    width="1em"
                                    n
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill="none"
                                      d="M0 0h24v24H0V0z"
                                    ></path>
                                    <path
                                      fill-opacity=".3"
                                      d="M16.49 4.5c1.79 0 3.42.73 4.59 1.91l1.06-1.06A7.94 7.94 0 0016.49 3c-2.21 0-4.21.9-5.66 2.34l1.06 1.06a6.545 6.545 0 014.6-1.9z"
                                    ></path>
                                    <path
                                      fill-opacity=".3"
                                      d="M16.49 6c-1.38 0-2.63.56-3.54 1.46l1.06 1.06c.63-.63 1.51-1.03 2.47-1.03s1.84.39 2.47 1.03l1.06-1.06A4.94 4.94 0 0016.49 6z"
                                    ></path>
                                    <path d="M16.49 9c-.55 0-1.05.22-1.41.59L16.49 11l1.41-1.41c-.36-.37-.86-.59-1.41-.59z"></path>
                                    <path
                                      d="M15 17.83c1.29.54 2.63.89 4 1.07v-2.23l-2.35-.47L15 17.83zM7.33 5H5.1c.18 1.37.53 2.7 1.07 4L7.8 7.35 7.33 5z"
                                      opacity=".3"
                                    ></path>
                                    <path d="M20.2 14.87l-3.67-.73c-.5-.1-.83.2-.9.27l-2.52 2.5c-2.5-1.43-4.57-3.5-6-6l2.5-2.52c.23-.24.33-.57.27-.9L9.13 3.8c-.09-.46-.5-.8-.98-.8H4c-.56 0-1.03.47-1 1.03.17 2.89 1.05 5.6 2.43 7.97 1.58 2.73 3.85 4.99 6.57 6.57 2.37 1.37 5.08 2.26 7.97 2.43.55.03 1.03-.43 1.03-1v-4.15c0-.48-.34-.89-.8-.98zM5.1 5h2.23l.47 2.35L6.17 9c-.54-1.3-.9-2.63-1.07-4zM19 18.9c-1.37-.18-2.7-.53-4-1.07l1.65-1.63 2.35.47v2.23z"></path>
                                  </svg>
                                  <span className="card-text ps-2">
                                    <small className="text-muted">
                                      {expertDetails[0]?.contact_code &&
                                      !expertDetails[0]?.contact_code.includes(
                                        "+"
                                      )
                                        ? `+${expertDetails[0]?.contact_code}`
                                        : expertDetails[0]?.contact_code}{" "}
                                      {expertDetails[0]?.contact_number}
                                    </small>
                                  </span>
                                </div>
                              ) : null}
                              {/* </div> */}
                              {/* <div className="d-flex px-3"> */}
                              {expertDetails[0]?.country !== null &&
                              expertDetails[0]?.country !== undefined &&
                              expertDetails[0]?.country !== "" ? (
                                <div className="text-muted pe-4">
                                  <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    stroke-width="0"
                                    viewBox="0 0 24 24"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path fill="none" d="M0 0h24v24H0z"></path>
                                    <path d="M20.94 11A8.994 8.994 0 0013 3.06V1h-2v2.06c-1.13.12-2.19.46-3.16.97l1.5 1.5A6.995 6.995 0 0119 12c0 .94-.19 1.84-.52 2.65l1.5 1.5c.5-.96.84-2.02.97-3.15H23v-2h-2.06zM3 4.27l2.04 2.04A8.914 8.914 0 003.06 11H1v2h2.06A8.994 8.994 0 0011 20.94V23h2v-2.06c1.77-.2 3.38-.91 4.69-1.98L19.73 21 21 19.73 4.27 3 3 4.27zm13.27 13.27a6.995 6.995 0 01-9.81-9.81l9.81 9.81z"></path>
                                  </svg>
                                  <span className="card-text ps-2">
                                    <small className="text-muted">
                                      {expertDetails[0]?.country}
                                    </small>
                                  </span>
                                </div>
                              ) : null}

                              {expertDetails[0]?.company !== null &&
                              expertDetails[0]?.company !== undefined &&
                              expertDetails[0]?.company !== "" ? (
                                <div className="text-muted pe-4">
                                  <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    stroke-width="0"
                                    viewBox="0 0 16 16"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022ZM6 8.694 1 10.36V15h5V8.694ZM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15Z"></path>
                                    <path d="M2 11h1v1H2v-1Zm2 0h1v1H4v-1Zm-2 2h1v1H2v-1Zm2 0h1v1H4v-1Zm4-4h1v1H8V9Zm2 0h1v1h-1V9Zm-2 2h1v1H8v-1Zm2 0h1v1h-1v-1Zm2-2h1v1h-1V9Zm0 2h1v1h-1v-1ZM8 7h1v1H8V7Zm2 0h1v1h-1V7Zm2 0h1v1h-1V7ZM8 5h1v1H8V5Zm2 0h1v1h-1V5Zm2 0h1v1h-1V5Zm0-2h1v1h-1V3Z"></path>
                                  </svg>
                                  <span className="card-text p-2">
                                    <small className="text-muted">
                                      {expertDetails[0]?.company}
                                    </small>
                                  </span>
                                </div>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div className="fixedCol-2 p-xl-4 text-center">
                        <h5 className="mt-0" style={{ fontWeight: 600 }}>
                          {expertDetails[0]?.hourly_currency
                            ? expertDetails[0]?.hourly_currency
                            : "USD"}{" "}
                          {expertDetails[0]?.hourly_rate_call_cunsltn}/
                          <small className="text-muted fw-normal">
                            {expertDetails[0]?.charge_duration || "hour"}
                          </small>
                        </h5>
                        <Link
                          to={`/expert/profile?expertId=${expertDetails[0]?._id}`}
                        >
                          <Button size="large" className="viewButton btn">
                            View Profile
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </Col>

                  {callList.length > 0 && (
                    <Col xl={11}>
                      <h3 className="mt-5" style={{ fontWeight: 500 }}>
                        Call Records
                      </h3>

                      <div>
                        <Table
                          columns={columns}
                          dataSource={callList?.slice(0, 5)}
                          onChange={onChange}
                          loading={loading}
                          pagination={false}
                          scroll={{ x: 800 }}
                        ></Table>
                      </div>
                    </Col>
                  )}

                  <Col xl={11}>
                    <h3 className="mt-5" style={{ fontWeight: 500 }}>
                      Payment Methods
                    </h3>
                    <div>
                      <Row className="row row-cols-1 row-cols-md-3 g-3">
                        <Col>
                          <Link to={"/expert/payment"}>
                            <div
                              className="bg-white pb-4 ps-4 pe-4 expertBox responsivePaymentBox"
                              style={{ cursor: "pointer" }}
                            >
                              <AmazonIcon
                                className="paymentIconSize"
                                color="03C4DC"
                              />
                              <h4 className="paymentTitle">Amazon Gift Card</h4>
                              <p className="paymentSubTitle text-muted">
                                Receive an Amazon Gift Card
                              </p>
                            </div>
                          </Link>
                        </Col>

                        <Col>
                          <Link to={"/expert/payment"}>
                            <div
                              className="bg-white pb-4 ps-4 pe-4 expertBox responsivePaymentBox"
                              style={{ cursor: "pointer" }}
                            >
                              <BankIcon
                                className="paymentIconSize"
                                color="03C4DC"
                              />
                              <h4 className="paymentTitle">Add Bank Account</h4>
                              <p className="paymentSubTitle text-muted">
                                Receive a Direct Bank Transfer
                              </p>
                            </div>
                          </Link>
                        </Col>
                        {/* <Col>
                          <Link to={"/expert/payment"}>
                            <div
                              className="bg-white pb-4 ps-4 pe-4 expertBox responsivePaymentBox"
                              style={{ cursor: "pointer" }}
                            >
                              <PaypalIcon
                                className="paymentIconSize"
                                color="03C4DC"
                              />
                              <h4 className="paymentTitle">
                                Add Paypal Account
                              </h4>
                              <p className="paymentSubTitle text-muted">
                                Receive using a PayPal ID
                              </p>
                            </div>
                          </Link>
                        </Col> */}
                        <Col>
                          <Link to={"/expert/payment"}>
                            <div
                              className="bg-white pb-4 ps-4 pe-4 expertBox responsivePaymentBox"
                              style={{ cursor: "pointer" }}
                            >
                              <CharityIcon
                                className="paymentIconSize"
                                color="03C4DC"
                              />
                              <h4 className="paymentTitle">
                                Charitable Donation
                              </h4>
                              <p className="paymentSubTitle text-muted">
                                Donate to a Charity
                              </p>
                            </div>
                          </Link>
                        </Col>
                      </Row>
                    </div>
                  </Col>

                  <Col xl={11} className="mb-5">
                    <h3 className="mt-5" style={{ fontWeight: 500 }}>
                      Compliance
                    </h3>
                    <Row>
                      <Col>
                        <div className="expertBox bg-white p-3">
                          <Row>
                            <Col>
                              <h5
                                className="mt-0"
                                style={{ fontSize: "18px", fontWeight: 500 }}
                              >
                                JOINED ON{" "}
                                {formatJoinDate(userData?.data?.createdAt)}
                              </h5>
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontWeight: 400,
                                  marginBottom: "20px",
                                }}
                              >
                                Nextyn requires you to fill out our standard
                                compliance documents before scheduling your
                                first call. This is an industry-standard
                                agreement which, most importantly, asks you not
                                to share any confidential or proprietary
                                information. We will request your signature
                                before your first call, but you can also review
                                and sign here beforehand. Some of our clients
                                have more extensive compliance needs and require
                                the extended version of our compliance document.
                                You can also review and sign that here if you
                                wish.
                              </p>
                            </Col>
                          </Row>

                          {getData ? (
                            <>
                              <Row>
                                <Col className="col-auto me-auto">
                                  <Link
                                    to={"/expert/compliance"}
                                    style={{
                                      color: "#5746EC",
                                      fontSize: "17px",
                                      fontWeight: 500,
                                      cursor: "pointer",
                                    }}
                                  >
                                    EXPERT TERMS & CONDITIONS - SIGNED
                                    (DOWNLOAD)
                                  </Link>
                                </Col>
                                <Col className="col-auto">
                                  <p
                                    style={{
                                      color: "#F33636",
                                      fontSize: "17px",
                                      fontWeight: 500,
                                    }}
                                  >
                                    SIGNED ON {formatDate(getData?.sign_date)}
                                  </p>
                                </Col>
                              </Row>
                            </>
                          ) : (
                            <>
                              <Row>
                                <Col className="col-auto m-auto">
                                  {/* uncomment above row if document is signed */}
                                  <Link
                                    className="viewButton btn"
                                    to={"/expert/compliance"}
                                  >
                                    SIGN DOCUMENT
                                  </Link>
                                </Col>
                              </Row>
                            </>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </main>
          </>
        )}
      </div>
    </>
  );
}

export default Dashboard;
