import { Table, message, notification } from "antd";
import API_PATH from "../../Constants/api-path";
import React, { useEffect, useState } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const onChange = (pagination, filters, sorter, extra) => {
  // console.log("params", pagination, filters, sorter, extra);
};
const CallRecords = () => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [loading, setLoading] = useState(false);
  const [callList, setCallList] = useState([]);

  const convertDate = (date) => {
    //console.log(date)
    if (date !== null && date !== "") {
      var d = new Date(date);
      var day = d.getDate();
      var x = d.toDateString().substr(4, 3);
      var year = d.getFullYear();
      let p = `${x} ${day}, ${year}`;
      //console.log(p)
      return p;
    } else {
      return "---------";
    }
  };

  const GetCallList = async (page, selectedOptions) => {
    setLoading(true);

    try {
      const res = await fetch(`${API_PATH.CALL_LIST}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
      });

      const result = await res.json();
      if (result) {
        // console.log(result.data);
        setCallList(result.data);
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    GetCallList();
  }, []);

  const columns = [
    {
      title: "SNo.",
      dataIndex: "sr",
      key: "sr",
      render: (text, record, rowIndex) => rowIndex + 1,
      width: "50px",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => `${convertDate(date)}`,
      width: "100px",
    },
    {
      title: "Project Name",
      dataIndex: "project_title",
      key: "project_title",
      render: (text, record) => {
        // Truncate the project title to 6 words
        return (
          <>
            <p
              className="projectTitle"
              title={record?.project_title}
              style={{ cursor: "pointer" }}
            >
              {record.project_title || "-"}
            </p>
          </>
        );
      },
    },
    {
      title: "Company Name",
      dataIndex: "client_company",
      key: "client_company",
      width: "100px",
    },

    {
      title: "Expert Name",
      dataIndex: "expert_name",
      key: "expert_name",
      width: "150px",
    },
    {
      title: "Client Name",
      dataIndex: "client_name",
      key: "client_name",
      width: "150px",
    },
  ];

  return (
    <>
      <div className="dasb-div bk-div float-start w-100">
        <div className="body-expart-div">
          <div className="">
            <p className="fs-5 mb-3">
              <strong style={{ color: "#5746ec" }}>Call Records </strong>
            </p>
            <Table
              columns={columns}
              dataSource={callList.slice(0, 5)}
              onChange={onChange}
              className="paddTable"
              pagination={false}
              scroll={{ x: 800 }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default CallRecords;
