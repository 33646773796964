import React, { useState } from "react";
import { Button, message, Upload, Table } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import "./Invoice.css";
import noDataFount from "../../assests/images/No-data-pana.svg";
const Invoice = () => {
  const [excelData, setExcelData] = useState([]);
  const [showVerifyModal, setShowVerifyModal] = useState(false);

  const parseExcelData = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);

        const cleanedData = sheetData.map((row) => {
          return Object.fromEntries(
            Object.entries(row).map(([key, value]) => {
              if (typeof value === "string") {
                return [key, value.replace(/"/g, "")];
              }
              return [key, value];
            })
          );
        });

        resolve(cleanedData);
      };

      reader.readAsArrayBuffer(file);
    });
  };

  const generateInvoicePDF = async (content, fileName) => {
    try {
      let cont = document.createElement("div");
      cont.setAttribute("id", "generatedPDF");
      cont.style.position = "fixed";
      cont.style.top = "0";
      cont.style.zIndex = 9;
      cont.innerHTML = content;
      document.body.appendChild(cont);
      await html2canvas(cont, {
        scale: 2,
        x: 0,
        y: 0,
        width: 1000,
        height: 1200,
      }).then((canvas) => {
        document.getElementById("generatedPDF").remove();
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF({ compress: true });

        const pdfWidth = pdf.internal.pageSize.getWidth();
        const imgProps = pdf.getImageProperties(imgData);
        const imgWidth = pdfWidth;
        const imgHeight = (imgProps.height * imgWidth) / imgProps.width;

        // Add the image to the PDF and handle multi-page content
        pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);

        pdf.save(`${fileName}.pdf`);
      });

      // // pdf.addFileToVFS(workSans, fontBase64);
      // pdf.addFont(workSans, "WorkSans", "normal");
      // pdf.setFont("WorkSans");

      // pdf.html(content, {
      //   callback: function (doc) {
      //     // addFooters();
      //     doc.save("invoice.pdf");
      //   },
      //   margin: [10, 10, 10, 20],
      //   x: 0,
      //   y: 0,
      //   width: 408, //target width in the PDF document
      //   windowWidth: 900, //window width in CSS pixels
      //   // header: header,
      // });
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const props = {
    name: "file",
    // action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
    headers: {
      authorization: "authorization-text",
    },

    customRequest: ({ file, onSuccess }) => {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    },
    onChange(info) {
      console.log("info: ", info);
      if (info.file.status === "done") {
        parseExcelData(info.file.originFileObj).then((parsedData) => {
          console.log("Excel Data:", parsedData);
          setExcelData(parsedData);
        });
        setShowVerifyModal(true);
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const verifyColumns = Object.keys(excelData[0] || {}).map((key) => ({
    title: key,
    dataIndex: key,
    key: key,
  }));

  const columnsWithAction = [
    ...verifyColumns,
    {
      title: "Action",
      key: "action",
      render: (text, record, index) => (
        <>
          {console.log(record, "record")}
          <Button
            type="primary"
            onClick={() =>
              generateInvoicePDF(
                renderInvoiceContent(record, index),
                `${record?.Name}-${record?.Invoice_No}`
              )
            }
          >
            Download PDF
          </Button>
        </>
      ),
    },
  ];

  const parseExcelDate = (excelDate) => {
    const date = new Date((excelDate - 25569) * 86400 * 1000);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const renderInvoiceContent = (data, index) => {
    const formattedDate = parseExcelDate(data.Date);
    return `<div style="margin:40px;width:920px;height:1200px">
	<div id="pdf" style="text-align: center;">
		<h4 style="font-family: 'Work Sans', sans-serif;font-size: 25px;">Invoice</h4>
	</div>
	<div style="text-align: right;margin-top: 30px;">
		<p style="font-family: 'Work Sans', sans-serif;"><strong>Nextyn Pte Ltd</strong><br />68 Circular Road, #02-01, 049422, Singapore<br /><a href="mailto:info@nextyn.com">info@nextyn.com</a></p>
	</div>
	<div style="display: flex;width:100%;margin-top:40px">
		<div style="width:60%;border-right:7px solid #fff;background: #f5f6fa;padding: 20px 20px">
			<p style="font-family: 'Work Sans', sans-serif;">${data?.Name}</p>
			<p style="font-family: 'Work Sans', sans-serif;">${data?.Country}</p>
			<p style="font-family: 'Work Sans', sans-serif;">${data?.Email_ID}</p>
		</div>
		<div style="width:40%;border-left: 8px solid #fff;background: #f5f6fa;padding: 20px 20px;text-align:right">
			<p style="font-family: 'Work Sans', sans-serif;">Invoice - ${data?.Invoice_No}</p>
			<p style="font-family: 'Work Sans', sans-serif;">Date - ${formattedDate}</p>
		</div>
	</div>

	<div style="margin-top:20px">
		<p style="font-family: 'Work Sans', sans-serif;">Payment terms (Due in 14 days)</p>
	</div>

	<table class="sdfwd" style="width:100%;border-spacing:0;margin-top:20px">
			<thead>
				<th style="width:40%;background: #007ae1;color:#fff;padding: 10px;font-size:16px;font-family: 'Work Sans', sans-serif;">
					Description
				</th><th style="background: #007ae1;color:#fff;padding: 10px;font-size:16px;font-family: 'Work Sans', sans-serif;">
					Hourly Consulting Fee
				</th><th style="background: #007ae1;color:#fff;padding: 10px;font-size:16px;font-family: 'Work Sans', sans-serif;">
					Duration
				</th><th style="background: #007ae1;color:#fff;padding: 10px;font-size:16px;font-family: 'Work Sans', sans-serif;">
					Total
				</th>
			</thead>
				<tbody>
					<tr>
						<td style="padding: 10px;
			border: 1px solid #f5f6fa;font-family: 'Work Sans', sans-serif;">${data?.DESCRIPTION}</td>
						<td style="padding: 10px;
			border: 1px solid #f5f6fa;font-family: 'Work Sans', sans-serif;">${data?.Hourly_Consulting_Fee}</td>
						<td style="padding: 10px;
			border: 1px solid #f5f6fa;font-family: 'Work Sans', sans-serif;">${data?.Duration}</td>
						<td style="padding: 10px;
			border: 1px solid #f5f6fa;font-family: 'Work Sans', sans-serif;">${data?.TOTAL}</td>
					</tr>
					<tr>
		                <td style="padding: 10px;
			border: 1px solid #f5f6fa;font-family: 'Work Sans', sans-serif;">&nbsp;</td>
		                <td style="padding: 10px;
			border: 1px solid #f5f6fa;font-family: 'Work Sans', sans-serif;" colspan="2">
		                    Sub Total
		                </td>
		                <td style="padding: 10px;
			border: 1px solid #f5f6fa;font-family: 'Work Sans', sans-serif;">${data?.SUB_TOTAL}</td>
		            </tr>
				</tbody>
		</table>
		<div style="margin-top:40px;text-align: center;">
		<p style="font-family: 'Work Sans', sans-serif;font-weight: 500;word-spacing:0.1px">
			* This is a System Generated Invoice *
		</p>
	</div></div>`;
  };

  // const handleDownloadAllInvoices = () => {
  //     excelData.forEach((data, index) => {
  //         const content = renderInvoiceContent(data, index);
  //         generateInvoicePDF(content, `invoice_${index + 1}.pdf`);
  //     });
  // };

  return (
    <div class="container">
      <div class="row justify-content-center text-center">
        <div className="col-md-12">
          <div className="invoice-generator">
            {excelData.length ? (
              <Upload {...props}>
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            ) : (
              ""
            )}
          </div>
          <div class="mt-4">
            {excelData.length ? (
              <Table
                dataSource={excelData}
                columns={columnsWithAction}
                scroll={{ x: 800, y: 650 }}
              />
            ) : (
              <div className="invoice-generator">
                <img src={noDataFount} className="w-50" alt={"No data found"} />
                <h5>Click on below button to upload File</h5>
                <div>
                  <Upload {...props}>
                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                  </Upload>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invoice;
