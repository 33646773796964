import { Form, Input, Select, Button, Spin } from "antd";
import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import useStep from "../../../CustomHooks/UseStep";
import LinkedinLogin from "../../components/LinkedinRegistration";
import ExpertAuth from "../../components/ExpertAuth";

const { Option } = Select;

function RegistrationStep1() {
  const history = useNavigate();
  const { setCurrentStep } = useStep();
  const [form] = Form.useForm();
  const [step1, setStep1] = useState({
    first_name: "",
    last_name: "",
    company: "",
    years_of_experience: "",
  });

  const [loading, setIsLoading] = useState(false);

  const experience = [
    { key: 1, label: 1, value: 1 },
    { key: 2, label: 2, value: 2 },
  ];

  for (let i = 3; i <= 70; i++) {
    experience.push({ key: i, label: i, value: i });
  }

  const handleStep1Change = (event) => {
    const { name, value } = event.target;
    setStep1((prevStep1) => ({
      ...prevStep1,
      [name]: value,
    }));
  };

  const handleStep1ChangeExp = (value) => {
    const selectValue = value;
    setStep1({ ...step1, years_of_experience: selectValue });
  };

  const handleSubmit = () => {
    saveToLocalStorage();
    history("/expert-register?regType=1&step=2");
    setCurrentStep(2);
  };

  const saveToLocalStorage = () => {
    const step1 = {
      first_name: form?.getFieldValue("first_name"),
      last_name: form?.getFieldValue("last_name"),
      company: form?.getFieldValue("company"),
      years_of_experience: form?.getFieldValue("years_of_experience"),
    };

    const expirationTime = new Date()?.getTime() + 60 * 60 * 1000; // 1 hour from now
    const dataToStore = {
      data: step1,
      expiration: expirationTime,
    };

    localStorage?.setItem("step1", JSON?.stringify(dataToStore));
  };

  const isLocalStorageExpired = () => {
    const storedStep1 = localStorage?.getItem("step1");
    if (storedStep1) {
      const parsedStep1 = JSON.parse(storedStep1);
      const now = new Date()?.getTime();
      return parsedStep1?.expiration && now > parsedStep1?.expiration;
    }
    return true;
  };

  useEffect(() => {
    if (!isLocalStorageExpired()) {
      const storedStep1 = localStorage?.getItem("step1");
      if (storedStep1) {
        const parsedStep1 = JSON.parse(storedStep1);
        setStep1(parsedStep1?.data);
        form.setFieldsValue({
          first_name: parsedStep1.data?.first_name,
          last_name: parsedStep1.data?.last_name,
          company: parsedStep1.data?.company,
          years_of_experience: parsedStep1.data?.years_of_experience,
        });
      }
    } else {
      localStorage?.removeItem("step1"); // Remove expired item from local storage
    }
  }, [form]);

  return (
    <React.Fragment>
      <ExpertAuth />
      <div
        className={
          loading
            ? "text-center loginScreenLinkedin active"
            : "text-center loginScreenLinkedin"
        }
      >
        <Spin size="large" />
      </div>
      <div className="expertRegistration step1">
        <h5>Step 1 : Registration Process</h5>
        <p>
          Your contact information will help our team get in touch with you for
          potential consultation opportunities.
        </p>

        <div>
          <LinkedinLogin
            txt="Sign Up With Linkedin"
            setIsLoading={setIsLoading}
          />
        </div>

        <div class="centerLine">Or</div>

        <div>
          <Form layout="vertical" autoComplete="off" form={form}>
            <Row>
              <Col xs={12} md={6}>
                <Form.Item
                  name="first_name"
                  label="First Name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your first name!",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    name="first_name"
                    autoComplete="nope"
                    size="large"
                    placeholder="First Name"
                    value={step1?.first_name}
                    onChange={handleStep1Change}
                  />
                </Form.Item>
              </Col>
              <Col xs={12} md={6}>
                <Form.Item
                  name="last_name"
                  label="Last Name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your last name!",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    name="last_name"
                    autoComplete="nope"
                    size="large"
                    placeholder="Last Name"
                    value={step1?.last_name}
                    onChange={handleStep1Change}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col xs={12} md={6}>
                <Form.Item
                  name="company"
                  label="Company Name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your company name!",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    name="company"
                    autoComplete="nope"
                    size="large"
                    placeholder="Company name"
                    value={step1?.company}
                    onChange={handleStep1Change}
                  />
                </Form.Item>
              </Col>
              <Col xs={12} md={6}>
                <Form.Item
                  name="years_of_experience"
                  label="Years of Experience"
                  rules={[
                    {
                      required: true,
                      message: "Please input your years of experience!",
                    },
                  ]}
                >
                  <Select
                    size="large"
                    name="years_of_experience"
                    placeholder="Select Year"
                    value={step1?.years_of_experience}
                    onChange={handleStep1ChangeExp}
                  >
                    {experience?.map((item) => (
                      <Option key={item?.key} value={item?.value}>
                        {item?.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Form.Item shouldUpdate className="text-end">
                {() => (
                  <Button
                    onClick={handleSubmit}
                    className="btn btn-responsive expertButtonNext"
                    size="large"
                    htmlType="submit"
                    disabled={
                      !form.isFieldsTouched(true) ||
                      !!form
                        .getFieldsError()
                        .filter(({ errors }) => errors.length).length
                    }
                  >
                    Next
                  </Button>
                )}
              </Form.Item>
            </Row>
          </Form>
        </div>
      </div>
    </React.Fragment>
  );
}

export default RegistrationStep1;
