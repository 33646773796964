import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    deleteModal: false,
    addCreditModal: false,
    open: false,
    creditHistory: false,
    transactionsDrawer: false,
    settingDrawer: false,
    selectedCompany: {},
    credit_value_in_dollor: 100,
    company_list: { data: [], count: 0 },
    paymentHistoryList: { data: [], count: 0 },
    transactionHistoryList: { data: [], count: 0 },
    paymentHistoryPagination: { page: 1, limit: 10 },
    transactionHistoryPagination: { page: 1, limit: 10 },
    companyPagination: { page: 1, limit: 10 },
    credit_usage_data: {},
    selectedRowTimeAndRefund: [],
    searchValue: "",
    selectedTransaction: {},
    openConfirm: false,
    formvalues: {
        "amount": undefined,
        "credits": undefined,
        "payment_date": null,
        "payment_refrence_id": "",
        "credit_validity": null
    }
}

const creditManagementSlice = createSlice({
    initialState: initialState,
    name: "creditManagement",
    reducers: {
        setDeleteModal: (state, { payload }) => {
            state.deleteModal = payload
        },
        setOpen: (state, { payload }) => {
            state.open = payload
        },
        setOpenConfirm: (state, { payload }) => {
            state.openConfirm = payload
        },
        setAddCreditModal: (state, { payload }) => {
            state.addCreditModal = payload
        },
        setCreditHistory: (state, { payload }) => {
            state.creditHistory = payload
        },
        setTransactionsDrawer: (state, { payload }) => {
            state.transactionsDrawer = payload
        },
        setSettingDrawer: (state, { payload }) => {
            state.settingDrawer = payload
        },
        setCompanyList: (state, { payload }) => {
            state.company_list = payload
        },
        setSelectedCompany: (state, { payload }) => {
            state.selectedCompany = payload
        },
        setPaymentHistoryList: (state, { payload }) => {
            state.paymentHistoryList = payload
        },
        setTransactionHistoryList: (state, { payload }) => {
            state.transactionHistoryList = payload
        },
        setPaymentHistoryPagination: (state, { payload }) => {
            state.paymentHistoryPagination = payload
        },
        setTransactionHistoryPagination: (state, { payload }) => {
            state.transactionHistoryPagination = payload
        },
        setCompanyPagination: (state, { payload }) => {
            state.companyPagination = payload
        },
        setSearch: (state, { payload }) => {
            state.searchValue = payload
        },
        setFormValues: (state, { payload }) => {
            state.formvalues = payload
        },
        setCreditValueInDollor: (state, { payload }) => {
            state.credit_value_in_dollor = payload
        },
        setCreditUsageData: (state, { payload }) => {
            state.credit_usage_data = payload
        },
        setSelectedRowTimeAndRefund: (state, { payload }) => {
            state.selectedRowTimeAndRefund = payload
        },
        setSelectedTransaction: (state, { payload }) => {
            state.selectedTransaction = payload
        },
        clearAll: (state, { payload }) => {
            state.formvalues = {
                "amount": undefined,
                "credits": undefined,
                "payment_date": null,
                "payment_refrence_id": "",
                "credit_validity": null
            }
        }
    }
})
export const creditManagementReducer = creditManagementSlice.reducer
export const { setDeleteModal, setAddCreditModal, setCreditHistory, setTransactionsDrawer, setSettingDrawer, setCompanyList, setSelectedCompany, setPaymentHistoryList, setTransactionHistoryList, setPaymentHistoryPagination, setCompanyPagination, setSearch, setFormValues, setCreditValueInDollor, clearAll, setCreditUsageData, setTransactionHistoryPagination, setSelectedRowTimeAndRefund, setSelectedTransaction, setOpen, setOpenConfirm } = creditManagementSlice.actions