import React from "react";
import Helmet from "react-helmet";
import Sidebar from "../../components/Sidebar";
import { Spin } from "antd";
import FindConsultant from "./FindConsultant";

const Consultant = () => {
    return (
        <>
            <Helmet>
                <title>{"Find Experts | Nextyn"}</title>
            </Helmet>
            <div className="page-wrapper chiller-theme toggled">
                <div className="main-sec d-lg-flex">
                    <Sidebar />
                </div>
                <div className="text-center loginScreenLinkedin">
                    <Spin size="large" />
                </div>
                <main className="body-total content-wrapper float-start w-100">
                    <div>
                        <div className="d-block d-lg-block">
                            <FindConsultant />
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
};

export default Consultant;
