import React, { useState, useEffect } from "react";
import {
  Modal,
  Input,
  DatePicker,
  Checkbox,
  Button,
  Row,
  Col,
  Form,
  message,
  notification
} from "antd";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { MdModeEditOutline } from "react-icons/md";
import { FaGripLines } from "react-icons/fa";
import dayjs from "dayjs";
import API_PATH from "../../Constants/api-path";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

import customParseFormat from "dayjs/esm/plugin/customParseFormat";
dayjs.extend(customParseFormat);

const ItemType = "CARD";
const { TextArea } = Input;
const FormItem = Form.Item;

const DraggableCard = ({
  index,
  data,
  moveCard,
  handleInputChange,
  handleDateChange,
  handleCheckboxChange,
  removeRow,
  handleTextAreaChange,
}) => {
  const [, ref] = useDrag({
    type: ItemType,
    item: { index },
  });

  const [, drop] = useDrop({
    accept: ItemType,
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveCard(draggedItem.index, index);
      }
    },
  });

  return (
    <div ref={(node) => ref(drop(node))} style={{ marginBottom: "1rem" }}>
      <div>
        <FaGripLines style={{ cursor: "grab", marginRight: "8px" }} />
      </div>
      <div>
        <Form layout="vertical">
          <Row className="d-flex justify-content-between align-items-center">
            <Col span={7}>
              <Input type="hidden" value={data?.companyURL} />
              <Input type="hidden" value={data?.logo_url} />
              <FormItem label="Company Name">
                <Input
                  size="large"
                  value={data?.companyName}
                  onChange={(e) =>
                    handleInputChange(index, "companyName", e.target.value)
                  }
                  placeholder="Company Name"
                />
              </FormItem>
            </Col>
            <Col span={7}>
              <FormItem label="Designation">
                <Input
                  size="large"
                  value={data?.designation}
                  onChange={(e) =>
                    handleInputChange(index, "designation", e.target.value)
                  }
                  placeholder="Designation"
                />
              </FormItem>
            </Col>
            <Col span={7}>
              <FormItem label="Location">
                <Input
                  size="large"
                  value={data?.location}
                  onChange={(e) =>
                    handleInputChange(index, "location", e.target.value)
                  }
                  placeholder="Location"
                />
              </FormItem>
            </Col>
          </Row>

          <Row className="d-flex justify-content-between align-items-center">
            <Col span={7}>
              <FormItem label="From">
                <DatePicker
                  size="large"
                  name="from"
                  value={data?.from ? dayjs(data?.from, "MMM YYYY") : null}
                  onChange={(value) => handleDateChange(index, "from", value)}
                  placeholder="From"
                  style={{ width: "100%" }}
                  picker="month"
                  format="MMM YYYY"
                  allowClear={false}
                />
              </FormItem>
            </Col>
            <Col span={7}>
              <FormItem label="Till">
                <DatePicker
                  size="large"
                  name="till"
                  value={data?.till ? dayjs(data?.till, "MMM YYYY") : null}
                  onChange={(value) => handleDateChange(index, "till", value)}
                  placeholder="Till"
                  style={{ width: "100%" }}
                  picker="month"
                  format="MMM YYYY"
                  allowClear={false}
                />
              </FormItem>
            </Col>

            <Col span={7}>
              <FormItem label="Present">
                <Checkbox
                  size="large"
                  checked={data?.isPresent === "yes"}
                  onChange={(value) => handleCheckboxChange(index, value)}
                >
                  Present
                </Checkbox>

              </FormItem>
            </Col>
          </Row>

          <FormItem label="Describe Work">
            <TextArea
              rows={4}
              value={data?.workDescription}
              onChange={(e) => handleTextAreaChange(index, e.target.value)}
              placeholder="Describe work..."
            />
          </FormItem>
        </Form>

        {data.index > 0 && (
          <div className="d-flex justify-content-end align-items-center my-2">
            <Button
              className="text-danger"
              onClick={() => removeRow(index)}
              type="danger"
            >
              Remove
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

const MyModal = ({ data, expert_id, fetchData }) => {
  console.log(data, "datas");
  const [visible, setVisible] = useState(false);
  const [formData, setFormData] = useState([
    {
      companyName: "",
      designation: "",
      location: "",
      from: null,
      till: null,
      isPresent: "no",
      workDescription: "",
      index: 0,
    },
  ]);

  const userData = JSON.parse(localStorage.getItem("userData"));

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleInputChange = (index, field, value) => {
    setFormData((prevData) =>
      prevData.map((data, i) => {
        if (i === index) {
          return {
            ...data,
            [field]:
              (field === "from" || field === "till") && value
                ? dayjs(value).format("MMM YYYY")
                : value,
          };
        }
        return data;
      })
    );
  };

  const handleDateChange = (index, field, value) => {
    handleInputChange(index, field, value);
  };

  const handleCheckboxChange = (index, value) => {
    const updatedFormData = [...formData];
    updatedFormData[index].isPresent = value ? "yes" : "no";
    setFormData(updatedFormData);
  };


  const addRow = () => {
    setFormData([
      ...formData,
      {
        companyName: "",
        designation: "",
        location: "",
        from: null,
        till: null,
        isPresent: "no",
        workDescription: "",
        index: formData.length,
      },
    ]);
  };

  const removeRow = (index) => {
    const updatedData = formData.filter((_, i) => i !== index);
    setFormData(updatedData);
  };

  const moveCard = (fromIndex, toIndex) => {
    const updatedData = [...formData];
    const [movedItem] = updatedData.splice(fromIndex, 1);
    updatedData.splice(toIndex, 0, { ...movedItem, index: toIndex });
    setFormData(updatedData);
  };

  const handleTextAreaChange = (index, value) => {
    const updatedData = [...formData];
    updatedData[index]["workDescription"] = value;
    setFormData(updatedData);
  };

  const handleSave = async () => {
    const postData = {
      career_title: [],
      company_name: [],
      what_work: [],
      location: [],
      experience: [],
      date_from: [],
      date_till: [],
      company_url: [],
      logo_url: [],
      till_present: [],
    };


    formData?.forEach((data) => {
      postData.career_title?.push(data?.designation);
      postData.company_name?.push(data?.companyName);
      postData.what_work?.push(data?.workDescription);
      postData.location.push(data?.location);
      // postData.experience.push(data?.experience); 
      postData.date_from?.push(data?.from);
      postData.date_till.push(data?.till);
      postData.company_url?.push(data?.companyURL);
      postData.logo_url.push(data?.logo_url);
      postData.till_present.push(data?.isPresent);
    });

    console.log("postData", postData);

    try {
      const res = await fetch(`${API_PATH.UPDATE_QC_UPDATE}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
        body: JSON.stringify({
          ...postData,
          expert_id: expert_id,
        }),
      });

      const result = await res.json();
      if (result) {
        if (res.status === 200 || res.status === true) {
          handleCancel(false);
          fetchData();
          notification.success({
            message: "Expert experience updated",
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />,
            style: {
              backgroundColor: "#2ecc71",
              color: "#fff !important",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          });
        }
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
  };


  useEffect(() => {
    if (data && data?.length > 0) {
      const preFilledData = data?.map((experience, index) => {
        const from = experience?.date_from;
        const till = experience?.date_till;
  
        // Check if the date string contains only a year
        const isYearOnlyFrom = /^\d{4}$/.test(from);
        const isYearOnlyTill = /^\d{4}$/.test(till);
  
        // Create default date strings using January and December
        const defaultFromDate = isYearOnlyFrom ? `Jan ${from}` : from;
        const defaultTillDate = isYearOnlyTill ? `Dec ${till}` : till;
  
        return {
          companyName: experience?.company_name,
          designation: experience?.career_title,
          location: experience?.location,
          from: isYearOnlyFrom || !from ? defaultFromDate : from,
          till: isYearOnlyTill || !till ? defaultTillDate : till,
          isPresent: experience?.till_present === "yes" ? "yes" : "no",
          workDescription: experience?.what_work,
          companyURL: experience?.company_url,
          logo_url: experience?.logo_url,
          index,
        };
      });
      setFormData(preFilledData);
    }
  }, [data]);
  
  


  const addFooter = (
    <div className="d-flex justify-content-end align-items-center mt-2">
        <button className="secondaryBttn btn" onClick={() => handleCancel(false)}>
          Cancel
        </button>
        <button className="viewButton btn ms-2" onClick={handleSave}>
          Submit
        </button>
    </div>
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <div>
        <MdModeEditOutline
          style={{ cursor: "pointer", fontSize: "20px" }}
          onClick={showModal}
        >
          Open Modal
        </MdModeEditOutline>
        <Modal
          title={<span className="modalHeader">Experience</span>}
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          width={800}
          footer={addFooter}
          centered
        >
          {formData?.map((data, index) => (
            <DraggableCard
              key={index}
              index={index}
              data={data}
              moveCard={moveCard}
              handleInputChange={handleInputChange}
              handleDateChange={handleDateChange}
              handleCheckboxChange={handleCheckboxChange}
              handleTextAreaChange={handleTextAreaChange}
              removeRow={removeRow}
            />
          ))}
          <Button onClick={addRow} type="dashed" block>
            Add Row
          </Button>
        </Modal>
      </div>
    </DndProvider>
  );
};

export default MyModal;
