import moment from "moment"

export const toQueryString = (paramsArray) => {
    let params_obj = {}
    Object.entries(paramsArray)?.filter(([key, value]) => value !== "" && value !== null && value !== undefined && value?.length !== 0)
        .forEach(([key1, value]) => {
            if (Array.isArray(value)) {
                params_obj = {
                    ...params_obj,
                    [key1]: JSON.stringify(value)
                }
            } else {
                if (["page", "limit", "key", "expert_type", "search", "searchkey"].includes(key1)) {
                    params_obj = {
                        ...params_obj,
                        [key1]: value
                    }
                } else {
                    params_obj = {
                        ...params_obj,
                        [key1]: JSON.stringify([value])
                    }
                }

            }
        })
    return params_obj


}

export const getFinancialYear = () => {
    const today = moment();
    const financialYearStart = moment().month(3).date(1); // April 1st of the current year
    let financialYear;
    if (today.isBefore(financialYearStart)) {
        financialYear = today.subtract(1, 'year').format('YYYY');
    } else {
        financialYear = today.format('YYYY');
    }
    return financialYear
}

export const Calculate_days = (projectStart, projectEnd) => {
    // const startDate = new Date(projectStart);
    let startDate = new Date();
    if (projectEnd) {
        startDate = new Date();
    } else {
        startDate = new Date(projectStart);
    }
    const currentDate = !["", "undefined", undefined, null, "null"]?.includes(projectEnd)
        ? new Date(projectEnd)
        : new Date();
    const timeDifference = currentDate.getTime() - startDate.getTime();
    const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));

    return `${daysDifference} days`;
}

export const formatNumber = (value) => {
    // Check if the number is an integer
    if (Number.isInteger(value)) {
        return value;
    } else {
        // If it's a floating-point number, format it to 2 decimal places
        return value.toFixed(1);
    }
}

export const getQueryParams = (query) => {
    if (query?.startsWith('?')) {
        query = query?.slice(1);
    }

    const result = {};
    let pairs = [];
    let buffer = '';
    let inBrackets = false;

    for (let i = 0; i < query?.length; i++) {
        const char = query[i];

        if (char === '[') inBrackets = true;
        if (char === ']') inBrackets = false;

        if (char === '&' && !inBrackets) {
            pairs?.push(buffer);
            buffer = '';
        } else {
            buffer += char;
        }
    }
    pairs?.push(buffer); // push the last part after the loop
    pairs.forEach(pair => {
        const [key, value] = pair?.split('=');
        const decodedValue = decodeURIComponent(value?.replace(/\+/g, ' '));
        // Check if the decoded value is a JSON array
        if (decodedValue?.startsWith('[') && decodedValue?.endsWith(']')) {
            try {
                result[key] = JSON.parse(decodedValue);
            } catch (e) {
                result[key] = decodedValue;
            }
        } else {
            result[key] = decodedValue;
        }
    });
    return result;
}