import { Tabs } from "antd";
import React from "react";
import "react-h5-audio-player/lib/styles.css";
import "../../App.css";
import "../../assests/css/custom.css";
import AddPhantomKeyModal from "./AddPhantomKeyModal";
import QCExpertProfile from "./QCExpertProfile";
import ErrorQcProfiles from "./ErrorQcProfiles";
import useQCExpert from "./hook/useQCExpert";

// const MyFormItemGroup = ({ prefix, children }) => {
//   const prefixPath = React.useContext(MyFormItemContext);
//   const concatPath = React.useMemo(
//     () => [...prefixPath, ...toArr(prefix)],
//     [prefixPath, prefix]
//   );
//   return (
//     <MyFormItemContext.Provider value={concatPath}>
//       {children}
//     </MyFormItemContext.Provider>
//   );
// };
// const MyFormItem = ({ name, ...props }) => {
//   const prefixPath = React.useContext(MyFormItemContext);
//   const concatName =
//     name !== undefined ? [...prefixPath, ...toArr(name)] : undefined;
//   return <Form.Item name={concatName} {...props} />;
// };

const ExotelTable = () => {
  const { GetQcList } = useQCExpert();
  const items = [
    {
      key: "1",
      label: "QC Experts",
      children: <QCExpertProfile />,
    },
    {
      key: "2",
      label: "Error Profiles",
      children: <ErrorQcProfiles />,
    },
  ];
  const onChange = (key) => {
    console.log(key);
    if (key === "1") {
      GetQcList({ page: 1, selectedOptions: { key: "" } });
    }
  };

  return (
    <>
      <div>
        <div className="body-expart-div">
          <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
        </div>
      </div>
      <div
        className="offcanvas ttt offcanvas-end"
        tabIndex="-1"
        id="submitted"
        aria-labelledby="offcanvasRightLabel"
      >
        {/* {loading1 ? ( */}
        <>
          <div
            className="offcanvas-header align-items-center justify-content-between"
            style={{
              borderBottom: "1px solid rgb(197, 195, 195)",
            }}
          >
            <button
              type="button"
              className="btn-close "
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
            <h5 id="offcanvasRightLabel">Add New Manager</h5>
          </div>
          <div className="offcanvas-body">
            <div className="experien-list mt-4">
              <div>
                <ul className="nextyn_list_items">
                  <li className="nextyn_list_item border-bottom-0">
                    <div className="expertCompany_2UyI2">
                      <span className="title_1p_LH">From:</span>
                      <div className="companyName_1dSwA">Capital One</div>
                    </div>
                    <div className="expertCompany_2UyI2">
                      <span className="title_1p_LH">To:</span>
                      <div className="companyName_1dSwA">Capital One</div>
                    </div>
                    <div className="expertCompany_2UyI2">
                      <span className="title_1p_LH">Through:</span>
                      <div className="companyName_1dSwA">+17182858858</div>
                    </div>
                  </li>
                  <li className="nextyn_list_item border-bottom-0">
                    <div className="expertCompany_2UyI2">
                      <span className="title_1p_LH">Call Reference Id:</span>
                      <div className="companyName_1dSwA">Capital One</div>
                    </div>
                    <div className="expertCompany_2UyI2">
                      <span className="title_1p_LH">Total Talk Time:</span>
                      <div className="companyName_1dSwA">s</div>
                    </div>
                  </li>
                  <li className="nextyn_list_item border-bottom-0">
                    <div className="expertCompany_2UyI2">
                      <span className="title_1p_LH">Start Time:</span>
                      <div className="companyName_1dSwA">
                        Mon, 10 Apr 2023 11:14:01
                      </div>
                    </div>
                    <div className="expertCompany_2UyI2">
                      <span className="title_1p_LH">End Time:</span>
                      <div className="companyName_1dSwA">
                        Mon, 10 Apr 2023 11:14:01
                      </div>
                    </div>
                    <div className="expertCompany_2UyI2">
                      <span className="title_1p_LH">Call Time:</span>
                      <div className="companyName_1dSwA">
                        Mon, 10 Apr 2023 11:14:01
                      </div>
                    </div>
                  </li>
                  <li className="nextyn_list_item border-bottom-0">
                    <div className="expertCompany_2UyI2">
                      <span className="title_1p_LH">Transcription:</span>
                      <div className="companyName_1dSwA">Capital One</div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </>
        <AddPhantomKeyModal />
      </div>
    </>
  );
};

export default ExotelTable;
