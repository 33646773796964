import {
  PlusCircleOutlined,
  ExclamationCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Switch,
  notification,
} from "antd";
import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import API_PATH from "../../Constants/api-path";
import axios from "axios";
import { useLocation, useNavigate, createSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import moment from "moment";
import "moment/locale/en-gb"; // Import locale if needed, adjust locale as per your requirements
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";

import customParseFormat from "dayjs/esm/plugin/customParseFormat";
dayjs.extend(customParseFormat);
const { Option } = Select;

const AddNewExpertSection = () => {
  const [loading, setLoading] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const history = useNavigate();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [experts, setExperts] = useState([]);
  const [value, setValue] = useState("");
  const editorRef = useRef(null);
  const [iSchanged, setIschanged] = useState(false);
  const [addData, setAddData] = useState({
    expert_id: "",
    email: null,
    submit: "",
    link: "",
    consulting_rate: "",
    expertCurrency: "USD",
    hourly_consulting_rate: "",
    expert_client_Currency: "USD",
    overview: "",
    current_location: "",
  });

  const [selectedFromID, setSelectedFromID] = useState("");

  const [status, setStatusData] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [selectedValue, setSelectedValue] = useState(null);
  const [options, setOptions] = useState([]);
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  let query = useQuery();
  let project_id = query.get("project_id");

  let expertID = query.get("expertID");

  const [inputs, setInputs] = useState([
    {
      title: "",
      company: "",
      startDate: "",
      endDate: "",
      current_show_check: "no",
      till_present: "no",
    },
  ]);

  const [inputsTwo, setInputsTwo] = useState([
    {
      titlePrev: "",
      companyPrev: "",
      startDatePrev: "",
      endDatePrev: "",
      previous_show_check: "no",
    },
  ]);
  // const handleSearch = (value) => {
  //   const filteredOptions = experts
  //     .map((itemData) => {
  //       const dataName = itemData.name
  //         ? itemData.name
  //         : `${itemData.first_name} ${itemData.last_name}`;
  //       return { value: dataName };
  //     })
  //     .filter((option) => option.value.toLowerCase().includes(value.toLowerCase()));

  //   // Check if there's an exact match with the input value
  //   const exactMatchOption = filteredOptions.find(
  //     (option) => option.value.toLowerCase() === value.toLowerCase()
  //   );

  //   if (exactMatchOption) {
  //     setSelectedValue(exactMatchOption.value); // Set the selected value
  //   } else {
  //     setOptions(filteredOptions); // Update the options list
  //   }
  // };

  // const onBlur = () => {
  //   const selectedValue = addData?.expert_id;
  //   if (selectedValue && !experts.some((itemData) => itemData.name === selectedValue)) {
  //     // If the selected value doesn't exist in the original experts array, add it as a custom option
  //     setExperts((prevExperts) => [
  //       ...prevExperts,
  //       {
  //         name: selectedValue, // Assuming your expert object has a "name" property
  //         // Add other properties if needed, e.g., first_name, last_name, etc.
  //       },
  //     ]);
  //   }
  // };

  // const handleSelect = (value) => {
  //   setSelectedValue(value);
  // };

  const handleInputChange = useCallback((value, field, index) => {
    setInputs((prevInputs) =>
      prevInputs?.map((input, i) => {
        if (i === index) {
          return {
            ...input,
            [field]:
              (field === "startDate" || field === "endDate") && value
                ? dayjs(value)?.format("MM, YYYY") // Format the date in "01, 2020" format
                : value, // If value is null (cleared), then set field to null
          };
        }
        return input;
      })
    );
  }, []);

  const handleInputChangeTwo = useCallback((value, field, index) => {
    setInputsTwo((prevInputs) =>
      prevInputs?.map((input, i) => {
        if (i === index) {
          return {
            ...input,
            [field]:
              (field === "startDatePrev" || field === "endDatePrev") && value
                ? dayjs(value)?.format("MM, YYYY") // Format the date in "01, 2020" format
                : value, // If value is null (cleared), then set field to null
          };
        }
        return input;
      })
    );
  }, []);

  const handleAddInput = useCallback(() => {
    const newInputs = [
      ...inputs,
      { title: "", company: "", startDate: "", endDate: "" },
    ];
    setInputs(newInputs);
  }, [inputs]);

  const handleAddInputTwo = useCallback(() => {
    const newInputs = [
      ...inputsTwo,
      { titlePrev: "", companyPrev: "", startDatePrev: "", endDatePrev: "" },
    ];
    setInputsTwo(newInputs);
  }, [inputsTwo]);

  const handleDeleteInput = useCallback(
    (index) => {
      const newInputs = [...inputs];
      newInputs.splice(index, 1);
      setInputs(newInputs);
    },
    [inputs]
  );

  const handleDeleteInputTwo = useCallback(
    (index) => {
      const newInputs = [...inputsTwo];
      newInputs.splice(index, 1);
      setInputsTwo(newInputs);
    },
    [inputsTwo]
  );

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setAddData((prevData) => ({ ...prevData, [name]: value }));
  }, []);

  const expertCurrency = useCallback(
    (value) => {
      const selectValue = value || "USD";
      setAddData({ ...addData, expertCurrency: selectValue });
    },
    [addData]
  );

  const expertRate = useCallback(
    (value) => {
      const selectValue = value;
      setAddData({ ...addData, consulting_rate: selectValue });
    },
    [addData]
  );

  const clientCurrency = useCallback(
    (value) => {
      const selectValue = value || "USD";
      setAddData({ ...addData, expert_client_Currency: selectValue });
    },
    [addData]
  );

  const clientRate = useCallback(
    (value) => {
      const selectValue = value;
      setAddData({ ...addData, hourly_consulting_rate: selectValue });
    },
    [addData]
  );

  const handleCurrentChange = useCallback(
    (checked, index) => {
      const updatedInputs = [...inputs];
      updatedInputs[index].current_show_check = checked ? "yes" : "no";
      setInputs(updatedInputs);
    },
    [inputs]
  );

  const handleCheckboxChange = useCallback((event, index) => {
    const { checked } = event.target;
    setInputs((prevInputs) =>
      prevInputs.map((input, i) =>
        i === index ? { ...input, till_present: checked ? "yes" : "no" } : input
      )
    );
  }, []);

  const handlePrevChange = useCallback(
    (checked, index) => {
      const updatedInputs = [...inputsTwo];
      updatedInputs[index].previous_show_check = checked ? "yes" : "no";
      setInputsTwo(updatedInputs);
    },
    [inputsTwo]
  );

  const getFormattedDateInOneFormat = useCallback((date) => {
    let trimDate = date?.trim();
    if (trimDate && trimDate != null && trimDate != "null") {
      const possibleFormats = ["MM, YYYY", "MM-YYYY", "MMM YYYY", "YYYY"];
      let detectedFormat = null;
      for (let format of possibleFormats) {
        const parsedDate = moment(trimDate?.trim(), format, true);
        if (parsedDate.isValid()) {
          detectedFormat = format;
          break;
        }
      }
      return dayjs(trimDate, detectedFormat)?.format("MM, YYYY");
    } else {
      return "";
    }
  }, []);

  const onChange = useCallback(
    async (value, option) => {
      const selectValue = value;
      const setSelectedID = option?.id;
      setSelectedFromID(setSelectedID, "setSelectedID");
      // setAddData({ ...addData, expert_id: selectValue });
      setAddData({ ...addData, email: selectValue, expert_id: setSelectedID });

      try {
        const res = await axios.get(
          `${API_PATH.EXPERT_PROFILE}/${setSelectedID}`
        );
        // const linkedinURL = res?.data?.data?.[0]?.linkedin_url || '';
        setAddData({
          ...addData,
          // expert_id: res?.data?.data[0]?.full_Name || '',
          expert_id: res?.data?.data[0]?._id || "",
          email: res?.data?.data[0]?.email || "",
          link: res.data.data[0].linkedin_url || "",
          submit: res?.data?.data[0]?.submit || "",
          consulting_rate: res?.data?.data[0]?.hourly_rate_call_cunsltn || "",
          expertCurrency: res?.data?.data[0]?.hourly_currency || "",
          overview: res?.data?.data[0]?.overview || "",
          current_location: res?.data?.data[0]?.company || "",
        });

        const currentData = res?.data?.data[0]?.expertexperiences;

        const temDatas = currentData?.map((value, index) => {
          if (value?.till_present === "yes") {
            return {
              title: value?.career_title,
              company: value?.company_name,
              startDate: getFormattedDateInOneFormat(value?.date_from),
              endDate: getFormattedDateInOneFormat(value?.date_till),
              till_present: value?.till_present,
              current_show_check: value?.show_check ? value?.show_check : "no",
            };
          }
          return null;
        });

        const filteredData = temDatas.filter((val) => val !== null);
        setInputs(filteredData);

        const currentPrevData = res?.data?.data[0]?.expertexperiences;

        const temPrevData = currentPrevData.map((value, index) => {
          // console.log('currentPrevData: ', value);
          if (value?.till_present === "no") {
            return {
              titlePrev: value?.career_title || "",
              companyPrev: value?.company_name || "",
              startDatePrev: getFormattedDateInOneFormat(value?.date_from), //dayjs(value?.date_from, "MMMM YYYY").format('MM-YYYY'),
              endDatePrev: getFormattedDateInOneFormat(value?.date_till), //dayjs(value?.endDate, "MMMM YYYY").format('MM-YYYY'),
              previous_show_check: value?.show_check || "no",
            };
          } else {
            return null;
          }
        });

        const filteredPrevData = temPrevData.filter((val) => val !== null);
        setInputsTwo(filteredPrevData);
      } catch (error) {
        console.error(error);
      }
    },
    [addData, getFormattedDateInOneFormat]
  );

  const [, setFilteredExperts] = useState([]); // State to hold filtered experts

  const onSearch = useCallback(
    (query) => {
      const filtered = experts.filter((itemData) => {
        const dataName = itemData?.name
          ? itemData?.name
          : `${itemData?.first_name} ${itemData?.last_name}`;
        return dataName.toLowerCase().includes(query.toLowerCase());
      });
      setFilteredExperts(filtered);
    },
    [experts]
  );

  function filterDuplicates(array) {
    return Array.from(new Set(array));
  }

  const AddExpert = useCallback(
    async (status) => {
      // e.preventDefault();
      setLoading(true);

      const transformedData = inputs?.map((input) => ({
        current_designation: input?.title,
        current_compny: input?.company,
        current_designation_date: `${input?.startDate} - ${input?.endDate}`,
        current_show_check: input?.current_show_check,
        till_present: input?.till_present === "yes" ? "yes" : "no",
      }));

      const isTillPresentChecked = transformedData.some(
        (data) => data?.till_present === "yes"
      );

      const postData = {
        current_compny: [],
        current_designation: [],
        current_designation_date: [],
        current_show_check: [],
        till_present: isTillPresentChecked ? "yes" : "no",
      };

      const current_compny = postData?.current_compny;
      const current_designation = postData?.current_designation;
      const current_designation_date = postData?.current_designation_date;
      const current_show_check = postData?.current_show_check;
      const till_present = postData?.till_present;

      for (let i = 0; i < transformedData?.length; i++) {
        const data = transformedData[i];
        postData.current_compny?.push(data?.current_compny);
        postData.current_designation?.push(data?.current_designation);

        // Split the combined date string into individual start and end date values
        const [startDate, endDate] =
          data?.current_designation_date?.split(" - ");

        // Combine start and end dates into a single string with the desired separator
        postData?.current_designation_date?.push(
          `${startDate?.trim()} - ${endDate?.trim()}`
        );

        postData?.current_show_check?.push(data?.current_show_check);
        // Check if any of the inputs have till_present === "yes"
        if (transformedData.some((data) => data?.till_present === "yes")) {
          postData.till_present = "yes"; // Update the till_present value to "yes"
        }
      }

      const transformedDataTwo = inputsTwo?.map((input) => ({
        previous_designation: input?.titlePrev,
        previous_compny: input?.companyPrev,
        previous_designation_date: `${input?.startDatePrev} - ${input?.endDatePrev}`, // Combine start and end dates into a single string
        previous_show_check: input?.previous_show_check,
      }));

      const postDataTwo = {
        previous_compny: [],
        previous_designation: [],
        previous_designation_date: [],
        previous_show_check: [],
      };

      const previous_compny = postDataTwo?.previous_compny;
      const previous_designation = postDataTwo?.previous_designation;
      const previous_designation_date = postDataTwo?.previous_designation_date;
      const previous_show_check = postDataTwo?.previous_show_check;

      for (let i = 0; i < transformedDataTwo?.length; i++) {
        const data = transformedDataTwo[i];
        postDataTwo?.previous_compny?.push(data?.previous_compny);
        postDataTwo?.previous_designation?.push(data?.previous_designation);

        // Split the combined date string into individual start and end date values
        const [startDatePrev, endDatePrev] =
          data?.previous_designation_date?.split(" - ");

        // Combine start and end dates into a single string with the desired separator
        postDataTwo?.previous_designation_date?.push(
          `${startDatePrev?.trim()} - ${endDatePrev?.trim()}`
        );

        postDataTwo?.previous_show_check?.push(data?.previous_show_check);
        if (data?.till_present === "yes") {
          postDataTwo.till_present = "yes"; // Update the till_present value if any input has "yes"
        }
      }

      if (status === "Submit") {
        // Perform save logic
        setStatusData("Submit");
        // Make the necessary API request or update the state accordingly
      } else if (status === "Save as Draft") {
        // Perform draft logic
        setStatusData("Save as Draft");
        // Make the necessary API request or update the state accordingly
      }

      try {
        if (expertID) {
          const res = await fetch(`${API_PATH?.PROJECT_EXPERT_UPDATE}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userData?.token}`,
            },
            body: JSON.stringify({
              expert_id: addData?.expert_id,
              email: addData?.email, //added by onkar
              project_id: project_id,
              submit: status,
              link: addData?.link,
              consulting_rate: addData?.consulting_rate,
              expertCurrency: addData?.expertCurrency,
              hourly_consulting_rate: addData?.hourly_consulting_rate,
              expert_client_Currency: addData?.expert_client_Currency,
              user_id: project_id,
              overview: addData?.overview,
              current_location: addData?.current_location,
              current_compny: current_compny,
              current_designation: current_designation,
              till_present: till_present, // Join the array elements with '/'
              current_designation_date: current_designation_date,
              current_show_check: current_show_check,
              previous_compny: previous_compny,
              previous_designation: previous_designation,
              previous_designation_date: previous_designation_date,
              previous_show_check: previous_show_check,
              experts_detail_id: expertID,
            }),
          });

          const result = await res.json();
          if (result) {
            setSpinner(false);
            if (res.status === 200 || res.status === true) {
              // setAddData("")
              // setInputs("")
              // setInputsTwo("")
              history(
                `/dashboard/expert_list?${createSearchParams({ project_id })}`
              );

              notification.success({
                message: "Expert has been updated",
                icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
                style: {
                  backgroundColor: "#2ecc71",
                  color: "#fff !important",
                  border: "1px solid #52c41a",
                },
                duration: 5,
                placement: "topRight",
              });
            }
          } else {
            notification.error({
              message: "Error: Something went wrong server error",
              icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
              style: {
                backgroundColor: "#e74c3c",
                color: "#fff",
                border: "1px solid #c0392b",
              },
              duration: 5,
              placement: "topRight",
            });
          }
        } else {
          const res = await fetch(`${API_PATH?.Add_Expert}`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userData?.token}`,
            },
            body: JSON.stringify({
              expert_id: addData?.expert_id,
              email: addData?.email,
              added_by_team: true,
              project_id: project_id,
              submit: status,
              link: addData?.link,
              consulting_rate: addData?.consulting_rate,
              expertCurrency: addData?.expertCurrency,
              hourly_consulting_rate: addData?.hourly_consulting_rate,
              expert_client_Currency: addData?.expert_client_Currency,
              user_id: project_id,
              overview: addData?.overview,
              current_location: addData?.current_location,
              current_compny: current_compny,
              current_designation: current_designation,
              till_present: till_present, // Join the array elements with '/'
              current_designation_date: current_designation_date,
              current_show_check: current_show_check,
              previous_compny: previous_compny,
              previous_designation: previous_designation,
              previous_designation_date: previous_designation_date,
              previous_show_check: previous_show_check,
            }),
          });

          const result = await res.json();
          if (result) {
            if (res.status === 200 || res.status === true) {
              if (status === "Save as Draft") {
                history(
                  `/dashboard/expert_list?${createSearchParams({ project_id })}`
                );
                notification.success({
                  message: "Draft saved successfully",
                  icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
                  style: {
                    backgroundColor: "#2ecc71",
                    color: "#fff !important",
                    border: "1px solid #52c41a",
                  },
                  duration: 5,
                  placement: "topRight",
                });
              } else {
                history(
                  `/dashboard/expert_list?${createSearchParams({ project_id })}`
                );
                notification.success({
                  message: "Expert submitted successfully",
                  icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
                  style: {
                    backgroundColor: "#2ecc71",
                    color: "#fff !important",
                    border: "1px solid #52c41a",
                  },
                  duration: 5,
                  placement: "topRight",
                });
              }
            }
          } else {
            notification.error({
              message: "Error: Something went wrong server error",
              icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
              style: {
                backgroundColor: "#e74c3c",
                color: "#fff",
                border: "1px solid #c0392b",
              },
              duration: 5,
              placement: "topRight",
            });
          }
        }
      } catch (error) {
        setLoading(false);

        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
      setLoading(false);
    },
    [
      addData?.consulting_rate,
      addData?.current_location,
      addData?.email,
      addData?.expertCurrency,
      addData?.expert_client_Currency,
      addData?.expert_id,
      addData?.hourly_consulting_rate,
      addData?.link,
      addData?.overview,
      expertID,
      history,
      inputs,
      inputsTwo,
      project_id,
      userData?.token,
    ]
  );

  const getExperts = useCallback(async () => {
    setLoading(true);
    await axios
      .get(`${API_PATH.EXPERT_LIST}?page=1&limit=1000`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
      })
      .then((res) => {
        setLoading(true);
        setExperts(filterDuplicates([...res?.data?.data]));
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [userData?.token]);

  const fetchData = useCallback(async () => {
    try {
      const res = await axios.get(`${API_PATH.PROJECT_EXPERT_GET}/${expertID}`);
      setAddData({
        expert_id: res?.data?.data[0]?.expert_id,
        email: res?.data?.data[0]?.expertprofiles?.[0]?.email,
        link: res?.data?.data[0]?.link,
        submit: res?.data?.data[0]?.submit,
        consulting_rate: res?.data?.data[0]?.consulting_rate,
        expertCurrency: res?.data?.data[0]?.expertCurrency,
        hourly_consulting_rate: res?.data?.data[0]?.hourly_consulting_rate,
        expert_client_Currency: res?.data?.data[0]?.expert_client_Currency,
        overview: res?.data?.data[0]?.overview,
        current_location: res?.data?.data[0]?.current_location,
      });

      const currentData = res?.data?.data[0]?.expert_experience;
      const temDatas = currentData?.map((value, index) => {
        if (value?.current_designation) {
          // Extract the start and end dates
          const [startDate, endDate] =
            value?.current_designation_date.split("-");

          console.log(
            "getFormattedDateInOneFormat(startDate)",
            getFormattedDateInOneFormat(startDate)
          );
          return {
            title: value?.current_designation,
            company: value?.current_compny,
            startDate: getFormattedDateInOneFormat(startDate),
            endDate: getFormattedDateInOneFormat(endDate),
            till_present: value?.till_present,
            current_show_check: value?.show_check,
          };
        }
      });

      const filteredData = temDatas.filter((val) => val !== null);
      setInputs(filteredData);

      const currentPrevData = res?.data?.data[0]?.expert_experience;

      const temPrevData = currentPrevData.map((value, index) => {
        if (value?.previous_designation) {
          // Split the combined date string into individual start and end date values
          const [startDatePrev, endDatePrev] =
            value?.previous_designation_date.split("-");
          return {
            titlePrev: value?.previous_designation || "",
            companyPrev: value?.previous_compny || "",
            startDatePrev: getFormattedDateInOneFormat(startDatePrev),
            endDatePrev: getFormattedDateInOneFormat(endDatePrev),
            previous_show_check: value?.show_check || "no",
          };
        }
        // Return null for entries without a valid previous designation
        return null;
      });

      // Filter out null entries to get the valid data
      const filteredPrevData = temPrevData.filter((val) => val !== null);
      setInputsTwo(filteredPrevData);
    } catch (error) {
      console.error(error);
    }
  }, [expertID, getFormattedDateInOneFormat]);

  const oncecall = useRef(false);
  console.log("oncecall: ", oncecall);

  useEffect(() => {
    if (!oncecall.current) {
      oncecall.current = true;
      getExperts();
      fetchData();
      setLoading(false);
    }
  }, [fetchData, getExperts]);

  const suffixSelector = (
    <Form.Item noStyle>
      <Select
        style={{
          width: 100,
        }}
        size="large"
        name="expertCurrency"
        value={addData?.expertCurrency || "USD"} // Set the value to the state value
        onChange={expertCurrency}
        defaultValue="USD"
      >
        <Option value="USD">USD</Option>
        <Option value="GBP">GBP</Option>
        <Option value="EUR">EUR</Option>
        <Option value="INR">INR</Option>
      </Select>
    </Form.Item>
  );

  const suffixSelectorTwo = (
    <Form.Item noStyle>
      <Select
        style={{
          width: 100,
        }}
        size="large"
        name="expert_client_Currency"
        value={addData?.expert_client_Currency || "USD"}
        onChange={clientCurrency}
        defaultValue="USD"
      >
        <Option value="USD">USD</Option>
        <Option value="GBP">GBP</Option>
        <Option value="EUR">EUR</Option>
        <Option value="INR">INR</Option>
      </Select>
    </Form.Item>
  );

  const SearchFieldOption = (
    <Option key="search-field" value="search-field" disabled>
      <Input.Search
        size="large"
        placeholder="Search Expert"
        onSearch={(value) => {
          onSearch(value);
        }}
      />
    </Option>
  );

  const optionsDatas = useMemo(
    () =>
      experts?.map((itemData) => {
        const dataName = itemData?.email;
        // ? itemData?.name
        // : `${itemData?.first_name} ${itemData?.last_name}`;
        return { value: dataName, id: itemData?._id, key: itemData?._id };
      }),
    [experts]
  );

  return (
    <div className="row">
      <div className="col-md-12">
        <h2 class="card-inside-title">Profile</h2>
        <Form className="d-flex justify-content-between align-items-center">
          <Col span={7}>
            <Select
              showSearch
              placeholder="Expert Email"
              style={{
                width: "100%",
              }}
              size="large"
              options={optionsDatas}
              filterOption={(inputValue, option) => {
                return (
                  option?.value
                    ?.toUpperCase()
                    ?.indexOf(inputValue?.toUpperCase()) !== -1
                );
              }}
              name="expert_id"
              virtual={false}
              allowClear
              onChange={onChange}
              defaultValue={addData?.email}
              // value={addData?.expert_id}
              value={addData?.email}
            />
          </Col>

          {/* <Col span={7}>
            <Select
              size="large"
              style={{ width: "100%" }}
              showSearch
              name="expert_id"
              placeholder="Expert Name"
              onChange={onChange}
              onSearch={onSearch}
              onBlur={onBlur}
              filterOption={false}
              notFoundContent={false}
              value={addData?.expert_id}
              virtual={false}
              allowClear // To enable clearing the input when there's no matched option
            >
              {filteredExperts.map((itemData) => {
                const dataName = itemData?.name
                  ? itemData?.name
                  : `${itemData?.first_name} ${itemData?.last_name}`;
                return <Option key={dataName} value={dataName}>{dataName}</Option>;
              })}

              {addData?.expert_id && !filteredExperts.some((itemData) => itemData.name === addData.expert_id) && (
                <Option key={addData.expert_id} value={addData.expert_id}>
                  {addData.expert_id}
                </Option>
              )}
            </Select>
          </Col> */}

          {/* <div className="col-4">
            <div className="location">
              <Dropdown show={open}>
                <FormControl
                  className="card-dropdown"
                  id="location"
                  aria-expanded="false"
                  style={{ height: "2.55rem" }}
                  type="search"
                  onFocus={() => setOpen(true)}
                  onBlur={() => setOpen(false)}
                  // onChange={(event) => {
                  //   setSearch(event.target.value);
                  // }}
                  placeholder="Location"
                  autoComplete="off"
                // value={search}
                />
                <Dropdown.Menu
                  style={{ backgroundColor: "white" }}
                  className="dropdown-list"
                  aria-labelledby="location"
                >
                  {open &&
                    (randomCountryData.length ? (
                      randomCountryData
                        .filter((item) => !selectedItem.includes(item))
                        .map((item) => (
                          <Dropdown.Item
                            key={item}
                            onClick={() => {
                              handleItemClick(item);
                              setOpen(false);
                            }}
                          >
                            {capitalizeFirstLetter(item)}
                          </Dropdown.Item>
                        ))
                    ) : (
                      <Dropdown.Item disabled>No data found</Dropdown.Item>
                    ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div> */}

          <Col span={7}>
            <Input
              size="large"
              placeholder="Linkedin URL"
              name="link"
              value={addData?.link}
              onChange={handleChange}
            />
          </Col>
          <Col span={7}>
            <Input
              size="large"
              placeholder="Location"
              name="current_location"
              value={addData?.current_location}
              onChange={handleChange}
            />
          </Col>
        </Form>
      </div>

      <div className="col-md-12">
        <h2 class="card-inside-title">Current Designation</h2>
        <Form>
          {console.log("inputs", inputs)}
          {inputs?.map(
            (input, index) =>
              input && (
                <>
                  <div key={index}>
                    <Row
                      gutter={[24, 24]}
                      className="d-flex justify-content-between align-items-center"
                    >
                      <Col span={12}>
                        <Input
                          size="large"
                          placeholder="Job Title"
                          name="title"
                          value={input?.title}
                          onChange={(event) =>
                            handleInputChange(
                              event.target.value,
                              "title",
                              index
                            )
                          }
                        />
                      </Col>
                      <Col span={12}>
                        <Input
                          size="large"
                          placeholder="Company"
                          name="company"
                          value={input?.company}
                          onChange={(event) =>
                            handleInputChange(
                              event.target.value,
                              "company",
                              index
                            )
                          }
                        />
                      </Col>
                    </Row>

                    <Row
                      gutter={[24, 24]}
                      className="d-flex justify-content-between align-items-center mt-4"
                    >
                      <Col span={12}>
                        <DatePicker
                          size="large"
                          style={{ width: "100%" }}
                          placeholder="Start Date"
                          name="startDate"
                          value={
                            input?.startDate
                              ? dayjs(input?.startDate, "MM, YYYY")
                              : null
                          }
                          onChange={(value) =>
                            handleInputChange(value, "startDate", index)
                          }
                          picker="month"
                          format="MM, YYYY" // Include the day of the month in the format
                          disabledDate={(current) =>
                            current && current.isAfter(dayjs(), "month")
                          }
                        />
                      </Col>
                      <Col span={12}>
                        <DatePicker
                          size="large"
                          style={{ width: "100%" }}
                          placeholder="End Date"
                          name="endDate"
                          value={
                            input?.endDate
                              ? dayjs(input?.endDate, "MM, YYYY")
                              : null
                          }
                          onChange={(value) =>
                            handleInputChange(value, "endDate", index)
                          }
                          picker="month"
                          format="MM, YYYY" // Include the day of the month in the format
                          disabledDate={(current) =>
                            current && current.isAfter(dayjs(), "month")
                          }
                        />
                      </Col>
                    </Row>

                    <Row
                      gutter={[24, 24]}
                      className="d-flex justify-content-between align-items-center mt-4"
                    >
                      <Col span={12}>
                        <Switch
                          checked={input?.current_show_check === "yes"}
                          onChange={(checked) =>
                            handleCurrentChange(checked, index)
                          }
                        />
                      </Col>

                      <Col span={12}>
                        <Checkbox
                          checked={input?.till_present === "yes"}
                          onChange={(event) =>
                            handleCheckboxChange(event, index)
                          }
                          size="large"
                        >
                          Present
                        </Checkbox>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    {inputs.length > 1 ? (
                      <div className="d-flex justify-content-end align-items-center my-2">
                        <Button
                          type="primary"
                          onClick={() => handleDeleteInput(index)}
                        >
                          Remove
                        </Button>
                      </div>
                    ) : null}
                  </div>
                </>
              )
          )}

          <div className="d-flex justify-content-center align-items-center mt-4">
            <Button
              size="default"
              icon={<PlusCircleOutlined />}
              className="text-center d-flex align-items-center viewButton"
              onClick={handleAddInput}
            >
              Add Current Designation
            </Button>
          </div>
        </Form>
      </div>

      <div className="col-md-12">
        <h2 class="card-inside-title">Previous Designation</h2>
        <Form>
          {inputsTwo?.map((input, index) => (
            <>
              <div key={index}>
                <Row
                  gutter={[24, 24]}
                  className="d-flex justify-content-between align-items-center"
                >
                  <Col span={12}>
                    <Input
                      size="large"
                      placeholder="Job Title"
                      name="titlePrev"
                      value={input?.titlePrev}
                      onChange={(event) =>
                        handleInputChangeTwo(
                          event.target.value,
                          "titlePrev",
                          index
                        )
                      }
                    />
                  </Col>
                  <Col span={12}>
                    <Input
                      size="large"
                      placeholder="Company"
                      name="companyPrev"
                      value={input?.companyPrev}
                      onChange={(event) =>
                        handleInputChangeTwo(
                          event.target.value,
                          "companyPrev",
                          index
                        )
                      }
                    />
                  </Col>
                </Row>

                <Row
                  gutter={[24, 24]}
                  className="d-flex justify-content-between align-items-center mt-4"
                >
                  <Col span={12}>
                    <DatePicker
                      size="large"
                      style={{ width: "100%" }}
                      placeholder="Start Date"
                      name="startDatePrev"
                      value={
                        input?.startDatePrev
                          ? dayjs(input?.startDatePrev, "MM, YYYY")
                          : null
                      }
                      onChange={(value) =>
                        handleInputChangeTwo(value, "startDatePrev", index)
                      }
                      picker="month"
                      format="MM, YYYY" // Include the day of the month in the format
                      disabledDate={(current) =>
                        current && current.isAfter(dayjs(), "month")
                      }
                    />
                  </Col>
                  <Col span={12}>
                    <DatePicker
                      size="large"
                      style={{ width: "100%" }}
                      placeholder="End Date"
                      name="endDatePrev"
                      value={
                        input?.endDatePrev
                          ? dayjs(input?.endDatePrev, "MM, YYYY")
                          : null
                      }
                      onChange={(value) =>
                        handleInputChangeTwo(value, "endDatePrev", index)
                      }
                      picker="month"
                      format="MM, YYYY" // Include the day of the month in the format
                      disabledDate={(current) =>
                        current && current.isAfter(dayjs(), "month")
                      }
                    />
                  </Col>
                </Row>

                <Row
                  gutter={[24, 24]}
                  className="d-flex justify-content-between align-items-center mt-4"
                >
                  <Col span={12}>
                    <Switch
                      checked={input?.previous_show_check === "yes"}
                      value={input?.previous_show_check === "yes"}
                      onChange={(checked) => handlePrevChange(checked, index)}
                    />
                  </Col>
                  {/* <Col span={12}>
              <Checkbox size="large">Present</Checkbox>
            </Col> */}
                </Row>
              </div>

              <div>
                {inputsTwo.length > 1 ? (
                  <div className="d-flex justify-content-end align-items-center my-2">
                    <Button
                      type="primary"
                      onClick={() => handleDeleteInputTwo(index)}
                    >
                      Remove
                    </Button>
                  </div>
                ) : null}
              </div>
            </>
          ))}

          <div className="d-flex justify-content-center align-items-center mt-4">
            <Button
              size="medium"
              icon={<PlusCircleOutlined />}
              className="text-center d-flex align-items-center viewButton"
              onClick={handleAddInputTwo}
            >
              Add Previous Designation
            </Button>
          </div>
        </Form>
      </div>

      <div className="col-md-12">
        <h2 class="card-inside-title">Overview</h2>
        <Form>
          <Row
            gutter={[24, 24]}
            className="d-flex justify-content-between align-items-center"
          >
            <Col span={24}>
              <CKEditor
                editor={Editor}
                data={addData?.overview}
                onReady={(editor) => {
                  console.info("Editor is ready to use!", editor);
                }}
                config={{
                  toolbar: [
                    "heading",
                    "|",
                    "bold",
                    "italic",
                    "link",
                    "bulletedList",
                    "numberedList",
                    "|",
                    "outdent",
                    "indent",
                    "|",
                    "undo",
                    "redo",
                  ],
                  placeholder: "To reference an expert type @",
                }}
                onChange={(event, editor) => {
                  const newData = editor.getData();
                  setAddData((prevData) => ({
                    ...prevData,
                    overview: newData,
                  }));
                }}
              />
              {/* <TextArea
                showCount
                maxLength={100}
                style={{
                  height: 120,
                  marginBottom: 24,
                }}
                name="overview"
                value={addData?.overview}
                onChange={handleChange}
                placeholder="Please enter a detailed overview of the experts experience and topical knowledge"
              /> */}
            </Col>
          </Row>

          <Row
            gutter={[24, 24]}
            className="d-flex justify-content-between align-items-center mt-4"
          >
            <Col span={12}>
              <Form.Item label="Expert: Hourly Consulting Rate" />
              <InputNumber
                addonBefore={suffixSelector}
                style={{
                  width: "100%",
                }}
                size="large"
                name="consulting_rate"
                value={addData?.consulting_rate}
                onChange={expertRate}
              />
            </Col>

            <Col span={12}>
              <Form.Item label="Client: Hourly Consulting Rate" />
              <InputNumber
                addonBefore={suffixSelectorTwo}
                style={{
                  width: "100%",
                }}
                size="large"
                name="hourly_consulting_rate"
                value={addData?.hourly_consulting_rate}
                onChange={clientRate}
              />
            </Col>
          </Row>
        </Form>
      </div>
      <div className="d-flex justify-content-center align-items-center mt-4">
        <Button
          size="default"
          className="text-center me-2 viewBttn"
          onClick={() => AddExpert("Save as Draft")}
        >
          Save as Draft
        </Button>
        <Button
          size="default"
          className="text-center viewButton"
          onClick={() => AddExpert("Submit")}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default AddNewExpertSection;
