import { Drawer, Form, Input, notification, Select, Spin } from "antd";
import React, { memo, useEffect, useMemo, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  clearAll,
  setFormValues,
  setMeetingInfo,
  setSelectedMeeting,
  setSelectedMeetingDetails,
  setTranscriptText,
} from "../Slice/internalMeeting.slice";
import moment from "moment";
import CustomEditor from "./CustomEditor";
import DebounceSelect from "./DebounceSelect";
import ReactPlayerCustom from "./ReactPlayerCustom";
import { Country } from "country-state-city";
import API_URL from "../../../../Constants/api-path";
import {
  ExclamationCircleOutlined,
} from "@ant-design/icons";
const { Option } = Select;

function InternalMeetingDrawer({ updateTranscript, loadingTranscript, loadingTranscriptDraft, capitalizeFirstLetter, isLoading, isValidating }) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const permission = useMemo(() => userData?.data?.roleData?.[0]?.role_permission, [userData?.data?.roleData])
  const { meetingInfo, selectedMeetingDetails, formvalues } = useSelector(
    (state) => state.internalmeeting
  );
  const [loading, setLoading] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const countries = Country?.getAllCountries();
  const updatedCountries = useMemo(
    () =>
      countries?.map((country, index) => {
        return (
          <Option key={country.isoCode} value={country.name}>
            {country.name}
          </Option>
        );
      }),
    [countries]
  );

  const GetCategoryList = async () => {
    setLoading(true);
    try {
      const res = await fetch(`${API_URL.ADD_EXPERT_CATEGORIES}?limit=50`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
      });

      const result = await res.json();
      if (result) {
        console.log(result?.data, "cateDataa");
        setCategoryList(result?.data);
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  };

  const handleDropdownIndustry = (open) => {
    if (open) {
      GetCategoryList();
    }
  };
  useEffect(() => {
    form.setFieldsValue(formvalues)
  }, [form, formvalues])

  return (
    <Drawer
      title={capitalizeFirstLetter(selectedMeetingDetails?.topic)}
      placement={"right"}
      width={"80%"}
      destroyOnClose
      onClose={() => {
        dispatch(setSelectedMeeting({}));
        dispatch(setSelectedMeetingDetails({}));
        dispatch(clearAll());
        form.resetFields();
        dispatch(setTranscriptText(""));
        dispatch(setMeetingInfo(false));
      }}
      open={meetingInfo}
    >
      {(isLoading || isValidating) &&
        <div className="d-flex justify-content-center align-items-center"><Spin size="large" /></div>
      }
      {(!isLoading && !isValidating) && <div>
        <Row>
          <Col md={6}>
            <h5 className="my-1" style={{ fontWeight: 500, fontSize: "14px" }}>
              Meeting title
            </h5>
            <Input
              style={{ fontSize: "14px" }}
              value={selectedMeetingDetails?.topic}
              onChange={(e) => {
                let obj = {
                  ...selectedMeetingDetails,
                  topic: e.target.value,
                };
                dispatch(setSelectedMeetingDetails(obj));
              }}
            />
          </Col>
          <Col md={6}>
            <h5 className="my-1" style={{ fontWeight: 500, fontSize: "14px" }}>
              Meeting Id
            </h5>
            <p style={{ fontSize: "14px" }}>
              {" "}
              {selectedMeetingDetails?.meetingId}
            </p>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={6}>
            <h5 className="my-1" style={{ fontWeight: 500, fontSize: "14px" }}>
              Host Email
            </h5>
            <p style={{ fontSize: "14px" }}>
              {" "}
              {selectedMeetingDetails?.host_email}
            </p>
          </Col>
          <Col md={6}>
            <h5 className="my-1" style={{ fontWeight: 500, fontSize: "14px" }}>
              Start Date & Time
            </h5>
            <p style={{ fontSize: "14px" }}>
              {moment(selectedMeetingDetails?.start_time)?.format("lll")}
            </p>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col lg={12}>
            <h5 className="my-1" style={{ fontWeight: 500, fontSize: "14px" }}>
              Keyword
            </h5>
            <Input.TextArea
              placeholder="Keyword"
              style={{ fontSize: "14px" }}
              value={selectedMeetingDetails?.keyword}
              onChange={(e) => {
                let obj = {
                  ...selectedMeetingDetails,
                  keyword: e.target.value,
                };
                dispatch(setSelectedMeetingDetails(obj));
              }}
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col lg={6}>
            <h5 className="my-1" style={{ fontWeight: 500, fontSize: "14px" }}>
              Expert Designation
            </h5>
            <Input
              placeholder="Expert Designation"
              style={{ fontSize: "14px" }}
              value={selectedMeetingDetails?.expert_designation}
              onChange={(e) => {
                let obj = {
                  ...selectedMeetingDetails,
                  expert_designation: e.target.value,
                };
                dispatch(setSelectedMeetingDetails(obj));
              }}
            />
          </Col>
          <Col lg={6}>
            <h5 className="my-1" style={{ fontWeight: 500, fontSize: "14px" }}>
              Expert Company
            </h5>
            <Input
              placeholder=" Expert Company"
              style={{ fontSize: "14px" }}
              value={selectedMeetingDetails?.expert_company}
              onChange={(e) => {
                let obj = {
                  ...selectedMeetingDetails,
                  expert_company: e.target.value,
                };
                dispatch(setSelectedMeetingDetails(obj));
              }}
            />
          </Col>
        </Row>
        {/* <Row className="mt-4">
        <Col lg={12}>
          <h5 className="my-1" style={{ fontWeight: 500, fontSize: "14px" }}>
            Brand
          </h5>

          <Input.TextArea
            placeholder="Brand"
            style={{ fontSize: "14px" }}
            value={selectedMeetingDetails?.brand}
            onChange={(e) => {
              let obj = {
                ...selectedMeetingDetails,
                brand: e.target.value,
              };
              dispatch(setSelectedMeetingDetails(obj));
            }}
          />
        </Col>
      </Row> */}
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={formvalues}
        >
          <Row className="mt-4">
            <Col>
              <Form.Item
                label="Company Select"
                name="company_select"
              >
                <DebounceSelect
                  type="company"
                  size="large"
                  mode="multiple"
                  // value={formvalues?.company_select}
                  placeholder="Select Companies"
                  onChange={(newValue) => {
                    let obj = {
                      ...selectedMeetingDetails,
                      company_select: newValue,
                    };
                    form.setFieldValue("company_select", newValue)
                    dispatch(setFormValues({ ...formvalues, company_select: newValue }))
                    dispatch(setSelectedMeetingDetails(obj));
                  }}
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                // name="country"
                label="Select Country"
              >
                <Select
                  name="country"
                  size="large"
                  allowClear
                  mode="multiple"
                  value={selectedMeetingDetails.country}
                  placeholder="Select Country"
                  autoComplete="off"
                  showSearch
                  onChange={(value) => {
                    let obj = {
                      ...selectedMeetingDetails,
                      country: value,
                    };
                    form.setFieldValue("country", value)
                    dispatch(setFormValues({ ...formvalues, country: value }))
                    dispatch(setSelectedMeetingDetails(obj));

                  }}
                >
                  {updatedCountries}
                </Select>
              </Form.Item>
            </Col>
            <Col >
              <Form.Item
                // name="industry"
                label={"Industry"}
              // rules={[
              //   {
              //     required: true,
              //     message: "Main Industry is required",
              //   },
              // ]}
              >
                <Select
                  style={{
                    width: "100%",
                  }}
                  name="industry"
                  size="large"
                  mode="tags"
                  placeholder="Type for search"
                  value={selectedMeetingDetails.industry}
                  onDropdownVisibleChange={handleDropdownIndustry}
                  loading={loading}
                  onChange={(value) => {
                    console.log('value: ', value);
                    let obj = {
                      ...selectedMeetingDetails,
                      industry: value,
                    };
                    form.setFieldValue("industry", value)
                    dispatch(setFormValues({ ...formvalues, industry: value }))
                    dispatch(setSelectedMeetingDetails(obj));

                  }}
                  filterOption={(input, option) =>
                    option?.children
                      ?.toLowerCase()
                      .indexOf(input?.toLowerCase()) >= 0
                  }
                >
                  {categoryList?.map((option) => (
                    <Option key={option?.key} value={option?.label}>
                      {option?.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Row className="mt-4 border bg-white">
          <ReactPlayerCustom resData={selectedMeetingDetails} />
        </Row>
        <Row className="mt-4">
          <Col lg={12}>
            <h5 className="my-2" style={{ fontWeight: 500, fontSize: "14px" }}>
              Transcript
            </h5>
          </Col>

          <Col lg={12} className="projectEditor">
            <CustomEditor />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col className="text-end">
            <button
              className="viewButton btn ms-2"
              onClick={() => {
                updateTranscript(0);
                form.resetFields();
              }}
            >
              {loadingTranscriptDraft && (
                <Spinner
                  size="sm"
                  style={{
                    width: "1rem",
                    height: "1rem",
                    marginRight: "5px",
                  }}
                />
              )}
              Save as Draft{" "}
            </button>

            {permission?.[13]?.internalMeeting?.publish && <button
              className="viewButton btn ms-2"
              onClick={() => {
                updateTranscript(1);
                form.resetFields();
              }}
            >
              {loadingTranscript && (
                <Spinner
                  size="sm"
                  style={{
                    width: "1rem",
                    height: "1rem",
                    marginRight: "5px",
                  }}
                />
              )}
              Publish
            </button>}
          </Col>
        </Row>
      </div>}
    </Drawer >
  );
}

export default memo(InternalMeetingDrawer);
