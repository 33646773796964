import { UserOutlined, CheckCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Alert, Button, Form, Input, message, notification } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Spinner } from "reactstrap";
import API_PATH from "../../Constants/api-path";

function ForgotPasswordForm() {
  const [form] = Form.useForm();
  const [getEmail, setEmail] = useState({
    email: "",
  });
  const [spinner, setSpinner] = useState(false);
  const inputRef = useRef(null);
  const [error, setError] = useState("");
  const [, forceUpdate] = useState({});

  const emailRules = [
    {
      type: 'email',
      message: 'Please enter a valid email address!',
    },
    {
      required: true,
      message: 'Email is required!',
    },
  ];

  // To disable submit button at the beginning.
  useEffect(() => {
    forceUpdate({});
  }, []);

  const handleInputs = (e) => {
    const { name, value } = e.target;
    const list = { ...getEmail, [name]: value };
    // checkEmail(list.email);
    setEmail(list);
    setError("");
  };

  const sendOtpMail = async (e) => {
    e.preventDefault();
    try {
      let { email } = getEmail;
      email = email.toLowerCase();
      setSpinner(true);
      const newProtocol = window.location.protocol;
      const newHost = window.location.host;
      const host = `${newProtocol}//${newHost}`;

      const res = await fetch(`${API_PATH.ADMINFORGOTPASSWORD}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email: email }),
      });

      const data = await res.json();
      if (data) {
        setSpinner(true);
        if (data.status === 200 || data.status === true) {
          inputRef.current.value = "";
          setEmail("");
          notification.success({
            message: "An email has been sent to registered email ID",
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#52c41a",
              color: "#fff",
              height: "40%",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          });
        } else {
          inputRef.current.value = "";
          setEmail("");
          setSpinner(false);
          setError(data?.message);
        }
      } else {
        // console.log("fdddddddddddddddddddddfdf");
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
        setSpinner(false);
      }
    } catch (error) {
      console.log(error);
      setSpinner(false);
      setError(error?.response?.data?.message);
    }
  };

  const something = (event) => {
    if (event.keyCode === 13) {
      sendOtpMail(event);
    }
  };

  return (
    <>
      {error ? <Alert message={error} type="error" /> : null}
      <Form form={form} layout="vertical" autoComplete="off">
        <Form.Item
          name={["user", "email"]}
          label="Please enter your registered email"
          rules={emailRules}
        >
          <Input
            ref={inputRef}
            prefix={<UserOutlined className="site-form-item-icon" />}
            type="text"
            name="email"
            autoComplete="none"
            placeholder="Enter Your E-mail Address"
            onChange={handleInputs}
            onKeyDown={(e) => something(e)}
            size="large"
            value={getEmail}
          />
        </Form.Item>

        <div className="form-group">
          <Form.Item shouldUpdate>
            {() => (
              <Button
                onClick={sendOtpMail}
                className="btn login-btn"
                type="primary"
                htmlType="submit"
                disabled={
                  !form.isFieldsTouched(true) ||
                  !!form.getFieldsError().filter(({ errors }) => errors.length)
                    .length
                }
              >
                {spinner ? (
                  <>
                    <Spinner
                      size="sm"
                      style={{
                        width: "1rem",
                        height: "1rem",
                        marginRight: "5px",
                      }}
                    />
                    Reset Password
                  </>
                ) : (
                  "Reset Password"
                )}
              </Button>
            )}
          </Form.Item>
        </div>
      </Form>

      <div className="form-group">
        <p className="text-center">
          <NavLink to="/admin-login" className="register-btn px-2">
            Login
          </NavLink>
        </p>
      </div>
    </>
  );
}
export default ForgotPasswordForm;
