import { authReducer } from "../Utils/auth.action";
import { creditManagementReducer } from "../pages/CloudFeatures/CMS/Slice/creditManagement.slice";
import { internalMeetingReducer } from "../pages/CloudFeatures/InternalMeetings/Slice/internalMeeting.slice";
// import { creditManagementReducer } from "../pages/CloudFeatures/CMS/Slice/creditManagement.slice";
// import { internalMeetingReducer } from "../pages/CloudFeatures/InternalMeetings/Slice/internalMeeting.slice";

import { qcexpertReducer } from "../pages/QCExperts/feature/QCExpert.slice";

//there are the reducers we created in whole application
const reducers = {
  internalmeeting: internalMeetingReducer,
  creditManagement: creditManagementReducer,
  qcMeeting: qcexpertReducer,
  auth: authReducer
  // internalmeeting: internalMeetingReducer,
  // creditManagement: creditManagementReducer
};
export default reducers;
