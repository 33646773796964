import { DatePicker, Form, Select, Spin, TreeSelect } from "antd";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import API_PATH from "../../Constants/api-path";
import "./CallRecords.css";

const { Option } = Select;
const { TreeNode } = TreeSelect;

const { RangePicker } = DatePicker;

const SelectionOption = ({ selectedOptions, setSelectedOptions }) => {
  console.log(selectedOptions, "selectedOptions");

  const userData = JSON.parse(localStorage.getItem("userData"));
  let history = useNavigate();
  // let queryParams = queryString.parse(window.location.search);

  const [loading, setLoading] = useState(false);
  const [allProject, setAllProject] = useState([]);
  const [allCompany, setAllCompany] = useState([]);
  const [allExpert, setAllExpert] = useState([]);
  const [allClient, setAllClient] = useState([]);
  const [allInvoice, setAllInvoice] = useState([]);
  const [allSdate, setAllSdate] = useState([]);
  const [allEdate, setAllEdate] = useState([]);
  const selectRefs = {
    project: useRef(null),
    company: useRef(null),
    expert: useRef(null),
    client: useRef(null),
    invoice: useRef(null),
  };

  const getExpertsOptions = (selectedOptions) => {
    setLoading(true);
    axios
      .get(API_PATH.CALL_OPTION, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
        params: selectedOptions,
      })
      .then((res) => {
        console.log(res, "resLenght");
        setAllProject(res?.data?.project);
        setAllCompany(res?.data?.company);
        setAllExpert(res?.data?.expert);
        setAllClient(res?.data?.client);
        setAllInvoice(res?.data?.invoice);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
    setLoading(false);
  };

  const constructUrl = (selectedOptions) => {
    const { project, company, expert, client, invoice, sdate, edate } =
      selectedOptions || {};
  
    const projectParam = Array.isArray(project) ? project.join(', ') : project;
    const companyParam = Array.isArray(company) ? company.join(', ') : company;
    const expertParam = Array.isArray(expert) ? expert.join(', ') : expert;
    const clientParam = Array.isArray(client) ? client.join(', ') : client;
    const invoiceParam = Array.isArray(invoice) ? invoice.join(', ') : invoice;
  
    const sanitizedProject = projectParam ? projectParam.replace(/%20/g, ' ') : '';
    const sanitizedCompany = companyParam
      ? decodeURIComponent(companyParam)
      : '';
    const sanitizedExpert = expertParam ? decodeURIComponent(expertParam) : '';
    const sanitizedClient = clientParam ? decodeURIComponent(clientParam) : '';
    const sanitizedInvoice = invoiceParam
      ? decodeURIComponent(invoiceParam)
      : '';
  
    const urlParams = [
      `project="${sanitizedProject}"`,
      `company="${sanitizedCompany}"`,
      `expert="${sanitizedExpert}"`,
      `client="${sanitizedClient}"`,
      `invoice="${sanitizedInvoice}"`,
      `sdate=${sdate}`,
      `edate=${edate}`,
    ];
  
    return history({
      pathname: `/dashboard/call_records`,
      search: `?${urlParams.join('&')}`,
    });
  };
  

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    getExpertsOptions();
    if (selectedOptions) {
      const url = constructUrl(selectedOptions);
      console.log(url); // Output the constructed URL
    }
  }, [selectedOptions]);

  // useEffect(() => {
  //   const handleOutsideClick = (event) => {
  //     if (
  //       dropdownRef.current &&
  //       !dropdownRef.current.contains(event.target)
  //     ) {
  //       setSelectedOptions([]);
  //     }
  //   };

  //   document.addEventListener('click', handleOutsideClick);

  //   return () => {
  //     document.removeEventListener('click', handleOutsideClick);
  //   };
  // }, [dropdownRef]);

  // const Replacepagechange = (
  //   project,
  //   company,
  //   expert,
  //   client,
  //   invoice,
  //   sdate,
  //   edate
  // ) => {
  //   history({
  //     pathname: `/dashboard/call_records`,
  //     search: `?project=${encodeURIComponent(project)}&company=${encodeURIComponent(company)}&expert=${encodeURIComponent(expert)}&client=${encodeURIComponent(client)}&invoice=${encodeURIComponent(invoice)}&sdate=${encodeURIComponent(sdate)}&edate=${encodeURIComponent(edate)}`,
  //   });
  // };

  // const baseURL = "https://platform.nextyn.com/dashboard/call-records";
  // const queryParams = [];

  // if (invoice) queryParams.push(`invoice=${encodeURIComponent(invoice)}`);
  // if (client) queryParams.push(`client=${encodeURIComponent(client)}`);
  // if (expert) queryParams.push(`expert=${encodeURIComponent(expert)}`);
  // if (company) queryParams.push(`company=${encodeURIComponent(company)}`);
  // if (sdate) queryParams.push(`start=${encodeURIComponent(sdate)}`);
  // if (edate) queryParams.push(`end=${encodeURIComponent(edate)}`);

  // const url = `${baseURL}?${queryParams.join("&")}`;

  const onChange = (value, optionname) => {
    setSelectedOptions((prevState) => {
      if (prevState[optionname] !== value) {
        let updatedValue = value;
        if (Array.isArray(value)) {
          updatedValue = value.join(", ");
        }

        return {
          ...prevState,
          [optionname]: updatedValue,
        };
      }
      return prevState;
    });
  };

  const onDate = (dates, dateStrings) => {
    const [startDate, endDate] = dateStrings;

    // const formattedStartDate = moment(startDate).format('MMMM DD, YYYY');
    // const formattedEndDate = moment(endDate).format('MMMM DD, YYYY');

    setSelectedOptions((prevState) => ({
      ...prevState,
      sdate: startDate,
      edate: endDate,
    }));
  };

  const onSearch = (value) => {
    console.log("search:", value);
  };

  // const handleSidebarClick = (optionName) => {
  //   setSelectValues(prevState => ({
  //     ...prevState,
  //     [optionName]: '',
  //   }));
  // };

  // const handleOutsideClick = (event) => {
  //   Object.keys(selectRefs).forEach(optionName => {
  //     if (selectRefs[optionName].current && !selectRefs[optionName].current.contains(event.target)) {
  //       handleSidebarClick(optionName);
  //     }
  //   });
  // };

  // const onSearch = (value) => {
  //   const filteredOptions = allProject.filter((option) =>
  //     option?.category_name.toLowerCase().includes(value.toLowerCase())
  //   );
  //   setProjectOptions(filteredOptions);
  // };

  return (
    <div>
      <div className="row gx-2 gy-2">
        {/* <a href={url} target="_blank" rel="noopener noreferrer">{url}</a> */}

        <div className="col-12 col-md-4">
          {loading ? (
            <Spin />
          ) : (
            <Form>
              <Form.Item name="project" className="mb-0">
                <Select
                  showSearch
                  placeholder="Select a project"
                  className="input-select"
                  size="large"
                  optionFilterProp="children"
                  mode="multiple" // Add mode prop with value "multiple" to enable multiple selections
                  onChange={(value) => onChange(value, "project")}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option?.props?.children
                      ?.toLowerCase()
                      .indexOf(input?.toLowerCase()) !== -1
                  }
                  value={
                    selectedOptions?.project
                      ? selectedOptions.project.split(", ")
                      : []
                  }
                  ref={selectRefs.project}
                >
                  {allProject?.map((option) => (
                    <Option key={option?._id} value={option?._id}>
                      {option?._id}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>
          )}
        </div>

        <div className="col-12 col-md-4">
          {loading ? (
            <Spin />
          ) : (
            <Form>
              <Form.Item name="company" className="mb-0">
                <Select
                  showSearch
                  placeholder="Select a company"
                  size="large"
                  className="input-select"
                  optionFilterProp="children"
                  onChange={(value) => onChange(value, "company")}
                  onSearch={onSearch}
                  mode="multiple" // Add mode prop with value "multiple" to enable multiple selections
                  // onBlur={handleSidebarClickTwo} // Add the onBlur event handler
                  filterOption={(input, option) =>
                    option?.props?.children
                      ?.toLowerCase()
                      .indexOf(input?.toLowerCase()) !== -1
                  }
                  value={
                    selectedOptions?.company
                      ? selectedOptions?.company?.split(", ")
                      : []
                  }
                >
                  {allCompany?.map((option) => (
                    <Option key={option?._id} value={option?._id}>
                      {option?._id}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>
          )}
        </div>

        <div className="col-12 col-md-4">
          {loading ? (
            <Spin />
          ) : (
            <Form>
              <Form.Item name="expert" className="mb-0">
                <Select
                  showSearch
                  placeholder="Select a expert"
                  size="large"
                  className="input-select"
                  optionFilterProp="children"
                  onChange={(value) => onChange(value, "expert")}
                  mode="multiple" // Add mode prop with value "multiple" to enable multiple selections
                  // onBlur={handleSidebarClick} // Add the onBlur event handler
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option?.props?.children
                      ?.toLowerCase()
                      .indexOf(input?.toLowerCase()) !== -1
                  }
                  value={
                    selectedOptions?.expert
                      ? selectedOptions?.expert?.split(", ")
                      : []
                  }
                >
                  {allExpert?.map((option) => (
                    <Option key={option?._id} value={option?._id}>
                      {option?._id}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>
          )}
        </div>

        <div className="col-12 col-md-4">
          {loading ? (
            <Spin />
          ) : (
            <Form>
              <Form.Item name="client" className="mb-0">
                <Select
                  showSearch
                  placeholder="Select a client"
                  size="large"
                  className="input-select"
                  optionFilterProp="children"
                  onChange={(value) => onChange(value, "client")}
                  onSearch={onSearch}
                  mode="multiple" // Add mode prop with value "multiple" to enable multiple selections
                  // onBlur={handleSidebarClick} // Add the onBlur event handler
                  filterOption={(input, option) =>
                    option?.props?.children
                      ?.toLowerCase()
                      .indexOf(input?.toLowerCase()) !== -1
                  }
                  value={
                    selectedOptions?.client
                      ? selectedOptions?.client?.split(", ")
                      : []
                  }
                >
                  {allClient?.map((option) => (
                    <Option key={option?._id} value={option?._id}>
                      {option?._id}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>
          )}
        </div>

        <div className="col-12 col-md-4">
          {loading ? (
            <Spin />
          ) : (
            <Form>
              <Form.Item name="invoice" className="mb-0">
                <Select
                  showSearch
                  placeholder="Select a invoice"
                  size="large"
                  className="input-select"
                  optionFilterProp="children"
                  onChange={(value) => onChange(value, "invoice")}
                  mode="multiple" // Add mode prop with value "multiple" to enable multiple selections
                  onSearch={onSearch}
                  // onBlur={handleSidebarClick} // Add the onBlur event handler
                  filterOption={(input, option) =>
                    option?.props?.children
                      ?.toLowerCase()
                      .indexOf(input?.toLowerCase()) !== -1
                  }
                  value={
                    selectedOptions?.invoice
                      ? selectedOptions?.invoice?.split(", ")
                      : []
                  }
                >
                  {allInvoice?.map((option) => (
                    <Option key={option?.id} value={option?.id}>
                      {option?.id}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>
          )}
        </div>

        <div className="col-12 col-md-4">
          {/* <Space direction="vertical" size="large"> */}
          {loading ? (
            <Spin />
          ) : (
            <Form>
              <Form.Item name="date" className="mb-0">
                <RangePicker
                  size="large"
                  format="YYYY-MM-DD"
                  onChange={onDate}
                  style={{width:'100%'}}
                  // onBlur={handleSidebarClick} // Add the onBlur event handler
                />
              </Form.Item>
            </Form>
          )}
          {/* </Space> */}
        </div>
      </div>
    </div>
  );
};

export default SelectionOption;
