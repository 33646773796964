import { Button, Drawer, Input, Pagination, Table, TimePicker, Tooltip, message } from 'antd'
import React, { useMemo } from 'react'
import { setOpen, setOpenConfirm, setSelectedRowTimeAndRefund, setSelectedTransaction, setTransactionHistoryList, setTransactionsDrawer } from '../Slice/creditManagement.slice'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import moment from 'moment'
import _ from 'lodash';
import useCreditManagement from '../hooks/useCreditManagement'
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import axios from 'axios'
import API_PATH from "../../../../Constants/api-path";
import UpdateCreditsModal from '../../../../components/UpdateCreditsModal'
import ConfirmationModal from '../../../../components/ConfirmationModal'

dayjs.extend(customParseFormat);

function TransactionDrawer() {
    const { transactionsDrawer, transactionHistoryList, selectedCompany, transactionHistoryPagination, selectedRowTimeAndRefund, openConfirm, selectedTransaction } = useSelector((state) => state.creditManagement)
    const { getTransactionHistoryById, fetchCompanies } = useCreditManagement()
    const dispatch = useDispatch()
    const userData = JSON.parse(localStorage.getItem("userData"))
    const MODULE_CONSTANTS = [
        { id: 1, name: "News" },
        { id: 2, name: "Transcript" },
        { id: 3, name: "Expert" },
        { id: 4, name: "Premium Expert" },
        { id: 5, name: "Unlock company" }
    ]
    const convertToTitleCase = (inputString = "") => {
        return inputString.toLowerCase().replace(/\b\w/g, function (match) {
            return match.toUpperCase();
        });
    };
    const onChange = (time, timeString, data) => {
        dispatch(setSelectedRowTimeAndRefund([...selectedRowTimeAndRefund,
        {
            _id: data?._id,
            time: timeString,
            dayJsTime: time,
            refund: ""
        }]))
    };

    const handleRefund = async (data) => {
        try {
            const response = await axios
                .post(
                    `${API_PATH.HANDLE_REFUND_CREDITS}`,
                    {
                        transaction_id: data?._id,
                        refund_by: userData?.data?._id,
                        credit_refunded: data?.credits_spend
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${userData?.token}`,
                        }
                    }
                )
            if (response.status) {
                getTransactionHistoryById({ is_paginating: true, page_limit: 10, page_no: 1 })
                dispatch(setOpenConfirm(false))
                message.success("Credits refunded successfully!")
            }

        } catch (error) {
            dispatch(setOpenConfirm(false))
            console.log('error: ', error);

        }

    }
    const transactionSource = transactionHistoryList?.data?.map((data, index) => {
        return ({
            key: data?._id,
            user: data?.user_details?.name,
            usedFor: MODULE_CONSTANTS.find((element) => element.id === data?.credits_used_for)?.name,
            credit: MODULE_CONSTANTS.find((element) => element.id === data?.credits_used_for)?.name === "Expert" ?
                <span onClick={() => {
                    dispatch(setSelectedTransaction(data))
                    dispatch(setOpen(true))
                }}> <Input value={data?.credits_spend}
                    readOnly
                    onChange={(e) => {
                        let arra = {
                            ...transactionHistoryList,
                            data: transactionHistoryList?.data.map((item, idx) => {
                                if (idx === index) {
                                    return {
                                        ...item,
                                        credits_spend: e.target.value
                                    };
                                } else {
                                    return item;
                                }
                            })
                        }
                        dispatch(setTransactionHistoryList(arra))
                    }}
                    />

                </span> : data?.credits_spend,
            dateTime: moment(data?.created_at).format("lll"),
            time: <span> <TimePicker showSecond={false} onChange={(time, timeString) => onChange(time, timeString, data)} value={dayjs(selectedRowTimeAndRefund?.filter((val) => val?._id === data?._id)?.[0]?.time || "00:00:00", 'HH:mm')} /></span>,
            refund: <Button onClick={() => {
                dispatch(setOpenConfirm(true))

            }}>Refund</Button>,
            topic:
                <Tooltip title={data?.transcriptTopic?.topic || data?.newsTitle?.title ||
                    `${data?.credits_used_for === 5 ? convertToTitleCase(data?.cloud_companies?.name) : data?.company_details?.companyName ?? "NA"}
              ${data?.designation?.designation?.split("+")?.[0] ? ", " + data?.designation?.designation?.split("+")?.[0] : data?.credits_used_for === 5 ? "" : "NA"}`}>
                    {_.truncate(
                        data?.transcriptTopic?.topic || data?.newsTitle?.title ||
                        `${data?.credits_used_for === 5 ? convertToTitleCase(data?.cloud_companies?.name) : data?.company_details?.companyName ?? "NA"} ${data?.designation?.designation?.split("+")?.[0] ? ", " + data?.designation?.designation?.split("+")?.[0] : data?.credits_used_for === 5 ? "" : "NA"}` || "", {
                        'length': 100,
                        'omission': '...'
                    }
                    )}</Tooltip>
        })

    });
    const transactionColumn = [
        {
            title: "User",
            dataIndex: "user",
            key: "user",
            width: "10%"
        },
        {
            title: "Used for",
            dataIndex: "usedFor",
            key: "usedFor",
            width: "10%"
        },
        {
            title: "Topic/Designation",
            dataIndex: "topic",
            key: "topic",
            width: "30%"
        },
        // {
        //     title: "selectTime",
        //     dataIndex: "time",
        //     key: "time",
        //     width: "25%"
        // },
        {
            title: "Credit",
            dataIndex: "credit",
            key: "credit",
            width: "10%"
        },
        {
            title: "Refund",
            dataIndex: "refund",
            key: "refund",
            width: "5%"
        },
        {
            title: "Date & Time",
            dataIndex: "dateTime",
            key: "dateTime",
            width: "10%"
        },
    ];

    const paginationSettings = useMemo(() => {
        return {
            current: transactionHistoryPagination?.page,
            onChange: (index) => getTransactionHistoryById({ is_paginating: true, page_no: index }),
            pageSize: transactionHistoryPagination?.limit,
            total: transactionHistoryList?.count,
        };
    }, [transactionHistoryPagination.limit, transactionHistoryPagination.page, transactionHistoryList?.count, getTransactionHistoryById]);

    return (
        <Drawer
            title={selectedCompany?.company_details?.companyName}
            placement={"right"}
            width={"80%"}
            onClose={() => {
                fetchCompanies({ is_refetching: true })
                dispatch(setTransactionsDrawer(false))
            }}
            open={transactionsDrawer}
        >
            <Table
                dataSource={transactionSource}
                columns={transactionColumn}
                pagination={false}
                scroll={{ x: 800 }}
                size="large"
            />
            <Pagination
                {...paginationSettings}
                showSizeChanger={false}
                showTotal={(callPage, range) =>
                    `${range[0]}-${range[1]} of ${callPage} items`
                }
                style={{ marginTop: 16, marginBottom: 16, textAlign: "right" }}
            />

            <UpdateCreditsModal getTransactionHistoryById={getTransactionHistoryById} />
            <ConfirmationModal open={openConfirm} setOpen={() => {
                dispatch(setOpenConfirm(!openConfirm))
            }}
                okFunction={() => {
                    handleRefund(selectedTransaction)
                }}
                cancelFunction={() => {
                    dispatch(setOpenConfirm(false))
                }}
                message={"Are you sure?"} title={"Refund"} />
        </Drawer>
    )
}

export default TransactionDrawer