import React, { useMemo } from 'react'
import {
    Drawer,
    Table, Dropdown, Space, Pagination
} from "antd";
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { setCreditHistory, setPaymentHistoryList, setPaymentHistoryPagination } from '../Slice/creditManagement.slice';
import { MdDehaze } from 'react-icons/md';
import moment from 'moment';
import CurrencyFormat from 'react-currency-format';
import useCreditManagement from '../hooks/useCreditManagement';
function PaymentHistoryDrawer() {
    const dispatch = useDispatch()
    const { creditHistory, paymentHistoryList, paymentHistoryPagination, selectedCompany } = useSelector((state) => state.creditManagement)
    const { getPaymentHistoryById } = useCreditManagement()
    const items = [
        // {
        //     label: (
        //         <div href="#" onClick={() => dispatch(setAddCreditModal(true))}>
        //             <Space>
        //                 <MdBorderColor />
        //                 Edit
        //             </Space>
        //         </div>
        //     ),
        //     key: "0",
        // },
        // {
        //     label: (
        //         <div href="#" onClick={() => dispatch(setDeleteModal(true))}>
        //             <Space>
        //                 <MdDelete />
        //                 Delete
        //             </Space>
        //         </div>
        //     ),
        //     key: "3",
        // },
    ];
    const paymentSource = paymentHistoryList?.data?.map((data) => ({
        amount: <CurrencyFormat
            disabled={true}
            displayType="text"
            thousandSeparator
            prefix="$"
            value={data?.amount || 0}
            decimalScale={2}
            fixedDecimalScale
            placeholder="Enter Amount"
            className={`text-gray-700`}
        />,
        credit: data?.credits,
        date: data?.payment_date ? moment(data?.payment_date)?.format("ll") : "NA",
        referenceId: data?.payment_refrence_id ? data?.payment_refrence_id : "NA",
        validity: data?.credit_validity !== "lifetime" ? moment(data?.credit_validity)?.format("ll") : "Lifetime",
        addedBy: data?.user_details?.name,
        action: data,
    }))

    const paymentColumn = [
        {
            title: "Amount",
            dataIndex: "amount",
            key: "amount",
        },
        {
            title: "Credits",
            dataIndex: "credit",
            key: "credit",
        },
        {
            title: "Payment Date",
            dataIndex: "date",
            key: "date",
        },
        {
            title: "Reference Id",
            dataIndex: "referenceId",
            key: "referenceId",
        },
        {
            title: "Validity",
            dataIndex: "validity",
            key: "validity",
        },
        {
            title: "Added By",
            dataIndex: "addedBy",
            key: "addedBy",
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (data) => (
                <Dropdown
                    menu={{
                        items,
                    }}
                    trigger={["click"]}
                >
                    <span onClick={(e) => {
                        e.preventDefault()
                    }}>
                        <Space size="middle">
                            <MdDehaze style={{ cursor: "pointer" }} />
                        </Space>
                    </span>
                </Dropdown>
            ),
        },
    ];
    const paginationSettings = useMemo(() => {
        return {
            current: paymentHistoryPagination.page,
            onChange: (index) => getPaymentHistoryById({ is_paginating: true, page_no: index }),
            pageSize: paymentHistoryPagination.limit,
            total: paymentHistoryList?.count,
        };
    }, [getPaymentHistoryById, paymentHistoryList?.count, paymentHistoryPagination.limit, paymentHistoryPagination.page]);
    return (
        <Drawer
            title={`Payment History - ${selectedCompany?.company_details?.companyName || ""}`}
            placement={"right"}
            width={"80%"}
            onClose={() => {
                dispatch(setCreditHistory(false))
                dispatch(setPaymentHistoryPagination({ page: 1, limit: 10 }))
                dispatch(setPaymentHistoryList({ data: [], count: 0 }))
            }}
            open={creditHistory}
        >
            <Table
                dataSource={paymentSource}
                columns={paymentColumn}
                pagination={false}
                scroll={{ x: 800 }}
                size="large"
            />
            <Pagination
                {...paginationSettings}
                showSizeChanger={false}
                showTotal={(callPage, range) =>
                    `${range[0]}-${range[1]} of ${callPage} items`
                }
                style={{ marginTop: 16, marginBottom: 16, textAlign: "right" }}
            />
        </Drawer>
    )
}

export default PaymentHistoryDrawer