import {
  Form,
  Input,
  Select,
  Spin,
  Tag,
  Tooltip,
  notification,
} from "antd";

import axios from "axios";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import {
  createSearchParams,
  Link,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { toast } from "react-toastify";
import company_icon3 from "../../assests/images/company_icons3.png";
// import StarredButton from "../components/StarredButton";
import API_PATH from "../../Constants/api-path";
// import MobileMenu from "../MobileMenu";
import Sidebar from "../../components/Sidebar";
import { BsChevronLeft } from "../../Utils/Icons";
import "./ExpertProfile.css";
import user_icon from "../../assests/images/user.png";
import { Col, Modal, Row } from "react-bootstrap";
import { MdModeEditOutline, MdDone, MdClear } from "react-icons/md";
// import { Form, Input, Select, Space, Tag, message, Tooltip, Spin } from "antd";
import PhoneInput from "react-phone-input-2";
import ExperienceModal from "./ExperienceModal";
import EducationModal from "./EducationModal";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined
} from "@ant-design/icons";

const { Option } = Select;

const text = <span>Industry</span>;
const text2 = <span>Tags</span>;
const text3 = <span>Email</span>;
const text4 = <span>Contact</span>;

const customColors = ["#5746ec"];
var ExpertId = "";

const countryOptions = [
  { code: "+1", name: "United States" },
  { code: "+44", name: "United Kingdom" },
  { code: "+61", name: "Australia" },
  { code: "+33", name: "France" },
  { code: "+49", name: "Germany" },
  { code: "+81", name: "Japan" },
  { code: "+91", name: "India" },
  { code: "+86", name: "China" },
  { code: "+55", name: "Brazil" },
  { code: "+52", name: "Mexico" },
  { code: "+39", name: "Italy" },
  { code: "+7", name: "Russia" },
  { code: "+82", name: "South Korea" },
  { code: "+351", name: "Portugal" },
  { code: "+34", name: "Spain" },
  { code: "+54", name: "Argentina" },
  { code: "+63", name: "Philippines" },
  { code: "+234", name: "Nigeria" },
  { code: "+62", name: "Indonesia" },
  { code: "+55", name: "Anguilla" },
  { code: "+1264", name: "Antigua and Barbuda" },
  { code: "+54", name: "Argentina" },
  { code: "+374", name: "Armenia" },
  { code: "+297", name: "Aruba" },
  { code: "+61", name: "Australia" },
  { code: "+43", name: "Austria" },
  { code: "+994", name: "Azerbaijan" },
  { code: "+1242", name: "Bahamas" },
  { code: "+973", name: "Bahrain" },
  { code: "+880", name: "Bangladesh" },
  { code: "+1246", name: "Barbados" },
  { code: "+375", name: "Belarus" },
  { code: "+32", name: "Belgium" },
  { code: "+501", name: "Belize" },
  { code: "+229", name: "Benin" },
  { code: "+1441", name: "Bermuda" },
  { code: "+975", name: "Bhutan" },
  { code: "+591", name: "Bolivia" },
  { code: "+387", name: "Bosnia and Herzegovina" },
  { code: "+267", name: "Botswana" },
  { code: "+55", name: "Brazil" },
  { code: "+246", name: "British Indian Ocean Territory" },
  { code: "+1284", name: "British Virgin Islands" },
  { code: "+673", name: "Brunei" },
  { code: "+359", name: "Bulgaria" },
  { code: "+226", name: "Burkina Faso" },
  { code: "+257", name: "Burundi" },
  { code: "+855", name: "Cambodia" },
  { code: "+237", name: "Cameroon" },
  { code: "+1", name: "Canada" },
  { code: "+238", name: "Cape Verde" },
  { code: "+599", name: "Caribbean Netherlands" },
  { code: "+1345", name: "Cayman Islands" },
  { code: "+236", name: "Central African Republic" },
  { code: "+235", name: "Chad" },
  { code: "+56", name: "Chile" },
  { code: "+86", name: "China" },
  { code: "+57", name: "Colombia" },
  { code: "+269", name: "Comoros" },
  { code: "+242", name: "Congo - Brazzaville" },
  { code: "+243", name: "Congo - Kinshasa" },
  { code: "+682", name: "Cook Islands" },
  { code: "+506", name: "Costa Rica" },
  { code: "+225", name: "Côte d’Ivoire" },
  { code: "+385", name: "Croatia" },
  { code: "+53", name: "Cuba" },
  { code: "+599", name: "Curaçao" },
  { code: "+357", name: "Cyprus" },
  { code: "+420", name: "Czech Republic" },
  { code: "+45", name: "Denmark" },
  { code: "+253", name: "Djibouti" },
  { code: "+1767", name: "Dominica" },
  { code: "+1809", name: "Dominican Republic" },
  { code: "+670", name: "East Timor" },
  { code: "+593", name: "Ecuador" },
  { code: "+20", name: "Egypt" },
  { code: "+503", name: "El Salvador" },
  { code: "+240", name: "Equatorial Guinea" },
  { code: "+291", name: "Eritrea" },
  { code: "+372", name: "Estonia" },
  { code: "+251", name: "Ethiopia" },
  { code: "+500", name: "Falkland Islands" },
  { code: "+298", name: "Faroe Islands" },
  { code: "+679", name: "Fiji" },
  { code: "+358", name: "Finland" },
  { code: "+33", name: "France" },
  { code: "+594", name: "French Guiana" },
  { code: "+689", name: "French Polynesia" },
  { code: "+241", name: "Gabon" },
  { code: "+220", name: "Gambia" },
  { code: "+995", name: "Georgia" },
  { code: "+49", name: "Germany" },
  { code: "+233", name: "Ghana" },
  { code: "+350", name: "Gibraltar" },
  { code: "+30", name: "Greece" },
  { code: "+299", name: "Greenland" },
  { code: "+1473", name: "Grenada" },
  { code: "+590", name: "Guadeloupe" },
  { code: "+1671", name: "Guam" },
  { code: "+502", name: "Guatemala" },
  { code: "+44", name: "Guernsey" },
  { code: "+224", name: "Guinea" },
  { code: "+245", name: "Guinea-Bissau" },
  { code: "+592", name: "Guyana" },
  { code: "+509", name: "Haiti" },
  { code: "+504", name: "Honduras" },
  { code: "+852", name: "Hong Kong SAR China" },
  { code: "+36", name: "Hungary" },
  { code: "+354", name: "Iceland" },
  { code: "+91", name: "India" },
  { code: "+62", name: "Indonesia" },
  { code: "+98", name: "Iran" },
  { code: "+964", name: "Iraq" },
  { code: "+353", name: "Ireland" },
  { code: "+44", name: "Isle of Man" },
  { code: "+972", name: "Israel" },
  { code: "+39", name: "Italy" },
  { code: "+1876", name: "Jamaica" },
  { code: "+81", name: "Japan" },
  { code: "+44", name: "Jersey" },
  { code: "+962", name: "Jordan" },
  { code: "+7", name: "Kazakhstan" },
  { code: "+254", name: "Kenya" },
  { code: "+686", name: "Kiribati" },
  { code: "+383", name: "Kosovo" },
  { code: "+965", name: "Kuwait" },
  { code: "+996", name: "Kyrgyzstan" },
  { code: "+856", name: "Laos" },
  { code: "+371", name: "Latvia" },
  { code: "+961", name: "Lebanon" },
  { code: "+266", name: "Lesotho" },
  { code: "+231", name: "Liberia" },
  { code: "+218", name: "Libya" },
  { code: "+423", name: "Liechtenstein" },
  { code: "+370", name: "Lithuania" },
  { code: "+352", name: "Luxembourg" },
  { code: "+853", name: "Macau SAR China" },
  { code: "+389", name: "Macedonia" },
  { code: "+261", name: "Madagascar" },
  { code: "+265", name: "Malawi" },
  { code: "+60", name: "Malaysia" },
  { code: "+960", name: "Maldives" },
  { code: "+223", name: "Mali" },
  { code: "+356", name: "Malta" },
  { code: "+692", name: "Marshall Islands" },
  { code: "+596", name: "Martinique" },
  { code: "+222", name: "Mauritania" },
  { code: "+230", name: "Mauritius" },
  { code: "+262", name: "Mayotte" },
  { code: "+52", name: "Mexico" },
  { code: "+691", name: "Micronesia" },
  { code: "+373", name: "Moldova" },
  { code: "+377", name: "Monaco" },
  { code: "+976", name: "Mongolia" },
  { code: "+382", name: "Montenegro" },
  { code: "+1664", name: "Montserrat" },
  { code: "+212", name: "Morocco" },
  { code: "+258", name: "Mozambique" },
  { code: "+95", name: "Myanmar (Burma)" },
  { code: "+264", name: "Namibia" },
  { code: "+674", name: "Nauru" },
  { code: "+977", name: "Nepal" },
  { code: "+31", name: "Netherlands" },
  { code: "+687", name: "New Caledonia" },
  { code: "+64", name: "New Zealand" },
  { code: "+505", name: "Nicaragua" },
  { code: "+227", name: "Niger" },
  { code: "+234", name: "Nigeria" },
  { code: "+683", name: "Niue" },
  { code: "+672", name: "Norfolk Island" },
  { code: "+850", name: "North Korea" },
  { code: "+1670", name: "Northern Mariana Islands" },
  { code: "+47", name: "Norway" },
  { code: "+968", name: "Oman" },
  { code: "+92", name: "Pakistan" },
  { code: "+680", name: "Palau" },
  { code: "+970", name: "Palestinian Territories" },
  { code: "+507", name: "Panama" },
  { code: "+675", name: "Papua New Guinea" },
  { code: "+595", name: "Paraguay" },
  { code: "+51", name: "Peru" },
  { code: "+63", name: "Philippines" },
  { code: "+48", name: "Poland" },
  { code: "+351", name: "Portugal" },
  { code: "+1", name: "Puerto Rico" },
  { code: "+974", name: "Qatar" },
  { code: "+262", name: "Réunion" },
  { code: "+40", name: "Romania" },
  { code: "+7", name: "Russia" },
  { code: "+250", name: "Rwanda" },
  { code: "+590", name: "Saint Barthélemy" },
  { code: "+290", name: "Saint Helena" },
  { code: "+1869", name: "Saint Kitts and Nevis" },
  { code: "+1758", name: "Saint Lucia" },
  { code: "+590", name: "Saint Martin" },
  { code: "+508", name: "Saint Pierre and Miquelon" },
  { code: "+1784", name: "Saint Vincent and the Grenadines" },
  { code: "+685", name: "Samoa" },
  { code: "+378", name: "San Marino" },
  { code: "+239", name: "São Tomé and Príncipe" },
  { code: "+966", name: "Saudi Arabia" },
  { code: "+221", name: "Senegal" },
  { code: "+381", name: "Serbia" },
  { code: "+248", name: "Seychelles" },
  { code: "+232", name: "Sierra Leone" },
  { code: "+65", name: "Singapore" },
  { code: "+1721", name: "Sint Maarten" },
  { code: "+421", name: "Slovakia" },
  { code: "+386", name: "Slovenia" },
  { code: "+677", name: "Solomon Islands" },
  { code: "+252", name: "Somalia" },
  { code: "+27", name: "South Africa" },
  { code: "+82", name: "South Korea" },
  { code: "+211", name: "South Sudan" },
  { code: "+34", name: "Spain" },
  { code: "+94", name: "Sri Lanka" },
  { code: "+249", name: "Sudan" },
  { code: "+597", name: "Suriname" },
  { code: "+47", name: "Svalbard and Jan Mayen" },
  { code: "+268", name: "Swaziland" },
  { code: "+46", name: "Sweden" },
  { code: "+41", name: "Switzerland" },
  { code: "+963", name: "Syria" },
  { code: "+886", name: "Taiwan" },
  { code: "+992", name: "Tajikistan" },
  { code: "+255", name: "Tanzania" },
  { code: "+66", name: "Thailand" },
  { code: "+670", name: "Timor-Leste" },
  { code: "+228", name: "Togo" },
  { code: "+690", name: "Tokelau" },
  { code: "+676", name: "Tonga" },
  { code: "+1868", name: "Trinidad and Tobago" },
  { code: "+216", name: "Tunisia" },
  { code: "+90", name: "Turkey" },
  { code: "+993", name: "Turkmenistan" },
  { code: "+1649", name: "Turks and Caicos Islands" },
  { code: "+688", name: "Tuvalu" },
  { code: "+1340", name: "U.S. Virgin Islands" },
  { code: "+256", name: "Uganda" },
  { code: "+380", name: "Ukraine" },
  { code: "+971", name: "United Arab Emirates" },
  { code: "+44", name: "United Kingdom" },
  { code: "+1", name: "United States" },
  { code: "+598", name: "Uruguay" },
  { code: "+998", name: "Uzbekistan" },
  { code: "+678", name: "Vanuatu" },
  { code: "+39", name: "Vatican City" },
  { code: "+58", name: "Venezuela" },
  { code: "+84", name: "Vietnam" },
  { code: "+681", name: "Wallis and Futuna" },
  { code: "+212", name: "Western Sahara" },
  { code: "+967", name: "Yemen" },
  { code: "+260", name: "Zambia" },
  { code: "+263", name: "Zimbabwe" },
];

function ExpertProfile() {
  document.title = "Nextyn | Expert Profile";

  const userData = JSON.parse(localStorage.getItem("userData"));
  const [ModalToggle, setModalToggle] = useState(false);
  const history = useNavigate();
  const handleClose1 = () => setModalToggle(false);

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const [Loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [loading, setloading] = useState(false);
  const [expertDetails, setExpertDetails] = useState([]);
  const [educationDetails, setEducationDetails] = useState([]);
  const [experienceDetails, setexperienceDetails] = useState([]);
  const [payload, setPayload] = useState({
    email: "",
    permission: "view",
  });
  const [userEmail, setEmail] = useState("");
  const [show, setShow] = useState(false);
  const [workData, setworkData] = useState({});
  const [selectedIndustOptions, setSelectedIndustOptions] = useState([]);
  const [filteredIndustOptions, setFilteredIndustOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [phone, setPhone] = useState("");

  const [editMode, setEditMode] = useState(false);
  const [editedData, setEditedData] = useState("");

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const handleChangeData = (e) => {
    setEditedData(e.target.textContent);
  };

  const handleSave = async () => {
    toggleEditMode();

    // setLoading(true);

    try {
      const res = await fetch(`${API_PATH.UPDATE_QC_UPDATE}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
        body: JSON.stringify({
          background_summery: editedData,
          expert_id: expert_id,
        }),
      });

      const result = await res.json();
      if (result) {
        // GetExpertProfile();
        if (res.status === 200 || res.status === true) {
          fetchData();
          notification.success({
            message: "Expert details updated",
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#2ecc71",
              color: "#fff !important",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          }); // wind
          // window.location.reload();
        }
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      // setLoading(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    // setLoading(false);
  };

  const handleContentChange = (e) => {
    setEditedData(e.target.textContent);
  };

  let query = useQuery();
  let expert_id = query.get("expertId");
  const headers = {
    "x-access-token": userData.access_token,
  };

  const [updateValue, setupdateValue] = useState({
    full_Name: "",
    first_name: "",
    last_name: "",
    contact_number: "",
    linkendin_url: "",
    industry: "",
    tags: "",
    contact_code: "",
    expert_id: expert_id,
    email: "",
    company: "",
    country: "",
  });

  const handleInputs = (e) => {
    const key = e.target.name;
    const value = e.target.value;

    setupdateValue({ ...updateValue, [key]: value });
  };

  const handleOptionIndustChange = (selectedValues) => {
    setSelectedIndustOptions(selectedValues);
    const selectValue = selectedValues;
    setupdateValue({ ...updateValue, industry: selectValue });
  };

  const handleOptionChange = (selectedValues) => {
    setSelectedOptions(selectedValues);
    const selectValue = selectedValues;
    setupdateValue({ ...updateValue, tags: selectValue });
  };

  const handleChange = (event) => {
    setPayload({ ...payload, [event.target.name]: event.target.value });
    if (event.target.value.length > 0) setShow(true);
    else setShow(false);
  };

  const handleRequestInputs = (e) => {
    const key = e.target.name;
    const value = e.target.value;

    setEmail(value);
  };

  const getData = (data) => {
    setworkData({ ...data });
  };

  const modalClose = () => {
    setEmail("");
  };

  const GetIndustryList = async () => {
    // setLoading(true);
    try {
      const res = await fetch(`${API_PATH.ADD_EXPERT_INDUSTRY}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const result = await res.json();
      if (result) {
        setFilteredIndustOptions(result?.data);
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      // setLoading(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    // setLoading(false);
  };

  const GetTagsList = async () => {
    // setLoading(true);
    try {
      const res = await fetch(`${API_PATH.ADD_EXPERT_TAG}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const result = await res.json();
      if (result) {
        setFilteredOptions(result?.data);
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      // setLoading(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    // setLoading(false);
  };

  const handlePhoneChange = (value, country) => {
    setPhone(value);

    setupdateValue((prevStep3) => ({
      ...prevStep3,
      contact_code: country?.dialCode,
      contact_number: value?.replace(country?.dialCode, ""), // Remove dial code from value
    }));
  };

  const EditProfile = async (e) => {
    e.preventDefault();

    const full_Name = `${updateValue?.first_name} ${updateValue?.last_name}`;
    const name = `${updateValue?.first_name} ${updateValue?.last_name}`;

    const updatedValueToSend = {
      ...updateValue,
      industry: Array?.isArray(updateValue?.industry)
        ? updateValue?.industry
        : updateValue?.industry?.split(","),
      tags: Array?.isArray(updateValue?.tags)
        ? updateValue?.tags
        : updateValue?.tags?.split(","),
      full_Name: full_Name,
      name: name,
    };

    try {
      const res = await fetch(`${API_PATH.UPDATE_QC_UPDATE}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
        body: JSON.stringify(updatedValueToSend),
      });

      const result = await res.json();
      if (result) {
        // GetExpertProfile();
        if (res.status === 200 || res.status === true) {
          setShow(false);
          fetchData();
          notification.success({
            message: "Expert details updated",
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#2ecc71",
              color: "#fff !important",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          }); // wind
          // window.location.reload();
        }
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
  };

  useEffect(() => {
    GetIndustryList();
    GetTagsList();
  }, []);

  // const getExpertDetails = async (expertId) => {
  //   try {
  //     const res = await fetch(
  //       `${API_PATH.EXPERT_PROFILE}/${expertId}`,
  //       {
  //         method: "GET",
  //         headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${userData?.token}`,
  //       },
  //       }
  //     );

  //     const result = await res.json();
  //     if (result) {
  //       setExpertDetails(result.data);
  //     } else {
  //       return toast.error("something went wrong server error", {
  //         position: toast.POSITION.TOP_RIGHT,
  //         autoClose: 3000,
  //       });
  //     }
  //   } catch (error) {
  //     return toast.error("something went wrong server error", {
  //       position: toast.POSITION.TOP_RIGHT,
  //       autoClose: 3000,
  //     });
  //   }
  // };

  const getDefaultText = (permission) => {
    if (permission === "editi") return "Can edit and invite";
    if (permission === "edit") return "Can edit";
    if (permission === "view") return "Can view";
  };

  // const updatePermission = async (permission, email, expert_id) => {
  //   try {
  //     const res = await fetch(`${API_PATH.SHARE_EXPERT}/${expert_id}`, {
  //       method: "PUT",
  //       headers: {
  //         "Content-Type": "application/json",
  //         "x-access-token": userData.access_token,
  //       },
  //       body: JSON.stringify({ permission: permission, email: email }),
  //     });

  //     const result = await res.json();
  //     if (result) {
  //       getExpertDetails(expert_id);
  //     } else {
  //       return toast.error("something went wrong server error", {
  //         position: toast.POSITION.TOP_RIGHT,
  //         autoClose: 3000,
  //       });
  //     }
  //   } catch (error) {
  //     return toast.error("something went wrong server error", {
  //       position: toast.POSITION.TOP_RIGHT,
  //       autoClose: 3000,
  //     });
  //   }
  // };

  // const handelSubmit = async (data) => {
  //   let payload = {
  //     currentDesignation: data[1].designation || "",
  //     currentCompany: data[1].company_name || "",
  //     country: data.country || "",
  //     expertName: data.full_Name,
  //     expertRate: CalculateExpertRate(data.expertRate),
  //     expert_id: data.expert_id,
  //     clientName: userData.user.username,
  //     clientCompany: userData.user.company_id.name,
  //     clientCountry: userData.user.country,
  //     clientContactNumber: userData.user.contactNumber,
  //   };

  //   try {
  //     const res = await fetch(`${API_PATH.ONE_EXPERT}`, {
  //       method: "PUT",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(payload),
  //     });
  //     const result = await res.json();
  //     if (result) {
  //       return toast.success(result.msg, {
  //         position: toast.POSITION.TOP_RIGHT,
  //         autoClose: 3000,
  //       });
  //     } else {
  //       return toast.error("something went wrong server error", {
  //         position: toast.POSITION.TOP_RIGHT,
  //         autoClose: 3000,
  //       });
  //     }
  //   } catch (error) {
  //     return toast.error("something went wrong server error", {
  //       position: toast.POSITION.TOP_RIGHT,
  //       autoClose: 3000,
  //     });
  //   }
  // };

  const fetchData = () => {
    axios
      .get(`${API_PATH.TEMP_EXPERT_DETAILS}/${expert_id}`, {
        headers,
      })
      .then((res) => {
        setExpertDetails(res.data.data);
        setEducationDetails(res?.data?.data[0]?.experteducations);
        setexperienceDetails(res?.data?.data[0]?.expertexperiences);

        setupdateValue((prevStep) => ({
          ...prevStep,
          first_name: res.data.data[0]?.first_name || "",
          last_name: res.data.data[0]?.last_name || "",
          linkendin_url:
            res.data.data[0]?.linkendin_url ||
            res.data.data[0]?.linkendin_url ||
            res.data.data[0]?.linkedin_url,
          industry: res.data.data[0]?.industry || "",
          tags: res.data.data[0]?.tags || "",
          contact_code: res.data.data[0]?.contact_code || "",
          contact_number: res.data.data[0]?.contact_number || "",
          expert_id: expert_id,
          email: res.data.data[0]?.email || "",
          company: res.data.data[0]?.company || "",
          country: res.data.data[0]?.country || "",
        }));

        setPhone(
          `+${res.data.data[0]?.contact_code}${res.data.data[0]?.contact_number}`
        );

        // if (res.data.data[0]?.contact_number) {
        //   setPhone(res.data.data[0]?.contact_number);

        //   const dialCodeMatch = res.data.data[0]?.contact_number.match(/^\+(\d+)/);
        //   if (dialCodeMatch) {
        //     const dialCode = dialCodeMatch[1];
        //     console.log(dialCode, "dialCode");

        //     const selectedCountry = countryOptions?.find(
        //       (option) => option?.code === `+${dialCode}`
        //     );

        //     if (selectedCountry) {
        //       setupdateValue((prevStep) => ({
        //         ...prevStep,
        //         contact_code: dialCode,
        //       }));
        //     } else {
        //       console.log("No matching country found for dial code:", dialCode);
        //     }
        //   }
        // }

        setLoading(true);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  // useEffect(() => {
  //   axios
  //     .get(`${API_PATH.TEMP_EXPERT_DETAILS}/${expert_id}`, {
  //       headers,
  //     })
  //     .then((res) => {
  //       console.log(res.data.data, "resData");
  //       setExpertDetails(res.data.data);
  //       setEducationDetails(res?.data?.data[0]?.experteducations);
  //       setexperienceDetails(res?.data?.data[0]?.expertexperiences);

  //       setupdateValue((prevStep) => ({
  //         ...prevStep,
  //         first_name: res.data.data[0]?.first_name || "",
  //         last_name: res.data.data[0]?.last_name || "",
  //         linkendin_url: res.data.data[0]?.linkedin_url || res.data.data[0]?.linkendin_url || "",
  //         industry: res.data.data[0]?.industry || "",
  //         tags: res.data.data[0]?.tags || "",
  //         contact_code: res.data.data[0]?.contact_code || "",
  //         contact_number: res.data.data[0]?.contact_number || "",
  //         expert_id: expert_id,
  //         email: res.data.data[0]?.email || "",
  //       }));

  //       console.log(res.data.data[0]?.industry, "industry");

  //       if (res.data.data[0]?.contact_number) {
  //         setPhone(res.data.data[0]?.contact_number);

  //         const dialCodeMatch = res.data.data[0]?.contact_number.match(/^\+(\d+)/);
  //         if (dialCodeMatch) {
  //           const dialCode = dialCodeMatch[1];
  //           console.log(dialCode, "dialCode");

  //           const selectedCountry = countryOptions?.find(
  //             (option) => option?.code === `+${dialCode}`
  //           );

  //           if (selectedCountry) {
  //             setupdateValue((prevStep) => ({
  //               ...prevStep,
  //               contact_code: dialCode,
  //             }));
  //           } else {
  //             console.log("No matching country found for dial code:", dialCode);
  //           }
  //         }
  //       }

  //       setLoading(true);
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //       console.log(err);
  //     });
  // }, []);

  const goToFcPage = (data) => {
    history({
      pathname: "/fc",
      search: `?${createSearchParams({
        expertId: data.expertId ? data.expertId : data.expert_id,
      })}`,
    });
    return toast.success("Please select a time slot", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
    });
  };

  if (Loading) {
    var expertRate = expertDetails[0]?.hourly_rate_call_cunsltn;
    var experience = [];
    for (var i = 0; i < experienceDetails?.length; i++) {
      experience?.push(experienceDetails[i]);
    }

    var CN = [];
    function uniqueData(data) {
      var companyName = [];
      for (var i = 0; i < data.length; i++) {
        if (companyName.indexOf(data[i].company_name) == -1) {
          companyName.push(data[i].company_name);
        }
      }
      CN.push(...companyName);
      return companyName;
    }
    uniqueData(experience);
    var store = [];

    for (let i = 0; i < CN.length; i++) {
      let local = [];
      let print = [];
      for (let j = 0; j < experience.length; j++) {
        if (CN[i] == experience[j].company_name) {
          let obj = {};
          obj.title = experience[j].company_name;
          obj.dateFrom = experience[j].date_from;
          obj.dateTill = experience[j].date_till
            ? experience[j].date_till
            : experience[j].till_present === "yes"
              ? "Present"
              : experience[j].experience === "Less than a year" && obj.dateFrom;
          obj.whatWork = experience[j].what_work;
          obj.location = experience[j].location;
          obj.tillPresent = experience[j].till_present;
          obj.experience = experience[j].experience;
          obj.logoUrl = experience[j].logo_url;
          obj.designation = experience[j].career_title;
          obj.timeE = new Date(obj.dateFrom).getTime();
          obj.timeS =
            obj.dateTill === "Present"
              ? new Date().getTime()
              : experience[j].experience !== "Less than a year"
                ? new Date(obj.dateTill).getTime()
                : new Date(obj.dateFrom).getTime();
          local.push(obj);
        }
        var min = Math.min(...local.map((item) => item.timeE));
        var max = Math.max(...local.map((item) => item.timeS));
      }

      var timed = {
        min: min,
        max: max,
      };

      print.push(
        CN[i],
        local.sort((a, b) => {
          return b.timeS - a.timeS;
        }),
        timed
      );
      store.push(print);
      store = store.sort((a, b) => {
        return b[1][0].timeS - a[1][0].timeS;
      });
    }
  }

  const ConstantsArray = (array) => {
    var type = typeof array;
    let newArray = array;
    if (type == "string") {
      newArray = array?.split(",");
    }
    return newArray;
  };

  function getMonthDifference(startDate, endDate) {
    console.log("endDate", endDate);
    return (
      endDate.getMonth() -
      startDate.getMonth() +
      12 * (endDate.getFullYear() - startDate.getFullYear())
    );
  }

  const Convert_Month_Year = (months) => {
    var month = months + 1;

    var year = Math.floor(month / 12);
    if (year === 0) {
      var month_year = (month % 12) + " mos";
      return month_year;
    } else if (month % 12 === 0) {
      var month_year = year + " yrs ";
      return month_year;
    } else {
      var month_year = year + " yrs " + (month % 12) + " mos";
      return month_year;
    }
  };

  const convertDate = (date) => {
    if (date !== null && date !== "") {
      var d = new Date(date);
      var day = d.getDate();
      var x = d.toDateString().substr(4, 3);
      var year = d.getFullYear();
      let p = `${x} ${day}, ${year}`;
      return p;
    } else {
      return "---------";
    }
  };

  function expertSummery(data) {
    // if (data !== null && data !== "" && data !== undefined) {
    return (
      <div
        className="comon-divs-re"
        style={{ width: "100%", paddingRight: "20px" }}
      >
        <div className="pvs-header__title-container">
          <h2 className="pvs-header__title text-heading-large d-flex justify-content-between">
            <span
              aria-hidden="true"
              style={{
                fontSize: "20px",
                fontWeight: "400",
              }}
            >
              About
            </span>

            {editMode ? (
              <div>
                <MdDone
                  style={{
                    cursor: "pointer",
                    fontSize: "20px",
                    color: "green",
                  }}
                  onClick={handleSave}
                >
                  Save
                </MdDone>
                <MdClear
                  style={{
                    cursor: "pointer",
                    fontSize: "20px",
                    color: "red",
                  }}
                  onClick={toggleEditMode}
                >
                  Cancel
                </MdClear>
              </div>
            ) : (
              <MdModeEditOutline
                style={{ cursor: "pointer", fontSize: "20px" }}
                onClick={toggleEditMode}
              >
                Edit
              </MdModeEditOutline>
            )}
          </h2>
        </div>

        <div className="inline-show-more-text">
          {/* <span
              aria-hidden="true"
              data-name="background_summery"
              style={{ fontSize: "14px" }}
              // contentEditable={true}
              // suppressContentEditableWarning={true}
              // onBlur={(e) => handleChange(e.target.textContent, "background_summary")}
            >
              {data}
            </span> */}
          {/* <div
            contentEditable={true}
            suppressContentEditableWarning={true}
            onBlur={(e) => handleChange(e.target.textContent, "background_summary")}
            style={{ fontSize: "14px", border: "1px solid #ccc", padding: "4px", borderRadius: "4px" }}
          >
            {data}
          </div> */}

          {editMode ? (
            <div
              contentEditable={true}
              suppressContentEditableWarning={true}
              onBlur={handleChangeData}
              style={{
                cursor: "pointer",
                border: "1px solid #ccc",
                padding: "4px",
              }}
            >
              {data}
            </div>
          ) : (
            <div className="inline-show-more-text">
              <span
                aria-hidden="true"
                data-name="background_summery"
                style={{ fontSize: "14px" }}
              >
                {data}
              </span>
            </div>
          )}
        </div>
      </div>
    );
    // } else {
    //   return null;
    // }
  }

  const submitData = async (status) => {
    try {
      setloading(true)
      if (status === "Process Again") {
        const res = await fetch(`${API_PATH.TEMP_EXPERT_PROCESS_AGAIN}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          },
          body: JSON.stringify({
            expert_id: expert_id,
          }),
        });

        const result = await res.json();
        if (result) {
          if (result && (result?.status === 200 || result?.status === true)) {
            // fetchData();
            setTimeout(() => {
              history("/dashboard/qc_experts");
            }, 1000);
            notification.success({
              message:
                "Profile processed again, please check after 5 - 10 min.",
              icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
              style: {
                backgroundColor: "#2ecc71",
                color: "#fff !important",
                border: "1px solid #52c41a",
              },
              duration: 5,
              placement: "topRight",
            }); // wind
          }
        }
      } else {
        const res = await fetch(`${API_PATH.TEMP_EXPERT_SUBMIT}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          },
          body: JSON.stringify({
            expert_id: expert_id,
          }),
        });

        const result = await res.json();
        if (result) {
          if (result && (result?.status === 200 || result?.status === true)) {
            // fetchData();
            setTimeout(() => {
              history("/dashboard/qc_experts");
            }, 1000);
            notification.success({
              message: "Expert moved to main table",
              icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
              style: {
                backgroundColor: "#2ecc71",
                color: "#fff !important",
                border: "1px solid #52c41a",
              },
              duration: 5,
              placement: "topRight",
            }); // wind
          }
        }
      }
      setloading(false)
    } catch (error) {
      console.error("Error:", error);
      setloading(false)
      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
  };

  const addDefaultSrc = (ev) => {
    ev.target.src = user_icon;
  };

  if (!Loading) {
    return (
      <div style={{ textAlign: "center" }}>
        <Spinner animation="border" />
      </div>
    );
  } else {
    return (
      <>
        <>
          {/* <MobileMenu /> */}
          <div className="page-wrapper chiller-theme toggled">
            <div className="main-sec d-lg-flex">
              <div className="sidebar-menu" id="navAccordion">
                <Sidebar />
              </div>
            </div>
            <main className="body-total content-wrapper float-start w-100">
              <div>
                {/* <div className="d-block d-lg-block">
                  <div className="top-bar-menu float-start">
                    <TopMenu />
                  </div>
                </div> */}

                <div className="bk-div float-start w-100">
                  <div className="body-expart-div request-page projects-scope">
                    <h4 className="d-flex align-items-center">
                      <Link to={"#"} onClick={() => history(-1)}>
                        <BsChevronLeft className="me-3 search-btn-back" />
                      </Link>
                      Expert Profile
                    </h4>

                    <div className="mt-4">
                      <section className="content">
                        <div className="findExperts">
                          <div className="linkedinBody">
                            <Row className="g-3">
                              <Col>
                                <div className="bg-white expertBox mb-3">
                                  <div className="p-4">
                                    <div className="d-flex justify-content-end align-items-end text-end">
                                      <Tooltip
                                        color="#5746EB"
                                        title="Edit"
                                        placement="top"
                                      >
                                        <MdModeEditOutline
                                          onClick={() => {
                                            setShow(true);
                                          }}
                                          className="me-2"
                                          style={{
                                            fontSize: "18px",
                                            cursor: "pointer",
                                          }}
                                        />
                                      </Tooltip>
                                    </div>
                                    <div className="g-0 d-flex justify-content-between">
                                      <div
                                        className="fixedWidthCol position-relative"
                                        style={{ width: "150px" }}
                                      >
                                        {expertDetails[0]?.image !== null &&
                                          expertDetails[0]?.image !== "" &&
                                          expertDetails[0]?.image ? (
                                          <>
                                            <img
                                              src={expertDetails[0]?.image}
                                              alt={expertDetails[0]?.first_name}
                                              style={{
                                                borderRadius: "50%",
                                                width: "100%",
                                              }}
                                              onError={addDefaultSrc}
                                            />
                                          </>
                                        ) : (
                                          <img
                                            src={user_icon}
                                            alt={expertDetails[0]?.first_name}
                                            style={{
                                              borderRadius: "50%",
                                              width: "100%",
                                            }}
                                            loading="lazy"
                                          />
                                        )}

                                        <Tooltip
                                          placement="top"
                                          color="#5746EB"
                                          title={
                                            expertDetails[0]
                                              ?.by_linkedinAccount === "Self"
                                              ? "Self Registered"
                                              : "Added by " +
                                              expertDetails[0]
                                                ?.by_linkedinAccount
                                          }
                                        >
                                          <div
                                            className={
                                              expertDetails[0]
                                                ?.by_linkedinAccount +
                                              " expertTypes"
                                            }
                                          ></div>
                                        </Tooltip>
                                      </div>
                                      <div>
                                        <div>
                                          <h5
                                            style={{ fontWeight: 600 }}
                                            className="m-0"
                                          >
                                            {expertDetails[0]?.hourly_currency
                                              ? expertDetails[0]
                                                ?.hourly_currency
                                              : "USD"}{" "}
                                            {expertDetails[0]
                                              ?.hourly_rate_call_cunsltn
                                              ? expertDetails[0]
                                                ?.hourly_rate_call_cunsltn
                                              : "250"}
                                            /
                                            <small className="text-muted fw-normal">
                                              hour
                                            </small>
                                          </h5>
                                          <h5 className="my-1">
                                            <svg
                                              style={{
                                                fontSize: "20px",
                                                color: "rgb(87, 70, 236)",
                                              }}
                                              stroke="currentColor"
                                              fill="currentColor"
                                              stroke-width="0"
                                              viewBox="0 0 1024 1024"
                                              height="1em"
                                              width="1em"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path d="M880 184H712v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H384v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H144c-17.7 0-32 14.3-32 32v664c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V216c0-17.7-14.3-32-32-32zm-40 656H184V460h656v380zM184 392V256h128v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h256v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h128v136H184z"></path>
                                            </svg>
                                            <span
                                              className="ps-3"
                                              style={{ fontSize: "15px" }}
                                            >
                                              Since{" "}
                                              {convertDate(
                                                expertDetails[0]?.createdAt
                                                  ? expertDetails[0]?.createdAt
                                                  : expertDetails[0]?.created_at
                                              )}
                                            </span>
                                          </h5>
                                          <h5 className="my-1">
                                            <svg
                                              style={{
                                                fontSize: "20px",
                                                color: "rgb(87, 70, 236)",
                                              }}
                                              stroke="currentColor"
                                              fill="currentColor"
                                              stroke-width="0"
                                              viewBox="0 0 512 512"
                                              height="1em"
                                              width="1em"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                fill="none"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="32"
                                                d="M320 96H88a40 40 0 00-40 40v240a40 40 0 0040 40h334.73a40 40 0 0040-40V239"
                                              ></path>
                                              <path
                                                fill="none"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="32"
                                                d="M112 160l144 112 87-65.67"
                                              ></path>
                                              <circle
                                                cx="431.95"
                                                cy="128.05"
                                                r="47.95"
                                              ></circle>
                                              <path d="M432 192a63.95 63.95 0 1163.95-63.95A64 64 0 01432 192zm0-95.9a32 32 0 1031.95 32 32 32 0 00-31.95-32z"></path>
                                            </svg>
                                            <span
                                              className="ps-3"
                                              style={{ fontSize: "15px" }}
                                            >
                                              {expertDetails[0]?.email}
                                            </span>
                                          </h5>
                                          <h5 className="my-1">
                                            <svg
                                              style={{
                                                fontSize: "20px",
                                                color: "rgb(87, 70, 236)",
                                              }}
                                              stroke="currentColor"
                                              fill="currentColor"
                                              stroke-width="0"
                                              version="1.2"
                                              baseProfile="tiny"
                                              viewBox="0 0 24 24"
                                              height="1em"
                                              width="1em"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path d="M8 19h-3v-10h3v10zm11 0h-3v-5.342c0-1.392-.496-2.085-1.479-2.085-.779 0-1.273.388-1.521 1.165v6.262h-3s.04-9 0-10h2.368l.183 2h.062c.615-1 1.598-1.678 2.946-1.678 1.025 0 1.854.285 2.487 1.001.637.717.954 1.679.954 3.03v5.647z"></path>
                                              <ellipse
                                                cx="6.5"
                                                cy="6.5"
                                                rx="1.55"
                                                ry="1.5"
                                              ></ellipse>
                                            </svg>
                                            <span
                                              className="ps-3"
                                              style={{ fontSize: "15px" }}
                                            >
                                              <a
                                                href={
                                                  expertDetails[0]?.linkedin_url
                                                }
                                                target="_blank"
                                                style={{
                                                  color: "#5746ec",
                                                  fontWeight: 500,
                                                }}
                                              >
                                                View
                                              </a>
                                            </span>
                                          </h5>

                                          {expertDetails[0]?.company !== null &&
                                            expertDetails[0]?.company !==
                                            undefined ? (
                                            <h5 className="my-1">
                                              <svg
                                                style={{
                                                  fontSize: "20px",
                                                  color: "rgb(87, 70, 236)",
                                                }}
                                                stroke="currentColor"
                                                fill="currentColor"
                                                stroke-width="0"
                                                viewBox="0 0 16 16"
                                                height="1em"
                                                width="1em"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022ZM6 8.694 1 10.36V15h5V8.694ZM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15Z"></path>
                                                <path d="M2 11h1v1H2v-1Zm2 0h1v1H4v-1Zm-2 2h1v1H2v-1Zm2 0h1v1H4v-1Zm4-4h1v1H8V9Zm2 0h1v1h-1V9Zm-2 2h1v1H8v-1Zm2 0h1v1h-1v-1Zm2-2h1v1h-1V9Zm0 2h1v1h-1v-1ZM8 7h1v1H8V7Zm2 0h1v1h-1V7Zm2 0h1v1h-1V7ZM8 5h1v1H8V5Zm2 0h1v1h-1V5Zm2 0h1v1h-1V5Zm0-2h1v1h-1V3Z"></path>
                                              </svg>
                                              <span
                                                className="ps-3"
                                                style={{ fontSize: "15px" }}
                                              >
                                                {expertDetails[0]?.company}
                                              </span>
                                            </h5>
                                          ) : (
                                            <></>
                                          )}

                                          {expertDetails[0]?.contact_number ===
                                            null ||
                                            expertDetails[0]?.contact_number ===
                                            undefined ||
                                            expertDetails[0]?.contact_number ===
                                            "" ? null : (
                                            <h5 className="my-1">
                                              <svg
                                                style={{
                                                  fontSize: "20px",
                                                  color: "rgb(87, 70, 236)",
                                                }}
                                                stroke="currentColor"
                                                fill="currentColor"
                                                stroke-width="0"
                                                viewBox="0 0 24 24"
                                                height="1em"
                                                width="1em"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  fill="none"
                                                  d="M0 0h24v24H0V0z"
                                                ></path>
                                                <path
                                                  fill-opacity=".3"
                                                  d="M16.49 4.5c1.79 0 3.42.73 4.59 1.91l1.06-1.06A7.94 7.94 0 0016.49 3c-2.21 0-4.21.9-5.66 2.34l1.06 1.06a6.545 6.545 0 014.6-1.9z"
                                                ></path>
                                                <path
                                                  fill-opacity=".3"
                                                  d="M16.49 6c-1.38 0-2.63.56-3.54 1.46l1.06 1.06c.63-.63 1.51-1.03 2.47-1.03s1.84.39 2.47 1.03l1.06-1.06A4.94 4.94 0 0016.49 6z"
                                                ></path>
                                                <path d="M16.49 9c-.55 0-1.05.22-1.41.59L16.49 11l1.41-1.41c-.36-.37-.86-.59-1.41-.59z"></path>
                                                <path
                                                  d="M15 17.83c1.29.54 2.63.89 4 1.07v-2.23l-2.35-.47L15 17.83zM7.33 5H5.1c.18 1.37.53 2.7 1.07 4L7.8 7.35 7.33 5z"
                                                  opacity=".3"
                                                ></path>
                                                <path d="M20.2 14.87l-3.67-.73c-.5-.1-.83.2-.9.27l-2.52 2.5c-2.5-1.43-4.57-3.5-6-6l2.5-2.52c.23-.24.33-.57.27-.9L9.13 3.8c-.09-.46-.5-.8-.98-.8H4c-.56 0-1.03.47-1 1.03.17 2.89 1.05 5.6 2.43 7.97 1.58 2.73 3.85 4.99 6.57 6.57 2.37 1.37 5.08 2.26 7.97 2.43.55.03 1.03-.43 1.03-1v-4.15c0-.48-.34-.89-.8-.98zM5.1 5h2.23l.47 2.35L6.17 9c-.54-1.3-.9-2.63-1.07-4zM19 18.9c-1.37-.18-2.7-.53-4-1.07l1.65-1.63 2.35.47v2.23z"></path>
                                              </svg>
                                              <span
                                                className="ps-3"
                                                style={{ fontSize: "15px" }}
                                              >
                                                {expertDetails[0]
                                                  ?.contact_code &&
                                                  !expertDetails[0]?.contact_code.includes(
                                                    "+"
                                                  )
                                                  ? `+${expertDetails[0]?.contact_code}`
                                                  : expertDetails[0]
                                                    ?.contact_code}{" "}
                                                {
                                                  expertDetails[0]
                                                    ?.contact_number
                                                }
                                              </span>
                                            </h5>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <Row>
                                      <Col>
                                        <div className="mt-3">
                                          {console.log(
                                            "expertDetails",
                                            expertDetails
                                          )}
                                          {expertDetails[0]?.full_Name !==
                                            null &&
                                            expertDetails[0]?.full_Name !==
                                            undefined &&
                                            expertDetails[0]?.full_Name !== "" ? (
                                            <h3 className="find_companyName mb-0 mt-0">
                                              {expertDetails[0]?.full_Name}
                                            </h3>
                                          ) : (
                                            <h3 className="find_companyName mb-0 mt-0">
                                              {expertDetails[0]?.first_name}{" "}
                                              {expertDetails[0]?.last_name}
                                            </h3>
                                          )}

                                          <div className="d-flex">
                                            {expertDetails[0]?.country !==
                                              null &&
                                              expertDetails[0]?.country !==
                                              undefined ? (
                                              <div className="text-muted pe-4">
                                                <i
                                                  class="las la-globe-americas me-2"
                                                  style={{ color: "#5746ec" }}
                                                ></i>
                                                <span className="card-text">
                                                  <small className="text-muted">
                                                    {expertDetails[0]?.country}
                                                  </small>
                                                </span>
                                              </div>
                                            ) : (
                                              <></>
                                            )}
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="mt-2">
                                      <Col>
                                        {expertDetails[0]?.industry !== null &&
                                          expertDetails[0]?.industry !==
                                          undefined ? (
                                          <>
                                            {customColors?.map((color) => (
                                              <Tooltip
                                                placement="top"
                                                color={color}
                                                title={text}
                                              >
                                                <svg
                                                  style={{
                                                    fontSize: "20px",
                                                    color: "rgb(87, 70, 236)",
                                                  }}
                                                  stroke="currentColor"
                                                  fill="currentColor"
                                                  stroke-width="0"
                                                  viewBox="0 0 24 24"
                                                  height="1em"
                                                  width="1em"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <g>
                                                    <path
                                                      fill="none"
                                                      d="M0 0h24v24H0z"
                                                    ></path>
                                                    <path d="M10 10.111V1l11 6v14H3V7l7 3.111zm2-5.742v8.82l-7-3.111V19h14V8.187L12 4.37z"></path>
                                                  </g>
                                                </svg>
                                              </Tooltip>
                                            ))}

                                            <span
                                              className="ps-3"
                                              style={{ fontSize: "15px" }}
                                            >
                                              {ConstantsArray(
                                                expertDetails[0]?.industry
                                              )?.map((curElem, id) => {
                                                // Added By Dharmendra
                                                return (
                                                  <>
                                                    {
                                                      <Tag
                                                        color="blue"
                                                        bordered={false}
                                                        key={id}
                                                      >
                                                        {curElem}
                                                      </Tag>
                                                    }
                                                  </>
                                                );
                                              })}
                                            </span>
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </Col>
                                    </Row>

                                    <Row className="mt-2">
                                      <Col>
                                        {expertDetails[0]?.tags !== null &&
                                          expertDetails[0]?.tags !== undefined ? (
                                          <>
                                            {customColors.map((color) => (
                                              <Tooltip
                                                placement="top"
                                                color={color}
                                                title={text2}
                                              >
                                                <svg
                                                  style={{
                                                    fontSize: "20px",
                                                    color: "rgb(87, 70, 236)",
                                                  }}
                                                  stroke="currentColor"
                                                  fill="none"
                                                  stroke-width="1.5"
                                                  viewBox="0 0 24 24"
                                                  aria-hidden="true"
                                                  height="1em"
                                                  width="1em"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <path
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    d="M13.5 21v-7.5a.75.75 0 01.75-.75h3a.75.75 0 01.75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349m-16.5 11.65V9.35m0 0a3.001 3.001 0 003.75-.615A2.993 2.993 0 009.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 002.25 1.016c.896 0 1.7-.393 2.25-1.016a3.001 3.001 0 003.75.614m-16.5 0a3.004 3.004 0 01-.621-4.72L4.318 3.44A1.5 1.5 0 015.378 3h13.243a1.5 1.5 0 011.06.44l1.19 1.189a3 3 0 01-.621 4.72m-13.5 8.65h3.75a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75H6.75a.75.75 0 00-.75.75v3.75c0 .415.336.75.75.75z"
                                                  ></path>
                                                </svg>
                                              </Tooltip>
                                            ))}

                                            <span
                                              className="ps-3"
                                              style={{ fontSize: "15px" }}
                                            >
                                              {ConstantsArray(
                                                expertDetails[0]?.tags
                                              )?.map((curElem, id) => {
                                                return (
                                                  <>
                                                    <Tag
                                                      color="volcano"
                                                      bordered={false}
                                                      key={id}
                                                    >
                                                      {curElem}
                                                    </Tag>
                                                  </>
                                                );
                                              })}
                                            </span>
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </Col>
                                    </Row>
                                  </div>
                                </div>

                                {expertSummery(
                                  expertDetails[0]?.background_summery
                                )}
                              </Col>
                            </Row>

                            {store?.length > 0 ? (
                              <div
                                className="comon-divs-re"
                                style={{ width: "100%", paddingRight: "20px" }}
                              >
                                <div className="pvs-header__title-container">
                                  <h2 className="pvs-header__title text-heading-large d-flex align-items-center">
                                    <span
                                      className="me-2"
                                      aria-hidden="true"
                                      style={{
                                        fontSize: "20px",
                                        fontWeight: "400",
                                      }}
                                    >
                                      Experience
                                    </span>
                                    <ExperienceModal
                                      data={experienceDetails}
                                      expert_id={expert_id}
                                      fetchData={fetchData}
                                    />
                                  </h2>
                                </div>
                                <div className="inline-show-more-text">
                                  {store.map((curElem, id) => {
                                    return (
                                      <>
                                        <div className="companies" key={id}>
                                          <div className="companyLogo mb-4">
                                            {curElem[1][0].logoUrl !== null &&
                                              curElem[1][0].logoUrl !== "" &&
                                              curElem[1][0].logoUrl !==
                                              undefined ? (
                                              <img
                                                src={curElem[1][0].logoUrl}
                                                alt="logo"
                                                onError={addDefaultSrc}
                                              />
                                            ) : (
                                              <img
                                                src={company_icon3}
                                                alt={curElem[1][0].logoUrl}
                                                loading="lazy"
                                              />
                                            )}

                                            <div style={{ marginLeft: "20px" }}>
                                              <h5
                                                className="companyName"
                                                style={{
                                                  fontSize: "18px",
                                                  fontWeight: 500,
                                                  marginBottom: "5px",
                                                }}
                                              >
                                                {curElem[0]}
                                              </h5>
                                              {curElem[1][0].tillPresent ===
                                                "yes" ? (
                                                <p className="totalExperience">
                                                  {Convert_Month_Year(
                                                    getMonthDifference(
                                                      new Date(curElem[2]?.min),
                                                      new Date(Date.now())
                                                    )
                                                  )}
                                                </p>
                                              ) : (
                                                <p className="totalExperience">
                                                  {/* Calculate duration from dateFrom to dateTill */}
                                                  {console.log(
                                                    "curElem",
                                                    curElem
                                                  )}
                                                  {curElem[1][0]?.experience !==
                                                    "Less than a year"
                                                    ? Convert_Month_Year(
                                                      getMonthDifference(
                                                        new Date(
                                                          curElem[2]?.min
                                                        ),
                                                        new Date(
                                                          curElem[2]?.max
                                                        )
                                                      )
                                                    )
                                                    : curElem[1][0]?.dateFrom +
                                                    " - " +
                                                    curElem[1][0]?.experience}
                                                </p>
                                              )}
                                            </div>
                                          </div>
                                          <div className="experienceWrapper">
                                            {curElem[1].map((curElem, id) => {
                                              return (
                                                <div
                                                  className="experienceInfo"
                                                  key={id}
                                                >
                                                  <h5
                                                    className="companyName mb-1"
                                                    style={{
                                                      fontSize: "18px",
                                                    }}
                                                  >
                                                    {curElem?.designation}
                                                  </h5>
                                                  <p
                                                    className="text-body-small inline t-black--light break-words"
                                                    style={{ fontWeight: 500 }}
                                                  >
                                                    {curElem?.experience !==
                                                      "Less than a year" &&
                                                      curElem.dateFrom +
                                                      " - " +
                                                      (curElem.dateTill ===
                                                        null ||
                                                        curElem.dateTill ===
                                                        undefined ||
                                                        curElem.dateTill ===
                                                        "" ||
                                                        curElem?.dateTill === 1
                                                        ? "Present"
                                                        : curElem.dateTill)}
                                                  </p>
                                                  {curElem.location ? (
                                                    <p className="text-body-small inline t-black--light break-words mb-3">
                                                      {curElem.location}
                                                    </p>
                                                  ) : null}
                                                  <div
                                                    style={{
                                                      maxWidth: "92%",
                                                    }}
                                                  >
                                                    {curElem.what_work !==
                                                      null ? (
                                                      <p className="experienceContent">
                                                        {curElem.whatWork}
                                                      </p>
                                                    ) : (
                                                      <></>
                                                    )}
                                                  </div>
                                                </div>
                                              );
                                            })}
                                          </div>
                                        </div>
                                      </>
                                    );
                                  })}
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                            {educationDetails?.length > 0 ? (
                              <div
                                className="comon-divs-re"
                                style={{ width: "100%", paddingRight: "20px" }}
                              >
                                <div className="pvs-header__title-container">
                                  <h2 className="pvs-header__title text-heading-large d-flex align-items-center">
                                    <span
                                      aria-hidden="true"
                                      style={{
                                        fontSize: "20px",
                                        fontWeight: "400",
                                      }}
                                      className="me-2"
                                    >
                                      Education
                                    </span>
                                    <EducationModal
                                      data={educationDetails}
                                      expert_id={expert_id}
                                      fetchData={fetchData}
                                    />
                                  </h2>
                                </div>

                                {educationDetails.map((curElem, id) => {
                                  return (
                                    <>
                                      <div
                                        className="companyLogo mb-2"
                                        key={id}
                                      >
                                        {curElem.logo_url !== null &&
                                          curElem.logo_url !== undefined ? (
                                          <img
                                            src={curElem.logo_url}
                                            alt="logo"
                                            onError={addDefaultSrc}
                                          />
                                        ) : (
                                          <img src={company_icon3} alt="logo" />
                                        )}
                                        <div style={{ marginLeft: "20px" }}>
                                          <h5
                                            className="companyName"
                                            style={{
                                              fontWeight: 500,
                                              marginBottom: "5px",
                                            }}
                                          >
                                            {curElem.institute_name}
                                          </h5>
                                          <p className="totalExperience">
                                            {curElem.degree}
                                          </p>
                                          <p className="text-body-small inline t-black--light break-words">
                                            {curElem.description !== null
                                              ? curElem.description
                                              : ""}
                                          </p>
                                        </div>
                                      </div>
                                    </>
                                  );
                                })}
                              </div>
                            ) : (
                              <></>
                            )}

                            <div className="d-flex justify-content-center align-items-center my-4">
                              <button
                                className="secondaryBttn btn"
                                onClick={() => submitData("Process Again")}
                              >
                                Process Again
                              </button>
                              <button
                                className="viewButton btn ms-2 d-flex align-items-center gap-2"
                                disabled={loading}
                                onClick={() => submitData("Submit")}
                              >
                                Submit  {loading && <LoadingOutlined />}
                              </button>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </>

        <Modal
          show={show}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          keyboard={false}
        >
          <Modal.Body className="expertEditProfile">
            <div
              className="position-absolute closeIcons"
              onClick={() => setShow(false)}
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 24 24"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill="none"
                  stroke="#000"
                  stroke-width="2"
                  d="M3,3 L21,21 M3,21 L21,3"
                ></path>
              </svg>
            </div>
            <div className="text-center">
              <h5 className="">Edit Profile</h5>
            </div>
            <Form layout="vertical" autoComplete="off" form={form}>
              <Row>
                <Col xs={12} md={6}>
                  <Form.Item label="First Name">
                    <Input
                      type="text"
                      autoComplete="nope"
                      size="large"
                      placeholder="First Name"
                      name="first_name"
                      value={updateValue?.first_name}
                      onChange={handleInputs}
                    ></Input>
                  </Form.Item>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Item label="Last Name">
                    <Input
                      type="text"
                      autoComplete="nope"
                      size="large"
                      placeholder="Last Name"
                      name="last_name"
                      value={updateValue?.last_name}
                      onChange={handleInputs}
                    ></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <Form.Item label="Email">
                    <Input
                      type="text"
                      autoComplete="nope"
                      size="large"
                      placeholder="email"
                      name="email"
                      value={updateValue?.email}
                      onChange={handleInputs}
                    ></Input>
                  </Form.Item>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Item label="Contact Details">
                    <PhoneInput
                      className="react-tel-input-2"
                      country={"us"}
                      enableSearch={true}
                      value={phone}
                      onChange={(value, country) =>
                        handlePhoneChange(value, country)
                      }
                      inputStyle={{
                        width: "100%",
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <Form.Item label="Company">
                    <Input
                      type="text"
                      autoComplete="nope"
                      size="large"
                      placeholder="Company"
                      name="company"
                      value={updateValue?.company}
                      onChange={handleInputs}
                    ></Input>
                  </Form.Item>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Item label="Country">
                    <Input
                      type="text"
                      autoComplete="nope"
                      size="large"
                      placeholder="Country"
                      name="country"
                      value={updateValue?.country}
                      onChange={handleInputs}
                    ></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Form.Item label="Linkedin URL">
                    <Input
                      type="text"
                      autoComplete="nope"
                      size="large"
                      placeholder="https://www.linkedin.com/in/393001107/"
                      name="linkendin_url"
                      value={updateValue?.linkendin_url}
                      onChange={handleInputs}
                    ></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Form.Item label="Main Industry">
                    {loading ? (
                      <Spin />
                    ) : (
                      <Select
                        style={{
                          width: "100%",
                        }}
                        name="industry"
                        size="large"
                        mode="tags"
                        placeholder="Type for search"
                        defaultValue={
                          Array.isArray(updateValue?.industry)
                            ? updateValue?.industry
                            : updateValue?.industry?.split(",")
                        } // Use the array directly
                        onChange={handleOptionIndustChange}
                        filterOption={(input, option) =>
                          option?.children
                            ?.toLowerCase()
                            .indexOf(input?.toLowerCase()) >= 0
                        }
                      >
                        {filteredIndustOptions?.map((option) => (
                          <Option
                            key={option?._id}
                            value={option?.category_name}
                          >
                            {option?.category_name}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Form.Item label="Tags">
                    {loading ? (
                      <Spin />
                    ) : (
                      <Select
                        style={{
                          width: "100%",
                        }}
                        name="tags"
                        size="large"
                        mode="tags"
                        placeholder="Type for search"
                        defaultValue={
                          Array.isArray(updateValue?.tags)
                            ? updateValue?.tags
                            : updateValue?.tags?.split(",")
                        }
                        onChange={handleOptionChange}
                        filterOption={(input, option) =>
                          option?.children
                            ?.toLowerCase()
                            .indexOf(input?.toLowerCase()) >= 0
                        }
                      >
                        {filteredOptions?.map((option) => (
                          <Option key={option?._id} value={option?.name}>
                            {option?.name}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col className="text-end">
                  <a
                    className="btn btn-responsive expertButtonNext me-3"
                    onClick={() => setShow(false)}
                  >
                    Close
                  </a>
                  <button
                    className="btn btn-responsive viewButton"
                    onClick={EditProfile}
                  >
                    Submit
                  </button>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default ExpertProfile;
