import {
  DeleteOutlined,
  EditOutlined,
  SearchOutlined,
  ExclamationCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { MdDelete, MdBorderColor, MdEdit } from "react-icons/md";
import {
  Button,
  Form,
  Input,
  Modal,
  Select,
  Space,
  Table,
  message,
  Pagination,
  Tooltip,
  notification,
} from "antd";
import React, { useEffect, useState } from "react";
import "react-h5-audio-player/lib/styles.css";
import "../../App.css";
import API_PATH from "../../Constants/api-path";
import "../../assests/css/custom.css";
import { useNavigate } from "react-router-dom";
import { Row, Col } from "react-bootstrap";

const { Option } = Select;

const MyFormItemContext = React.createContext([]);
function toArr(str) {
  return Array.isArray(str) ? str : [str];
}
const MyFormItemGroup = ({ prefix, children }) => {
  const prefixPath = React.useContext(MyFormItemContext);
  const concatPath = React.useMemo(
    () => [...prefixPath, ...toArr(prefix)],
    [prefixPath, prefix]
  );
  return (
    <MyFormItemContext.Provider value={concatPath}>
      {children}
    </MyFormItemContext.Provider>
  );
};
const MyFormItem = ({ name, ...props }) => {
  const prefixPath = React.useContext(MyFormItemContext);
  const concatName =
    name !== undefined ? [...prefixPath, ...toArr(name)] : undefined;
  return <Form.Item name={concatName} {...props} />;
};

const ExotelTable = () => {
  const dataSource = [
    {
      key: "1",
      name: "Akshay Jain",
      email: "akshay.jain@nextyn.com",
      role: "Admin",
      contact: "9769300880",
      add: "03-Feb-2020",
      action: (
        <>
          <Space size="middle">
            <EditOutlined
              onClick={() => setModal3Open(true)}
              style={{ fontSize: "18px" }}
            />
            <DeleteOutlined
              onClick={() => setModal2Open(true)}
              style={{ fontSize: "18px" }}
            />
          </Space>
        </>
      ),
    },
    {
      key: "2",
      name: "Akshay Jain",
      email: "akshay.jain@nextyn.com",
      role: "Admin",
      contact: "9769300880",
      add: "03-Feb-2020",
      action: (
        <>
          <Space size="middle">
            <EditOutlined
              onClick={() => setModal3Open(true)}
              style={{ fontSize: "18px" }}
            />
            <DeleteOutlined
              onClick={() => setModal2Open(true)}
              style={{ fontSize: "18px" }}
            />
          </Space>
        </>
      ),
    },
    {
      key: "3",
      name: "Akshay Jain",
      email: "akshay.jain@nextyn.com",
      role: "Admin",
      contact: "9769300880",
      add: "03-Feb-2020",
      action: (
        <>
          <Space size="middle">
            <EditOutlined style={{ fontSize: "18px" }} />
            <DeleteOutlined
              onClick={() => setModal2Open(true)}
              style={{ fontSize: "18px" }}
            />
          </Space>
        </>
      ),
    },
    {
      key: "4",
      name: "Akshay Jain",
      email: "akshay.jain@nextyn.com",
      role: "Admin",
      contact: "9769300880",
      add: "03-Feb-2020",
      action: (
        <>
          <Space size="middle">
            <EditOutlined style={{ fontSize: "18px" }} />
            <DeleteOutlined
              onClick={() => setModal2Open(true)}
              style={{ fontSize: "18px" }}
            />
          </Space>
        </>
      ),
    },
    {
      key: "5",
      name: "Akshay Jain",
      email: "akshay.jain@nextyn.com",
      role: "Admin",
      contact: "9769300880",
      add: "03-Feb-2020",
      action: (
        <>
          <Space size="middle">
            <EditOutlined style={{ fontSize: "18px" }} />
            <DeleteOutlined
              onClick={() => setModal2Open(true)}
              style={{ fontSize: "18px" }}
            />
          </Space>
        </>
      ),
    },
    {
      key: "6",
      name: "Akshay Jain",
      email: "akshay.jain@nextyn.com",
      role: "Admin",
      contact: "9769300880",
      add: "03-Feb-2020",
      action: (
        <>
          <Space size="middle">
            <EditOutlined style={{ fontSize: "18px" }} />
            <DeleteOutlined
              onClick={() => setModal2Open(true)}
              style={{ fontSize: "18px" }}
            />
          </Space>
        </>
      ),
    },
    {
      key: "7",
      name: "Akshay Jain",
      email: "akshay.jain@nextyn.com",
      role: "Admin",
      contact: "9769300880",
      add: "03-Feb-2020",
      action: (
        <>
          <Space size="middle">
            <EditOutlined style={{ fontSize: "18px" }} />
            <DeleteOutlined
              onClick={() => setModal2Open(true)}
              style={{ fontSize: "18px" }}
            />
          </Space>
        </>
      ),
    },
    {
      key: "8",
      name: "Akshay Jain",
      email: "akshay.jain@nextyn.com",
      role: "Admin",
      contact: "9769300880",
      add: "03-Feb-2020",
      action: (
        <>
          <Space size="middle">
            <EditOutlined style={{ fontSize: "18px" }} />
            <DeleteOutlined
              onClick={() => setModal2Open(true)}
              style={{ fontSize: "18px" }}
            />
          </Space>
        </>
      ),
    },
    {
      key: "9",
      name: "Akshay Jain",
      email: "akshay.jain@nextyn.com",
      role: "Admin",
      contact: "9769300880",
      add: "03-Feb-2020",
      action: (
        <>
          <Space size="middle">
            <EditOutlined style={{ fontSize: "18px" }} />
            <DeleteOutlined
              onClick={() => setModal2Open(true)}
              style={{ fontSize: "18px" }}
            />
          </Space>
        </>
      ),
    },
    {
      key: "10",
      name: "Akshay Jain",
      email: "akshay.jain@nextyn.com",
      role: "Admin",
      contact: "9769300880",
      add: "03-Feb-2020",
      action: (
        <>
          <Space size="middle">
            <EditOutlined style={{ fontSize: "18px" }} />
            <DeleteOutlined
              // onClick={() => setModal2Open(true)}
              style={{ fontSize: "18px" }}
            />
          </Space>
        </>
      ),
    },
  ];
  let history = useNavigate();
  const [form] = Form.useForm();
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(dataSource);
  const [modal1Open, setModal1Open] = useState(false);
  const [modal2Open, setModal2Open] = useState(false);
  const [modal3Open, setModal3Open] = useState(false);
  const [componentSize, setComponentSize] = useState("default");
  const onFormLayoutChange = ({ size }) => {
    setComponentSize(size);
  };
  const [managerList, setManagerList] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: managerList?.length,
  });
  const [loading, setLoading] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [addManager, setAddManger] = useState({
    name: "",
    email: "",
    contact_number: "",
    role_id: "",
    password: "",
  });
  const [managerId, setManagerId] = useState("");
  const [roleList, setRoleList] = useState([]);
  const [updateMangerId, setUpdateMangerId] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [filteredClientList, setFilteredClientList] = useState([]);

  const [callPage, setCallPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const convertDate = (date) => {
    //console.log(date)
    if (date !== null && date !== "") {
      var d = new Date(date);
      var day = d.getDate();
      var x = d.toDateString().substr(4, 3);
      var year = d.getFullYear();
      let p = `${x} ${day}, ${year}`;
      //console.log(p)
      return p;
    } else {
      return "---------";
    }
  };

  useEffect(() => {
    GetManagerList(currentPage, selectedOptions);
    GetRoleList();
    const url = constructUrl(selectedOptions);
    console.log(url); // Output the constructed UR
  }, [currentPage, selectedOptions]);

  const GetManagerList = async (page, selectedOptions) => {
    setLoading(true);
    try {
      let object = {};

      console.log(object, "object");

      if (selectedOptions?.key) {
        object.key = selectedOptions?.key;
      }
      const queryParams = new URLSearchParams(object); // Construct query parameters from the object properties
      const res = await fetch(
        `${
          API_PATH.MANAGER_LIST
        }?page=${page}&limit=10&${queryParams?.toString()}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          },
        }
      );

      const result = await res.json();
      if (result) {
        console.log(result?.data, "resultData");
        setManagerList(result?.data);
        setCallPage(result?.countData);
        console.log(result?.countData, "result?.data?.countData");
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  };

  const GetRoleList = async () => {
    try {
      const res = await fetch(`${API_PATH.ROLE_LIST}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
      });

      const result = await res.json();
      if (result) {
        console.log(result?.data, "roleData");
        setRoleList(result?.data);
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  };

  const handleInputs = (e) => {
    const key = e.target.name;
    const value = e.target.value;

    setAddManger({ ...addManager, [key]: value });
  };

  function handleSelectChange(value) {
    console.log(`Selected value: ${value}`);
    const selectValue = value;
    setAddManger({ ...addManager, role_id: selectValue });
  }

  const handleMobileNumberChange = (e) => {
    const value = e.target.value;
    const key = e.target.name;

    setAddManger({ ...addManager, contact_number: value });

    const numericValue = value.replace(/\D/g, ""); // Remove non-numeric characters

    if (numericValue.length !== 10) {
      setErrorMessage("Mobile number should be 10 digits");
    } else {
      setErrorMessage(null);
    }
  };

  const AddManager = async () => {
    // e.preventDefault();
    setLoading(true);

    addManager.email = addManager?.email?.toLowerCase();

    try {
      const res = await fetch(`${API_PATH.MANAGER_ADD}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
        body: JSON.stringify(addManager),
      });

      const result = await res.json();
      if (result) {
        GetManagerList();
        if (res.status === 200 || res.status === true) {
          // console.log(res.status);
          setModal1Open(false);

          notification.success({
            message: "Manager account created",
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#2ecc71",
              color: "#fff !important",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          });

          // window.location.reload();
        }
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  };

  const getMangerId = (_id) => {
    setManagerId(_id);
    // console.log(deleteId);
  };

  const deleteMangerModal = () => {
    // console.log(isUserId, "deleteUserModal");
    DeleteManager(managerId);
  };

  const DeleteManager = async (_id) => {
    console.log(_id, "mangerId");
    // e.preventDefault();
    setLoading(true);

    try {
      const res = await fetch(`${API_PATH.MANAGER_DELETE}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
        body: JSON.stringify({
          admin_id: [_id],
        }),
      });

      const result = await res.json();
      if (result) {
        GetManagerList();
        if (res.status === 200 || res.status === true) {
          // console.log(res.status);

          notification.success({
            message: "Manager account deleted",
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#2ecc71",
              color: "#fff !important",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          });

          // window.location.reload();
        }
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  };

  const UpdateManager = async () => {
    // e.preventDefault();
    setLoading(true);

    try {
      const res = await fetch(`${API_PATH.MANAGER_UPDATE}/${updateMangerId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
        body: JSON.stringify({
          name: addManager?.name,
          email: addManager?.email,
          contact_number: addManager?.contact_number,
          role_id: addManager?.role_id,
        }),
      });

      const result = await res.json();
      if (result) {
        GetManagerList();
        if (res.status === 200 || res.status === true) {
          // console.log(res.status);

          notification.success({
            message: "Manager account updated",
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#2ecc71",
              color: "#fff !important",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          });

          // window.location.reload();
        }
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  };

  const setUserModal = ({ data, id }) => {
    console.log(data, "curElem");

    const res = data?.find((val) => val._id === id);

    console.log("obj found update func res", res);
    console.log(res?.rolename[0], "rolename");

    setAddManger({
      name: res?.name,
      email: res?.email,
      contact_number: res?.contact_number,
      role_id: res?.role_id ? res?.role_id : "",
    });
    setUpdateMangerId(id);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Role",
      dataIndex: "rolename",
      key: "rolename",
      render: (rolename) => `${rolename ? rolename : "-"}`,
    },
    {
      title: "Contact Number",
      dataIndex: "contact_number",
      key: "contact_number",
    },
    {
      title: "Added on",
      dataIndex: "created_at",
      render: (created_at) => `${convertDate(created_at)}`,
    },
    {
      title: "Action",
      dataIndex: "_id",
      key: "action",
      render: (_id) => (
        // <Space size="middle">
        //   <Tooltip color="#5746EB" title="Edit" placement="top">
        //     <MdBorderColor
        //       onClick={() => {
        //         setModal3Open(true);
        //         setUserModal({ data: managerList, id: _id });
        //       }}
        //       style={{ fontSize: "18px", cursor: "pointer" }}
        //     />
        //   </Tooltip>

        //   <Tooltip color="#5746EB" title="Delete" placement="top">
        //     <MdDelete
        //       onClick={() => {
        //         setModal2Open(true);
        //         getMangerId(_id);
        //       }}
        //       style={{ fontSize: "18px", cursor: "pointer" }}
        //     />
        //   </Tooltip>
        // </Space>

        <div className="d-flex expertActions align-items-center gap-1">
          <Tooltip placement="top" title={"Edit"} color="#5746EB">
            <span>
              <button
                size="large"
                className="viewBttnExpert"
                onClick={() => {
                  setModal3Open(true);
                  setUserModal({ data: managerList, id: _id });
                }}
              >
                <MdEdit />
              </button>
            </span>
          </Tooltip>

          <Tooltip placement="top" title="Delete" color="#5746EB">
            <button
              size="large"
              className="viewBttnExpert"
              onClick={() => {
                setModal2Open(true);
                getMangerId(_id);
              }}
            >
              <MdDelete />
            </button>
          </Tooltip>
        </div>
      ),
    },
  ];

  useEffect(() => {
    const filteredData = managerList?.filter((record) =>
      Object.values(record)
        .join("")
        .toLowerCase()
        .includes(searchText.toLowerCase())
    );
    setFilteredClientList(filteredData);
    setPagination({
      ...pagination,
      current: 1,
      total: filteredData.length,
    });
  }, [managerList, searchText]);

  const handleSearch = (value) => {
    setSearchText(value);
    setCurrentPage(1); // Reset the currentPage to 1 when the search filter changes
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPagination(pagination);
  };

  const emailRules = [
    {
      type: "email",
      message: "Please enter a valid email address!",
    },
    {
      required: true,
      message: "Email is required!",
    },
  ];

  const paginationSettings = {
    current: currentPage,
    onChange: handlePageChange,
    pageSize: 10,
    total: callPage,
  };

  const updateFooter = (
    <div className="d-flex justify-content-end align-items-center">
      <button
        className="secondaryBttn btn"
        onClick={() => setModal3Open(false)}
      >
        Close
      </button>

      <button
        onClick={() => {
          UpdateManager();
          setModal3Open(false);
        }}
        className="viewButton btn ms-2"
      >
        Update
      </button>
    </div>
  );

  const addFooter = (
    <div className="d-flex justify-content-end align-items-center">
      <Form.Item style={{ marginBottom: 0 }}>
        <button
          class="secondaryBttn btn"
          onClick={() => {
            setModal1Open(false);
            form.resetFields();
          }}
        >
          Close
        </button>
      </Form.Item>
      <Form.Item shouldUpdate style={{ marginBottom: 0 }}>
        {() => (
          <button
            onClick={() => {
              AddManager();
              form.resetFields();
            }}
            className="viewButton btn ms-2"
            disabled={
              !form.isFieldsTouched(true) ||
              !!form.getFieldsError().filter(({ errors }) => errors.length)
                .length ||
              !!errorMessage // Disable the button if there is a mobile number validation error
            }
          >
            Add as manager
          </button>
        )}
      </Form.Item>
    </div>
  );

  const footer = (
    <div>
      <button
        key="cancel"
        className="secondaryBttn btn"
        onClick={() => setModal2Open(false)}
      >
        No
      </button>
      <button
        key="submit"
        className="viewButton btn ms-2"
        onClick={() => {
          deleteMangerModal();
          setModal2Open(false);
        }}
      >
        Yes
      </button>
    </div>
  );

  const constructUrl = (options) => {
    const { key } = options;

    const projectParam = Array.isArray(key) ? key.join(", ") : key;
    const sanitizedProject = projectParam
      ? projectParam.replace(/%20/g, " ")
      : "";

    const urlParams = [`key=${encodeURIComponent(sanitizedProject)}`]; // Remove quotes and properly encode

    return history({
      pathname: `/dashboard/managers`,
      search: `?${urlParams.join("&")}`,
    });
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setSelectedOptions({
      key: inputValue,
    });
    setSearchText(inputValue);
    setCurrentPage(1);
  };

  return (
    <>
      <div>
        <div className="bk-div float-start w-100">
          {" "}
          {/* <hr className="sp-hr" /> */}
          <div className="body-expart-div">
            <div className="d-flex justify-content-between align-items-center">
              <h2>Managers</h2>
              <button
                className="btn viewButton"
                onClick={() => {
                  setModal1Open(true);
                  setAddManger("");
                }}
                size="large"
              >
                Add New Manager
              </button>
            </div>

            <Input
              placeholder="Search"
              value={selectedOptions?.key}
              onChange={handleInputChange}
              prefix={<SearchOutlined />}
              style={{ marginBottom: 16 }}
              size="large"
            />
            <Table
              dataSource={filteredClientList}
              columns={columns}
              loading={loading}
              pagination={false}
              onChange={handleTableChange}
              scroll={{ x: 800 }}
              size="large"
            />
            <Pagination
              {...paginationSettings}
              showSizeChanger={false}
              style={{ marginTop: 16, marginBottom: 16, textAlign: "right" }}
            />
          </div>
        </div>
      </div>

      <Modal
        title={<span className="modalHeader">Add New Manager</span>}
        footer={addFooter}
        open={modal1Open}
        onOk={() => {
          form.resetFields();
          setModal1Open(false);
        }}
        onCancel={() => {
          form.resetFields();
          setModal1Open(false);
        }}
        width={680}
        centered
      >
        <Form
          form={form}
          autoComplete="off"
          layout="vertical"
          name="form_in_modal"
          initialValues={{
            modifier: "public",
          }}
          onFinish={() => {
            setModal1Open(false);
            form.resetFields();
            AddManager();
          }}
        >
          <Row>
            <Col>
              <Form.Item
                label="Name:"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Name is required!",
                  },
                ]}
              >
                <Input
                  name="name"
                  size="large"
                  value={addManager?.name}
                  onChange={handleInputs}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Item
                label="Mobile:"
                name="contact_number"
                rules={[
                  { required: true, message: "Mobile number is required!" },
                ]}
                validateStatus={errorMessage ? "error" : ""}
                help={errorMessage}
              >
                <Input
                  name="contact_number"
                  size="large"
                  value={addManager?.contact_number}
                  maxLength={10}
                  onChange={handleMobileNumberChange}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Item
                label="Email:"
                name={["user", "email"]}
                rules={emailRules}
              >
                <Input
                  name="email"
                  autoComplete="off"
                  size="large"
                  value={addManager?.email}
                  onChange={handleInputs}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Password is required!",
                  },
                ]}
              >
                <Input.Password
                  name="password"
                  autoComplete="off"
                  size="large"
                  value={addManager?.password}
                  onChange={handleInputs}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Item
                label="Role:"
                name="role_id"
                rules={[
                  {
                    required: true,
                    message: "Password is required!",
                  },
                ]}
              >
                <Select
                  size="large"
                  style={{
                    width: "100%",
                  }}
                  value={addManager?.role_id}
                  onChange={handleSelectChange}
                >
                  {roleList?.map((option, index) => (
                    <Select.Option value={option?._id} key={index}>
                      {option?.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        title={<span className="modalHeader">Update Manager</span>}
        open={modal3Open}
        onOk={() => {
          setModal3Open(false);
          form.resetFields();
        }}
        onCancel={() => {
          setModal3Open(false);
          form.resetFields();
        }}
        visible={true}
        footer={updateFooter}
        width={600}
        centered
      >
        <div className="mt-4 text-center">
          <Form
            form={form}
            layout="horizontal"
            name="form_in_modal"
            initialValues={{
              modifier: "public",
            }}
          >
            <Row>
              <Col xs={2} md={2}>
                <Form.Item label="Name:" />
              </Col>
              <Col xs={10} md={10}>
                <Input
                  name="name"
                  size="large"
                  value={addManager?.name}
                  onChange={handleInputs}
                />
              </Col>
            </Row>

            <Row>
              <Col xs={2} md={2}>
                <Form.Item label="Mobile:"></Form.Item>
              </Col>
              <Col xs={10} md={10}>
                <Input
                  name="contact_number"
                  size="large"
                  value={addManager?.contact_number}
                  onChange={handleInputs}
                />
              </Col>
            </Row>

            <Row>
              <Col xs={2} md={2}>
                <Form.Item label="Email:"></Form.Item>
              </Col>
              <Col xs={10} md={10}>
                <Input
                  name="email"
                  size="large"
                  value={addManager?.email}
                  onChange={handleInputs}
                />
              </Col>
            </Row>

            <Row>
              <Col xs={2} md={2}>
                <Form.Item label="Role:"></Form.Item>
              </Col>
              <Col xs={10} md={10}>
                <Select
                  size="large"
                  style={{
                    width: "100%",
                  }}
                  value={addManager?.role_id}
                  onChange={handleSelectChange}
                >
                  {roleList?.map((option, index) => (
                    <Select.Option value={option?._id} key={index}>
                      {option?.name}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>

      <Modal
        title={<span className="modalHeader">Delete this Account.</span>}
        open={modal2Open}
        onOk={() => setModal2Open(false)}
        onCancel={() => setModal2Open(false)}
        visible={true}
        footer={footer}
        width={800}
        centered
      >
        <div className="mt-4" style={{ width: "100%", textAlign: "left" }}>
          <p>
            This action cannot be undone. If you delete this account then their
            account will be deactivated and will not be able to log in again
            with their credentials. Are you sure you want to delete this
            account?
          </p>{" "}
        </div>
      </Modal>
    </>
  );
};

export default ExotelTable;
