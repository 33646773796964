import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";
import Helmet from "react-helmet";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "../../components/Sidebar";
import {
    Segmented,
    Select,
    DatePicker,
    Table,
    Tooltip,
    Input,
    notification,
    Pagination,
    Tag,
    Spin,
} from "antd";
import { Col, Row } from "react-bootstrap";
import { useNavigate, createSearchParams } from "react-router-dom";
import AvatarGroup from "react-avatar-group";
import { MdClose, MdDelete, MdModeEdit } from "react-icons/md";
import AddProjectModal from "./AddProjectModal";
import DeleteModal from "./DeleteModal";
import CloseProject from "./CloseProject";
import API_PATH from "../../Constants/api-path";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import queryString from "query-string";
import dayjs from "dayjs";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { MdCalendarToday, MdContentCopy } from "react-icons/md";
import _ from "lodash";
import { IoInformationCircleOutline } from "react-icons/io5";
import { Calculate_days, formatNumber } from "../../Utils/util";
import StatisticContainer from "./Components/StatisticContainer";
const { Option } = Select;
const { RangePicker } = DatePicker;

function HoldProjectList() {
    let queryParams = queryString.parse(window.location.search);
    const [show, setShow] = useState(false);
    const [handleCloseProject, setCloseProject] = useState(false);
    const [handleDeleteProject, setDeleteProject] = useState(false);
    const [liveProject, setliveProject] = useState([]);
    const [loading, setLoading] = useState(false);
    const [modalStatus, setModalStatus] = useState(3);
    const [teamId, setTeamId] = useState("");
    const [teamIdEdit, setTeamIdEdit] = useState("");
    const history = useNavigate();
    const [managerList, setManagerList] = useState([]);
    const [managerFilterList, setManagerFilterList] = useState([]);
    const [teamMemberList, setTeamMemberList] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState({
        project_name:
            queryParams?.project_name !== undefined ? queryParams?.project_name : "",
        client_name:
            queryParams?.client_name !== undefined ? queryParams?.client_name : "",
        added_by: !["", undefined, "undefined"]?.includes(queryParams?.added_by) ? queryParams?.added_by : [],
        manager_id:
            !["", undefined, "undefined"]?.includes(queryParams?.manager_id) ? queryParams?.manager_id : [],
        date:
            !["", undefined, "undefined"]?.includes(queryParams?.sdate) &&
                !["", undefined, "undefined"]?.includes(queryParams?.edate)
                ? [queryParams?.sdate, queryParams?.edate]
                : [],
    });

    const [teamIdClosed, setTeamIdClosed] = useState("");
    const [copiedId, setCopiedId] = useState(null);

    const [callPage, setCallPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(
        queryParams?.page !== undefined ? parseInt(queryParams.page) : 1
    );

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        constructUrl(selectedOptions, pageNumber);
        GetLiveProjectList(pageNumber, selectedOptions);

    };

    const paginationSettings = {
        current: currentPage,
        onChange: handlePageChange,
        pageSize: 10,
        total: callPage,
    };

    const handleClose = useCallback(() => {
        setTeamIdEdit("");
        setShow(false);
    }, []);
    const handleShow = useCallback((status) => {
        setModalStatus(status); // Set the status based on the button clicked
        setShow(true);
    }, []);
    const handleOptionChange = useCallback(
        (option) => {
            console.log('option: ', option);
            history(
                option === "Closed Projects"
                    ? "/dashboard/team/completed-projects"
                    : option === "On-hold Projects" ? "/dashboard/team/hold-projects" : "/dashboard/team/projects"
            );
            // setSelectedOption(option);
        },
        [history]
    );

    const userData = JSON.parse(localStorage.getItem("userData"));

    const convertDate = useCallback((dateString) => {
        if (!["", "undefined", undefined]?.includes(dateString)) {
            const options = { year: "numeric", month: "short", day: "numeric" };
            const formattedDate = new Date(dateString).toLocaleDateString(
                "en-US",
                options
            );
            return formattedDate;
        } else {
            return "-";
        }
    }, []);



    const GetLiveProjectList = useCallback(
        async (page, selectedOptions) => {
            setLoading(true);
            try {
                let object = {};

                if (selectedOptions?.project_name) {
                    object.project_name = selectedOptions?.project_name;
                }

                if (selectedOptions?.client_name) {
                    object.client_name = selectedOptions?.client_name;
                }

                if (selectedOptions?.added_by) {
                    object.added_by = selectedOptions?.added_by;
                }
                if (selectedOptions?.manager_id) {
                    object.manager_id = selectedOptions?.manager_id;
                }
                if (
                    selectedOptions?.date?.length > 0 &&
                    !["", undefined, "undefined"]?.selectedOptions?.date?.[0] &&
                    !["", undefined, "undefined"]?.selectedOptions?.date?.[1]
                ) {
                    object.sdate = selectedOptions?.date?.[0];
                    object.edate = selectedOptions?.date?.[1];
                }
                const queryParams = new URLSearchParams(object);
                const res = await fetch(
                    `${API_PATH.TEAM_HOLD_PROJECT_LIST}/${userData?.data?.email
                    }?page=${page}&limit=10&${queryParams?.toString()}`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${userData?.token}`,
                        },
                    }
                );
                const result = await res.json();
                if (result) {
                    setliveProject(result?.data);
                    setManagerFilterList(result?.allManagers);
                    setTeamMemberList(result?.allTeams);
                    setCallPage(result?.countData);
                }
                setLoading(false);

            } catch (error) {
                setLoading(false);
                notification.error({
                    message: "Error: Something went wrong server error",
                    icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
                    style: {
                        backgroundColor: "#e74c3c",
                        color: "#fff",
                        border: "1px solid #c0392b",
                    },
                    duration: 5,
                    placement: "topRight",
                });
            }
        },
        [userData?.data?.email, userData?.token]
    );

    const GetManagerList = useCallback(async () => {
        // setLoading(true);
        try {
            const res = await fetch(`${API_PATH.MANAGER_LIST}?limit=50`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userData?.token}`,
                },
            });

            const result = await res.json();
            if (result) {
                setManagerList(result?.data);
            } else {
                notification.error({
                    message: "Error: Something went wrong server error",
                    icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
                    style: {
                        backgroundColor: "#e74c3c",
                        color: "#fff",
                        border: "1px solid #c0392b",
                    },
                    duration: 5,
                    placement: "topRight",
                });
            }
            // setLoading(false);
        } catch (error) {
            // setLoading(false);

            notification.error({
                message: "Error: Something went wrong server error",
                icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
                style: {
                    backgroundColor: "#e74c3c",
                    color: "#fff",
                    border: "1px solid #c0392b",
                },
                duration: 5,
                placement: "topRight",
            });
        }
    }, [userData?.token]);

    useEffect(() => {
        GetManagerList();
    }, [GetManagerList]);

    const callonce = useRef(false)
    useEffect(() => {
        if (!callonce.current) {
            callonce.current = true
            GetLiveProjectList(currentPage, selectedOptions);
        }
    }, [GetLiveProjectList, currentPage, selectedOptions]);

    useEffect(() => {
        let queryParams = queryString.parse(window.location.search);
        if (queryParams.page && !isNaN(parseInt(queryParams.page))) {
            setCurrentPage(parseInt(queryParams.page));
        } else {
            setCurrentPage(1);
        }
    }, []);

    const filteredData = useMemo(
        () => liveProject?.filter((item) => item.status === 3),
        [liveProject]
    );

    const getTeamId = useCallback((_id) => {
        setTeamId(_id);
    }, []);

    const getTeamIdEdit = useCallback((_id) => {
        setTeamIdEdit(_id);
    }, []);

    const getTeamIdClosed = useCallback((_id) => {
        setTeamIdClosed(_id);
    }, []);

    const constructUrl = useCallback(
        (selectedOptions, pageNo) => {
            const { project_name, client_name, added_by, date, manager_id } =
                selectedOptions || {};
            const projectParam = Array.isArray(project_name)
                ? project_name.join(", ")
                : project_name;
            const companyParam = Array.isArray(client_name)
                ? client_name.join(", ")
                : client_name;
            // const expertParam = Array.isArray(added_by)
            //   ? added_by.join(", ")
            //   : added_by;
            const teamMemberParams = Array.isArray(added_by)
                ? added_by.join(", ")
                : added_by;
            const managerParams = Array.isArray(manager_id)
                ? manager_id.join(", ")
                : manager_id;
            const sdate =
                Array.isArray(date) && !["", undefined, "undefined"]?.includes(date[0])
                    ? JSON.stringify(date[0])
                    : "";
            const edate =
                Array.isArray(date) && !["", undefined, "undefined"]?.includes(date[1])
                    ? JSON.stringify(date[1])
                    : "";
            const clientPage = Array.isArray(pageNo) ? date.join(", ") : pageNo;

            const urlParams = [
                `project_name=${encodeURIComponent(projectParam)}`,
                `client_name=${encodeURIComponent(companyParam)}`,
                `added_by=${encodeURIComponent(teamMemberParams)}`,
                `manager_id=${encodeURIComponent(managerParams)}`,
                `sdate=${encodeURIComponent(sdate)}`,
                `edate=${encodeURIComponent(edate)}`,
                `page=${encodeURIComponent(clientPage)}`,
            ];

            return history({
                pathname: `/dashboard/team/hold-projects`,
                search: `?${urlParams.join("&")}`,
            });
        },
        [history]
    );

    const onChange = useCallback(
        (value, key) => {
            const updatedValue = value === undefined ? "" : value;
            setSelectedOptions((prev) => ({ ...prev, [key]: updatedValue }));
            const queryParams = queryString.parse(window.location.search);
            queryParams[key] = updatedValue;
            setCurrentPage(1);
            history({
                pathname: "/dashboard/team/hold-projects",
                search: `?${queryString.stringify(queryParams)}`,
            });
        },
        [history]
    );

    useEffect(() => {
        if (selectedOptions) {
            const url = constructUrl(selectedOptions, currentPage);
        }
    }, [selectedOptions, currentPage, constructUrl]);

    const goToDetailsPage = useCallback(
        (project_id, status) => {
            history({
                pathname: "/dashboard/team/project-overview",
                search: `?${createSearchParams({ project_id, status })}`,
            });
        },
        [history]
    );

    // const menu = useCallback(
    //   (_id) => (
    //     <Menu>
    //       <Menu.Item
    //         key="edit"
    //         onClick={() => {
    //           handleShow();
    //           getTeamIdEdit(_id);
    //         }}
    //       >
    //         <span>
    //           <MdModeEditOutline style={{ marginRight: "8px" }} />
    //           Edit
    //         </span>
    //       </Menu.Item>
    //       <Menu.Item
    //         key="close"
    //         onClick={() => {
    //           setCloseProject(true);
    //           getTeamIdClosed(_id);
    //         }}
    //       >
    //         <span>
    //           <MdOutlineCheck style={{ marginRight: "8px" }} />
    //           Close Project
    //         </span>
    //       </Menu.Item>
    //       <Menu.Item
    //         key="delete"
    //         onClick={() => {
    //           setDeleteProject(true);
    //           getTeamId(_id);
    //         }}
    //       >
    //         <span>
    //           <MdDelete style={{ marginRight: "8px" }} />
    //           Delete Project
    //         </span>
    //       </Menu.Item>
    //     </Menu>
    //   ),
    //   [getTeamId, getTeamIdClosed, getTeamIdEdit, handleShow]
    // );

    const columns = [
        {
            title: "Project",
            dataIndex: "project_title",
            key: "project_title",
            render: (text, record) => {
                return (
                    <>
                        <div>
                            <div className="d-flex align-items-center gap-1">
                                <span>
                                    <Tooltip
                                        color="#5746EB"
                                        title={record?.project_title}
                                        placement="topLeft"
                                    >
                                        <h5
                                            onClick={() => goToDetailsPage(record?._id, modalStatus)}
                                            className="projectTitle"
                                        >
                                            {_.truncate(record?.project_title, {
                                                length: 40,
                                                omission: "...",
                                            }) || "-"}
                                        </h5>
                                    </Tooltip>
                                </span>
                                <span className="projectTitle align-middle">
                                    <Tooltip
                                        placement="right"
                                        overlayClassName="projectStatesPopover"
                                        trigger={"click"}
                                        title={
                                            <>
                                                <h4
                                                    style={{
                                                        fontSize: "14px",
                                                        color: "rgb(87, 70, 236)",
                                                        margin: "6px 0",
                                                        fontWeight: 600,
                                                    }}
                                                >
                                                    Project Statistics
                                                </h4>
                                                <StatisticContainer record={record} />
                                            </>
                                        }
                                    >
                                        <IoInformationCircleOutline />
                                    </Tooltip>
                                </span>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <small className="text-muted">
                                    <span className="d-flex gap-2">
                                        <span>

                                            {convertDate(record?.project_start)}
                                        </span>
                                        <span>-</span>
                                        {record?.project_deadline &&
                                            <span>

                                                {convertDate(record?.project_deadline)}
                                            </span>
                                        }

                                    </span>
                                    {Calculate_days(
                                        record?.project_start,
                                        record?.project_deadline
                                    )}
                                </small>
                                <CopyToClipboard
                                    text={record?._id}
                                    onCopy={() => {
                                        setCopiedId(record?._id);
                                    }}
                                >
                                    <Tooltip
                                        title={copiedId === record?._id ? "Copied!" : "Copy ID"}
                                    >
                                        <MdContentCopy
                                            className="ms-3"
                                            style={{ cursor: "pointer" }}
                                        />
                                    </Tooltip>
                                </CopyToClipboard>
                            </div>
                        </div>
                    </>
                );
            },
            width: "30%"
        },

        {
            title: "Client",
            dataIndex: "client_name",
            key: "client_name",
            width: "10%"

        },
        {
            title: <span>Expected<br />Calls</span>,
            dataIndex: "expected_calls",
            key: "expected_calls",
            render: (text, record) => {
                return (
                    <>
                        <div className="d-flex justify-content-center" onClick={() => goToDetailsPage(record?._id)}>
                            <p>{record?.no_of_calls_expected || "-"}</p>
                        </div>
                    </>
                );
            },
            align: "center",
            width: "5%"
        }, {
            title: <span>Profiles<br />Sent</span>,
            dataIndex: "no_of_profiles_sent",
            key: "no_of_profiles_sent",
            align: "center",
            width: "5%",
            render: (text, record) => {
                return (
                    <>
                        <div onClick={() => goToDetailsPage(record?._id)}>
                            <p>{record?.no_of_profiles_sent || "-"}</p>
                        </div>
                    </>
                );
            }
        },
        {
            title: <span>Completed<br />Calls</span>,
            dataIndex: "no_of_calls_completed",
            key: "no_of_calls_completed",
            align: "center",
            width: "5%",
            render: (text, record) => {
                return (
                    <>
                        <div onClick={() => goToDetailsPage(record?._id)}>
                            <p>{record?.no_of_calls_completed || "-"}</p>
                        </div>
                    </>
                );
            },
        },

        {
            title: "Manager",
            dataIndex: "manager",
            key: "manager",
            align: "center",
            render: (record) => (
                <span className="d-flex justify-content-center">
                    {record?.[0]?.name && (
                        <AvatarGroup
                            avatars={[record?.[0]?.name || "Nextyn"]}
                            uppercase={true}
                            initialCharacters={2}
                            fontColor="#f33636"
                            max={3}
                            displayAllOnHover={false}
                            size={30}
                            fontSize=".38"
                            backgroundColor="#EFEFEF"
                        />
                    )}
                </span>
            ),
            width: "5%"

        },
        {
            title: "Team",
            // dataIndex: "team_count",
            // key: "name",
            render: (record) => (
                <div className="d-flex justify-content-center">
                    {record?.invite_team_dashboard_name ? <AvatarGroup
                        avatars={record?.invite_team_dashboard_name
                            ?.split(",")
                            ?.filter(
                                (item) =>
                                    item.toLowerCase() !== userData?.data?.name?.toLowerCase()
                            )}
                        uppercase={true}
                        initialCharacters={2}
                        fontColor="#f33636"
                        max={3}
                        displayAllOnHover={false}
                        size={30}
                        fontSize=".38"
                        backgroundColor="#EFEFEF"
                    /> : "-"}
                </div>
            ),
            align: "center",
            width: "10%"
        },
        {
            title: "Action",
            dataIndex: "_id",
            key: "action",
            render: (_id) => (
                <>
                    <div className="d-flex justify-content-center expertActions align-items-center gap-1">
                        <Tooltip placement="top" title={"View"} color="#5746EB">
                            <span>
                                <button
                                    size="large"
                                    className="viewBttnExpert"
                                    onClick={() => goToDetailsPage(_id)}
                                >
                                    <i
                                        className="fa fa-eye"
                                        aria-hidden="true"
                                        style={{ fontSize: "12px", cursor: "pointer" }}
                                    ></i>
                                </button>
                            </span>
                        </Tooltip>
                        <Tooltip placement="top" title={"Edit"} color="#5746EB">
                            <span>
                                <button
                                    size="large"
                                    className="viewBttnExpert"
                                    onClick={() => {
                                        getTeamIdEdit(_id);
                                        handleShow();
                                    }}
                                >
                                    <MdModeEdit />
                                </button>
                            </span>
                        </Tooltip>
                        <Tooltip placement="top" title="Close Project" color="#5746EB">
                            <span>
                                <button
                                    size="large"
                                    className="viewBttnExpert"
                                    onClick={() => {
                                        getTeamIdClosed(_id);
                                        setCloseProject(true);
                                    }}
                                >
                                    <MdClose />
                                </button>
                            </span>
                        </Tooltip>
                        <Tooltip placement="top" title="Delete" color="#5746EB">
                            <button
                                size="large"
                                className="viewBttnExpert"
                                onClick={() => {
                                    setDeleteProject(true);
                                    getTeamId(_id);
                                }}
                            >
                                <MdDelete />
                            </button>
                        </Tooltip>
                    </div>
                    {/* <Dropdown overlay={menu(_id)} trigger={["click"]}>
            <MoreOutlined style={{ fontSize: "20px", cursor: "pointer" }} />
          </Dropdown> */}
                </>
            ),
            width: "10%",
            align: "center"

        },
    ];

    return (
        <>
            {/* <MobileMenu /> */}
            {/* <AdminDashboard /> */}
            <Helmet>
                <title>{"Team live projects | Nextyn Platform"}</title>
            </Helmet>
            <div className="page-wrapper chiller-theme toggled">
                <div className="main-sec d-lg-flex">
                    <div className="sidebar-menu" id="navAccordion">
                        <Sidebar />
                    </div>
                </div>
                <main className="body-total content-wrapper float-start w-100">
                    <div>
                        <div className="bk-div float-start w-100">
                            <div className="body-expart-div projects-pages">
                                <div className="d-md-flex project-sec-1 top-ps-seection justify-content-between align-items-center m-queries">
                                    <h2> My Projects </h2>
                                    <Segmented
                                        size="large"
                                        defaultValue={"On-hold Projects"}
                                        options={["Active Projects", "On-hold Projects", "Closed Projects"]}
                                        onChange={handleOptionChange}
                                    />
                                    <div>
                                        <button
                                            className="viewButton btn"
                                            onClick={() => {
                                                handleShow(1);
                                                setTeamIdEdit("");
                                            }}
                                        >
                                            Add Projects
                                        </button>
                                    </div>
                                </div>
                                <div className="tab-content" id="pills-tabContent">
                                    <Row className="mt-3  g-2">
                                        <Col md={2}>
                                            <Input
                                                size="large"
                                                placeholder="Search by project name"
                                                className="input-size"
                                                value={selectedOptions?.project_name}
                                                onChange={(e) =>
                                                    onChange(e.target.value, "project_name")
                                                }
                                                style={{ height: "40px" }}
                                                allowClear
                                            />
                                        </Col>
                                        <Col md={2}>
                                            <Input
                                                size="large"
                                                placeholder="Search by client name"
                                                className="input-size"
                                                value={selectedOptions?.client_name}
                                                onChange={(e) =>
                                                    onChange(e.target.value, "client_name")
                                                }
                                                style={{ height: "40px" }}
                                                allowClear
                                            />
                                        </Col>
                                        {userData?.data?.roleData?.[0]?.name === "Super Admin" && (
                                            <Col md={2}>
                                                <Select
                                                    style={{ width: "100%" }}
                                                    placeholder="Search by manager"
                                                    className="input-size"
                                                    size="large"
                                                    mode="multiple"
                                                    showSearch
                                                    value={selectedOptions?.manager_id || null}
                                                    onChange={(value) => onChange(value, "manager_id")}
                                                    filterOption={(input, option) =>
                                                        option?.props?.children
                                                            ?.toLowerCase()
                                                            .indexOf(input?.toLowerCase()) !== -1
                                                    }
                                                    allowClear
                                                >
                                                    {managerFilterList &&
                                                        managerFilterList?.map((option, index) => (
                                                            <Option value={option?._id} key={index}>
                                                                {option?.manager}
                                                            </Option>
                                                        ))}
                                                </Select>
                                            </Col>
                                        )}
                                        {(userData?.data?.roleData?.[0]?.name === "Super Admin" ||
                                            userData?.data?.roleData?.[0]?.name === "Manager") && (
                                                <Col md={2}>
                                                    <Select
                                                        style={{ width: "100%" }}
                                                        placeholder="Search by team member"
                                                        className="input-size"
                                                        mode="multiple"
                                                        size="large"
                                                        showSearch
                                                        value={selectedOptions?.added_by || null}
                                                        onChange={(value) => onChange(value, "added_by")}
                                                        filterOption={(input, option) =>
                                                            option?.props?.children
                                                                ?.toLowerCase()
                                                                .indexOf(input?.toLowerCase()) !== -1
                                                        }
                                                        allowClear
                                                    >
                                                        {teamMemberList &&
                                                            teamMemberList?.map((option, index) => (
                                                                <Option value={option?._id} key={index}>
                                                                    {option?.name}
                                                                </Option>
                                                            ))}
                                                    </Select>
                                                </Col>
                                            )}
                                        <Col md={3}>
                                            <RangePicker
                                                size="large"
                                                style={{ width: "100%", height: "40px" }}
                                                format="YYYY-MM-DD"
                                                className="input-size"
                                                value={
                                                    selectedOptions?.date?.[0] &&
                                                        selectedOptions?.date?.[1]
                                                        ? [
                                                            dayjs(selectedOptions?.date?.[0], "YYYY-MM-DD"),
                                                            dayjs(selectedOptions?.date?.[1], "YYYY-MM-DD"),
                                                        ]
                                                        : []
                                                }
                                                onChange={(date, dateString) =>
                                                    onChange(dateString, "date")
                                                }
                                                allowClear
                                            />
                                        </Col>
                                        <Col md={1}>
                                            <button
                                                className="viewButton btn"
                                                onClick={() => {
                                                    GetLiveProjectList(currentPage, selectedOptions);
                                                }}
                                            >
                                                Search
                                            </button>
                                        </Col>
                                    </Row>

                                    {loading ?
                                        <Row className="mt-3 pt-3">
                                            <Col className="d-flex justify-content-center align-items-center h-40">
                                                <Spin />
                                            </Col>
                                        </Row>
                                        : <Row className="mt-3">
                                            <Col id="table-custom">
                                                <Table
                                                    dataSource={filteredData}
                                                    columns={columns}
                                                    // loading={loading}
                                                    size="large"
                                                    pagination={false}
                                                // scroll={{ x: 800 }}
                                                />
                                                <Pagination
                                                    {...paginationSettings}
                                                    showSizeChanger={false}
                                                    style={{
                                                        marginTop: 16,
                                                        marginBottom: 16,
                                                        textAlign: "right",
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>

            <AddProjectModal
                show={show}
                handleClose={handleClose}
                teamIdEdit={teamIdEdit}
                managerList={managerList}
                selectedOptions={selectedOptions}
                currentPage={currentPage}
                GetLiveProjectList={GetLiveProjectList}
            />

            <DeleteModal
                show={handleDeleteProject}
                setShow={setDeleteProject}
                type={"Project"}
                teamId={teamId}
                GetLiveProjectList={GetLiveProjectList}
            />

            <CloseProject
                show={handleCloseProject}
                setShow={setCloseProject}
                status={modalStatus}
                teamIdClosed={teamIdClosed}
                GetLiveProjectList={GetLiveProjectList}
                updatedStatus={2}
            />
        </>
    );
}

export default HoldProjectList;
