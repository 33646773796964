import { Select, Spin } from 'antd';
import React, { useMemo } from 'react'
import debounce from 'lodash/debounce'
import { useSelector } from 'react-redux';
import useCreateMeeting from '../hooks/useCreateMeeting';

function DebounceSelect({ type, optionType = "name", debounceTimeout = 800, ...props }) {
    const { fetching } = useSelector((state) => state.internalmeeting)
    const { experts, managers, clients, fetchExperts, fetchClients, fetchManagers, fetchCompanies, companyList } = useCreateMeeting()

    const optionsDatas = useMemo(() => {
        return experts?.map((itemData) => {
            const dataName = itemData?.name
                ? itemData?.name
                : `${itemData?.first_name} ${itemData?.last_name}`;
            return { value: itemData?._id, label: dataName };
        })
    }, [experts]);

    const optionsDatasEmail = useMemo(() => {
        return experts?.map((itemData) => {
            const dataEmail = itemData?.email
            return { key: itemData?._id, value: dataEmail, label: dataEmail, title: itemData?.name };
        })
    }, [experts]);

    const optionsDatasClient = useMemo(() => {
        return clients?.map((itemData) => {
            const dataName = itemData?.name
            return { value: itemData?._id, label: dataName };
        })
    }, [clients]);

    const optionsDatasClientEmail = useMemo(() => {
        return clients?.map((itemData) => {
            const dataEmail = itemData?.email
            return {
                key: itemData?._id, value: dataEmail, label: dataEmail, title: itemData?.name
            };
        })
    }, [clients]);


    const optionsDatasManager = useMemo(() => {
        return managers?.map((itemData) => {
            const dataName = itemData?.name
            return { value: itemData?._id, label: dataName };
        })
    }, [managers]);
    const optionsDatasManagerEmail = useMemo(() => {
        return managers?.map((itemData) => {
            const dataEmail = itemData?.email
            const dataName = itemData?.name
            return { key: itemData?._id, value: dataEmail, label: dataName };
        })
    }, [managers]);

    const optionsDatasCompanies = useMemo(() => {
        return companyList?.map((itemData) => {
            const dataName = itemData?.company_name
            return { value: itemData?._id, label: dataName };
        })
    }, [companyList]);

    const debounceFetcher = useMemo(() => {
        let loadOptions = []
        if (type === "expert") {
            loadOptions = (value) => {
                fetchExperts({ is_filtering: true, value: value })
            };
        } if (type === "client") {
            loadOptions = (value) => {
                fetchClients({ is_filtering: true, value: value })
            };
        } else if (type === "manager") {
            loadOptions = (value) => {
                fetchManagers({ is_filtering: true, value: value })
            };
        }
        else if (type === "company") {
            loadOptions = (value) => {
                fetchCompanies({ is_filtering: true, value: value })
            };
        }

        return debounce(loadOptions, debounceTimeout);
    }, [type, debounceTimeout, fetchExperts, fetchClients, fetchManagers, fetchCompanies]);

    if (type === "expert") {
        return (
            <Select
                showSearch
                labelInValue
                allowClear
                filterOption={false}
                onDropdownVisibleChange={(open) => open && fetchExperts({ is_filtering: false, value: "" })}
                onSearch={debounceFetcher}
                notFoundContent={fetching ? <Spin size="small" /> : null}
                {...props}
                options={optionType === "email" ? optionsDatasEmail : optionsDatas}
            />
        )
    } if (type === "client") {
        return (
            <Select
                showSearch
                labelInValue
                allowClear
                filterOption={false}
                onDropdownVisibleChange={(open) => open && fetchClients({ is_filtering: false, value: "" })}
                onSearch={debounceFetcher}
                notFoundContent={fetching ? <Spin size="small" /> : null}
                {...props}
                options={optionType === "email" ? optionsDatasClientEmail : optionsDatasClient}
            />
        )
    } else if (type === "manager") {
        return (
            <Select
                showSearch
                labelInValue
                filterOption={false}
                onDropdownVisibleChange={(open) => open && fetchManagers({ is_filtering: false, value: "" })}
                onSearch={debounceFetcher}
                notFoundContent={fetching ? <Spin size="small" /> : null}
                {...props}
                options={optionType === "email" ? optionsDatasManagerEmail : optionsDatasManager}
            />
        )

    } else if (type === "company") {
        return (
            <Select
                showSearch
                labelInValue
                filterOption={false}
                onDropdownVisibleChange={(open) => open && fetchCompanies({ is_filtering: false, value: "" })}
                onSearch={debounceFetcher}
                notFoundContent={fetching ? <Spin size="small" /> : null}
                {...props}
                options={optionsDatasCompanies}
            />
        )

    }


}

export default DebounceSelect