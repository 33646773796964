import React, { useEffect } from "react";
import Sidebar from "../../Dashboard/Sidebar/Sidebar";
import ExpertAuth from "../../components/ExpertAuth";

import ChangePasswordForm from "./ChangePasswordForm";
import { Col, Row } from "react-bootstrap";

const ChangePassword = () => {
  useEffect(() => {
    document.body.style.overflow = "";
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  return (
    <>
      <ExpertAuth />
      <div className="page-wrapper chiller-theme toggled">
        <div className="main-sec d-lg-flex">
          <Sidebar />
        </div>
        <main className="body-total content-wrapper float-start w-100">
          <div className="container-fluid">
            {/* <div className="d-block d-lg-block">
              <div className="top-bar-menu float-start" id="header">
                <TopMenu2 />
              </div>
            </div> */}

            <div className="dasb-div bk-div float-start w-100">
              <div className="body-expart-div projects-pages support-page">
                <div className="inside-support">
                  <Row className="justify-content-center">
                    <Col md={6}>
                      <h2> Change Password</h2>
                    </Col>
                  </Row>
                </div>
                <div className="faq-section mt-4">
                  <ChangePasswordForm />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default ChangePassword;
