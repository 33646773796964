import React, { useState } from 'react';
import { Divider, Modal } from 'antd';
const ConfirmationModal = ({ message, title, open, setOpen, okFunction, cancelFunction }) => {
    const [confirmLoading, setConfirmLoading] = useState(false);

    const handleOk = () => {
        setConfirmLoading(true);
        setTimeout(() => {
            okFunction()
            setConfirmLoading(false);
        }, 1000);
    };
    const handleCancel = () => {
        cancelFunction()
        setOpen(false);
    };
    return (
        <>
            <Modal
                centered
                title={title}
                open={open}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                okText={"Yes"}
            >
                {/* <Divider style={{ marginTop: "0px" }} /> */}
                <p>{message}</p>
                {/* <Divider style={{ marginBottom: "0px" }} /> */}
            </Modal>
        </>
    );
};
export default ConfirmationModal;