import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import Helmet from "react-helmet";
import Sidebar from "../../components/Sidebar";
import ConsultingTable from "./ConsultingTable";

const ExotelSection = () => {

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 100);
  }, []);

  return (
    <>
      {/* <MobileMenu /> */}
      <Helmet>
        <title>{"Nextyn | Managers"}</title>

        {/* <script src="//in.fw-cdn.com/30634560/343565.js" chat="true"></script> */}
      </Helmet>
      <div className="page-wrapper chiller-theme toggled">
        <div className="main-sec d-lg-flex">
          <Sidebar />
        </div>
        <main className="body-total content-wrapper float-start w-100">
          <div className="container-fluid">
            <div className="d-block d-lg-block">
              {open ? (
                <ConsultingTable />
              ) : (
                <>
                  <div
                    className="d-flex justify-content-center"
                    style={{ marginTop: "20px" }}
                  >
                    <div role="status">
                      <Spinner animation="border" />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default ExotelSection;
