import { SearchOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Select,
  Space,
  Table,
  Pagination,
  notification,
  Divider,
  Tooltip,
  Tag,
} from "antd";
import React, { useState, useEffect, useRef, useCallback } from "react";
import "react-h5-audio-player/lib/styles.css";
import "../../App.css";
import "../../assests/css/custom.css";
import API_PATH from "../../Constants/api-path";
import { MdEdit, MdOutlineClose } from "react-icons/md";
import {
  ExclamationCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { MdDelete, MdBorderColor } from "react-icons/md";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import UpdateZoomMeetingModal from "./UpdateZoomMeetingModal";
import dayjs from "dayjs";
import axios from "axios";
import moment from "moment";

const { RangePicker } = DatePicker;

const countryOptions = [
  { code: "+1", name: "United States" },
  { code: "+44", name: "United Kingdom" },
  { code: "+61", name: "Australia" },
  { code: "+33", name: "France" },
  { code: "+49", name: "Germany" },
  { code: "+81", name: "Japan" },
  { code: "+91", name: "India" },
  { code: "+86", name: "China" },
  { code: "+55", name: "Brazil" },
  { code: "+52", name: "Mexico" },
  { code: "+39", name: "Italy" },
  { code: "+7", name: "Russia" },
  { code: "+82", name: "South Korea" },
  { code: "+351", name: "Portugal" },
  { code: "+34", name: "Spain" },
  { code: "+54", name: "Argentina" },
  { code: "+63", name: "Philippines" },
  { code: "+234", name: "Nigeria" },
  { code: "+62", name: "Indonesia" },
  { code: "+55", name: "Anguilla" },
  { code: "+1264", name: "Antigua and Barbuda" },
  { code: "+54", name: "Argentina" },
  { code: "+374", name: "Armenia" },
  { code: "+297", name: "Aruba" },
  { code: "+61", name: "Australia" },
  { code: "+43", name: "Austria" },
  { code: "+994", name: "Azerbaijan" },
  { code: "+1242", name: "Bahamas" },
  { code: "+973", name: "Bahrain" },
  { code: "+880", name: "Bangladesh" },
  { code: "+1246", name: "Barbados" },
  { code: "+375", name: "Belarus" },
  { code: "+32", name: "Belgium" },
  { code: "+501", name: "Belize" },
  { code: "+229", name: "Benin" },
  { code: "+1441", name: "Bermuda" },
  { code: "+975", name: "Bhutan" },
  { code: "+591", name: "Bolivia" },
  { code: "+387", name: "Bosnia and Herzegovina" },
  { code: "+267", name: "Botswana" },
  { code: "+55", name: "Brazil" },
  { code: "+246", name: "British Indian Ocean Territory" },
  { code: "+1284", name: "British Virgin Islands" },
  { code: "+673", name: "Brunei" },
  { code: "+359", name: "Bulgaria" },
  { code: "+226", name: "Burkina Faso" },
  { code: "+257", name: "Burundi" },
  { code: "+855", name: "Cambodia" },
  { code: "+237", name: "Cameroon" },
  { code: "+1", name: "Canada" },
  { code: "+238", name: "Cape Verde" },
  { code: "+599", name: "Caribbean Netherlands" },
  { code: "+1345", name: "Cayman Islands" },
  { code: "+236", name: "Central African Republic" },
  { code: "+235", name: "Chad" },
  { code: "+56", name: "Chile" },
  { code: "+86", name: "China" },
  { code: "+57", name: "Colombia" },
  { code: "+269", name: "Comoros" },
  { code: "+242", name: "Congo - Brazzaville" },
  { code: "+243", name: "Congo - Kinshasa" },
  { code: "+682", name: "Cook Islands" },
  { code: "+506", name: "Costa Rica" },
  { code: "+225", name: "Côte d’Ivoire" },
  { code: "+385", name: "Croatia" },
  { code: "+53", name: "Cuba" },
  { code: "+599", name: "Curaçao" },
  { code: "+357", name: "Cyprus" },
  { code: "+420", name: "Czech Republic" },
  { code: "+45", name: "Denmark" },
  { code: "+253", name: "Djibouti" },
  { code: "+1767", name: "Dominica" },
  { code: "+1809", name: "Dominican Republic" },
  { code: "+670", name: "East Timor" },
  { code: "+593", name: "Ecuador" },
  { code: "+20", name: "Egypt" },
  { code: "+503", name: "El Salvador" },
  { code: "+240", name: "Equatorial Guinea" },
  { code: "+291", name: "Eritrea" },
  { code: "+372", name: "Estonia" },
  { code: "+251", name: "Ethiopia" },
  { code: "+500", name: "Falkland Islands" },
  { code: "+298", name: "Faroe Islands" },
  { code: "+679", name: "Fiji" },
  { code: "+358", name: "Finland" },
  { code: "+33", name: "France" },
  { code: "+594", name: "French Guiana" },
  { code: "+689", name: "French Polynesia" },
  { code: "+241", name: "Gabon" },
  { code: "+220", name: "Gambia" },
  { code: "+995", name: "Georgia" },
  { code: "+49", name: "Germany" },
  { code: "+233", name: "Ghana" },
  { code: "+350", name: "Gibraltar" },
  { code: "+30", name: "Greece" },
  { code: "+299", name: "Greenland" },
  { code: "+1473", name: "Grenada" },
  { code: "+590", name: "Guadeloupe" },
  { code: "+1671", name: "Guam" },
  { code: "+502", name: "Guatemala" },
  { code: "+44", name: "Guernsey" },
  { code: "+224", name: "Guinea" },
  { code: "+245", name: "Guinea-Bissau" },
  { code: "+592", name: "Guyana" },
  { code: "+509", name: "Haiti" },
  { code: "+504", name: "Honduras" },
  { code: "+852", name: "Hong Kong SAR China" },
  { code: "+36", name: "Hungary" },
  { code: "+354", name: "Iceland" },
  { code: "+91", name: "India" },
  { code: "+62", name: "Indonesia" },
  { code: "+98", name: "Iran" },
  { code: "+964", name: "Iraq" },
  { code: "+353", name: "Ireland" },
  { code: "+44", name: "Isle of Man" },
  { code: "+972", name: "Israel" },
  { code: "+39", name: "Italy" },
  { code: "+1876", name: "Jamaica" },
  { code: "+81", name: "Japan" },
  { code: "+44", name: "Jersey" },
  { code: "+962", name: "Jordan" },
  { code: "+7", name: "Kazakhstan" },
  { code: "+254", name: "Kenya" },
  { code: "+686", name: "Kiribati" },
  { code: "+383", name: "Kosovo" },
  { code: "+965", name: "Kuwait" },
  { code: "+996", name: "Kyrgyzstan" },
  { code: "+856", name: "Laos" },
  { code: "+371", name: "Latvia" },
  { code: "+961", name: "Lebanon" },
  { code: "+266", name: "Lesotho" },
  { code: "+231", name: "Liberia" },
  { code: "+218", name: "Libya" },
  { code: "+423", name: "Liechtenstein" },
  { code: "+370", name: "Lithuania" },
  { code: "+352", name: "Luxembourg" },
  { code: "+853", name: "Macau SAR China" },
  { code: "+389", name: "Macedonia" },
  { code: "+261", name: "Madagascar" },
  { code: "+265", name: "Malawi" },
  { code: "+60", name: "Malaysia" },
  { code: "+960", name: "Maldives" },
  { code: "+223", name: "Mali" },
  { code: "+356", name: "Malta" },
  { code: "+692", name: "Marshall Islands" },
  { code: "+596", name: "Martinique" },
  { code: "+222", name: "Mauritania" },
  { code: "+230", name: "Mauritius" },
  { code: "+262", name: "Mayotte" },
  { code: "+52", name: "Mexico" },
  { code: "+691", name: "Micronesia" },
  { code: "+373", name: "Moldova" },
  { code: "+377", name: "Monaco" },
  { code: "+976", name: "Mongolia" },
  { code: "+382", name: "Montenegro" },
  { code: "+1664", name: "Montserrat" },
  { code: "+212", name: "Morocco" },
  { code: "+258", name: "Mozambique" },
  { code: "+95", name: "Myanmar (Burma)" },
  { code: "+264", name: "Namibia" },
  { code: "+674", name: "Nauru" },
  { code: "+977", name: "Nepal" },
  { code: "+31", name: "Netherlands" },
  { code: "+687", name: "New Caledonia" },
  { code: "+64", name: "New Zealand" },
  { code: "+505", name: "Nicaragua" },
  { code: "+227", name: "Niger" },
  { code: "+234", name: "Nigeria" },
  { code: "+683", name: "Niue" },
  { code: "+672", name: "Norfolk Island" },
  { code: "+850", name: "North Korea" },
  { code: "+1670", name: "Northern Mariana Islands" },
  { code: "+47", name: "Norway" },
  { code: "+968", name: "Oman" },
  { code: "+92", name: "Pakistan" },
  { code: "+680", name: "Palau" },
  { code: "+970", name: "Palestinian Territories" },
  { code: "+507", name: "Panama" },
  { code: "+675", name: "Papua New Guinea" },
  { code: "+595", name: "Paraguay" },
  { code: "+51", name: "Peru" },
  { code: "+63", name: "Philippines" },
  { code: "+48", name: "Poland" },
  { code: "+351", name: "Portugal" },
  { code: "+1", name: "Puerto Rico" },
  { code: "+974", name: "Qatar" },
  { code: "+262", name: "Réunion" },
  { code: "+40", name: "Romania" },
  { code: "+7", name: "Russia" },
  { code: "+250", name: "Rwanda" },
  { code: "+590", name: "Saint Barthélemy" },
  { code: "+290", name: "Saint Helena" },
  { code: "+1869", name: "Saint Kitts and Nevis" },
  { code: "+1758", name: "Saint Lucia" },
  { code: "+590", name: "Saint Martin" },
  { code: "+508", name: "Saint Pierre and Miquelon" },
  { code: "+1784", name: "Saint Vincent and the Grenadines" },
  { code: "+685", name: "Samoa" },
  { code: "+378", name: "San Marino" },
  { code: "+239", name: "São Tomé and Príncipe" },
  { code: "+966", name: "Saudi Arabia" },
  { code: "+221", name: "Senegal" },
  { code: "+381", name: "Serbia" },
  { code: "+248", name: "Seychelles" },
  { code: "+232", name: "Sierra Leone" },
  { code: "+65", name: "Singapore" },
  { code: "+1721", name: "Sint Maarten" },
  { code: "+421", name: "Slovakia" },
  { code: "+386", name: "Slovenia" },
  { code: "+677", name: "Solomon Islands" },
  { code: "+252", name: "Somalia" },
  { code: "+27", name: "South Africa" },
  { code: "+82", name: "South Korea" },
  { code: "+211", name: "South Sudan" },
  { code: "+34", name: "Spain" },
  { code: "+94", name: "Sri Lanka" },
  { code: "+249", name: "Sudan" },
  { code: "+597", name: "Suriname" },
  { code: "+47", name: "Svalbard and Jan Mayen" },
  { code: "+268", name: "Swaziland" },
  { code: "+46", name: "Sweden" },
  { code: "+41", name: "Switzerland" },
  { code: "+963", name: "Syria" },
  { code: "+886", name: "Taiwan" },
  { code: "+992", name: "Tajikistan" },
  { code: "+255", name: "Tanzania" },
  { code: "+66", name: "Thailand" },
  { code: "+670", name: "Timor-Leste" },
  { code: "+228", name: "Togo" },
  { code: "+690", name: "Tokelau" },
  { code: "+676", name: "Tonga" },
  { code: "+1868", name: "Trinidad and Tobago" },
  { code: "+216", name: "Tunisia" },
  { code: "+90", name: "Turkey" },
  { code: "+993", name: "Turkmenistan" },
  { code: "+1649", name: "Turks and Caicos Islands" },
  { code: "+688", name: "Tuvalu" },
  { code: "+1340", name: "U.S. Virgin Islands" },
  { code: "+256", name: "Uganda" },
  { code: "+380", name: "Ukraine" },
  { code: "+971", name: "United Arab Emirates" },
  { code: "+44", name: "United Kingdom" },
  { code: "+1", name: "United States" },
  { code: "+598", name: "Uruguay" },
  { code: "+998", name: "Uzbekistan" },
  { code: "+678", name: "Vanuatu" },
  { code: "+39", name: "Vatican City" },
  { code: "+58", name: "Venezuela" },
  { code: "+84", name: "Vietnam" },
  { code: "+681", name: "Wallis and Futuna" },
  { code: "+212", name: "Western Sahara" },
  { code: "+967", name: "Yemen" },
  { code: "+260", name: "Zambia" },
  { code: "+263", name: "Zimbabwe" },
];

const ExotelTable = () => {
  let history = useNavigate();
  const [form] = Form.useForm();
  const [displayExpert, setDisplayExpert] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [showCreateModal, setCreateModal] = useState(false);
  const [editZoomModal, setEditZoomModal] = useState(false);

  const [zoomId, setZoomId] = useState("");
  const [zoomList, setZoomList] = useState([]);
  const [zoomAccountList, setZoomAccountList] = useState([]);
  const [zoomCompanyList, setZoomCompanyList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [viewId, setViewId] = useState("");
  const [roleList, setRoleList] = useState([]);
  const [phone, setPhone] = useState("");
  const [companyOption, setFilteredCompany] = useState("");

  const [selectedOptions, setSelectedOptions] = useState({
    key: "",
    sortType: "",
    sortBy: "",
  });
  const [zoomData, setZoomData] = useState({
    zoomId: "",
    meetingId: "",
    startDate: "",
    passcode: "",
    startTime: "",
    endDate: "",
    endTime: "",
    comapnyName: "",
    client: "",
    client_user: [],
    client_user_id: [],
    client_id: viewId,
    zoommeeting_id: "",
    expertCompany: "",
    expertDesignation: "",
  });

  const [clienData, setClienData] = useState({
    name: "",
    email: "",
    comapnyName: "",
    contact_number: "",
    country: "",
    contact_code: "",
    role_id: "",
    registrationType: "",
  });
  const [callPage, setCallPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const callOnce = useRef(false);

  const [clientView, setClientView] = useState([]);

  const GetZoomList = useCallback(
    async ({
      page = currentPage,
      key = selectedOptions.key,
      sortBy = selectedOptions.sortBy,
      sortType = selectedOptions.sortType,
    }) => {
      setLoading(true);
      try {
        let object = {};
        if (key) {
          object.key = key;
        }
        const queryParams = new URLSearchParams(object); // Construct query parameters from the object properties

        const res = await fetch(
          `${
            API_PATH.ZOOM_LIST
          }?page=${page}&limit=10&sortBy=${sortBy}&sortType=${sortType}${
            queryParams?.toString() !== "" ? "&" + queryParams?.toString() : ""
          }`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userData?.token}`,
            },
          }
        );

        const result = await res.json();
        if (result) {
          setZoomList(result?.data);
          setCallPage(result?.countData);
        } else {
          notification.error({
            message: "Error: Something went wrong server error",
            icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
            style: {
              backgroundColor: "#e74c3c",
              color: "#fff",
              border: "1px solid #c0392b",
            },
            duration: 5,
            placement: "topRight",
          });
        }
      } catch (error) {
        setLoading(false);

        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
      setLoading(false);
    },
    [
      currentPage,
      selectedOptions.key,
      selectedOptions.sortBy,
      selectedOptions.sortType,
      userData?.token,
    ]
  );
  const handlePageChange = useCallback(
    async (pageNumber) => {
      setCurrentPage(pageNumber);
      await GetZoomList({ page: pageNumber });
    },
    [GetZoomList]
  );

  const paginationSettings = {
    current: currentPage,
    onChange: handlePageChange,
    pageSize: 10,
    total: callPage,
  };
  // useEffect(() => {
  //   const filteredData = zoomList?.filter((record) =>
  //     Object.values(record)
  //       .join("")
  //       .toLowerCase()
  //       .includes(searchText.toLowerCase())
  //   );
  //   setFilteredClientList(filteredData);
  //   setPagination({
  //     ...pagination,
  //     current: 1,
  //     total: filteredData.length,
  //   });
  // }, [zoomList, searchText, pagination]);

  const GetZoomAccountList = useCallback(async () => {
    setLoading(true);
    try {
      const res = await fetch(`${API_PATH.ZOOM_ACCOUNT_LIST}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
      });

      const result = await res.json();
      if (result) {
        setZoomAccountList(result?.data);
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  }, [userData?.token]);

  const GetZoomCompanyList = useCallback(async () => {
    setLoading(true);

    let object = {};

    if (companyOption) {
      object.key = `${companyOption}`;
    }

    const queryParams = new URLSearchParams(object);

    try {
      const res = await fetch(
        // `${API_PATH.CLIENT_LIST}?page=1&limit=10&${queryParams.toString()}`,
        `${API_PATH.CLIENT_LIST}?${queryParams.toString()}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          },
        }
      );

      const result = await res.json();
      if (result) {
        setZoomCompanyList(result?.data);
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  }, [companyOption, userData?.token]);

  const GetRoleList = useCallback(async () => {
    try {
      const res = await fetch(`${API_PATH.CLIENT_ROLE}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
      });

      const result = await res.json();
      if (result) {
        setRoleList(result?.data);
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  }, [userData?.token]);

  const constructUrl = useCallback(
    (options) => {
      const { key } = options;

      const projectParam = Array.isArray(key) ? key.join(", ") : key;
      const sanitizedProject = projectParam
        ? projectParam.replace(/%20/g, " ")
        : "";

      const urlParams = [`key=${encodeURIComponent(sanitizedProject)}`]; // Remove quotes and properly encode

      return history({
        pathname: `/dashboard/zoom-meetings`,
        search: `?${urlParams.join("&")}`,
      });
    },
    [history]
  );

  useEffect(() => {
    if (!callOnce.current) {
      callOnce.current = true;
      GetZoomList(currentPage, selectedOptions);
      GetZoomAccountList();
      GetZoomCompanyList();
      GetRoleList();
      constructUrl(selectedOptions);
      // console.log(url); // Output the constructed UR
    }
  }, [
    currentPage,
    selectedOptions,
    companyOption,
    GetZoomList,
    GetZoomAccountList,
    GetZoomCompanyList,
    GetRoleList,
    constructUrl,
  ]);

  const handleNameChange = useCallback(
    async (value, option) => {
      const selectedCompanyId = option?.key;
      const selectedDomain = option?.domain;
      // console.log(option, "selectedCompanyId");
      setViewId(selectedCompanyId);

      zoomData.client_id = selectedCompanyId;
      zoomData.comapnyName = selectedDomain;

      const selectValue = value;
      setZoomData({ ...zoomData, client: selectValue });

      setFilteredCompany(value || "");
      if (!value) {
        GetZoomCompanyList();
      }

      try {
        const res = await fetch(
          `${API_PATH.CLIENT_VIEW}/${selectedCompanyId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userData?.token}`,
            },
          }
        );

        const result = await res.json();
        if (result) {
          // console.log("setClientView", result);/
          setClientView(result?.data);
        } else {
          // Handle error
        }
      } catch (error) {
        // Handle error
      }
    },
    [GetZoomCompanyList, userData?.token, zoomData]
  );

  const AddZoomMeeting = useCallback(async () => {
    // e.preventDefault();
    setLoading(true);
    try {
      const meetingDetails = await axios.post(
        API_PATH.FETCH_MEETING_FROM_ZOOM,
        {
          zoomId: zoomData?.zoomId,
          meetingId: zoomData?.meetingId?.replace(/\s/g, ""),
        }
      );
      const details = meetingDetails?.data?.data;
      const formattedZoomData = {
        ...zoomData,
        meetingId: zoomData?.meetingId?.replace(/\s/g, ""),
        passcode: details?.password,
        expert_designation: zoomData?.expertDesignation,
        expert_company: zoomData?.expertCompany,
        startDate: moment(details?.start_time).format("YYYY-MM-DD"),
        startTime: moment(details?.start_time).format("HH:mm"),
        endDate: moment(details?.start_time)
          .add(details?.duration, "minutes")
          .format("YYYY-MM-DD"),
        endTime: moment(details?.start_time)
          .add(details?.duration, "minutes")
          .format("HH:mm"),
        topic: details?.topic,
      };
      const res = await fetch(`${API_PATH.ZOOM_ADD_MEETING}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
        body: JSON.stringify(formattedZoomData),
      });

      const result = await res.json();
      if (result) {
        GetZoomList({ page: 1, key: "" });
        setSelectedOptions({
          key: "",
          sortBy: "",
          sortType: "",
        });
        setCurrentPage(1);
        if (res.status === 200 || res.status === true) {
          form.resetFields();
          form.setFieldsValue({
            dateAndTime: [],
          });
          setZoomData({
            zoomId: "",
            meetingId: "",
            startDate: "",
            passcode: "",
            startTime: "",
            endDate: "",
            endTime: "",
            comapnyName: "",
            client: "",
            client_user: [],
            client_user_id: [],
            client_id: viewId,
            zoommeeting_id: "",
            expertCompany: "",
            expertDesignation: "",
          });
          setDisplayExpert(!displayExpert);
          notification.success({
            message: "Zoom meeting created",
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#2ecc71",
              color: "#fff !important",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          });

          // window.location.reload();
        }
      } else {
        setDisplayExpert(!displayExpert);
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);
      setDisplayExpert(!displayExpert);
      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  }, [GetZoomList, displayExpert, form, userData?.token, viewId, zoomData]);

  const AddClientData = useCallback(async () => {
    setLoading(true);
    try {
      const res = await fetch(`${API_PATH.CLIENT_CREATE}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
        body: JSON.stringify(clienData),
      });

      const result = await res.json();
      if (result) {
        if (result.status === 200 || result.status === true) {
          GetZoomCompanyList();
          handleNameChange();
          notification.success({
            message: "Client created",
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#2ecc71",
              color: "#fff !important",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          });
        } else if (
          result.status === false &&
          result.message === "Email is already exists."
        ) {
          notification.error({
            message: "Email is already exists.",
            icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
            style: {
              backgroundColor: "#e74c3c",
              color: "#fff",
              border: "1px solid #c0392b",
            },
            duration: 5,
            placement: "topRight",
          });
        } else {
          notification.error({
            message: "Error: Something went wrong server error",
            icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
            style: {
              backgroundColor: "#e74c3c",
              color: "#fff",
              border: "1px solid #c0392b",
            },
            duration: 5,
            placement: "topRight",
          });
        }
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  }, [GetZoomCompanyList, clienData, handleNameChange, userData?.token]);

  const DeleteZoomMeeting = useCallback(async () => {
    // e.preventDefault();
    setLoading(true);

    try {
      const res = await fetch(`${API_PATH.ZOOM_DELETE_MEETING}/${zoomId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
      });

      const result = await res.json();
      if (result) {
        GetZoomList({ page: 1, key: "" });
        setCurrentPage(1);
        if (res.status === 200 || res.status === true) {
          // console.log(res.status);

          notification.success({
            message: "Successful deleted",
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#2ecc71",
              color: "#fff !important",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          });

          // window.location.reload();
        }
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  }, [GetZoomList, userData?.token, zoomId]);

  const handleInputs = useCallback(
    (e) => {
      const name = e.target.name;
      const value = e.target.value;

      setZoomData({ ...zoomData, [name]: value });
    },
    [zoomData]
  );

  const handleInputsClient = useCallback(
    (e) => {
      const name = e.target.name;
      const value = e.target.value;

      setClienData({ ...clienData, [name]: value });
    },
    [clienData]
  );

  const handleChange = useCallback(
    (value) => {
      const selectValue = value;
      setZoomData({ ...zoomData, zoomId: selectValue });
    },
    [zoomData]
  );

  const handleNameChangeClient = useCallback((values, options) => {
    const updatedClientUser = values;
    const updatedClientUserId = options
      ?.map((option) => option?.key)
      ?.filter(Boolean);

    setZoomData((prevData) => ({
      ...prevData,
      client_user: updatedClientUser,
      client_user_id: updatedClientUserId,
    }));
  }, []);

  const getZoomId = useCallback((_id) => {
    setZoomId(_id);
    // console.log(deleteId);
  }, []);

  // const dataSource = filteredClientList?.map((data, index) => ({
  const dataSource = zoomList?.map((data, index) => ({
    meetingId: data?.meetingId,
    comapnyName: data?.client,
    startDate:
      data.startDate && data.startTime ? (
        <>
          <div>{moment(data?.startDate)?.format("DD MMM YYYY")}</div>
          <div className="text-muted" style={{ fontSize: "12px" }}>
            {data?.startTime} - {data?.endTime}
          </div>
        </>
      ) : (
        "-"
      ),

    assembly_response_status: (
      <>
        {console.log("data?.assembly_response_status", data?.transcriptStatus)}
        <Tooltip
          color="#5746EB"
          title={`${
            !data?.transcriptStatus
              ? "Waiting for recording"
              : !data?.assembly_response_status && data?.transcriptStatus
              ? "Transcript is in process"
              : "Transcript Generated"
          }`}
        >
          <Tag
            color={
              !data?.transcriptStatus
                ? "red"
                : !data?.assembly_response_status && data?.transcriptStatus
                ? "yellow"
                : "green"
            }
          >
            {!data?.transcriptStatus
              ? "W"
              : !data?.assembly_response_status && data?.transcriptStatus
              ? "P"
              : "S"}
          </Tag>
        </Tooltip>
        <Tooltip
          color="#5746EB"
          title={`${
            data?.transcriptStatus
              ? "Recording Downloaded"
              : "Waiting for Recording"
          }`}
        >
          <Tag color={data?.transcriptStatus ? "green" : "red"}>
            {data?.transcriptStatus ? "S" : "W"}
          </Tag>
        </Tooltip>
      </>
    ),
    _id: data?._id,
  }));
  const setUserModal = useCallback(({ data, id }) => {
    const res = data?.find((val) => val._id === id);
    setZoomData({
      zoomId: res?.zoomId,
      meetingId: res?.meetingId,
      startDate: res?.startDate,
      passcode: res?.passcode,
      startTime: res?.startTime,
      endDate: res?.endDate,
      endTime: res?.endTime,
      comapnyName: res?.comapnyName,
      expertCompany: res?.expert_company,
      expertDesignation: res?.expert_designation,
      client: res?.client,
      client_user: res?.client_user,
      client_user_id: res?.client_user_id,
      client_id: res?.client_id,
      topic: res?.topic,
      dateAndTime: [
        dayjs(`${res?.startDate} ${res?.startTime}`, "YYYY-MM-DD HH:mm"),
        dayjs(`${res?.endDate} ${res?.endTime}`, "YYYY-MM-DD HH:mm"),
      ],
      zoommeeting_id: res?._id,
      // client_id: viewId,
    });
    setViewId(res?.client_id);
    setFilteredCompany(res?.client || "");
    // setUpdateMangerId(id);
  }, []);

  const columns = [
    {
      title: "S.No",
      render: (text, record, rowIndex) => rowIndex + 1,
      width: "50px",
    },
    {
      title: "Meeting Id",
      dataIndex: "meetingId",
      key: "meetingId",
      width: "140px",
    },
    {
      title: "Client Name",
      dataIndex: "comapnyName",
      key: "comapnyName",
      sorter: true,
      width: "auto",
    },
    {
      title: "Date/Time",
      dataIndex: "startDate", // ["startDate", "startTime"],
      key: "sname",
      sorter: true,
      width: "140px",
    },
    {
      title: "Transcript",
      dataIndex: "assembly_response_status",
      key: "assembly_response_status",
      // render: (record) => {
      //   const color =
      //     record === "Success"
      //       ? "green"
      //       : record === "Processing"
      //       ? "yellow"
      //       : "red";
      //   return <Tag color={color}>{record}</Tag>;
      // },
      width: "100px",
    },
    // {
    //   title: "Recording",
    //   dataIndex: "transcriptStatus",
    //   key: "transcriptStatus",
    //   render: (record) => {
    //     const color =
    //       record === "Success"
    //         ? "green"
    //         : record === "Processing"
    //         ? "yellow"
    //         : "red";
    //     return <Tag color={color}>{record}</Tag>;
    //   },
    //   width: "100px",
    // },
    {
      title: "Action",
      dataIndex: "_id",
      key: "add",
      render: (_id) => (
        <div className="d-flex expertActions align-items-center gap-1">
          <Tooltip placement="top" title={"Edit"} color="#5746EB">
            <span>
              <button
                size="large"
                className="viewBttnExpert"
                onClick={() => {
                  setEditZoomModal(true);
                  setUserModal({ data: zoomList, id: _id });
                }}
              >
                <MdEdit />
              </button>
            </span>
          </Tooltip>

          <Tooltip placement="top" title="Delete" color="#5746EB">
            <button
              size="large"
              className="viewBttnExpert"
              onClick={() => {
                setDeleteModal(true);
                getZoomId(_id);
              }}
            >
              <MdDelete />
            </button>
          </Tooltip>
        </div>
        // <Space size="middle">
        //   <Tooltip color="#5746EB" title="Edit" placement="top">
        //     <MdBorderColor
        //       onClick={() => {
        //         setEditZoomModal(true);
        //         setUserModal({ data: zoomList, id: _id });
        //       }}
        //       style={{ fontSize: "18px", cursor: "pointer" }}
        //     ></MdBorderColor>
        //   </Tooltip>
        //   <Tooltip color="#5746EB" title="Delete" placement="top">
        //     <MdDelete
        //       onClick={() => {
        //         setDeleteModal(true);
        //         getZoomId(_id);
        //       }}
        //       style={{ fontSize: "18px", cursor: "pointer" }}
        //     />
        //   </Tooltip>
        // </Space>
      ),
      width: "100px",
    },
  ];

  const handleTableChange = useCallback(
    async (pagination, filters, sorter) => {
      setCurrentPage(1);
      setSelectedOptions({
        ...selectedOptions,
        sortBy: sorter?.field,
        sortType: sorter?.order === "ascend" ? "1" : "-1",
      });
      await GetZoomList({
        page: 1,
        sortBy: sorter?.field,
        sortType: sorter?.order === "ascend" ? "1" : "-1",
      });
    },
    [GetZoomList, selectedOptions]
  );

  const onChange = useCallback(
    (dates, dateStrings) => {
      const [startDate, startTime] = dateStrings[0].split(" ");
      const [endDate, endTime] = dateStrings[1].split(" ");

      setZoomData({
        ...zoomData,
        startDate: startDate,
        startTime: startTime,
        endDate: endDate,
        endTime: endTime,
      });
    },
    [zoomData]
  );

  const onOk = (value) => {
    // console.log("onOk: ", value);
  };

  const handleInputChange = useCallback(
    (e) => {
      const inputValue = e.target.value;
      setSelectedOptions({
        ...selectedOptions,
        key: inputValue,
      });
      setCurrentPage(1);
      GetZoomList({ page: 1, key: inputValue });
    },
    [GetZoomList, selectedOptions]
  );

  const handlePhoneChange = useCallback((value, country) => {
    setPhone(value);
    const selectedCountry = countryOptions?.find(
      (option) => option?.code === `+${country?.dialCode}`
    );
    if (selectedCountry) {
      setClienData((prevStep3) => ({
        ...prevStep3,
        contact_code: country?.dialCode,
        phoneNumber: value?.replace(country?.dialCode, ""),
        country: selectedCountry?.name,
      }));
    } else {
      // console.log("No matching country found for dial code:", country.dialCode);
    }
  }, []);

  const handleSelectChange = useCallback(
    (value) => {
      console.log(`Selected value: ${value}`);
      const selectValue = value;
      setClienData({ ...clienData, role_id: selectValue });
    },
    [clienData]
  );

  const handleSelectChangeMoveTo = useCallback(
    (value) => {
      const selectValue = value;
      setClienData({ ...clienData, registrationType: selectValue });
    },
    [clienData]
  );

  const setFilteredData = useCallback(
    (value) => {
      if (!value?.trim()) {
        setFilteredCompany("");
        GetZoomCompanyList();
      } else {
        setFilteredCompany(value);
      }
    },
    [GetZoomCompanyList]
  );

  return (
    <>
      <div>
        <div className="bk-div float-start w-100">
          <div className="body-expart-div">
            <div className="d-flex justify-content-between align-items-center">
              <h2>Zoom Meeting</h2>
              <button
                className="btn viewButton"
                onClick={() => {
                  form.resetFields();
                  setZoomData({
                    zoomId: "",
                    meetingId: "",
                    startDate: "",
                    passcode: "",
                    startTime: "",
                    endDate: "",
                    endTime: "",
                    comapnyName: "",
                    client: "",
                    client_user: [],
                    client_user_id: [],
                    client_id: viewId,
                    zoommeeting_id: "",
                  });
                  setDisplayExpert(!displayExpert);
                }}
                size="large"
              >
                Add Meeting
              </button>
            </div>

            {displayExpert ? (
              <>
                <div className="row d-flex justify-content-center align-items-center py-4">
                  <div className="col-md-10">
                    <div className="card bg-white p-4">
                      <Form
                        form={form}
                        layout="vertical"
                        onFinish={() => {
                          AddZoomMeeting();
                        }}
                      >
                        <div className="row">
                          <div className="col-md-6">
                            <Form.Item label="Select Zoom Account">
                              <Select
                                size="large"
                                placeholder="Select Zoom Account"
                                onChange={handleChange}
                                value={zoomData?.zoomId}
                                options={zoomAccountList?.map((item) => ({
                                  label: item?.email,
                                  value: item?._id,
                                }))}
                              />
                            </Form.Item>
                          </div>
                          <div className="col-md-6">
                            <Form.Item label="Meeting Id">
                              <Input
                                size="large"
                                placeholder="89223750266"
                                name="meetingId"
                                value={zoomData?.meetingId}
                                onChange={handleInputs}
                              />
                            </Form.Item>
                          </div>
                          <div className="col-md-6">
                            <Form.Item
                              label="Expert Designation"
                              name="expertDesignation"
                              rules={[
                                {
                                  required: true,
                                  message: "Expert designation is required!",
                                },
                              ]}
                            >
                              <Input
                                size="large"
                                placeholder="Expert Designation"
                                name="expertDesignation"
                                value={zoomData.expertDesignation}
                                onChange={handleInputs}
                                // onChange={(e) => {
                                //   let obj = {
                                //     ...formvalues,
                                //     meetingId: e.target.value,
                                //   };
                                //   dispatch(setFormValues(obj));
                                //   form.setFieldValue("meetingId", e.target.value);
                                // }}
                              />
                            </Form.Item>
                          </div>
                          <div className="col-md-6">
                            <Form.Item
                              label="Expert Company"
                              name="expertCompany"
                              rules={[
                                {
                                  required: true,
                                  message: "Expert company is required.",
                                },
                              ]}
                            >
                              <Input
                                size="large"
                                placeholder="Expert Company"
                                name="expertCompany"
                                value={zoomData.expertCompany}
                                onChange={handleInputs}
                                // onChange={(e) => {
                                //   let obj = {
                                //     ...formvalues,
                                //     meetingId: e.target.value,
                                //   };
                                //   dispatch(setFormValues(obj));
                                //   form.setFieldValue("meetingId", e.target.value);
                                // }}
                              />
                            </Form.Item>
                          </div>
                          <div className="col-md-6">
                            <Form.Item name="client" label="Client">
                              <Select
                                showSearch
                                size="large"
                                placeholder="Select Client"
                                onChange={handleNameChange}
                                name="client"
                                value={zoomData?.client}
                                onSearch={setFilteredData}
                                // optionFilterProp="children"
                                options={zoomCompanyList
                                  ?.filter((item) => item?.name)
                                  .map((item) => ({
                                    label: item?.name,
                                    value: item?.name,
                                    key: item?._id,
                                    domain: item?.domain,
                                  }))}
                                virtual={false}
                                allowClear
                              />
                            </Form.Item>
                          </div>
                          <div className="col-md-6">
                            <Form.Item name="client_user" label="Client User">
                              <Select
                                size="large"
                                placeholder="Select Client User"
                                name="client_user"
                                mode="multiple"
                                onChange={(values, options) =>
                                  handleNameChangeClient(values, options)
                                }
                                value={zoomData?.client_user}
                                options={clientView?.map((item) => ({
                                  label: item?.name,
                                  value: item?.name,
                                  key: item?._id,
                                }))}
                                dropdownRender={(menu) => (
                                  <>
                                    {menu}
                                    <Divider
                                      style={{
                                        margin: "8px 0",
                                      }}
                                    />
                                    <Space
                                      style={{
                                        padding: "0 8px 4px",
                                      }}
                                    >
                                      <Button
                                        icon={<PlusOutlined />}
                                        onClick={() => setCreateModal(true)}
                                      >
                                        Client user
                                      </Button>
                                    </Space>
                                  </>
                                )}
                                virtual={false}
                                allowClear
                              />
                            </Form.Item>
                          </div>

                          {/* <div className="col-md-6">
                            <Form.Item label="Meeting Passcode">
                              <Input
                                size="large"
                                placeholder="819302"
                                name="passcode"
                                value={zoomData?.passcode}
                                onChange={handleInputs}
                              />
                            </Form.Item>
                          </div> */}

                          {/* <div className="col-md-6">
                            <Form.Item name="dateAndTime" label="Date Time">
                              <RangePicker
                                size="large"
                                showTime={{
                                  format: "HH:mm",
                                }}
                                name="dateAndTime"
                                format="YYYY-MM-DD HH:mm"
                                onChange={onChange}
                                onOk={onOk}
                              />
                            </Form.Item>
                          </div> */}

                          <div className="d-flex justify-content-center align-items-center">
                            <Form.Item>
                              <button
                                className="viewButton btn"
                                type="submit"
                                // onClick={AddZoomMeeting}
                              >
                                Submit
                              </button>
                            </Form.Item>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </>
            ) : null}

            <Input
              placeholder="Search"
              value={selectedOptions?.key}
              onChange={handleInputChange}
              prefix={<SearchOutlined />}
              style={{ marginBottom: 16 }}
              size="large"
            />

            <Table
              // dataSource={filteredClientList}
              dataSource={dataSource}
              columns={columns}
              className="paddTable"
              loading={loading}
              pagination={false}
              onChange={handleTableChange}
              scroll={{ x: 800 }}
              size="large"
            />
            <Pagination
              {...paginationSettings}
              showSizeChanger={false}
              style={{ marginTop: 16, marginBottom: 16, textAlign: "right" }}
            />
          </div>
        </div>
      </div>

      <div
        className="offcanvas ttt offcanvas-end"
        tabIndex="-1"
        id="submitted"
        aria-labelledby="offcanvasRightLabel"
      >
        {/* {loading1 ? ( */}
        <>
          <div
            className="offcanvas-header align-items-center justify-content-between"
            style={{
              borderBottom: "1px solid rgb(197, 195, 195)",
            }}
          >
            <button
              type="button"
              className="btn-close "
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
            <h5 id="offcanvasRightLabel">Add New Manager</h5>
          </div>
          <div className="offcanvas-body">
            <div className="experien-list mt-4">
              <div>
                <ul className="nextyn_list_items">
                  <li className="nextyn_list_item border-bottom-0">
                    <div className="expertCompany_2UyI2">
                      <span className="title_1p_LH">From:</span>
                      <div className="companyName_1dSwA">Capital One</div>
                    </div>
                    <div className="expertCompany_2UyI2">
                      <span className="title_1p_LH">To:</span>
                      <div className="companyName_1dSwA">Capital One</div>
                    </div>
                    <div className="expertCompany_2UyI2">
                      <span className="title_1p_LH">Through:</span>
                      <div className="companyName_1dSwA">+17182858858</div>
                    </div>
                  </li>
                  <li className="nextyn_list_item border-bottom-0">
                    <div className="expertCompany_2UyI2">
                      <span className="title_1p_LH">Call Reference Id:</span>
                      <div className="companyName_1dSwA">Capital One</div>
                    </div>
                    <div className="expertCompany_2UyI2">
                      <span className="title_1p_LH">Total Talk Time:</span>
                      <div className="companyName_1dSwA">s</div>
                    </div>
                  </li>
                  <li className="nextyn_list_item border-bottom-0">
                    <div className="expertCompany_2UyI2">
                      <span className="title_1p_LH">Start Time:</span>
                      <div className="companyName_1dSwA">
                        Mon, 10 Apr 2023 11:14:01
                      </div>
                    </div>
                    <div className="expertCompany_2UyI2">
                      <span className="title_1p_LH">End Time:</span>
                      <div className="companyName_1dSwA">
                        Mon, 10 Apr 2023 11:14:01
                      </div>
                    </div>
                    <div className="expertCompany_2UyI2">
                      <span className="title_1p_LH">Call Time:</span>
                      <div className="companyName_1dSwA">
                        Mon, 10 Apr 2023 11:14:01
                      </div>
                    </div>
                  </li>
                  <li className="nextyn_list_item border-bottom-0">
                    <div className="expertCompany_2UyI2">
                      <span className="title_1p_LH">Transcription:</span>
                      <div className="companyName_1dSwA">Capital One</div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </>
        {/* ) : (
            ""
          )} */}
      </div>

      <Modal
        show={showDeleteModal}
        onHide={() => setDeleteModal(false)}
        backdrop="static"
        keyboard={false}
        centered
        size={"lg"}
        scrollable={true}
        dialogClassName={"bootStrapModal"}
        contentClassName={"bootStrapModalContent"}
      >
        <Modal.Body className="position-relative">
          <MdOutlineClose
            className="position-absolute"
            style={{ right: "15px", top: "15px", fontSize: "25px" }}
            onClick={() => setDeleteModal(false)}
          />
          <div className="text-left">
            <h4 className="modalHeader">Delete Meeting</h4>
          </div>
          <div>
            This action cannot be undone. Are you sure you want to delete this{" "}
            meeting ?
          </div>
          <div className="d-flex justify-content-end mt-3">
            <button
              className="secondaryBttn btn"
              onClick={() => setDeleteModal(false)}
            >
              No
            </button>
            <button
              onClick={() => {
                DeleteZoomMeeting();
                setDeleteModal(false);
              }}
              className="viewButton btn ms-2"
            >
              Yes
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showCreateModal}
        onHide={() => setCreateModal(false)}
        backdrop="static"
        keyboard={false}
        centered
        size={"lg"}
        scrollable={true}
        dialogClassName={"bootStrapModal"}
        contentClassName={"bootStrapModalContent"}
      >
        <Modal.Body className="position-relative">
          <MdOutlineClose
            className="position-absolute"
            style={{ right: "15px", top: "15px", fontSize: "25px" }}
            onClick={() => setCreateModal(false)}
          />
          <div className="text-left">
            <h4 className="modalHeader">Create Client</h4>
          </div>
          <div>
            <Form form={form} layout="vertical">
              <div className="row">
                <div className="col-md-6">
                  <Form.Item label="Name">
                    <Input
                      size="large"
                      name="name"
                      value={clienData?.name}
                      onChange={handleInputsClient}
                    />
                  </Form.Item>
                </div>

                <div className="col-md-6">
                  <Form.Item label="Email">
                    <Input
                      size="large"
                      name="email"
                      value={clienData?.email}
                      onChange={handleInputsClient}
                    />
                  </Form.Item>
                </div>

                <div className="col-md-6">
                  <Form.Item label="Company Name">
                    <Input
                      size="large"
                      name="companyName"
                      value={zoomData?.companyName}
                      onChange={handleInputsClient}
                    />
                  </Form.Item>
                </div>

                <div className="col-md-6">
                  <Form.Item label="Contact Number">
                    <PhoneInput
                      country={"us"}
                      enableSearch={true}
                      name="phoneNumber"
                      placeholder="Contact Number"
                      onChange={(value, country) => {
                        handlePhoneChange(value, country);
                      }}
                      inputStyle={{
                        width: "100%",
                      }}
                      value={phone}
                    />
                  </Form.Item>
                </div>

                <div className="col-md-6">
                  <Form.Item label="Role">
                    <Select
                      size="large"
                      style={{
                        width: "100%",
                      }}
                      value={clienData?.role_id}
                      onChange={handleSelectChange}
                    >
                      {roleList?.map((option, index) => (
                        <Select.Option value={option?._id} key={index}>
                          {option?.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>

                <div className="col-md-6">
                  <Form.Item label="Move to">
                    <Select
                      size="large"
                      style={{
                        width: "100%",
                      }}
                      value={clienData?.registrationType}
                      onChange={handleSelectChangeMoveTo}
                    >
                      <Select.Option value={"cloud"}>Cloud</Select.Option>
                      <Select.Option value={"client"}>Client</Select.Option>
                    </Select>
                  </Form.Item>
                </div>
              </div>
            </Form>
          </div>

          <div className="d-flex justify-content-end mt-3">
            <button
              className="secondaryBttn btn"
              onClick={() => setCreateModal(false)}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                setCreateModal(false);
                AddClientData();
              }}
              className="viewButton btn ms-2"
            >
              Submit
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <UpdateZoomMeetingModal
        editZoomModal={editZoomModal}
        setEditZoomModal={setEditZoomModal}
        zoomData={zoomData}
        setZoomData={setZoomData}
        zoomAccountList={zoomAccountList}
        zoomCompanyList={zoomCompanyList}
        clientView={clientView}
        setCreateModal={setCreateModal}
        setFilteredData={setFilteredData}
        setViewId={setViewId}
        setFilteredCompany={setFilteredCompany}
        GetZoomCompanyList={GetZoomCompanyList}
        setClientView={setClientView}
        viewId={viewId}
        GetZoomList={GetZoomList}
      />

      {/* <Modal
        show={editZoomModal}
        onHide={() => setEditZoomModal(false)}
        backdrop="static"
        keyboard={false}
        centered
        size={"lg"}
        scrollable={true}
        dialogClassName={"bootStrapModal"}
        contentClassName={"bootStrapModalContent"}
      >
        <Modal.Body className="position-relative">
          <MdOutlineClose
            className="position-absolute"
            style={{ right: "15px", top: "15px", fontSize: "25px" }}
            onClick={() => setEditZoomModal(false)}
          />
          <div className="text-left">
            <h4 className="modalHeader">Edit Zoom Meetingssss</h4>
          </div>
          <div>
            <Form form={form} layout="vertical">
              <div className="row">
                <div className="col-md-6">
                  <Form.Item label="Select Zoom Account">
                    <Select
                      size="large"
                      placeholder="Select Zoom Account"
                      onChange={handleChange}
                      value={zoomData?.zoomId}
                      options={zoomAccountList?.map((item) => ({
                        label: item?.email,
                        value: item?._id,
                      }))}
                    />
                  </Form.Item>
                </div>
                <div className="col-md-6">
                  <Form.Item label="Meeting Id">
                    <Input
                      size="large"
                      placeholder="Meeting Id"
                      name="meetingId"
                      value={zoomData?.meetingId}
                      onChange={handleInputs}
                    />
                  </Form.Item>
                </div>

                <div className="col-md-6">
                  <Form.Item name="client" label="Client">
                    <Select
                      showSearch
                      size="large"
                      placeholder="Select Clientssss"
                      onChange={handleNameChange}
                      name="client"
                      value={zoomData?.client}
                      options={zoomCompanyList
                        ?.filter((item) => item?.name)
                        .map((item) => ({
                          label: item?.name,
                          value: item?.name,
                          key: item?._id,
                          domain: item?.domain,
                        }))}
                      virtual={false}
                      allowClear
                    />
                  </Form.Item>
                </div>

                <div className="col-md-6">
                  <Form.Item name="client_user" label="Client User">
                    <Select
                      size="large"
                      placeholder="Select Client User"
                      name="client_user"
                      mode="multiple"
                      onChange={(values, options) => handleNameChangeClient(values, options)}
                      value={zoomData?.client_user}
                      options={clientView?.map((item) => ({
                        label: item?.name,
                        value: item?.name,
                        key: item?._id,
                      }))}
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <Divider
                            style={{
                              margin: "8px 0",
                            }}
                          />
                          <Space
                            style={{
                              padding: "0 8px 4px",
                            }}
                          >
                            <Button
                              icon={<PlusOutlined />}
                              onClick={() => setCreateModal(true)}
                            >
                              Create client
                            </Button>
                          </Space>
                        </>
                      )}
                      virtual={false}
                      allowClear
                    />
                  </Form.Item>
                </div>

                <div className="col-md-6">
                  <Form.Item label="Meeting Passcode">
                    <Input
                      size="large"
                      placeholder="819302"
                      name="passcode"
                      // value={zoomData?.passcode}
                      // onChange={handleInputs}
                      value={zoomData?.passcode}
                      onChange={handleInputs}
                    />
                  </Form.Item>
                </div>

                <div className="col-md-6">
                  <Form.Item name="dateAndTime" label="Date Time">
                    <RangePicker
                      size="large"
                      showTime={{
                        format: "HH:mm",
                      }}
                      name="dateAndTime"
                      format="YYYY-MM-DD HH:mm"
                      onChange={onChange}
                      onOk={onOk}
                    />
                  </Form.Item>
                </div>
              </div>
            </Form>
          </div>
          <div className="d-flex justify-content-end mt-3">
            <button
              className="secondaryBttn btn"
              onClick={() => setEditZoomModal(false)}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                setCreateModal(false);
                // AddClientData()
              }}
              className="viewButton btn ms-2"
            >
              Submit
            </button>
          </div>
        </Modal.Body>
      </Modal> */}
    </>
  );
};

export default ExotelTable;
