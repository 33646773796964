import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DangerousOutlinedIcon from "@mui/icons-material/DangerousOutlined";
import React, { useState } from "react";
import { BsPlusLg } from "react-icons/bs";
import { toast } from "react-toastify";
import "./AddMemberModal.css";
import API_PATH from "../Constants/api-path";
import { notification } from "antd";
import {
  LockOutlined,
  UserOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  WarningOutlined,
} from "@ant-design/icons";

const AddMemberModal = ({ ID }) => {
  console.log(ID);
  const [emailList, setEmailList] = useState([{ email: "" }]);
  const [messages, setMessage] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [ModalToggle, setModalToggle] = useState(false);
  const loginDetails = JSON.parse(localStorage.getItem("userData"));
  const userData = JSON.parse(localStorage.getItem("userData"));
  console.log("userData", userData);
  const handleInputChangeEmail = (e, index) => {
    const { name, value } = e.target;
    const list = [...emailList];
    list[index][name] = value;
    checkEmail(list[index].email);
    setEmailList(list);
  };

  const handleRemoveClick = (index) => {
    const list = [...emailList];
    list.splice(index, 1);
    setEmailList(list);
  };

  const handleAddClick = () => {
    setEmailList([...emailList, { email: "" }]);
  };

  const checkEmail = (email) => {
    //let emailData = [];

    //for (var Email in emailList) {
    const regEx = /[@]/;
    if (!regEx.test(email)) {
      //setChekemail(true)
      setMessage("");
    } else {
      //console.log("empty")
      //console.log("Email is invalid");
      return setMessage("Email is invalid");
    }
    // }
  };

  const handleSubmit = async () => {
    const { email } = emailList;

    // if (email === "") {
    //   return toast.warning("All fields are Mandatory", {
    //     position: toast.POSITION.RIGHT,
    //     autoClose: 3000,
    //   });
    // }
    setSpinner(true);

    let emailData = [];
    // console.log(emailData, "emailData");

    for (var Email in emailList) {
      if (emailList[Email].email !== "") emailData.push(emailList[Email].email);
    }
    console.log(emailData, "emailData");

    let payload = {
      project_id: ID,
      invite_team: emailData,
      login_name: userData?.data?.name,
    };
    console.log(payload, "payload");
    console.log(emailData.length, "emailData");
    if (payload.invite_team.length === 0 || emailData.length === 0) {
      document.getElementById("exampleModal").click();
      return notification.warning({
        message: "Warning: All fields are mandatory",
        icon: <WarningOutlined style={{ color: "#fff" }} />, // Custom warning icon
        style: {
          backgroundColor: "#f39c12", // Yellow background color
          color: "#fff", // Text color
          border: "1px solid #d35400", // Border color (optional)
          padding: "10px", // Padding (optional)
        },
        duration: 5, // Duration in seconds
        placement: "topRight", // Position of the notification
      });
    } else {
      try {
        const res = await fetch(`${API_PATH.PROJECT_INVITE_ADD}`, {
          method: "POST",
          body: JSON.stringify(payload),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          },
        });

        const result = await res.json();
        if (result) {
          if (result.status === 200 || result.status === true) {
            setSpinner(false);
            document.getElementById("exampleModal").click();
            setTimeout(() => {
              // window.location.reload();
            }, 3000);
            notification.success({
              message: "Invitation has been created",
              icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
              style: {
                backgroundColor: "#2ecc71",
                color: "#fff !important",
                border: "1px solid #52c41a",
              },
              duration: 5,
              placement: "topRight",
            });
          } else {
            notification.error({
              message: "Error: Something went wrong server error",
              icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
              style: {
                backgroundColor: "#e74c3c",
                color: "#fff",
                border: "1px solid #c0392b",
              },
              duration: 5,
              placement: "topRight",
            });
          }
        }
      } catch (error) {
        console.log(error, "error");
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    }
  };

  return (
    <div>
      <button
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
        variant="outlined"
        className="btn add-pls"
      >
        {" "}
        <BsPlusLg /> Add{" "}
      </button>

      <div
        className="modal fade "
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ border: "none", marginTop: "-10px" }}
            >
              <div className="text-center">
                <h2
                  className="modal-title modalHeader text-center"
                  id="exampleModalLabel"
                >
                  Add a team member to the project
                </h2>
              </div>

              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body  p-0">
              {/* <h2 className="text-center mb-5">
                Add a team member to the project
              </h2> */}
              <form>
                <div className="">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group ">
                        <div id="addnewEmails">
                          <div
                            className="pos-relative "
                            style={{
                              width: "100%",
                              padding: "1rem 1rem",
                            }}
                          >
                            {emailList.map((x, i) => {
                              return (
                                <div
                                  className="box d-flex align-items-center "
                                  key={i}
                                >
                                  <div className="col-sm-10 my-1 me-5">
                                    <label
                                      className="sr-only"
                                      htmlFor="inlineFormInputGroupUsername"
                                    >
                                      Enter Email
                                    </label>
                                    <div className="input-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="inlineFormInputGroupUsername"
                                        placeholder="Enter Email"
                                        name="email"
                                        value={x.email}
                                        onChange={(e) =>
                                          handleInputChangeEmail(e, i)
                                        }
                                      />
                                      <div className="input-group-prepend">
                                        <div className="input-group-text">
                                          {"@" +
                                            userData?.data?.email?.split(
                                              "@"
                                            )[1]}
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="btn-box d-grid">
                                    {emailList.length !== 1 && (
                                      <DangerousOutlinedIcon
                                        className="mr10"
                                        onClick={() => handleRemoveClick(i)}
                                      />
                                    )}
                                    {emailList.length - 1 === i && (
                                      <AddCircleOutlineIcon
                                        onClick={handleAddClick}
                                      />
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                            {messages === "" ? (
                              <></>
                            ) : (
                              <p
                                className="text-danger text-left"
                                style={{ textAlign: "left" }}
                              >
                                {messages}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>{" "}
            <div
              className="modal-footer d-flex"
              style={{
                border: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              {messages === "" ? (
                <button
                  variant="contained"
                  className="btn btnsm w-25"
                  onClick={handleSubmit}
                  id="closebtn1"
                  loading={spinner}
                >
                  Invite
                </button>
              ) : (
                <button
                  type="primary"
                  variant="contained"
                  className="btn btnsm w-25"
                  data-bs-dismiss="modal"
                  disabled
                  onClick={handleSubmit}
                >
                  Invite
                </button>
              )}
            </div>
          </div>
          {/* <ToastContainer /> */}
        </div>
      </div>
    </div>
  );
};

export default AddMemberModal;
