import React, { useState, useEffect } from "react";
import {
    Modal,
    Input,
    DatePicker,
    Checkbox,
    Button,
    Row,
    Col,
    Form,
    message,
    notification
} from "antd";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { MdModeEditOutline } from "react-icons/md";
import { FaGripLines } from "react-icons/fa";
import dayjs from "dayjs";
import API_PATH from "../../Constants/api-path";
import {
    CheckCircleOutlined,
    ExclamationCircleOutlined,
} from "@ant-design/icons";

import customParseFormat from "dayjs/esm/plugin/customParseFormat";
dayjs.extend(customParseFormat);

const ItemType = "CARD";
const { TextArea } = Input;
const FormItem = Form.Item;

const DraggableCard = ({
    index,
    data,
    moveCard,
    handleInputChange,
    handleDateChange,
    handleCheckboxChange,
    removeRow,
    handleTextAreaChange,
}) => {
    const [, ref] = useDrag({
        type: ItemType,
        item: { index },
    });

    const [, drop] = useDrop({
        accept: ItemType,
        hover: (draggedItem) => {
            if (draggedItem.index !== index) {
                moveCard(draggedItem.index, index);
            }
        },
    });

    return (
        <div ref={(node) => ref(drop(node))} style={{ marginBottom: "1rem" }}>
            <div>
                <FaGripLines style={{ cursor: "grab", marginRight: "8px" }} />
            </div>
            <div>
                <Form layout="vertical">
                    <Row className="d-flex justify-content-between align-items-center">
                        <Col span={24}>
                            <Input type="hidden" value={data?.institute_url} />
                            <Input type="hidden" value={data?.educations_logo_url} />
                            <FormItem label="Institute Name">
                                <Input
                                    size="large"
                                    value={data?.institute_name}
                                    onChange={(e) =>
                                        handleInputChange(index, "institute_name", e.target.value)
                                    }
                                    placeholder="Company Name"
                                />
                            </FormItem>
                        </Col>

                    </Row>

                    <Row className="d-flex justify-content-between align-items-center">
                        <Col span={24}>
                            <FormItem label="Degree">
                                <Input
                                    size="large"
                                    value={data?.degree}
                                    onChange={(e) =>
                                        handleInputChange(index, "degree", e.target.value)
                                    }
                                    placeholder="Designation"
                                />
                            </FormItem>
                        </Col>
                    </Row>

                </Form>

                {data.index > 0 && (
                    <div className="d-flex justify-content-end align-items-center my-2">
                        <Button
                            className="text-danger"
                            onClick={() => removeRow(index)}
                            type="danger"
                        >
                            Remove
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

const MyModal = ({ data, expert_id, fetchData }) => {
    console.log(data, "datas-->");
    const [visible, setVisible] = useState(false);
    const [formData, setFormData] = useState([
        {
            institute_name: "",
            degree: "",
            index: 0,
        },
    ]);
    const userData = JSON.parse(localStorage.getItem("userData"));

    const showModal = () => {
        setVisible(true);
    };

    const handleOk = () => {
        setVisible(false);
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const handleInputChange = (index, field, value) => {
        setFormData((prevData) =>
            prevData.map((data, i) => {
                if (i === index) {
                    return {
                        ...data,
                        [field]:
                            (field === "from" || field === "till") && value
                                ? dayjs(value).format("MMM YYYY")
                                : value,
                    };
                }
                return data;
            })
        );
    };

    const handleDateChange = (index, field, value) => {
        handleInputChange(index, field, value);
    };

    const handleCheckboxChange = (index, value) => {
        handleInputChange(index, "isPresent", value.target.checked);
    };

    const addRow = () => {
        setFormData([
            ...formData,
            {
                institute_name: "",
                degree: "",
                index: formData.length,
            },
        ]);
    };

    const removeRow = (index) => {
        const updatedData = formData.filter((_, i) => i !== index);
        setFormData(updatedData);
    };

    const moveCard = (fromIndex, toIndex) => {
        const updatedData = [...formData];
        const [movedItem] = updatedData.splice(fromIndex, 1);
        updatedData.splice(toIndex, 0, { ...movedItem, index: toIndex });
        setFormData(updatedData);
    };

    const handleTextAreaChange = (index, value) => {
        const updatedData = [...formData];
        updatedData[index]["workDescription"] = value;
        setFormData(updatedData);
    };

    const handleSave = async () => {
        const postData = {
            institute_name: [],
            degree: [],
            institute_url: [],
            educations_logo_url: [],
        };

        formData?.forEach((data) => {
            postData.institute_name?.push(data?.institute_name);
            postData.degree?.push(data?.degree);
            postData.institute_url?.push(data?.institute_url);
            postData.educations_logo_url?.push(data?.educations_logo_url);
        });

        console.log("postData", postData);

        try {
            const res = await fetch(`${API_PATH.UPDATE_QC_UPDATE}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userData?.token}`,
                },
                body: JSON.stringify({
                    ...postData,
                    expert_id: expert_id,
                  }),
            });

            const result = await res.json();
            if (result) {
                if (res.status === 200 || res.status === true) {
                    handleCancel(false);
                    fetchData();
                    notification.success({
                        message: "Expert education updated",
                        icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
                        style: {
                            backgroundColor: "#2ecc71",
                            color: "#fff !important",
                            border: "1px solid #52c41a",
                        },
                        duration: 5,
                        placement: "topRight",
                    });
                    // window.location.reload();
                }
            } else {
                notification.error({
                    message: "Error: Something went wrong server error",
                    icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
                    style: {
                        backgroundColor: "#e74c3c",
                        color: "#fff",
                        border: "1px solid #c0392b",
                    },
                    duration: 5,
                    placement: "topRight",
                });
            }
        } catch (error) {
            notification.error({
                message: "Error: Something went wrong server error",
                icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
                style: {
                    backgroundColor: "#e74c3c",
                    color: "#fff",
                    border: "1px solid #c0392b",
                },
                duration: 5,
                placement: "topRight",
            });
        }
    };

    useEffect(() => {
        if (data && data?.length > 0) {
            const preFilledData = data?.map((experience, index) => {
                return {
                    institute_name: experience?.institute_name,
                    degree: experience?.degree,
                    institute_url: experience?.institute_url,
                    educations_logo_url: experience?.educations_logo_url,
                    index,
                };
            });
            setFormData(preFilledData);
        }
    }, [data]);


    const addFooter = (
        <div className="d-flex justify-content-end align-items-center">
            <button className="secondaryBttn btn" onClick={() => handleCancel(false)}>
          Cancel
        </button>
        <button className="viewButton btn ms-2" onClick={handleSave}>
          Submit
        </button>
        </div>
    );

    return (
        <DndProvider backend={HTML5Backend}>
            <div>
                <MdModeEditOutline
                    style={{ cursor: "pointer", fontSize: "20px" }}
                    onClick={showModal}
                >
                    Open Modal
                </MdModeEditOutline>
                <Modal
                    title={<span className="modalHeader">Education</span>}
                    visible={visible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    width={800}
                    footer={addFooter}
                    centered
                >
                    {formData?.map((data, index) => (
                        <DraggableCard
                            key={index}
                            index={index}
                            data={data}
                            moveCard={moveCard}
                            handleInputChange={handleInputChange}
                            handleDateChange={handleDateChange}
                            handleCheckboxChange={handleCheckboxChange}
                            handleTextAreaChange={handleTextAreaChange}
                            removeRow={removeRow}
                        />
                    ))}
                    <Button onClick={addRow} type="dashed" block>
                        Add Row
                    </Button>
                </Modal>
            </div>
        </DndProvider>
    );
};

export default MyModal;
