import { useState } from 'react';
import { Form, Input, Button } from 'antd';

const YourComponent = () => {
  const [form] = Form.useForm();
  const [inputs, setInputs] = useState([
    { requiredField1: '', requiredField2: '', nonRequiredField1: '', nonRequiredField2: '' },
  ]);

  const handleInputChange = (event, name, index) => {
    const value = event?.target ? event.target.value : event;
    setInputs((prevInputs) => {
      const updatedInputs = [...prevInputs];
      updatedInputs[index][name] = value;
      return updatedInputs;
    });
  };

  const handleAddInput = () => {
    setInputs((prevInputs) => [
      ...prevInputs,
      { requiredField1: '', requiredField2: '', nonRequiredField1: '', nonRequiredField2: '' },
    ]);
  };

  const handleDeleteInput = (index) => {
    if (inputs.length > 1) {
      setInputs((prevInputs) => {
        const updatedInputs = [...prevInputs];
        updatedInputs.splice(index, 1);
        return updatedInputs;
      });
    }
  };

  const handleSubmit = () => {
    form.validateFields().then(() => {
      // Your submit logic here
    });
  };

  return (
    <Form form={form} onFinish={handleSubmit}>
      {inputs.map((input, index) => (
        <div key={index}>
          <Form.Item
            name={['inputs', index, 'requiredField1']}
            rules={[{ required: true, message: 'This field is required' }]}
          >
            <Input
              placeholder="Required Field 1"
              value={input.requiredField1}
              onChange={(event) => handleInputChange(event, 'requiredField1', index)}
            />
          </Form.Item>
          <Form.Item
            name={['inputs', index, 'requiredField2']}
            rules={[{ required: true, message: 'This field is required' }]}
          >
            <Input
              placeholder="Required Field 2"
              value={input.requiredField2}
              onChange={(event) => handleInputChange(event, 'requiredField2', index)}
            />
          </Form.Item>
          <Form.Item name={['inputs', index, 'nonRequiredField1']}>
            <Input
              placeholder="Non-required Field 1"
              value={input.nonRequiredField1}
              onChange={(event) => handleInputChange(event, 'nonRequiredField1', index)}
            />
          </Form.Item>
          <Form.Item name={['inputs', index, 'nonRequiredField2']}>
            <Input
              placeholder="Non-required Field 2"
              value={input.nonRequiredField2}
              onChange={(event) => handleInputChange(event, 'nonRequiredField2', index)}
            />
          </Form.Item>
          {inputs.length > 1 && (
            <Button type="button" onClick={() => handleDeleteInput(index)}>
              Remove
            </Button>
          )}
        </div>
      ))}
      <div>
        <Button type="primary" htmlType="submit" disabled={!form.isFieldsTouched(true)}>
          Submit
        </Button>
        <Button type="button" onClick={handleAddInput}>
          Add
        </Button>
      </div>
    </Form>
  );
};


export default YourComponent