import React, { useEffect } from "react";
import { Route, Routes, Navigate, useNavigate, useLocation } from "react-router-dom";
import "./App.css";
import "./assests/css/customResponsive.css";
import AddExpertsSection from "./pages/AddExperts/AddExpertsSection";
import CallRecordSection from "./pages/CallRecords/CallRecordSection";
import ClientSection from "./pages/Client/ClientSection";
import Consulting from "./pages/Consulting/ConsultingSection";
import Dashboard from "./pages/Dashboard/Dashboard";
import Demo from "./pages/Demo";
import EnquiryPage from "./pages/Enquiry/EnquiryPage";
import ExotelSection from "./pages/Exotel/ExotelSection";
import Expert from "./pages/Experts/Expert";
import ExpertProfile from "./pages/Experts/ExpertProfilePage";
import ForgotPasssword from "./pages/ForgotPassword/ForgotPassword";
import Login from "./pages/Login/Login";
import Logs from "./pages/Logs/LogsSection";
import ManagersSection from "./pages/Managers/MangersSection";
import QCExpertsSection from "./pages/QCExperts/QCExpertsSection";
import ResetPasssword from "./pages/ResetPassword/ResetPassword";
import AddNewRole from "./pages/Roles/AddNewRole";
import Roles from "./pages/Roles/RolesSection";
import ZoomPagesection from "./pages/ZoomMeeting/ZoomPageSection";
import AddNewExpert from "./pages/projects/AddNewExpert";
import ProjectSection from "./pages/projects/ProjectSection";
import ProjectsDetails from "./pages/projects/ProjectsDetails";
import ProjectsDetailsExpert from "./pages/projects/ProjectsDetailsExpert";
import ProjectsDetailsMessage from "./pages/projects/ProjectsDetailsMessage";
import Profile from "./pages/profile/UserProfile";
import ChangePassword from "./pages/ChangePassword/ChangePassword";
import PrivateRoute from "./pages/PrivateRoute/PrivateRoute";
import PrivateRouteTwo from "./pages/PrivateRoute/PrivateRouteTwo";
import TempExpert from "./pages/QCExperts/ExpertProfilePage";
import Policy from "./pages/Employee/Policy";
import Notification from "./pages/notifications/notification";
import LeadPage from "./pages/Lead/LeadPageSection";
import ExpertDetails from "./pages/projects/ExpertDetails.js";

// Expert Dashboard and pages
import ExpertLogin from "./expertDashboard/authentication/Login/Login";
import ExpertForgotPassword from "./expertDashboard/authentication/ForgotPassword/ForgotPassword";
import Register from "./expertDashboard/authentication/Register/Register";
import ExpertDashboard from "./expertDashboard/Dashboard/Pages/Dashboard";
import ExpertDashboardProfile from "./expertDashboard/Dashboard/Pages/ExpertProfile";
import ExpertCallRecords from "./expertDashboard/Dashboard/Pages/CallRecords";
import ExpertPayment from "./expertDashboard/Dashboard/Pages/Payment";
import ExpertCompliance from "./expertDashboard/Dashboard/Pages/Compliance";
import ResetPassword from "./expertDashboard/authentication/ResetPassword/ResetPassword";
import ExpertChangePassword from "./expertDashboard/authentication/ChangePassword/ChangePassword";
import ExpertPrivateRoute from "./expertDashboard/PrivateRoute/ExpertPrivateRoute";
import ExpertPrivateRouteTwo from "./expertDashboard/PrivateRoute/ExpertPrivateRouteTwo";
import LiveProjectList from "./pages/TeamProjects/LiveProjectList";
import CompletedProjects from "./pages/TeamProjects/CompletedProjects";
import ProjectOverview from "./pages/TeamProjects/ProjectOverview";
import ForgotPassword from "./expertDashboard/authentication/ChangePassword/ForgotPassword";
import InternalMeeting from "./pages/CloudFeatures/InternalMeetings/InternalMeeting";
import ClientList from "./pages/CloudFeatures/CMS/ClientList";
import CsvUpload from "./CsvUpload";
import Consultant from "./pages/Experts/Consultant";
import { history } from "./Utils/history.js";
import useDecodedToken from "./Utils/useDecodedToken.js";
import { useDispatch } from "react-redux";
import HoldProjectList from "./pages/TeamProjects/HoldProjectList.js";
import InvoiceGenerator from "./pages/InvoiceGenerator/InvoiceGenerator.js";

const App = () => {
  history.navigate = useNavigate();
  history.location = useLocation();
  const dispatch = useDispatch()
  //auto logout
  //----------------ADMIN CHECK---------------------
  const decodedToken = useDecodedToken();
  useEffect(() => {
    //ADDED TOKEN CHECK FOR LOGOUT IF EXPIRED THEN IT WILL NAVIGATE TO LOGOUT
    if (!decodedToken) {
      localStorage.removeItem("token")
      localStorage.removeItem("userData")
      localStorage.removeItem("auth")
      localStorage.removeItem("lastActiveTime")
      localStorage.removeItem("lastLoginTime")
    }
  }, [decodedToken, dispatch]);

  //----------------EXPERT CHECK---------------------

  let isExpertAuth = JSON.parse(localStorage.getItem("isExpertAuth"));
  if (isExpertAuth === null) {
    const pathname = window.location.pathname;
    localStorage.setItem("redirectPath", pathname + window.location.search);
  }


  useEffect(() => {
    const logoutUser = () => {
      localStorage.removeItem("expertData");
      localStorage.removeItem("isExpertAuth");
      localStorage.removeItem("lastExpertLoginTime");
      localStorage.removeItem("lastExpertActiveTime");
      history("/expert-login");
    };

    const lastExpertLoginTime = localStorage.getItem("lastExpertLoginTime");

    if (lastExpertLoginTime) {
      const elapsed = Date.now() - parseInt(lastExpertLoginTime, 10);
      if (elapsed >= 8 * 60 * 60 * 1000) {
        // 8 hours in milliseconds
        logoutUser();
      }
    }

    return () => {
      // Clear any cleanup you might have here
    };
  }, [history]);

  const RequireAuth = () => {
    const decodedToken = useDecodedToken()
    return decodedToken ? <Navigate to="/dashboard" replace /> : <Navigate to="/admin-login" replace />;
  };

  return (
    <Routes>
      <Route path="/" element={<RequireAuth />} />
      <Route
        path="/admin-login"
        element={
          <PrivateRouteTwo>
            <Login />
          </PrivateRouteTwo>
        }
      />
      <Route
        path="/forgot-password"
        // token={isAuth}
        element={
          <PrivateRouteTwo>
            <ForgotPasssword />
          </PrivateRouteTwo>
        }
      />
      <Route
        path="/reset-password/:token"
        element={
          <PrivateRouteTwo>
            <ResetPasssword />
          </PrivateRouteTwo>
        }
      />
      <Route
        path="/dashboard"
        // token={isAuth}
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      />
      <Route
        path="/dashboard/invoice-generator"
        element={
          <PrivateRoute>
            <InvoiceGenerator />
          </PrivateRoute>
        }
      ></Route>
      <Route
        path="/dashboard/clients"
        // token={isAuth}
        element={
          <PrivateRoute>
            <ClientSection />
          </PrivateRoute>
        }
      />
      <Route
        path="/dashboard/expert_search"
        // token={isAuth}
        element={
          <PrivateRoute>
            <Expert />
          </PrivateRoute>
        }
      />
      <Route
        path="/dashboard/consultant_search"
        // token={isAuth}
        element={
          <PrivateRoute>
            <Consultant />
          </PrivateRoute>
        }
      />
      <Route
        path="/dashboard/exotel"
        // token={isAuth}
        element={
          <PrivateRoute>
            <ExotelSection />
          </PrivateRoute>
        }
      />
      <Route
        path="/dashboard/call_records"
        // token={isAuth}
        element={
          <PrivateRoute>
            <CallRecordSection />
          </PrivateRoute>
        }
      />
      <Route
        path="/dashboard/projects"
        // token={isAuth}
        element={
          <PrivateRoute>
            <ProjectSection />
          </PrivateRoute>
        }
      />
      <Route
        exact="true"
        path="/csv_upload"
        // token={isAuth}
        element={
          <PrivateRoute>
            <CsvUpload />
          </PrivateRoute>
        }
      />
      <Route
        path="/dashboard/team/projects"
        // token={isAuth}
        element={
          <PrivateRoute>
            <LiveProjectList />
          </PrivateRoute>
        }
      />
      <Route
        path="/dashboard/team/hold-projects"
        // token={isAuth}
        element={
          <PrivateRoute>
            <HoldProjectList />
          </PrivateRoute>
        }
      />
      <Route
        path="/dashboard/team/completed-projects"
        // token={isAuth}
        element={
          <PrivateRoute>
            <CompletedProjects />
          </PrivateRoute>
        }
      />
      <Route
        path="/dashboard/team/project-overview"
        // token={isAuth}
        element={
          <PrivateRoute>
            <ProjectOverview />
          </PrivateRoute>
        }
      />
      <Route
        path="/expert-profile"
        // token={isAuth}
        element={
          <PrivateRoute>
            <ExpertProfile />
          </PrivateRoute>
        }
      />
      <Route
        path="/dashboard/project_details"
        // token={isAuth}
        element={
          <PrivateRoute>
            <ProjectsDetails />
          </PrivateRoute>
        }
      />
      <Route
        path="/dashboard/chats"
        // token={isAuth}
        element={
          <PrivateRoute>
            <ProjectsDetailsMessage />
          </PrivateRoute>
        }
      />
      <Route
        path="/dashboard/expert_list"
        // token={isAuth}
        element={
          <PrivateRoute>
            <ProjectsDetailsExpert />
          </PrivateRoute>
        }
      />
      <Route
        path="/dashboard/addExpert"
        // token={isAuth}
        element={
          <PrivateRoute>
            <AddNewExpert />
          </PrivateRoute>
        }
      />
      <Route
        path="/dashboard/enquiries"
        // token={isAuth}
        element={
          <PrivateRoute>
            <EnquiryPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/dashboard/managers"
        // token={isAuth}
        element={
          <PrivateRoute>
            <ManagersSection />
          </PrivateRoute>
        }
      />
      <Route
        path="/dashboard/roles"
        // token={isAuth}
        element={
          <PrivateRoute>
            <Roles />
          </PrivateRoute>
        }
      />
      <Route
        path="/dashboard/addRoles"
        // token={isAuth}
        element={
          <PrivateRoute>
            <AddNewRole />
          </PrivateRoute>
        }
      />
      <Route
        path="/dashboard/logs"
        // token={isAuth}
        element={
          <PrivateRoute>
            <Logs />
          </PrivateRoute>
        }
      />
      <Route
        path="/dashboard/temp_expert"
        // token={isAuth}
        element={
          <PrivateRoute>
            <AddExpertsSection />
          </PrivateRoute>
        }
      />
      <Route
        path="/dashboard/qc_experts"
        // token={isAuth}
        element={
          <PrivateRoute>
            <QCExpertsSection />
          </PrivateRoute>
        }
      />
      <Route
        path="/dashboard/temp-expert-profile"
        // token={isAuth}
        element={
          <PrivateRoute>
            <TempExpert />
          </PrivateRoute>
        }
      />
      <Route
        path="/dashboard/consulting"
        // token={isAuth}
        element={
          <PrivateRoute>
            <Consulting />
          </PrivateRoute>
        }
      />
      <Route
        path="/dashboard/zoom-meetings"
        // token={isAuth}
        element={
          <PrivateRoute>
            <ZoomPagesection />
          </PrivateRoute>
        }
      />
      <Route
        path="/dashboard/lead"
        // token={isAuth}
        element={
          <PrivateRoute>
            <LeadPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/dashboard/profile"
        // token={isAuth}
        element={
          <PrivateRoute>
            <Profile />
          </PrivateRoute>
        }
      />
      <Route
        path="/dashboard/hr-policy"
        // token={isAuth}
        element={
          <PrivateRoute>
            <Policy />
          </PrivateRoute>
        }
      />
      <Route
        path="/dashboard/notification"
        // token={isAuth}
        element={
          <PrivateRoute>
            <Notification />
          </PrivateRoute>
        }
      />
      <Route
        path="/dashboard/change-password"
        // token={isAuth}
        element={
          <PrivateRoute>
            <ChangePassword />
          </PrivateRoute>
        }
      />
      <Route
        path="/demo"
        // token={isAuth}
        element={
          <PrivateRoute>
            <Demo />
          </PrivateRoute>
        }
      />

      {/* Expert Dashboard and pages */}
      <Route
        path="/expert-login"
        element={
          <ExpertPrivateRouteTwo token={isExpertAuth}>
            <ExpertLogin />
          </ExpertPrivateRouteTwo>
        }
      />
      <Route
        path="/expert-forgot-password"
        element={
          <ExpertPrivateRouteTwo token={isExpertAuth}>
            <ExpertForgotPassword />
          </ExpertPrivateRouteTwo>
        }
      />
      <Route
        path="expert/dashboard/forgot-password"
        element={<ForgotPassword />}
      ></Route>
      <Route
        path="/expert-register"
        element={
          <ExpertPrivateRouteTwo token={isExpertAuth}>
            <Register />
          </ExpertPrivateRouteTwo>
        }
      />
      <Route
        path="/expert/dashboard"
        element={
          <ExpertPrivateRoute token={isExpertAuth}>
            <ExpertDashboard />
          </ExpertPrivateRoute>
        }
      />
      <Route
        path="/expert/profile"
        element={
          <ExpertPrivateRoute token={isExpertAuth}>
            <ExpertDashboardProfile />
          </ExpertPrivateRoute>
        }
      />
      <Route
        path="/expert/call-records"
        element={
          <ExpertPrivateRoute token={isExpertAuth}>
            <ExpertCallRecords />
          </ExpertPrivateRoute>
        }
      />
      <Route
        path="/expert/payment"
        element={
          <ExpertPrivateRoute token={isExpertAuth}>
            <ExpertPayment />
          </ExpertPrivateRoute>
        }
      />
      <Route
        path="/expert/compliance"
        element={
          <ExpertPrivateRoute token={isExpertAuth}>
            <ExpertCompliance />
          </ExpertPrivateRoute>
        }
      />
      <Route path="/expert/reset-password/:token" element={<ResetPassword />} />
      <Route
        path="/expert/change-password"
        element={
          <ExpertPrivateRoute token={isExpertAuth}>
            <ExpertChangePassword />
          </ExpertPrivateRoute>
        }
      />

      <Route
        path="/dashboard/cloud-feature/internal-meetings"
        element={
          <PrivateRoute>
            <InternalMeeting />
          </PrivateRoute>
        }
      ></Route>
      <Route
        path="/dashboard/cloud-feature/clients"
        element={
          <PrivateRoute>
            <ClientList />
          </PrivateRoute>
        }
      ></Route>
      <Route
        exact="true"
        path="/project/expert-profile"
        element={
          <PrivateRoute>
            <ExpertDetails />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};

export default App;
