import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { Spin } from "antd"

function ProjectScopeLeftSection({ datas }) {
  // console.log(datas.details);
  const [projectDetail, setProjectDetail] = useState({});
  const [Loading, setLoading] = useState(true);

  useEffect(() => {
    setProjectDetail({ ...datas });
    setLoading(false);
  }, [datas]);

  if (!Loading) {
    function RemoveHtml(myString) {
      if (myString !== undefined) {
        // console.log(myString);
        return myString.replace(/(<([^>]+)>)/gi, "");
      }
    }

    return (
      <>
        <h2 style={{ color: "rgb(82, 111, 215)" }} className="mb-4">
          {" "}
          {projectDetail?.project_title}{" "}
        </h2>
        {/* <h5 className="mt-5"> BRIEF </h5> */}
        <div className="projectOverView">
          <p dangerouslySetInnerHTML={{ __html: projectDetail?.question }}></p>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="d-flex justify-content-center align-items-center text-center" style={{ minHeight: '100vh' }}>
          <Spin size="large" />
        </div>
      </>
    );
  }
}

export default ProjectScopeLeftSection;
