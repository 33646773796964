export const projectType = [
    "Finance & Accounting",
    "Corporate Strategy",
    "Digital Transformation & Technology",
    "Operations & Supply Chain Management",
    "Human Resources & Organization",
    "Data & Business Analytics",
    "Sales & Marketing",
    "Market Research",
]

export const projectSubType = {
    "Finance & Accounting": ["Accounting & Taxation", "Finance"],
    "Corporate Strategy": ["Mergers & Acquisitions", "Interim Executive - Marketing", "Strategic Planning & Transformation"],
    "Digital Transformation & Technology": ["Digital Strategy & Transformation", "Technology Assessment & Implementation"],
    "Operations & Supply Chain Management": ["Operations & Process Improvement", "Supply Chain Strategy & Transformation"],
    "Human Resources & Organization": ["HR Strategy & Transformation", "Organization Design"],
    "Data & Business Analytics": ["Big Data & Analytics"],
    "Sales & Marketing": ["Sales", "Social Media Marketing Strategy", "Marketing Strategy"],
    "Market Research": ["General Market Research"],
}
export const projectSpecific = {
    "Accounting & Taxation": ["Tax Strategy", "Financial Statement Audit", "Digital Assurance & Transparency", "ESG Reporting Services", "Cost Management", "Accounting & Taxation Project Management"],
    "Finance": ["Financial Planning & Analysis", "Demand Forecasting", "Financial Modeling", "Finance Project Management"],
    "Mergers & Acquisitions": ["Mergers & Acquisitions Project Management", "Mergers & Acquisitions Strategy", "Due Diligence", "Post-Merger Integration", "Interim Executives"],
    "Interim Executive - Marketing": ["Interim Executive - IT", "Interim Executive - CEO/COO/CFO", "Interim Executive - Sales"],
    "Strategic Planning & Transformation": ["Value Creation Strategy", "Strategic Planning", "Strategic Transformation Project Management"],
    "Digital Strategy & Transformation": ["Digital & Mobile Strategy", "Cloud Strategy", "Digital Transformation Roadmapping", "Product Management", "Digital Strategy Project Management"],
    "Technology Assessment & Implementation": ["Software Implementation", "Technology Assessment", "Technology Implementation Project Management"],
    "Operations & Process Improvement": ["Business Process Redesign", "Commercial Operations", "Process Improvement", "Operations Project Management"],
    "Supply Chain Strategy & Transformation": ["Supply Chain Strategy", "Supply Chain Transformation", "Strategic Sourcing & Procurement", "Supply Chain Analysis & Benchmarking", "Supply Chain Project Management"],
    "HR Strategy & Transformation": ["Compensation & Benefits Planning", "Change Management", "Diversity, Equity, & Inclusion", "Talent Acquisition Strategy", "Learning & Development", "Hybrid Workforce Strategy", "HR Technology Strategy & Solutions", "HR Project Management"],
    "Organization Design": ["Organizational Design & Assessment", "Workforce Strategy & Insights", "Workforce Integration Post-Merger/Acquisition"],
    "Big Data & Analytics": ["Business Impact Analysis", "Data Science & Analysis", "Data Visualization", "Robotic Process Automation (RPA)", "Benchmarking", "Big Data & Analytics Project Management"],
    "Sales": ["Sales Team Optimization", "Revenue Operations", "Sales Project Management", "Digital Marketing"],
    "Social Media Marketing Strategy": ["SEO Strategy", "Content Development", "Lead & Demand Generation", "E-Commerce Strategy", "Digital Marketing Project Management"],
    "Marketing Strategy": ["New Market Entry", "Go-to-Market Strategy", "Brand Strategy", "Public Relations Strategy", "Marketing Strategy Project Management"],
    "General Market Research": ["Voice of the Customer Research", "Market Segmentation", "Competitive Analysis", "Market Mapping & TAM Analysis", "Pricing Analysis & Optimization", "Market Landscape", "Market Research Project Management"],

}