import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import API_PATH from "../../Constants/api-path";
import { Col, Row } from "react-bootstrap";

import { Card, DatePicker, Input, Select, Tooltip } from "antd";
import dayjs from "dayjs";
import { LuFilter } from "react-icons/lu";
import { FaFilter } from "react-icons/fa";
import moment from 'moment'
import { formatNumber, getFinancialYear } from "../../Utils/util";
const { Option } = Select;
const { RangePicker } = DatePicker;
function ProjectStats() {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const [managerFilterList, setManagerFilterList] = useState([]);
    const [teamMemberList, setTeamMemberList] = useState([]);
    const [statsData, setStatsData] = useState([]);
    const [statisticData, setStatisticData] = useState({
        currentMonth: [],
        financialYearData: [],
        previousMonthData: [],
    });
    const [show, setShow] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState({
        project_name: "",
        added_by: "",
        manager_id: "",
        date: "",
        project_status: "",
    });
    const getProjectStats = React.useCallback(
        async (options) => {
            let object = {};
            if (options?.project_name) {
                object.project_name = options?.project_name;
            }
            if (options?.added_by) {
                object.added_by = options?.added_by;
            }
            if (options?.manager_id) {
                object.manager_id = options?.manager_id;
            }
            if (options?.project_status) {
                object.project_status = options?.project_status;
            }
            if (
                options?.date &&
                !["", undefined, "undefined"]?.options?.date
            ) {
                object.sdate = options?.date;
            }
            // if (
            //     options?.date?.length > 0 &&
            //     !["", undefined, "undefined"]?.options?.date?.[0] &&
            //     !["", undefined, "undefined"]?.options?.date?.[1]
            // ) {
            //     object.sdate = options?.date?.[0];
            //     object.edate = options?.date?.[1];
            // }
            try {
                const queryParams = new URLSearchParams(object);
                const response = await axios.get(
                    API_PATH.PROJECT_STATS +
                    `${userData?.data?.email}?${queryParams?.toString()}`
                );
                setManagerFilterList(response?.data?.allManagers);
                setTeamMemberList(response?.data?.allTeams);
                setStatsData(response?.data?.data);
                setStatisticData({
                    currentMonth: response?.data?.currentMonthData,
                    financialYearData: response?.data?.financialYearData,
                    previousMonthData: response?.data?.previousMonthData,
                });
            } catch (error) {
                console.log('error: ', error);
            }
        },
        [userData?.data?.email]
    );

    const onChange = React.useCallback(
        (value, key) => {
            const updatedValue = value === undefined ? "" : value;
            let options = { ...selectedOptions, [key]: updatedValue };
            setSelectedOptions(options);
            getProjectStats(options);
        },
        [getProjectStats, selectedOptions]
    );
    const callOnce = useRef(false);

    useEffect(() => {
        if (!callOnce.current) {
            callOnce.current = true;
            getProjectStats(selectedOptions);
        }
    }, [getProjectStats, selectedOptions]);

    return (
        <div>
            <div className="dasb-div bk-div float-start w-100">
                <div className="body-expart-div">
                    <span className="d-flex align-items-center mb-3">
                        <p className="fs-5">
                            <strong style={{ color: "#5746ec" }}>Project Statistics </strong>
                        </p>
                        <Tooltip
                            title="filter"
                            overlayInnerStyle={{
                                background: "#5746EC",
                            }}
                        >
                            <span
                                onClick={() => {
                                    setShow(!show);
                                }}
                                className="ps-3"
                                style={{ cursor: "pointer" }}
                            >
                                {show ? <LuFilter color="#5746EC" /> : <FaFilter color="#5746EC" />}
                            </span>
                        </Tooltip>
                    </span>
                    {show && (
                        <Row className="mb-2 g-2">
                            <Col>
                                <Input
                                    size="large"
                                    placeholder="Search by project name"
                                    className="input-size"
                                    value={selectedOptions?.project_name}
                                    onChange={(e) => onChange(e.target.value, "project_name")}
                                    style={{ height: "40px" }}
                                    allowClear
                                />
                            </Col>
                            <Col>
                                <Select
                                    style={{ width: "100%" }}
                                    placeholder="Project Status"
                                    className="input-size"
                                    size="large"
                                    showSearch
                                    value={selectedOptions?.project_status
                                        || null}
                                    onChange={(value) => onChange(value, "project_status")}
                                    filterOption={(input, option) =>
                                        option?.props?.children
                                            ?.toLowerCase()
                                            .indexOf(input?.toLowerCase()) !== -1
                                    }
                                    allowClear
                                >
                                    <Option value={"active"} key={1}>
                                        {"Active"}
                                    </Option>
                                    <Option value={"close"} key={2}>
                                        {"Close"}
                                    </Option>

                                </Select>
                            </Col>

                            {userData?.data?.roleData?.[0]?.name === "Super Admin" && (
                                <Col>
                                    <Select
                                        style={{ width: "100%" }}
                                        placeholder="Search by manager"
                                        className="input-size"
                                        size="large"
                                        showSearch
                                        value={selectedOptions?.manager_id || null}
                                        onChange={(value) => onChange(value, "manager_id")}
                                        filterOption={(input, option) =>
                                            option?.props?.children
                                                ?.toLowerCase()
                                                .indexOf(input?.toLowerCase()) !== -1
                                        }
                                        allowClear
                                    >
                                        {managerFilterList &&
                                            managerFilterList?.map((option, index) => (
                                                <Option value={option?._id} key={index}>
                                                    {option?.manager}
                                                </Option>
                                            ))}
                                    </Select>
                                </Col>
                            )}
                            {(userData?.data?.roleData?.[0]?.name === "Super Admin" ||
                                userData?.data?.roleData?.[0]?.name === "Manager") && (
                                    <Col>
                                        <Select
                                            style={{ width: "100%" }}
                                            placeholder="Search by team member"
                                            className="input-size"
                                            size="large"
                                            showSearch
                                            value={selectedOptions?.added_by || null}
                                            onChange={(value) => onChange(value, "added_by")}
                                            filterOption={(input, option) =>
                                                option?.props?.children
                                                    ?.toLowerCase()
                                                    .indexOf(input?.toLowerCase()) !== -1
                                            }
                                            allowClear
                                        >
                                            {teamMemberList &&
                                                teamMemberList?.map((option, index) => (
                                                    <Option value={option?._id} key={index}>
                                                        {option?.name}
                                                    </Option>
                                                ))}
                                        </Select>
                                    </Col>
                                )}
                            {/* <Col>
                        <DatePicker
                            size="large"
                            style={{ width: "100%", height: "40px" }}
                            format="YYYY-MM-DD"
                            className="input-size"
                            value={
                                selectedOptions?.date
                                    ? dayjs(selectedOptions?.date, "YYYY-MM-DD")
                                    : ""
                                // selectedOptions?.date?.[0] && selectedOptions?.date?.[1]
                                //     ? [
                                //         dayjs(selectedOptions?.date?.[0], "YYYY-MM-DD"),
                                //         dayjs(selectedOptions?.date?.[1], "YYYY-MM-DD"),
                                //     ]
                                //     : []
                            }
                            onChange={(date, dateString) => onChange(dateString, "date")}
                            allowClear
                        />
                    </Col> */}
                        </Row>
                    )}
                    <Row className="g-2 mb-3">
                        <Col>
                            <Card title={<span className="d-flex justify-content-between">
                                <span>Performance Statistics (Numbers)</span>
                                <span>1 Apr {getFinancialYear()} - Present</span>
                            </span>} bordered={false}>
                                <div className="row">
                                    <div className="col-md-4 col-4">
                                        <span>
                                            Profiles Sent
                                            <span>
                                                <h6 className="col-indigo fw-bold">
                                                    {statisticData?.financialYearData?.[0]?.no_of_profiles_sent || 0}
                                                </h6>
                                            </span>
                                        </span>
                                    </div>
                                    <div className="col-md-4 col-4">
                                        <span>
                                            Expected Calls
                                            <span>
                                                <h6 className="col-indigo fw-bold">
                                                    {statisticData?.financialYearData?.[0]?.no_of_calls_expected || 0}
                                                </h6>
                                            </span>
                                        </span>
                                    </div>
                                    <div className="col-md-4 col-4">
                                        <span>
                                            Completed Calls
                                            <span>
                                                <h6 className="col-indigo fw-bold">
                                                    {statisticData?.financialYearData?.[0]?.no_of_calls_completed || 0}
                                                </h6>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                        <Col>
                            <Card title={<span className="d-flex justify-content-between">
                                <span>Performance Statistics (Percentage)</span>
                                <span>1 Apr {getFinancialYear()} - Present</span>
                            </span>} bordered={false}>
                                <div className="row">
                                    <div className="col-md-6 col-6">
                                        <span style={{ whiteSpace: "nowrap" }}>Fill Rate</span>
                                        <h6 className="col-indigo fw-bold">
                                            {formatNumber(
                                                (statisticData?.financialYearData?.[0]?.no_of_profiles_sent /
                                                    statisticData?.financialYearData?.[0]?.no_of_calls_expected) *
                                                100 || 0
                                            )}
                                            %
                                        </h6>
                                    </div>
                                    <div className="col-md-6 col-6">
                                        <span style={{ whiteSpace: "nowrap" }}>Call Rate</span>
                                        <h6 className="col-indigo fw-bold">
                                            {formatNumber(
                                                (statisticData?.financialYearData?.[0]?.no_of_calls_completed /
                                                    statisticData?.financialYearData?.[0]?.no_of_calls_expected) *
                                                100 || 0
                                            )}
                                            %
                                        </h6>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="g-2 mb-3">
                        <Col>
                            <Card title={<span className="d-flex justify-content-between">
                                <span>Performance Statistics (Numbers)</span>
                                <span>{moment().format('MMMM YYYY')}</span>
                            </span>} bordered={false}>
                                <div className="row">
                                    <div className="col-md-4 col-4">
                                        <span>
                                            Profiles Sent
                                            <span>
                                                <h6 className="col-indigo fw-bold">
                                                    {statisticData?.currentMonth?.[0]?.no_of_profiles_sent || 0}
                                                </h6>
                                            </span>
                                        </span>
                                    </div>
                                    <div className="col-md-4 col-4">
                                        <span>
                                            Expected Calls
                                            <span>
                                                <h6 className="col-indigo fw-bold">
                                                    {statisticData?.currentMonth?.[0]?.no_of_calls_expected || 0}
                                                </h6>
                                            </span>
                                        </span>
                                    </div>
                                    <div className="col-md-4 col-4">
                                        <span>
                                            Completed Calls
                                            <span>
                                                <h6 className="col-indigo fw-bold">
                                                    {statisticData?.currentMonth?.[0]?.no_of_calls_completed || 0}
                                                </h6>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                        <Col>
                            <Card title={<span className="d-flex justify-content-between">
                                <span>Performance Statistics (Percentage)</span>
                                <span>{moment().format('MMMM YYYY')}</span>
                            </span>} bordered={false}>
                                <div className="row">
                                    <div className="col-md-6 col-6">
                                        <span style={{ whiteSpace: "nowrap" }}>Fill Rate</span>
                                        <h6 className="col-indigo fw-bold">
                                            {formatNumber(
                                                (statisticData?.currentMonth?.[0]?.no_of_profiles_sent /
                                                    statisticData?.currentMonth?.[0]?.no_of_calls_expected) *
                                                100 || 0
                                            )}
                                            %
                                        </h6>
                                    </div>
                                    <div className="col-md-6 col-6">
                                        <span style={{ whiteSpace: "nowrap" }}>Call Rate</span>
                                        <h6 className="col-indigo fw-bold">
                                            {formatNumber(
                                                (statisticData?.currentMonth?.[0]?.no_of_calls_completed /
                                                    statisticData?.currentMonth?.[0]?.no_of_calls_expected) *
                                                100 || 0
                                            )}
                                            %
                                        </h6>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="g-2 mb-3">
                        <Col>
                            <Card title={<span className="d-flex justify-content-between">
                                <span>Performance Statistics (Numbers)</span>
                                <span>{moment().subtract(1, 'months').format('MMMM YYYY')}</span>
                            </span>} bordered={false}>
                                <div className="row">
                                    <div className="col-md-4 col-4">
                                        <span>
                                            Profiles Sent
                                            <span>
                                                <h6 className="col-indigo fw-bold">
                                                    {statisticData?.previousMonthData?.[0]?.no_of_profiles_sent || 0}
                                                </h6>
                                            </span>
                                        </span>
                                    </div>
                                    <div className="col-md-4 col-4">
                                        <span>
                                            Expected Calls
                                            <span>
                                                <h6 className="col-indigo fw-bold">
                                                    {statisticData?.previousMonthData?.[0]?.no_of_calls_expected || 0}
                                                </h6>
                                            </span>
                                        </span>
                                    </div>
                                    <div className="col-md-4 col-4">
                                        <span>
                                            Completed Calls
                                            <span>
                                                <h6 className="col-indigo fw-bold">
                                                    {statisticData?.previousMonthData?.[0]?.no_of_calls_completed || 0}
                                                </h6>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                        <Col>
                            <Card title={<span className="d-flex justify-content-between">
                                <span>Performance Statistics (Percentage)</span>
                                <span>{moment().subtract(1, 'months').format('MMMM YYYY')}</span>
                            </span>} bordered={false}>
                                <div className="row">
                                    <div className="col-md-6 col-6">
                                        <span style={{ whiteSpace: "nowrap" }}>Fill Rate</span>
                                        <h6 className="col-indigo fw-bold">
                                            {formatNumber(
                                                (statisticData?.previousMonthData?.[0]?.no_of_profiles_sent /
                                                    statisticData?.previousMonthData?.[0]?.no_of_calls_expected) *
                                                100 || 0
                                            )}
                                            %
                                        </h6>
                                    </div>
                                    <div className="col-md-6 col-6">
                                        <span style={{ whiteSpace: "nowrap" }}>Call Rate</span>
                                        <h6 className="col-indigo fw-bold">
                                            {formatNumber(
                                                (statisticData?.previousMonthData?.[0]?.no_of_calls_completed /
                                                    statisticData?.previousMonthData?.[0]?.no_of_calls_expected) *
                                                100 || 0
                                            )}
                                            %
                                        </h6>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
}

export default ProjectStats;
