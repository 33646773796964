import React, { useState, useRef, useEffect, useCallback, useMemo } from "react";
import { Helmet } from "react-helmet";
import Sidebar from "../../components/Sidebar";
import { Col, Modal, Row } from "react-bootstrap";
import {
  Card,
  Drawer,
  Dropdown,
  Form,
  Input,
  Select,
  Table,
  Tag,
  Tooltip,
  notification,
} from "antd";
import { PiDotsThreeBold } from "react-icons/pi";

import AddExpertSvg from "../../assests/images/addExpert.svg";
import AvatarGroup from "react-avatar-group";
import { MdDelete, MdOutlineClose } from "react-icons/md";
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from "react-icons/ai";
import CloseProject from "./CloseProject";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { useLocation, useNavigate } from "react-router-dom";
import API_PATH from "../../Constants/api-path";
import axios from "axios";
import { BsChevronLeft } from "../../Utils/Icons";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { countryOptions } from "../../Constants/CountryOptions";
import DebounceSelect from "../CloudFeatures/InternalMeetings/Components/DebounceSelect";
import { formatNumber } from "../../Utils/util";

function ProjectOverview() {
  const navigate = useNavigate();

  const [handleDeleteProject, setDeleteProject] = useState(false);
  const [handleDeleteExpert, setDeleteExpert] = useState(false);
  const [handleCloseProject, setCloseProject] = useState(false);
  const [updatedStatus, setUpdatedStatus] = useState(2);
  const [show, setShow] = useState(false);
  const [teamProject, setTeamProject] = useState(false);
  const editorRef = useRef(null);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [, setLoading] = useState(false);
  const [liveProject, setliveProject] = useState([]);
  const [phone, setPhone] = useState("");

  const [intData, setInvtData] = useState("");
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  let query = useQuery();
  let project_id = query.get("project_id");

  let status = query.get("status");
  const [form] = Form.useForm();
  const [emailList, setEmailList] = useState([]);
  const [messages, setMessage] = useState("");
  const [, setSpinner] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [, setExprtList] = useState([]);
  const [expertIdEdit, setExpertIdEdit] = useState("");
  const [emailError, setEmailError] = useState(null);

  const [addExpert, setAddExpert] = useState({
    expert_name: "",
    linkedin_url: "",
    contact_number: "",
    email: "",
    contact_code: "",
    rate: "",
    currency: "",
    added_by: "",
    project_id: "",
    remark: "",
    by_LinkedinAccount: "",
    country: "",
  });

  const handleInputChangeEmail = (e, index) => {
    const { name, value } = e.target;
    const list = [...emailList];
    list[index][name] = value;
    checkEmail(list[index].email);
    setEmailList(list);
  };

  const handleRemoveClick = (index) => {
    const list = [...emailList];
    list.splice(index, 1);
    setEmailList(list);
  };

  const handleAddClick = () => {
    setEmailList([...emailList, { email: "" }]);
  };

  const checkEmail = (email) => {
    const regEx = /[@]/;
    if (!regEx.test(email)) {
      setMessage("");
    } else {
      return setMessage("Email is invalid");
    }
  };

  const handleSubmit = async () => {
    setSpinner(true);

    let emailData = [];
    for (var Email in emailList) {
      if (emailList[Email]?.email !== "")
        emailData?.push(emailList[Email]?.email);
    }

    let payload = {
      project_id: project_id,
      invite_team_dashboard: emailList?.map((email) => email?.value?.split("@")?.[0]),
    };
    if (payload.invite_team?.length === 0 || emailData?.length === 0) {
      notification.warning({
        message: "Warning: All fields are mandatory",
        icon: <WarningOutlined style={{ color: "#fff" }} />, // Custom warning icon
        style: {
          backgroundColor: "#f39c12", // Yellow background color
          color: "#fff", // Text color
          border: "1px solid #d35400", // Border color (optional)
          padding: "10px", // Padding (optional)
        },
        duration: 5, // Duration in seconds
        placement: "topRight", // Position of the notification
      });
    } else {
      try {
        const res = await fetch(`${API_PATH.TEAM_PROJECT_INVITE}`, {
          method: "POST",
          body: JSON.stringify(payload),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          },
        });

        const result = await res.json();
        if (result) {
          if (result?.status === 200 || result?.status === true) {
            setTeamProject(false);
            GetLiveProjectList()
            notification.success({
              message: "Team member has been successfully invited",
              icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
              style: {
                backgroundColor: "#2ecc71",
                color: "#fff !important",
                border: "1px solid #52c41a",
              },
              duration: 5,
              placement: "topRight",
            });
          } else {
            notification.error({
              message: "Error: Something went wrong server error",
              icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
              style: {
                backgroundColor: "#e74c3c",
                color: "#fff",
                border: "1px solid #c0392b",
              },
              duration: 5,
              placement: "topRight",
            });
          }
        }
      } catch (error) {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    }
  };

  const convertDate = (date) => {
    if (date !== null && date !== "") {
      var d = new Date(date);
      var day = d.getDate();
      var x = d.toDateString().substr(4, 3);
      var year = d.getFullYear();
      let p = `${x} ${day}, ${year}`;
      return p;
    } else {
      return "---------";
    }
  };

  const editName = (email) => {
    if (typeof email !== "string") {
      return "NoName";
    }

    let emailID = email.split("@")[0];
    let [firstName, lastName] = emailID.split(".");
    firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);
    lastName = lastName
      ? lastName.charAt(0).toUpperCase() + lastName.slice(1)
      : "";
    return `${firstName} ${lastName}`.trim();
  };

  const GetLiveProjectList = async () => {
    setLoading(true);
    try {
      const res = await fetch(`${API_PATH.TEAM_PROJECT_ADD}/${project_id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
      });

      const result = await res.json();
      if (result) {
        setliveProject(result?.data);
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  };

  const CloseTeamProject = async () => {
    setLoading(true);
    try {
      const res = await fetch(`${API_PATH?.TEAM_PROJECT_ADD}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
        body: JSON.stringify({
          project_id: project_id,
          status: status,
        }),
      });

      const result = await res?.json();
      if (result) {
        if (result && (res.status === 200 || res.status === true)) {
          // setShow(false);
          // GetLiveProjectList();
          // GetCompleteProjectList();
          // setTimeout(() => {
          //   window.location.reload();
          // }, 1000);
          notification.success({
            message: "Project has been closed",
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#2ecc71",
              color: "#fff !important",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          });
        }
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }

    setLoading(false);
  };


  useEffect(() => {
    GetLiveProjectList();
  }, []);

  const getTeamIdEdit = (_id) => {
    setExpertIdEdit(_id);
  };

  const columns = [
    {
      title: "Sr.",
      dataIndex: "sr",
      key: "sr",
      render: (text, record, rowIndex) => rowIndex + 1,
    },
    {
      title: "Expert Name",
      dataIndex: "expert_name",
      key: "name",
      render: (text, record) => {
        const truncatedTitle = record?.expert_name
          ? record.expert_name.split(" ").slice(0, 4).join(" ")
          : "";

        // Add three dots at the end if the title was truncated
        const displayTitle =
          truncatedTitle !== record?.expert_name
            ? truncatedTitle + "..."
            : truncatedTitle;
        return (
          <>
            <Tooltip
              color="#5746EB"
              title={record?.expert_name}
              placement="topLeft"
            >
              <h5 className="projectTitle">{displayTitle}</h5>
            </Tooltip>
            {convertDate(record?.created_at)}
          </>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "name",
      render: (text, record) => (
        <>
          <p>{record?.email}</p>
        </>
      ),
    },
    {
      title: "Contact Number",
      dataIndex: "contact_number",
      key: "name",
      render: (text, record) => {
        const contactCode = record?.contact_code;
        const contactNumber = record?.contact_number;

        if (contactCode === "") {
          return `+${contactNumber}`;
        } else if (contactCode && contactNumber) {
          return `+${contactCode} ${contactNumber}`;
        } else if (contactCode) {
          return contactCode?.startsWith("+")
            ? `${contactCode} ${contactNumber}`
            : `+${contactCode} ${contactNumber}`;
        } else if (contactNumber) {
          return contactNumber;
        } else if (contactCode === "" && contactNumber === "") {
          return "-";
        } else {
          <></>;
        }
      },
    },
    {
      title: "Expert Rate",
      dataIndex: "rate",
      key: "name",
      render: (text, record) => `${record?.currency} ${record?.rate}`,
    },
    {
      title: "Added By",
      dataIndex: "addedBy",
      key: "addedBy",
      render: (text, record) => {
        const addedBy = record?.expertAddedBy;
        if (!addedBy) {
          // Handle the case when 'addedBy' is not defined
          return null; // or any appropriate fallback
        }

        const { name, email } = record?.expertAddedBy;
        const avatarName = name || email;

        return (
          <AvatarGroup
            avatars={[avatarName]}
            uppercase={true}
            className="team-info"
            initialCharacters={2}
            fontColor="#f33636"
            max={2}
            // bold={true}
            size={40}
            fontSize=".38"
            backgroundColor="#EFEFEF"
          />
        );
      },
    },
    {
      title: "Action",
      dataIndex: "_id",
      key: "action",
      render: (_id) => (
        <>
          <button
            onClick={() => {
              toggleDrawer();
              getTeamIdEdit(_id);
            }}
            style={{ fontWeight: "600", color: "#5746EC" }}
            className="btn"
          >
            View
          </button>
        </>
      ),
    },
  ];

  const getInvite = (inviteData) => {
    setInvtData(inviteData);
  };

  const removeNextynFromEmail = (email) => {
    if (email && email.includes("@nextyn.com")) {
      return email.replace("@nextyn.com", "");
    }
    return email;
  };

  const DeleteInvite = async () => {
    setLoading(true);
    try {
      const res = await fetch(`${API_PATH.TEAM_PROJECT_INVITE_REMOVE}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
        body: JSON.stringify({
          project_id: project_id,
          invite_team_dashboard: intData,
        }),
      });
      const result = await res.json();
      if (result) {
        if (result.status === 200 || result.status === true) {
          setShow(false);
          setDeleteProject(false)
          notification.success({
            message: "Team member has been removed",
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#2ecc71",
              color: "#fff !important",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          });
          // window.location.reload();
          GetLiveProjectList()
        } else {
          setShow(false);
          setDeleteProject(false)
          notification.error({
            message: "Error: Something went wrong server error",
            icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
            style: {
              backgroundColor: "#e74c3c",
              color: "#fff",
              border: "1px solid #c0392b",
            },
            duration: 5,
            placement: "topRight",
          });
        }
      }
    } catch (error) {
      setLoading(false);
      setDeleteProject(false)
      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
  };

  const handleInput = (e) => {
    const key = e.target.name;
    const value = e.target.value;

    setAddExpert({ ...addExpert, [key]: value });

    if (key === "email") {
      setEmailError(null); // Reset the email error message
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(value)) {
        setEmailError("Invalid email address");
      }
    }
  };


  function handleSelectChangeCurr(value) {
    const selectValue = value;
    setAddExpert({ ...addExpert, currency: selectValue });
  }

  const AddTeamExpert = async () => {
    setLoading(true);

    try {
      const requestData = {
        expert_name: addExpert?.expert_name,
        linkedin_url: addExpert?.linkedin_url,
        contact_number: addExpert?.contact_number,
        email: addExpert?.email,
        contact_code: addExpert?.contact_code,
        country: addExpert?.country,
        rate: addExpert?.rate,
        currency: addExpert?.currency,
        added_by: userData?.data?._id,
        project_id: project_id,
        remark: addExpert?.remark,
        by_LinkedinAccount: addExpert?.by_LinkedinAccount,
      };

      const requestDataEdit = {
        expert_name: addExpert?.expert_name,
        linkedin_url: addExpert?.linkedin_url,
        contact_number: addExpert?.contact_number,
        email: addExpert?.email,
        contact_code: addExpert?.contact_code,
        country: addExpert?.country,
        rate: addExpert?.rate,
        currency: addExpert?.currency,
        added_by: userData?.data?._id,
        project_id: project_id,
        remark: addExpert?.remark,
        by_LinkedinAccount: addExpert?.by_LinkedinAccount,
        team_project_experts_id: expertIdEdit,
      };

      if (expertIdEdit) {
        const res = await fetch(`${API_PATH.TEAM_EXPERT_EDIT}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          },
          body: JSON.stringify(requestDataEdit),
        });

        const result = await res.json();
        if (result) {
          if (result && (res.status === 200 || res.status === true)) {
            setShow();
            toggleDrawer();
            GetExpertList();
            setTimeout(() => {
              window.location.reload();
            }, 1000);
            notification.success({
              message: "Expert has been updated",
              icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
              style: {
                backgroundColor: "#2ecc71",
                color: "#fff !important",
                border: "1px solid #52c41a",
              },
              duration: 5,
              placement: "topRight",
            });
          }
        }
      } else {
        const res = await fetch(`${API_PATH.TEAM_EXPERT_ADD}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          },
          body: JSON.stringify(requestData),
        });

        const result = await res.json();
        if (result) {
          if (result?.status === 200 || result?.status === true) {
            setShow();
            GetExpertList();
            setTimeout(() => {
              window.location.reload();
            }, 1000);
            notification.success({
              message: "Expert has been added",
              icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
              style: {
                backgroundColor: "#2ecc71",
                color: "#fff !important",
                border: "1px solid #52c41a",
              },
              duration: 5,
              placement: "topRight",
            });
          }
        }
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }

    setLoading(false);
  };

  const GetExpertList = useCallback(async () => {
    setLoading(true);
    try {
      const res = await fetch(`${API_PATH.TEAM_EXPERT_LIST}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
      });

      const result = await res.json();
      if (result) {
        setExprtList(result?.data);
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  }, [userData?.token]);

  const fetchData = useCallback(async (expertIdEdit) => {
    try {
      const res = await axios?.get(
        `${API_PATH?.TEAM_EXPERT_BY_ID}/${expertIdEdit}`
      );

      const projectData = res?.data?.data;
      if (projectData) {
        setAddExpert({
          expert_name: projectData?.expert_name,
          linkedin_url: projectData?.linkedin_url,
          contact_number: projectData?.contact_number,
          email: projectData?.email,
          contact_code: projectData?.contact_code,
          rate: projectData?.rate,
          currency: projectData?.currency,
          remark: projectData?.remark,
          by_linkedinAccount: projectData?.by_linkedinAccount,
        });

        form.setFieldsValue({
          email: projectData?.email,
        });

        const phoneValue = `+${projectData?.contact_code}${projectData?.contact_number}`;

        setPhone(phoneValue);
      } else {
        setAddExpert({
          expert_name: "",
          linkedin_url: "",
          contact_number: "",
          email: "",
          contact_code: "",
          rate: "",
          currency: "",
          added_by: "",
          project_id: "",
          remark: "",
          by_LinkedinAccount: "",
        });
        setPhone("");
      }

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }, [form]);

  const DeleteExpert = async () => {
    setLoading(true);

    try {
      const res = await fetch(
        `${API_PATH.TEAM_EXPERT_DELETE}/${expertIdEdit}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          },
        }
      );

      const result = await res.json();
      if (result) {
        if (result.status === 200 || result.status === true) {
          setShow(false);
          notification.success({
            message: "Expert has been removed",
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#2ecc71",
              color: "#fff !important",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          });
          window.location.reload();
        } else {
          notification.error({
            message: "Error: Something went wrong server error",
            icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
            style: {
              backgroundColor: "#e74c3c",
              color: "#fff",
              border: "1px solid #c0392b",
            },
            duration: 5,
            placement: "topRight",
          });
        }
      }
    } catch (error) {
      setLoading(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    GetExpertList();
    if (expertIdEdit && expertIdEdit?.length > 0) {
      fetchData(expertIdEdit);
      setLoading(false);
    } else {
      setAddExpert({
        expert_name: "",
        linkedin_url: "",
        contact_number: "",
        email: "",
        contact_code: "",
        rate: "",
        currency: "",
        added_by: "",
        project_id: "",
        remark: "",
        by_LinkedinAccount: "",
      });
    }
  }, [GetExpertList, expertIdEdit, fetchData]);


  const handlePhoneChange = (value, country) => {

    setPhone(value);

    const selectedCountry = countryOptions?.find(
      (option) => option?.code === `+${country?.dialCode}`
    );

    if (selectedCountry) {
      setAddExpert((prevStep3) => ({
        ...prevStep3,
        contact_code: country?.dialCode,
        contact_number: value?.replace(country?.dialCode, ""),
        country: selectedCountry?.name,
      }));
    }
  };

  const toggleDrawer = () => {
    setIsDrawerVisible(!isDrawerVisible);
  };

  const handleIconClick = () => {
    navigate(-1);
  };

  const emailRules = [
    {
      type: "email",
      message: "Please enter a valid email address!",
    },
  ];

  const itemsActive = [
    {
      key: '2',
      label: (
        <div className="mt-0 pt-0">
          <span
            onClick={() => {
              setCloseProject(true)
              setUpdatedStatus(2)
            }}
            className=""
          >
            Close Project
          </span>
        </div>
      ),
    },
    {
      key: '3',
      label: (
        <div className="mt-0 pt-0">
          <span
            onClick={() => {
              setCloseProject(true)
              setUpdatedStatus(3)
            }}
            className=" "
          >
            Hold Project
          </span>
        </div>
      ),
    },
  ]

  const itemsHold = [
    {
      key: '2',
      label: (
        <div className="mt-0 pt-0">
          <span
            onClick={() => {
              setCloseProject(true)
              setUpdatedStatus(2)
            }}
            className=""
          >
            Close Project
          </span>
        </div>
      ),
    },
    {
      key: '1',
      label: (
        <div className="mt-0 pt-0">
          <span
            onClick={() => {
              setCloseProject(true)
              setUpdatedStatus(1)
            }}
            className=" "
          >
            Active Project
          </span>
        </div>
      ),
    },
  ]
  const items = liveProject?.status === 1 ? itemsActive : liveProject?.status === 3 ? itemsHold : []



  return (
    <>
      <Helmet>
        <title>{"Team completed projects | Nextyn Platform"}</title>
      </Helmet>
      <div className="page-wrapper chiller-theme toggled">
        <div className="main-sec d-lg-flex">
          <div className="sidebar-menu" id="navAccordion">
            <Sidebar />
          </div>
        </div>
        <main className="body-total content-wrapper float-start w-100">
          <div>
            <div className="bk-div float-start w-100">
              <div className="body-expart-div projectOverview">
                <div className="d-md-flex project-sec-1 top-ps-seection justify-content-between align-items-center m-queries">
                  <h2
                    className="d-flex align-items-center"
                    onClick={handleIconClick}
                    style={{ cursor: "pointer" }}
                  >
                    <BsChevronLeft className="me-2 search-btn-back" />
                    Project Overview
                  </h2>
                </div>
                <Row>
                  <Row className="g-3 teamProjectOverview">


                    {/* </Row> */}

                    {/* <Col xl={6}>
                        <Card className="d-flex ant-card-geog">
                          <div className="d-flex align-items-center gap-2 justify-content-center">
                            <span>
                              <h4 className="m-0">Profiles Sent</h4>
                              <hr />
                              <li>{liveProject?.no_of_profiles_sent}</li>
                            </span>
                            <span>
                              <h4 className="m-0">Expected Calls</h4>
                              <hr />
                              <span >{liveProject?.no_of_calls_expected} </span>
                            </span>
                            <span>
                              <h4 className="m-0">Completed calls</h4>
                              <hr />
                              <li>{liveProject?.no_of_calls_completed}</li>
                            </span>
                          </div>
                        </Card>
                      </Col>
                      <Col xl={3}>
                        <Card className="d-flex ant-card-geog">
                          <div className="d-flex align-items-center gap-2 justify-content-center">
                            <span>
                              <h4 className="m-0">Fill Rate</h4>
                              <hr />
                              <li>{(((liveProject?.no_of_profiles_sent || 0) / (liveProject?.no_of_calls_expected || 0)) * 100)?.toFixed(2)}%</li>
                            </span>
                            <span>
                              <h4 className="m-0">Call Rate</h4>
                              <hr />
                              <span >{((liveProject?.no_of_calls_completed / liveProject?.no_of_calls_expected) * 100)?.toFixed(2)}%</span>
                            </span>
                          </div>
                        </Card>
                      </Col> */}

                    {/* {liveProject?.no_of_calls_expected ? <Col xl={3}>
                        <Card className="ant-card-geog">
                          <h4 className="m-0">Expected Calls</h4>
                          <hr />
                          <span >{liveProject?.no_of_calls_expected} ({((liveProject?.no_of_calls_completed / liveProject?.no_of_calls_expected) * 100)?.toFixed(2)}%)</span>
                        </Card>
                      </Col> : ""}
                      {liveProject?.no_of_calls_completed ? <Col xl={3}>
                        <Card className="ant-card-geog">
                          <h4 className="m-0">Completed calls</h4>
                          <hr />
                          <li>{liveProject?.no_of_calls_completed}</li>
                        </Card>
                      </Col> : ""} */}
                  </Row>
                  <Col>
                    <Card className="projectOverView ant-card">
                      <h4 className="mt-0 pt-0" style={{ color: "#5746EB" }}>
                        {liveProject?.project_title}
                      </h4>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: liveProject?.overview,
                        }}
                      ></p>
                    </Card>
                    {/* {liveProject?.geography && (
                      <Col className="col-xl-4">
                        <Card
                          className="ant-card-geog"
                          title="Geography"
                          bordered={false}
                        >
                        
                          <ol className="projectOl">
                            {liveProject?.geography
                              ?.split(",")
                              ?.map((item, index) => (
                                <li key={index}>{item}</li>
                              ))}
                          </ol>
                        </Card>
                      </Col>
                    )} */}

                  </Col>

                  <Col xs={12} lg={6} xl={5} xxl={4}>
                    <Row>
                      <Col>
                        <Card
                          title="Performance Statistics (Numbers)"
                          bordered={false}
                        >
                          <div>
                            <div className="d-flex align-items-center justify-content-between">
                              <span>Profiles Sent</span>
                              <span className="col-indigo fw-bold">
                                {formatNumber(liveProject?.no_of_profiles_sent || 0)}
                              </span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                              <span>Expected Calls</span>
                              <span className="col-indigo fw-bold">
                                {formatNumber(liveProject?.no_of_calls_expected || 0)}
                              </span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                              <span>Completed Calls</span>
                              <span className="col-indigo fw-bold">
                                {formatNumber(liveProject?.no_of_calls_completed || 0)}
                              </span>
                            </div>
                          </div>
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <Col >
                        <Card
                          title="Performance Statistics (Percentages)"
                          bordered={false}
                        >
                          <div className="row">
                            <div className="d-flex align-items-center justify-content-between">
                              <span style={{ whiteSpace: "nowrap" }}>
                                Fill Rate
                              </span>
                              <span className="col-indigo fw-bold">
                                {formatNumber(
                                  (liveProject?.no_of_profiles_sent /
                                    liveProject?.no_of_calls_expected) *
                                  100 || 0
                                )}
                                %
                              </span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                              <span style={{ whiteSpace: "nowrap" }}>
                                Call Rate
                              </span>
                              <span className="col-indigo fw-bold">
                                {formatNumber(
                                  (liveProject?.no_of_calls_completed /
                                    liveProject?.no_of_calls_expected) *
                                  100 || 0
                                )}
                                %
                              </span>
                            </div>
                          </div>
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Card
                          className="ant-card"
                          style={{ padding: "0 0 10px" }}
                        >
                          <div className="d-flex align-items-center justify-content-between mt-0 mb-3 pt-0">
                            <h4 className="text-center m-0">Project Details</h4>
                            <Dropdown
                              menu={{
                                items
                              }}
                              placement="bottomLeft"
                              arrow
                            >
                              <PiDotsThreeBold />
                            </Dropdown>


                          </div>
                          {liveProject?.created_at && (
                            <>
                              <div className="d-flex align-items-center justify-content-between">
                                <p>Created At:</p>
                                <p>{convertDate(liveProject?.created_at)}</p>
                              </div>
                            </>
                          )}

                          {liveProject?.project_start && (
                            <>
                              <hr className="mt-1 mb-1" />
                              <div className="d-flex align-items-center justify-content-between">
                                <p>Project Start Date:</p>
                                <p>{convertDate(liveProject?.project_start)}</p>
                              </div>
                            </>
                          )}

                          {liveProject?.project_deadline && (
                            <>
                              <hr className="mt-1 mb-1" />
                              <div className="d-flex align-items-center justify-content-between">
                                <p>Deadline:</p>
                                <p>
                                  {convertDate(liveProject?.project_deadline)}
                                </p>
                              </div>
                            </>
                          )}

                          {liveProject?.updated_at && (
                            <>
                              <hr className="mt-1 mb-1" />
                              <div className="d-flex align-items-center justify-content-between">
                                <p>Last Updated At:</p>
                                <p>{convertDate(liveProject?.updated_at)}</p>
                              </div>
                            </>
                          )}

                          {liveProject?.addedBy?.name && (
                            <>
                              <hr className="mt-1 mb-1" />
                              <div className="d-flex align-items-center justify-content-between">
                                <p>Added By:</p>
                                <p>{liveProject?.addedBy?.name}</p>
                              </div>
                            </>
                          )}
                          {liveProject?.linkedin_account && (
                            <>
                              <hr className="mt-1 mb-1" />
                              <div className="d-flex align-items-center justify-content-between">
                                <p>Linkedin Account:</p>
                                <span
                                // href={liveProject?.linkedin_account}
                                // target="_blank"
                                >
                                  {liveProject?.linkedin_account}
                                </span>
                              </div>
                            </>
                          )}

                          {liveProject?.platform && (
                            <>
                              <hr className="mt-1 mb-1" />
                              <div className="d-flex align-items-center justify-content-between">
                                <p>Platform Used:</p>
                                <p>{liveProject?.platform}</p>
                              </div>
                            </>
                          )}
                          {liveProject?.client_name && (
                            <>
                              <hr className="mt-1 mb-1" />
                              <div className="d-flex align-items-center justify-content-between">
                                <p>Client:</p>
                                <p>{liveProject?.client_name}</p>
                              </div>
                            </>
                          )}
                          {liveProject?.geography && (
                            <>
                              <hr className="mt-1 mb-1" />
                              <div className="d-flex align-items-center justify-content-between">
                                <p>Geography of experts:</p>
                                <p>{liveProject?.geography}</p>
                              </div>
                            </>
                          )}
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Card
                          className="ant-card"
                          style={{ padding: "0 0 10px" }}
                        >
                          <div className="d-flex align-items-center justify-content-between text-center mb-3">
                            <h4 className="m-0">Client team Emails</h4>
                          </div>
                          <div className="client-teams-ul-li">
                            <ul className="list-unstyled">
                              {liveProject?.clientteamemail?.map((item, index) => (
                                <>
                                  {item && (
                                    <li>
                                      <div className="teams-li d-flex align-items-center">
                                        <figure>
                                          <div
                                            key={index}
                                            className="avatarImg d-flex"
                                          >
                                            <img
                                              width="40px"
                                              src={`https://ui-avatars.com/api/?&rounded=true&font-size=0.38&background=EFEFEF&color=f33636&name=${editName(
                                                item?.name || "NoName"
                                              )}`}
                                              alt={item?.name || "NoName"}
                                            />
                                          </div>
                                        </figure>
                                        <h5
                                          className="mb-0 ms-2 mt-0"
                                          key={index}
                                        >
                                          {item
                                            ? item?.name || "-"
                                            : null}
                                          {item && (
                                            <>
                                              <span className="d-block">{`${item?.email}`}</span>
                                            </>
                                          )}
                                          <span
                                            className="d-block"
                                            style={{ color: "#c40a36" }}
                                          >
                                            {item?.Invite === "Invite pending"
                                              ? `Invite pending (sent ${convertDate(
                                                item?.created_at
                                              )})`
                                              : null}
                                          </span>
                                        </h5>
                                      </div>
                                      {/* <div className="dropdown">
                                          <div>
                                            <a className="ant-dropdown-trigger">
                                              <span
                                                role="img"
                                                aria-label="ellipsis"
                                                tabindex="-1"
                                                className="anticon anticon-ellipsis"
                                                style={{ fontSize: "20px" }}
                                              >
                                                <Tooltip
                                                  color="#5746EB"
                                                  title="Delete"
                                                  placement="top"
                                                >
                                                  <MdDelete
                                                    style={{
                                                      fontSize: "20px",
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                      setDeleteProject(true);
                                                      getInvite(
                                                        removeNextynFromEmail(
                                                          item
                                                        )
                                                      );
                                                    }}
                                                  />
                                                </Tooltip>
                                              </span>
                                            </a>
                                          </div>
                                        </div> */}
                                    </li>
                                  )}
                                </>
                              ))}
                              {liveProject?.clientemail && (
                                <li>
                                  <div className="teams-li d-flex align-items-center">
                                    <figure>
                                      <div
                                        key={"343534"}
                                        className="avatarImg d-flex"
                                      >
                                        <img
                                          width="40px"
                                          src={`https://ui-avatars.com/api/?&rounded=true&font-size=0.38&background=EFEFEF&color=f33636&name=${editName(
                                            liveProject?.clientemail?.[0]?.name ||
                                            "NoName"
                                          )}`}
                                          alt={
                                            liveProject?.clientemail?.[0]?.name ||
                                            "NoName"
                                          }
                                        />
                                      </div>
                                    </figure>
                                    <h5 className="mb-0 ms-2 mt-0" key={343534}>
                                      {liveProject?.clientemail?.[0]?.name
                                        ? liveProject?.clientemail?.[0]?.name
                                        : null}{" "}
                                      <Tag
                                        size="small"
                                        color="#5746ec"
                                        style={{
                                          color: "white",
                                          fontSize: "10px",
                                          paddingTop: "0px",
                                        }}
                                        bordered={true}
                                      >
                                        POC
                                      </Tag>
                                      {liveProject?.clientemail && (
                                        <>
                                          <span className="d-block">{`${liveProject?.clientemail?.[0]?.email}`}</span>
                                        </>
                                      )}
                                      <span
                                        className="d-block"
                                        style={{ color: "#c40a36" }}
                                      >
                                        {liveProject?.client_email?.Invite ===
                                          "Invite pending"
                                          ? `Invite pending (sent ${convertDate(
                                            liveProject?.client_email
                                              ?.created_at
                                          )})`
                                          : null}
                                      </span>
                                    </h5>
                                  </div>
                                  {/* <div className="dropdown">
                                    <div>
                                      <a className="ant-dropdown-trigger">
                                        <span
                                          role="img"
                                          aria-label="ellipsis"
                                          tabindex="-1"
                                          className="anticon anticon-ellipsis"
                                          style={{ fontSize: "20px" }}
                                        >
                                          <Tooltip
                                            color="#5746EB"
                                            title="Delete"
                                            placement="top"
                                          >
                                            <MdDelete
                                              style={{
                                                fontSize: "20px",
                                                cursor: "pointer",
                                              }}
                                              onClick={() => {
                                                setDeleteProject(true);
                                                getInvite(
                                                  removeNextynFromEmail(
                                                    liveProject?.client_email
                                                  )
                                                );
                                              }}
                                            />
                                          </Tooltip>
                                        </span>
                                      </a>
                                    </div>
                                  </div> */}
                                </li>
                              )}
                            </ul>
                          </div>
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Card
                          className="ant-card"
                          style={{ padding: "0 0 10px" }}
                        >
                          <div className="d-flex align-items-center justify-content-between text-center mb-3">
                            <h4 className="m-0">Nextyn</h4>

                            <div className="">
                              <button
                                onClick={() => setTeamProject(true)}
                                className="btn add-pls"
                              >
                                Invite Team
                              </button>
                            </div>
                          </div>
                          <div className="client-teams-ul-li">
                            <ul className="list-unstyled">
                              {liveProject?.invite_team_dashboard
                                ?.split(",")
                                ?.map((item, index) => (
                                  <>
                                    {item && (
                                      <li>
                                        <div className="teams-li d-flex align-items-center">
                                          <figure>
                                            <div
                                              key={index}
                                              className="avatarImg d-flex"
                                            >
                                              <img
                                                width="40px"
                                                src={`https://ui-avatars.com/api/?&rounded=true&font-size=0.38&background=EFEFEF&color=f33636&name=${editName(
                                                  item || item || "NoName"
                                                )}`}
                                                alt={item || item || "NoName"}
                                              />
                                            </div>
                                          </figure>
                                          <h5
                                            className="mb-0 ms-2 mt-0"
                                            key={index}
                                          >
                                            {item
                                              ? editName(item || item)
                                              : null}
                                            {item &&
                                              !item.endsWith("@nextyn.com") ? (
                                              <span className="d-block">{`${item}@nextyn.com`}</span>
                                            ) : (
                                              <>
                                                <span className="d-block">{`${item}`}</span>
                                              </>
                                            )}
                                            <span
                                              className="d-block"
                                              style={{ color: "#c40a36" }}
                                            >
                                              {item?.Invite === "Invite pending"
                                                ? `Invite pending (sent ${convertDate(
                                                  item?.created_at
                                                )})`
                                                : null}
                                            </span>
                                          </h5>
                                        </div>
                                        <div className="dropdown">
                                          <div>
                                            <a className="ant-dropdown-trigger">
                                              <span
                                                role="img"
                                                aria-label="ellipsis"
                                                tabindex="-1"
                                                className="anticon anticon-ellipsis"
                                                style={{ fontSize: "20px" }}
                                              >
                                                <Tooltip
                                                  color="#5746EB"
                                                  title="Delete"
                                                  placement="top"
                                                >
                                                  <MdDelete
                                                    style={{
                                                      fontSize: "20px",
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                      setDeleteProject(true);
                                                      getInvite(
                                                        removeNextynFromEmail(
                                                          item
                                                        )
                                                      );
                                                    }}
                                                  />
                                                </Tooltip>
                                              </span>
                                            </a>
                                          </div>
                                        </div>
                                      </li>
                                    )}
                                  </>
                                ))}
                            </ul>
                          </div>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                {/* when expert not available */}
                {liveProject?.team_project_experts?.length === 0 ? (
                  <>
                    <Row>
                      <Col>
                        <Card className="text-center addExpertCard">
                          <img src={AddExpertSvg} />
                          <p>
                            No records available, click on below button to add
                            experts
                          </p>
                          <button
                            onClick={() => setShow(true)}
                            className="btn mt-3 viewButton"
                          >
                            Add Experts
                          </button>
                        </Card>
                      </Col>
                    </Row>
                  </>
                ) : null}

                {liveProject?.team_project_experts?.length > 0 ? (
                  <>
                    <Row>
                      <Col>
                        <div className="d-flex align-items-center justify-content-between">
                          <h4 style={{ color: "#5746EB" }}>Experts</h4>
                          <button
                            className="btn viewButton"
                            onClick={() => {
                              setShow(true);
                              setExpertIdEdit("");
                            }}
                          >
                            Add Experts
                          </button>
                        </div>
                        <Table
                          dataSource={liveProject?.team_project_experts}
                          columns={columns}
                        ></Table>
                      </Col>
                    </Row>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </main>
      </div>
      <Modal
        show={handleDeleteProject}
        onHide={() => setDeleteProject(false)}
        backdrop="static"
        keyboard={false}
        centered
        size={"lg"}
        scrollable={true}
        dialogClassName={"bootStrapModal"}
        contentClassName={"bootStrapModalContent"}
      >
        <Modal.Body className="position-relative">
          <MdOutlineClose
            className="position-absolute"
            style={{ right: "15px", top: "15px", fontSize: "25px" }}
            onClick={() => setDeleteProject(false)}
          />
          <div className="text-left">
            <h4 className="modalHeader">Remove invitee from project.</h4>
          </div>
          <div>Are you sure you want to remove?</div>
          <div className="d-flex justify-content-end mt-3">
            <button
              className="secondaryBttn btn"
              onClick={() => setDeleteProject(false)}
            >
              No
            </button>
            <button
              className="viewButton btn ms-2"
              onClick={() => DeleteInvite()}
            >
              Yes
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={handleDeleteExpert}
        onHide={() => setDeleteExpert(false)}
        backdrop="static"
        keyboard={false}
        centered
        size={"lg"}
        scrollable={true}
        dialogClassName={"bootStrapModal"}
        contentClassName={"bootStrapModalContent"}
      >
        <Modal.Body className="position-relative">
          <MdOutlineClose
            className="position-absolute"
            style={{ right: "15px", top: "15px", fontSize: "25px" }}
            onClick={() => setDeleteExpert(false)}
          />
          <div className="text-left">
            <h4 className="modalHeader">Delete Expert.</h4>
          </div>
          <div>
            This action cannot be undone. Are you sure you want to delete this
            expert ?
          </div>
          <div className="d-flex justify-content-end mt-3">
            <button
              className="secondaryBttn btn"
              onClick={() => setDeleteExpert(false)}
            >
              No
            </button>
            <button
              className="viewButton btn ms-2"
              onClick={() => DeleteExpert()}
            >
              Yes
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        backdrop="static"
        keyboard={false}
        centered
        size={"lg"}
        scrollable={true}
        dialogClassName={"bootStrapModal"}
        contentClassName={"bootStrapModalContent"}
      >
        <Modal.Body className="position-relative">
          <MdOutlineClose
            className="position-absolute"
            style={{ right: "15px", top: "15px", fontSize: "25px" }}
            onClick={() => setShow(false)}
          />
          <div className="text-left">
            <h4 className="modalHeader">Close Project</h4>
          </div>
          <div>
            Please ensure all expert profiles have been updated before closing
            this project. Are you sure you want to close this project ?
          </div>
          <div className="d-flex justify-content-end mt-3">
            <button
              className="secondaryBttn btn"
              onClick={() => setShow(false)}
            >
              No
            </button>
            <button
              className="viewButton btn ms-2"
              onClick={() => CloseTeamProject()}
            >
              Yes
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={teamProject}
        onHide={() => setTeamProject(false)}
        backdrop="static"
        keyboard={false}
        centered
        size={"md"}
        scrollable={true}
        dialogClassName={"bootStrapModal"}
        contentClassName={"bootStrapModalContent"}
      >
        <Modal.Body className="position-relative">
          <MdOutlineClose
            className="position-absolute"
            style={{ right: "15px", top: "15px", fontSize: "25px" }}
            onClick={() => setTeamProject(false)}
          />
          <div className="text-left">
            <h4 className="modalHeader">Invite Team</h4>
          </div>
          <div>
            <Form layout={"vertical"} form={form}>
              <div className="d-flex justify-content-between align-items-center">
                <Form.Item
                  className="mb-1"
                  name="email"
                  label="Invite Team"

                  rules={[{ required: true }]}
                  style={{ width: "100%" }}
                // key={i}
                >
                  {/* <Input
                        size="large"
                        type="text"
                        id="inlineFormInputGroupUsername"
                        autoComplete="off"
                        name="email"
                        value={x.email}
                        onChange={(e) => handleInputChangeEmail(e, i)}
                        addonAfter="@nextyn.com"
                      /> */}
                  <DebounceSelect
                    type="manager"
                    size="large"
                    optionType="email"
                    name={`project_manager`}
                    mode="multiple"
                    value={emailList}
                    placeholder="Select Managers"
                    style={{ flex: 1, marginRight: '8px' }}
                    virtual={false}
                    onChange={(selectedValue) => {
                      setEmailList(selectedValue)
                    }}
                    allowClear
                  />

                </Form.Item>
                {/* <div>
                      {emailList.length !== 1 && (
                        <AiOutlineMinusCircle
                          className="ms-2"
                          style={{ fontSize: "22px", color: "#5746EB" }}
                          onClick={() => handleRemoveClick(i)}
                        />
                      )}
                      {emailList.length - 1 === i && (
                        <AiOutlinePlusCircle
                          className="ms-2"
                          style={{ fontSize: "22px", color: "red" }}
                          onClick={handleAddClick}
                        />
                      )}
                    </div> */}
              </div>
              {messages === "" ? (
                <></>
              ) : (
                <p className="text-danger" style={{ marginLeft: "3%" }}>
                  {messages}
                </p>
              )}

              {/* <Form.Item
                className="mb-1"
                name="team"
                label="Invite Team"
                rules={[{ required: true }]}
                style={{ width: "100%" }}
              >
                <Input size="large" addonAfter="@nextyn.com" />
              </Form.Item>

              <AiOutlinePlusCircle
                className="ms-2"
                style={{ fontSize: "22px", color: "#5746EB" }}
              /> */}
              {/* <div className="d-flex align-items-center">
                <Form.Item
                  className="mb-1"
                  name="team"
                  label=""
                  style={{ width: "100%" }}
                  rules={[{ required: true }]}
                >
                  <Input size="large" addonAfter="@nextyn.com" />
                </Form.Item>

                <AiOutlineMinusCircle
                  className="ms-2"
                  style={{ fontSize: "22px", color: "red" }}
                />
              </div> */}
            </Form>
          </div>
          <div className="d-flex justify-content-end mt-3">
            <button
              className="secondaryBttn btn"
              onClick={() => setTeamProject(false)}
            >
              Cancel
            </button>
            <button className="viewButton btn ms-2" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={show}
        onHide={() => {
          setShow(false);
          setAddExpert({});
        }}
        backdrop="static"
        keyboard={false}
        centered
        size={"lg"}
        scrollable={true}
        dialogClassName={"bootStrapModal"}
        contentClassName={"bootStrapModalContent"}
      >
        <Modal.Body className="position-relative">
          <MdOutlineClose
            className="position-absolute"
            style={{ right: "15px", top: "15px", fontSize: "25px" }}
            onClick={() => setShow(false)}
          />
          <div className="text-center">
            <h4 className="modalHeader">Add Expert</h4>
          </div>
          <Form layout="vertical">
            <Row>
              <Col>
                <Form.Item label="Expert Name" rules={[{ required: true }]}>
                  <Input
                    type="text"
                    name="expert_name"
                    size="large"
                    value={addExpert?.expert_name}
                    onChange={handleInput}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  label="Expert Email"
                  // name={"email"}
                  rules={emailRules}
                  validateStatus={emailError ? "error" : ""}
                  help={emailError}
                >
                  <Input
                    type="email"
                    name="email"
                    size="large"
                    value={addExpert?.email || ""}
                    onChange={handleInput}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Item label="Linkedin Url">
                  <Input
                    type="text"
                    name="linkedin_url"
                    size="large"
                    value={addExpert?.linkedin_url}
                    onChange={handleInput}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              {/* <Col md={2}>
                <Form.Item label="Contact Code">
                  <Select
                    size="large"
                    placeholder="Select Country Code"
                    name="contact_code"
                    showSearch
                    value={addExpert?.contact_code}
                    onChange={handleSelectChange}
                    virtual={false}
                    allowClear
                  >
                    {countries?.map((country, key) => {
                      return (
                        <>
                          <Select.Option value={country?.phonecode}>
                            {country?.nicename}
                          </Select.Option>
                        </>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col md={4}>
                <Form.Item label="Contact Number">
                  <Input
                    name="contact_number"
                    size="large"
                    type="number"
                    value={addExpert?.contact_number}
                    onChange={handleInput}
                  />
                </Form.Item>
              </Col> */}
              <Col md={6}>
                <Form.Item label="Contact Number">
                  <PhoneInput
                    className="react-tel-input-2"
                    country={"us"}
                    enableSearch={true}
                    value={phone}
                    onChange={(value, country) => {
                      handlePhoneChange(value, country);
                    }}
                    inputStyle={{
                      width: "100%",
                    }}
                  />
                </Form.Item>
              </Col>
              <Col md={2}>
                <Form.Item label="Currency">
                  <Select
                    name="currency"
                    size="large"
                    placeholder="Select Currency"
                    value={addExpert?.currency}
                    onChange={handleSelectChangeCurr}
                  >
                    <Select.Option value="">Select Currency</Select.Option>
                    <Select.Option value="USD">USD</Select.Option>
                    <Select.Option value="EUR">EUR</Select.Option>
                    <Select.Option value="JPY">JPY</Select.Option>
                    <Select.Option value="GBP">GBP</Select.Option>
                    <Select.Option value="AUD">AUD</Select.Option>
                    <Select.Option value="CAD">CAD</Select.Option>
                    <Select.Option value="NZD">NZD</Select.Option>
                    <Select.Option value="SGD">SGD</Select.Option>
                    <Select.Option value="HKD">HKD</Select.Option>
                    <Select.Option value="INR">INR</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col md={4}>
                <Form.Item label="Expert Rate">
                  <Input
                    name="rate"
                    size="large"
                    type="number"
                    value={addExpert?.rate}
                    onChange={handleInput}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col className="projectEditor">
                <CKEditor
                  editor={Editor}
                  data={addExpert?.remark} // Prefill CKEditor with fetched overview data
                  onReady={(editor) => {
                    editorRef.current = editor;
                  }}
                  config={{
                    toolbar: [
                      "bold",
                      "italic",
                      "link",
                      "bulletedList",
                      "numberedList",
                    ],
                  }}
                  onChange={(event, editor) => {
                    const newData = editor.getData();
                    setAddExpert((prevData) => ({
                      ...prevData,
                      remark: newData,
                    }));
                  }}
                  onBlur={(editor) => {
                    console.log("Blur.", editor);
                  }}
                  onFocus={(editor) => {
                    console.log("Focus.", editor);
                  }}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Form.Item
                  label="Linkedin Account"
                  rules={[{ required: true }]}
                >
                  <Input
                    name="by_LinkedinAccount"
                    type="text"
                    placeholder="First name & last name with space"
                    size="large"
                    value={addExpert?.by_linkedinAccount}
                    onChange={handleInput}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <div className="d-flex justify-content-end mt-3">
            <button
              className="secondaryBttn btn"
              onClick={() => setShow(false)}
            >
              Close
            </button>
            <button
              className="viewButton btn ms-2"
              onClick={() => AddTeamExpert()}
            >
              Submit
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <CloseProject
        show={handleCloseProject}
        setShow={setCloseProject}
        status={status}
        updatedStatus={updatedStatus}
        teamIdClosed={project_id}
        GetLiveProjectList={GetLiveProjectList}
      />

      <Drawer
        title={
          <>
            <div style={{ float: "left" }}>Expert Profile</div>
          </>
        }
        placement="right"
        closable={true}
        onClose={toggleDrawer}
        visible={isDrawerVisible}
        width={550}
      >
        {/* Offcanvas content goes here */}
        {/* Replace the content below with your actual content */}
        <div>
          <div className="d-flex justify-content-between align-items-center">
            <div
              className="nameAndCompany_M"
              style={{
                fontWeight: "400",
                fontSize: "15px",
                color: "#333",
                marginTop: "-8px",
              }}
            >
              <strong
                id="nameAndCompany_M"
                style={{
                  fontWeight: "600",
                  fontSize: "15px",
                  color: "#333",
                }}
              >
                {addExpert?.expert_name}
              </strong>
            </div>
            <h2
              style={{
                fontWeight: "400",
                fontSize: "16px",
                color: "#333",
                marginBottom: "10px",
                marginTop: 0,
              }}
            >
              {addExpert?.email}
            </h2>
          </div>
          <div className="d-flex mt-1">
            <a
              onClick={() => setShow(true)}
              style={{ color: "#5746ec", cursor: "pointer" }}
              className="me-3"
            >
              Edit
            </a>
            <a
              onClick={() => setDeleteExpert(true)}
              style={{ color: "red", cursor: "pointer" }}
            >
              Delete
            </a>
          </div>
          <div className="mt-2">
            <div className="expertPanel_3Zo3_">
              <div className="section_3ByhA">
                {addExpert?.linkedin_url !== null &&
                  addExpert?.linkedin_url !== "" &&
                  addExpert?.linkedin_url ? (
                  <>
                    <div className="detailsRows_2038l">
                      <span className="detailsRowLeft_3h_zH">Linkedin</span>
                      <span className="detailsRowRight_3h_zH">
                        <a
                          href={addExpert?.linkedin_url}
                          target="_blank"
                          style={{ color: "#5746EC" }}
                        >
                          View Profile
                        </a>
                      </span>
                    </div>
                  </>
                ) : null}

                {addExpert?.rate !== null &&
                  addExpert?.rate !== "" &&
                  addExpert?.rate ? (
                  <>
                    <div className="detailsRows_2038l">
                      {/* if any field is coming null do not show that part */}
                      <span className="detailsRowLeft_3h_zH">Expert Rate </span>
                      <span className="detailsRowRight_3h_zH">
                        {addExpert?.currency} {addExpert?.rate}/hr
                      </span>
                    </div>
                  </>
                ) : null}

                {addExpert?.by_LinkedinAccount !== null &&
                  addExpert?.by_LinkedinAccount !== "" &&
                  addExpert?.by_LinkedinAccount ? (
                  <>
                    <div className="detailsRows_2038l">
                      <span className="detailsRowLeft_3h_zH">
                        By Linkedin Account
                      </span>
                      <span className="detailsRowRight_3h_zH">
                        {addExpert?.by_LinkedinAccount}
                      </span>
                    </div>
                  </>
                ) : null}

                {addExpert?.contact_number !== null &&
                  addExpert?.contact_number !== "" &&
                  addExpert?.contact_number ? (
                  <>
                    <div className="detailsRows_2038l">
                      <span className="detailsRowLeft_3h_zH">
                        Contact Number
                      </span>
                      <span className="detailsRowRight_3h_zH">
                        +{addExpert?.contact_code} {addExpert?.contact_number}
                      </span>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
          {addExpert?.remark !== null &&
            addExpert?.remark !== "" &&
            addExpert?.remark ? (
            <>
              <div className="overview mt-2">
                <h2
                  style={{
                    fontWeight: "500",
                    fontSize: "15px",
                  }}
                >
                  Overview
                </h2>
                <p dangerouslySetInnerHTML={{ __html: addExpert?.remark }}></p>
              </div>
            </>
          ) : null}
        </div>
      </Drawer>
    </>
  );
}

export default ProjectOverview;
