import {
    PlusOutlined,
} from "@ant-design/icons";
import {
    Button,
    DatePicker,
    Form,
    Input,
    Select,
    Space,
    Divider, notification,


} from "antd";
import {
    ExclamationCircleOutlined,
    CheckCircleOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import "react-h5-audio-player/lib/styles.css";
import "../../App.css";
import "../../assests/css/custom.css";
import { MdOutlineClose } from "react-icons/md";
import API_PATH from "../../Constants/api-path";
import { Modal } from "react-bootstrap";
import "react-phone-input-2/lib/style.css";
// const { RangePicker } = DatePicker;
function UpdateZoomMeetingModal({ editZoomModal, setEditZoomModal, zoomData, setZoomData, zoomAccountList, zoomCompanyList, clientView, setCreateModal, setFilteredData, setViewId, setFilteredCompany, GetZoomCompanyList, setClientView, GetZoomList, viewId }) {

    const [form] = Form.useForm();
    const userData = JSON.parse(localStorage.getItem("userData"));
    const handleNameChange = async (value, option) => {
        const selectedCompanyId = option?.key;
        const selectedDomain = option?.domain;
        setViewId(selectedCompanyId);
        zoomData.client_id = selectedCompanyId;
        zoomData.comapnyName = selectedDomain;
        setFilteredCompany(value || "");
        if (!value) {
            GetZoomCompanyList();
        }
        try {
            const res = await fetch(`${API_PATH.CLIENT_VIEW}/${selectedCompanyId}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userData?.token}`,
                },
            });

            const result = await res.json();
            if (result) {
                setClientView(result?.data);
            } else {
                // Handle error
            }
        } catch (error) {
            // Handle error
        }
    };
    const [loading, setLoading] = useState(false)

    const updateZoomMeeting = async () => {
        // e.preventDefault();
        setLoading(true);
        const formattedZoomData = {
            ...zoomData,
            meetingId: zoomData?.meetingId?.replace(/\s/g, ""),
        };

        try {
            const res = await fetch(`${API_PATH.ZOOM_ADD_MEETING}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userData?.token}`,
                },
                body: JSON.stringify(formattedZoomData),
            });

            const result = await res.json();
            if (result) {
                GetZoomList({ page: 1, key: "" });
                if (res.status === 200 || res.status === true) {
                    form.resetFields();
                    form.setFieldsValue({
                        dateAndTime: [],
                    });
                    setZoomData((prevData) => ({
                        ...prevData,
                        zoomId: "",
                        meetingId: undefined,
                        startDate: [],
                        passcode: "",
                        startTime: [],
                        endDate: [],
                        endTime: [],
                        comapnyName: "",
                        expertDesignation: "",
                        expertCompany: "",
                        client_user: [],
                        client_user_id: [],
                        client_id: viewId,
                        client: "",
                    }));
                    notification.success({
                        message: "Zoom meeting updated successfully",
                        icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
                        style: {
                            backgroundColor: "#2ecc71",
                            color: "#fff !important",
                            border: "1px solid #52c41a",
                        },
                        duration: 5,
                        placement: "topRight",
                    });

                    // window.location.reload();
                }
            } else {
                notification.error({
                    message: "Error: Something went wrong server error",
                    icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
                    style: {
                        backgroundColor: "#e74c3c",
                        color: "#fff",
                        border: "1px solid #c0392b",
                    },
                    duration: 5,
                    placement: "topRight",
                });
            }
        } catch (error) {
            setLoading(false);

            notification.error({
                message: "Error: Something went wrong server error",
                icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
                style: {
                    backgroundColor: "#e74c3c",
                    color: "#fff",
                    border: "1px solid #c0392b",
                },
                duration: 5,
                placement: "topRight",
            });
        }
        setLoading(false);
    };


    useEffect(() => {
        form.setFieldsValue(zoomData);
    }, [form, zoomData]);
    return (
        <Modal
            show={editZoomModal}
            onHide={() => setEditZoomModal(false)}
            backdrop="static"
            keyboard={false}
            centered
            size={"lg"}
            scrollable={true}
            dialogClassName={"bootStrapModal"}
            contentClassName={"bootStrapModalContent"}
        >
            <Modal.Body className="position-relative">
                <MdOutlineClose
                    className="position-absolute"
                    style={{ right: "15px", top: "15px", fontSize: "25px" }}
                    onClick={() => setEditZoomModal(false)}
                />
                <div className="text-left">
                    <h4 className="modalHeader">Edit Zoom Meetings</h4>
                </div>
                <div>
                    <Form form={form}
                        layout="vertical"
                        name="form_in_modal"
                        initialValues={zoomData}
                        onFinish={async () => {
                            await updateZoomMeeting()
                            //update function
                            setEditZoomModal(false)
                            form.resetFields();
                        }}
                    >
                        <div className="row">
                            <div className="col-md-6">
                                <Form.Item label="Select Zoom Account" name={"zoomId"}>
                                    <Select
                                        size="large"
                                        placeholder="Select Zoom Account"
                                        onChange={(value) => {
                                            let obj = {
                                                ...zoomData,
                                                zoomId: [value],
                                            };
                                            form.setFieldValue("zoomId", value);
                                            setZoomData(obj)


                                            const selectValue = value;
                                            setZoomData({ ...zoomData, zoomId: selectValue });
                                        }}
                                        //   value={zoomData?.zoomId}
                                        options={zoomAccountList?.map((item) => ({
                                            label: item?.email,
                                            value: item?._id,
                                        }))}
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-md-6">
                                <Form.Item label="Meeting Id" name="meetingId">
                                    <Input
                                        size="large"
                                        placeholder="Meeting Id"
                                        name="meetingId"
                                        //   value={zoomData?.meetingId}
                                        onChange={(e) => {
                                            let obj = {
                                                ...zoomData,
                                                meetingId: e.target.value,
                                            };
                                            setZoomData(obj);
                                            form.setFieldValue("meetingId", e.target.value);
                                        }}
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-md-6">
                                <Form.Item
                                    label="Expert Designation"
                                    name="expertDesignation"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Expert designation is required!",
                                        },
                                    ]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Expert Designation"
                                        name="expertDesignation"
                                        // value={zoomData.expertDesignation}
                                        onChange={(e) => {
                                            let obj = {
                                                ...zoomData,
                                                expertDesignation: e.target.value,
                                            };
                                            setZoomData(obj);
                                            form.setFieldValue("expertDesignation", e.target.value);
                                        }}
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-md-6">
                                <Form.Item
                                    label="Expert Company"
                                    name="expertCompany"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Expert company is required.",
                                        },
                                    ]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Expert Company"
                                        name="expertCompany"
                                        value={zoomData.expertCompany}
                                        onChange={(e) => {
                                            let obj = {
                                                ...zoomData,
                                                expertCompany: e.target.value,
                                            };
                                            setZoomData(obj);
                                            form.setFieldValue("expertCompany", e.target.value);
                                        }}
                                    />
                                </Form.Item>
                            </div>

                            <div className="col-md-6">
                                <Form.Item name="client" label="Client">
                                    <Select
                                        showSearch
                                        size="large"
                                        placeholder="Select Clients"
                                        onSearch={setFilteredData}
                                        onChange={(newValue, option) => {
                                            console.log('newValue: ', newValue);
                                            let obj = {
                                                ...zoomData,
                                                client: newValue,
                                            };
                                            if (newValue) {
                                                handleNameChange(newValue, option)
                                            }
                                            setZoomData(obj);
                                            form.setFieldValue("client", newValue);
                                        }}
                                        //   name="client"
                                        //   value={zoomData?.client}
                                        options={zoomCompanyList
                                            ?.filter((item) => item?.name)
                                            .map((item) => ({
                                                label: item?.name,
                                                value: item?.name,
                                                key: item?._id,
                                                domain: item?.domain,
                                            }))}
                                        virtual={false}
                                        allowClear
                                    />
                                </Form.Item>
                            </div>

                            <div className="col-md-6">
                                <Form.Item name="client_user" label="Client User">
                                    <Select
                                        size="large"
                                        placeholder="Select Client User"
                                        name="client_user"
                                        mode="multiple"
                                        onChange={(newValue, option) => {
                                            console.log('option: ', option);
                                            console.log('newValue: ', newValue);
                                            let client_user_id_modified = option?.map((data) => data?.key
                                            )
                                            let obj = {
                                                ...zoomData,
                                                client_user: newValue,
                                                client_user_id: client_user_id_modified
                                            };
                                            setZoomData(obj);
                                            form.setFieldValue("client_user", newValue);
                                        }}
                                        // value={zoomData?.client_user}
                                        options={clientView?.map((item) => ({
                                            label: item?.name,
                                            value: item?.name,
                                            key: item?._id,
                                        }))}
                                        dropdownRender={(menu) => (
                                            <>
                                                {menu}
                                                <Divider
                                                    style={{
                                                        margin: "8px 0",
                                                    }}
                                                />
                                                <Space
                                                    style={{
                                                        padding: "0 8px 4px",
                                                    }}
                                                >
                                                    <Button
                                                        icon={<PlusOutlined />}
                                                        onClick={() => setCreateModal(true)}
                                                    >
                                                        Client user
                                                    </Button>
                                                </Space>
                                            </>
                                        )}
                                        virtual={false}
                                        allowClear
                                    />
                                </Form.Item>
                            </div>

                            {/* <div className="col-md-6">
                                <Form.Item label="Meeting Passcode" name="passcode">
                                    <Input
                                        size="large"
                                        placeholder="819302"
                                        name="passcode"
                                        // value={zoomData?.passcode}
                                        // onChange={handleInputs}
                                        // value={zoomData?.passcode}
                                        onChange={(e) => {
                                            let obj = {
                                                ...zoomData,
                                                passcode: e.target.value,
                                            };
                                            setZoomData(obj)
                                            form.setFieldValue("passcode", e.target.value);
                                        }}
                                    />
                                </Form.Item>
                            </div>

                            <div className="col-md-6">
                                <Form.Item name="dateAndTime" label="Date Time">
                                    <RangePicker
                                        size="large"
                                        showTime={{
                                            format: "HH:mm",
                                        }}
                                        name="dateAndTime"
                                        format="YYYY-MM-DD HH:mm"
                                        onChange={(dates, dateStrings) => {
                                            console.log('dateStrings: ', dateStrings);
                                            console.log('dates: ', dates);
                                            const [startDate, startTime] = dateStrings[0].split(" ");
                                            const [endDate, endTime] = dateStrings[1].split(" ");
                                            setZoomData({
                                                ...zoomData,
                                                startDate: startDate,
                                                startTime: startTime,
                                                endDate: endDate,
                                                endTime: endTime,
                                                dateAndTime: dates
                                            });
                                            form.setFieldValue("dateAndTime", dates);
                                        }}
                                    // onOk={onOk}
                                    />
                                </Form.Item>
                            </div> */}
                        </div>
                        <div className="d-flex justify-content-end mt-3">
                            <Form.Item style={{ marginBottom: 0 }}>
                                <button
                                    type="button"
                                    className="secondaryBttn btn"
                                    onClick={() => setEditZoomModal(false)}
                                >
                                    Cancel
                                </button>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }}>
                                <button
                                    type="submit"
                                    className="viewButton btn ms-2"
                                >
                                    Submit
                                </button>
                            </Form.Item>
                        </div>
                    </Form>
                </div>

            </Modal.Body>
        </Modal>
    )
}

export default UpdateZoomMeetingModal