import { Card, Col, Row, notification } from 'antd'
import React, { useContext, useRef, useState } from 'react'
import { ApiContext } from '../NotificationApi';
import axios from 'axios';
import API_PATH from "../../Constants/api-path";
import { ExclamationCircleOutlined } from "@ant-design/icons";

function ExpertDashboard() {
    const { notifications } = useContext(ApiContext);
    const [allDatas, setAlldatas] = useState(0);
    const [monthData, setAllMonthData] = useState([]);
    const [clientList, setClientList] = useState("");

    const [, setLoading] = useState(false);
    const userData = JSON.parse(localStorage.getItem("userData"));
    const [, setExperts] = useState([]);

    const getExperts = React.useCallback(async () => {
        setLoading(true);
        await axios
            .get(`${API_PATH.EXPERT_LIST}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userData?.token}`,
                },
            })
            .then((res) => {
                setLoading(true);
                setExperts([...res.data.data]);
                // console.log(res, "datasss");
                setAlldatas(res?.data?.countData);
                // console.log(res?.data?.countData, "res.data");
            })
            .catch((error) => {
                setLoading(false);
                // console.log(error);
            });
    }, [userData?.token]);

    const GetMonthWeekList = React.useCallback(async () => {
        setLoading(true);
        try {
            const res = await fetch(`${API_PATH.MONTH_WEEK_LIST}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userData?.token}`,
                },
            });

            const result = await res.json();
            if (result) {
                // console.log(result, "resultMonthData");
                setAllMonthData(result);
            } else {
                notification.error({
                    message: "Error: Something went wrong server error",
                    icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
                    style: {
                        backgroundColor: "#e74c3c",
                        color: "#fff",
                        border: "1px solid #c0392b",
                    },
                    duration: 5,
                    placement: "topRight",
                });
            }
        } catch (error) {
            setLoading(false);

            notification.error({
                message: "Error: Something went wrong server error",
                icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
                style: {
                    backgroundColor: "#e74c3c",
                    color: "#fff",
                    border: "1px solid #c0392b",
                },
                duration: 5,
                placement: "topRight",
            });
        }
        setLoading(false);
    }, [userData?.token]);

    const GetClientList = React.useCallback(async () => {
        setLoading(true);
        try {
            const res = await fetch(`${API_PATH.CLIENT_LIST}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userData?.token}`,
                },
            });

            const result = await res.json();
            if (result) {
                // console.log(result?.data?.length, "resultClentData");
                setClientList(result?.data?.length);
            } else {
                notification.error({
                    message: "Error: Something went wrong server error",
                    icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
                    style: {
                        backgroundColor: "#e74c3c",
                        color: "#fff",
                        border: "1px solid #c0392b",
                    },
                    duration: 5,
                    placement: "topRight",
                });
            }
        } catch (error) {
            setLoading(false);

            notification.error({
                message: "Error: Something went wrong server error",
                icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
                style: {
                    backgroundColor: "#e74c3c",
                    color: "#fff",
                    border: "1px solid #c0392b",
                },
                duration: 5,
                placement: "topRight",
            });
        }
        setLoading(false);
    }, [userData?.token]);


    const callOnce = useRef(false)

    React.useEffect(() => {
        if (!callOnce.current) {
            callOnce.current = true
            getExperts()
            GetMonthWeekList()
            GetClientList()
        }
    }, [GetClientList, GetMonthWeekList, getExperts])

    return (
        <div>
            <div className="dasb-div bk-div float-start w-100">
                <div className="body-expart-div">
                    <span className="d-flex align-items-center">
                        <p className="fs-5">
                            <strong style={{ color: "#5746ec" }}>Expert Statistics </strong>
                        </p>
                    </span>
                    <Row
                        gutter={{ xs: 6, md: 12 }}
                        className="mt-3 row-cols-1 row-cols-lg-3"
                    >
                        <Col>
                            <Card
                                title="Experts (Platform)"
                                bordered={false}
                                extra={notifications?.expertData > 0 ? (
                                    <>
                                        <span class="sidebarBadge">{notifications?.expertData}</span>
                                    </>
                                ) : null}
                            >
                                <div className="row">
                                    <div className="col-md-4 col-4">
                                        <span>
                                            Overall
                                            <span>
                                                <h6 className="col-indigo fw-bold">{allDatas}</h6>
                                            </span>
                                        </span>
                                    </div>
                                    <div className="col-md-4 col-4">
                                        <span style={{ whiteSpace: "nowrap" }}>This month</span>
                                        <h6 className="col-indigo fw-bold">
                                            {monthData?.this_month_expert_platform}
                                        </h6>
                                    </div>
                                    <div className="col-md-4 col-4">
                                        <span style={{ whiteSpace: "nowrap" }}>This week</span>
                                        <h6 className="col-indigo fw-bold">
                                            {monthData?.this_week_expert_platform}
                                        </h6>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                        <Col>
                            <Card
                                title="Clients"
                                bordered={false}
                                extra={notifications?.clientData > 0 ? (
                                    <>
                                        <span class="sidebarBadge">{notifications?.clientData}</span>
                                    </>
                                ) : null}
                            >
                                <div className="row">
                                    <div className="col-md-4 col-4">
                                        <span>
                                            Overall
                                            <span>
                                                <h6 className="col-indigo fw-bold">{clientList}</h6>
                                            </span>
                                        </span>
                                    </div>
                                    <div className="col-md-4 col-4">
                                        <span style={{ whiteSpace: "nowrap" }}>This month</span>
                                        <h6 className="col-indigo fw-bold">
                                            {monthData?.this_month_client_cloud}
                                        </h6>
                                    </div>
                                    <div className="col-md-4 col-4">
                                        <span style={{ whiteSpace: "nowrap" }}>This week</span>
                                        <h6 className="col-indigo fw-bold">
                                            {monthData?.this_week_client_cloud}
                                        </h6>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                        <Col>
                            <Card title="Experts (Cloud)" bordered={false}>
                                <div className="row">
                                    <div className="col-md-4 col-4">
                                        <span>
                                            Overall
                                            <span>
                                                <h6 className="col-indigo fw-bold">
                                                    {monthData?.all_expert_cloud}
                                                </h6>
                                            </span>
                                        </span>
                                    </div>
                                    <div className="col-md-4 col-4">
                                        <span style={{ whiteSpace: "nowrap" }}>This month</span>
                                        <h6 className="col-indigo fw-bold">
                                            {monthData?.this_month_expert_cloud}
                                        </h6>
                                    </div>
                                    <div className="col-md-4 col-4">
                                        <span style={{ whiteSpace: "nowrap" }}>This week</span>
                                        <h6 className="col-indigo fw-bold">
                                            {monthData?.this_week_expert_cloud}
                                        </h6>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}

export default ExpertDashboard
