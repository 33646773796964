import {
  EyeOutlined,
  MoreOutlined,
  PlusOutlined,
  SearchOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Dropdown,
  Form,
  Input,
  Menu,
  Modal,
  Select,
  Space,
  Switch,
  Table,
  InputNumber,
  notification,
  Pagination,
  Tag,
  Tooltip,
} from "antd";
import { BiAddToQueue, BiTransfer } from "react-icons/bi";
import React, { useCallback, useEffect, useRef, useState } from "react";
import API_PATH from "../../Constants/api-path";
import { MdDelete } from "react-icons/md";
import { MdOutlineClose } from "react-icons/md";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Modal as BootstrapModal } from "react-bootstrap";
import { countryOptions } from "../../Constants/CountryOptions";
import axios from "axios";
import AddUserModal from "./AddUserModal";
import { FaEye } from "react-icons/fa6";
import ConfirmationModal from "../../components/ConfirmationModal";
import _ from "lodash";

const MyFormItemContext = React.createContext([]);
function toArr(str) {
  return Array.isArray(str) ? str : [str];
}
const MyFormItemGroup = ({ prefix, children }) => {
  const prefixPath = React.useContext(MyFormItemContext);
  const concatPath = React.useMemo(
    () => [...prefixPath, ...toArr(prefix)],
    [prefixPath, prefix]
  );
  return (
    <MyFormItemContext.Provider value={concatPath}>
      {children}
    </MyFormItemContext.Provider>
  );
};
const MyFormItem = ({ name, ...props }) => {
  const prefixPath = React.useContext(MyFormItemContext);
  const concatName =
    name !== undefined ? [...prefixPath, ...toArr(name)] : undefined;
  return <Form.Item name={concatName} {...props} />;
};

const ClientTable = () => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [modal1Open, setModal1Open] = useState(false);
  const [modal2Open, setModal2Open] = useState(false);
  const [modal3Open, setModal3Open] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clientList, setClientList] = useState([]);
  const [clientView, setClientView] = useState([]);
  // console.log('clientView: ', clientView);
  const [viewId, setViewId] = useState("");
  const [pagination, setPagination] = useState();
  const [showCreateModal, setCreateModal] = useState(false);
  const [userModal, setUserModal] = useState(false);
  const [form] = Form.useForm();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [callPage, setCallPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [viewCompany, setViewCompany] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState({});
  const [addUser, setAddUser] = useState({
    firstNmae: "",
    email: "",
    contact_code: "",
    contact_number: "",
    country: "",
    password: "",
    role_id: null,
    registrationType: "cloud",
    contact_number_full: "",
  });
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    GetClientList(pageNumber);
  };
  const [roleList, setRoleList] = useState([]);

  const [clienData, setClienData] = useState({
    name: "",
    email: "",
    comapnyName: "",
    contact_number: "",
    country: "",
    contact_code: "",
    // role_id: "",
    registrationType: "",
  });

  const [phone, setPhone] = useState("");

  const [companyId, setCompanyId] = useState("");
  const [price, setPrice] = useState({
    expertRate: "",
    currency: [],
    show_price: false,
  });
  const [expertId, setExpertId] = useState("");
  const [filteredClientList, setFilteredClientList] = useState([]);

  const paginationSettings = {
    current: currentPage,
    onChange: handlePageChange,
    pageSize: 10,
    total: callPage,
  };

  const GetClientList = useCallback(
    async (page = 1, selectedOptions) => {
      setLoading(true);
      try {
        let object = {};

        if (selectedOptions?.key) {
          object.key = selectedOptions?.key;
        }
        const queryParams = new URLSearchParams(object); // Construct query parameters from the object properties
        console.log("queryParams: ", queryParams.get("key"));

        const res = await fetch(
          `${API_PATH.CLIENT_LIST}?page=${page}&limit=10&key=${
            selectedOptions?.key || ""
          }`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userData?.token}`,
            },
          }
        );

        const result = await res.json();
        if (result) {
          setClientList(result?.data);
          const filteredData = result?.data?.filter((record) =>
            Object.values(record)
              .join("")
              .toLowerCase()
              .includes(searchText.toLowerCase())
          );
          console.log("filteredData: ", filteredData);
          setFilteredClientList(filteredData);
          setCallPage(result?.countData);
        } else {
          notification.error({
            message: "Error: Something went wrong server error",
            icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
            style: {
              backgroundColor: "#e74c3c",
              color: "#fff",
              border: "1px solid #c0392b",
            },
            duration: 5,
            placement: "topRight",
          });
        }
      } catch (error) {
        setLoading(false);
        console.log("notification", error);
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
      setLoading(false);
    },
    [searchText, userData?.token]
  );

  // const ViewClient = async (_id) => {
  //   setLoading(true);
  //   try {
  //     const res = await fetch(`${API_PATH.CLIENT_VIEW}/${_id}`, {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${userData?.token}`,
  //       },
  //     });

  //     const result = await res.json();
  //     if (result) {
  //       setClientView(result?.data);
  //       setCompanyId(result?.data[0]?.company_id);
  //     } else {
  //       notification.error({
  //         message: "Error: Something went wrong server error",
  //         icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
  //         style: {
  //           backgroundColor: "#e74c3c",
  //           color: "#fff",
  //           border: "1px solid #c0392b",
  //         },
  //         duration: 5,
  //         placement: "topRight",
  //       });
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     notification.error({
  //       message: "Error: Something went wrong server error",
  //       icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
  //       style: {
  //         backgroundColor: "#e74c3c",
  //         color: "#fff",
  //         border: "1px solid #c0392b",
  //       },
  //       duration: 5,
  //       placement: "topRight",
  //     });
  //     console.error("something went wrong server error");
  //   }
  //   setLoading(false);
  // };

  const UpdatePrice = async () => {
    // e.preventDefault();
    setLoading(true);

    try {
      const res = await fetch(`${API_PATH.CLIENT_UPDATE_PRICE}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
        body: JSON.stringify({
          ...price,
          company_id: companyId,
        }),
      });

      const result = await res.json();
      if (result) {
        GetClientList();
        if (res.status === 200 || res.status === true) {
          notification.success({
            message: "Price has been updated",
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#2ecc71",
              color: "#fff !important",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          });

          // window.location.reload();
        }
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);
      console.log("Update Price", error);
      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  };
  const moveToMynt = async (record) => {
    // e.preventDefault();
    setLoading(true);
    const body = {
      registration_type:
        record?.registrationType === "client" ? "cloud" : "client",
    };
    try {
      const res = await axios.put(
        `${API_PATH.MOVE_CLIENT_TO_MYNT}/${record?._id}`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          },
        }
      );

      if (res?.data?.status) {
        GetClientList();
        if (res.status === 200 || res.status === true) {
          notification.success({
            message: `Client updated successfully!`,
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#2ecc71",
              color: "#fff !important",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          });

          // window.location.reload();
        }
      } else {
        notification.error({
          message: "Error while moving Client",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);
      console.log("Update Price", error);
      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  };

  const VerifyExpert = async (_id) => {
    setLoading(true);

    try {
      const res = await fetch(`${API_PATH.CLIENT_VERIFY}/${_id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
      });

      const result = await res.json();
      if (result) {
        GetClientList();
        if (res.status === 200 || res.status === true) {
          notification.success({
            message: "Client has been verified",
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#2ecc71",
              color: "#fff !important",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          });

          // window.location.reload();
        }
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);
      console.log("Client could't verify due to following error:", error);
      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  };

  const DeleteExpertID = (_id) => {
    setExpertId(_id);
  };

  const DeleteExpert = async () => {
    // e.preventDefault();
    setLoading(true);

    try {
      const res = await fetch(`${API_PATH.CLIENT_DELETE}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
        body: JSON.stringify({
          client_id: [expertId],
        }),
      });

      const result = await res.json();
      if (result) {
        if (res.status === 200 || res.status === true) {
          viewClientModal();
          GetClientList();

          notification.success({
            message: "User has been deleted",
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#2ecc71",
              color: "#fff !important",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          });

          // window.location.reload();
        }
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);
      console.log("User could not be delete due to following error:", error);
      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  };

  const viewClientModal = async (id = null) => {
    console.log("id: ", id);
    setLoading(true);
    let vid = id === null ? viewId : id;
    let reslt = clientList.find((item) => item._id === vid);
    setViewCompany(reslt?.companyName);
    try {
      const res = await fetch(`${API_PATH.CLIENT_VIEW}/${vid}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
      });

      const result = await res.json();
      if (result) {
        setClientView(result?.data);
        setCompanyId(result?.data[0]?.company_id);
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);
      console.log("Following error is coming to fetch users", error);
      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
      console.error("something went wrong server error");
    }
    setLoading(false);
  };

  const getMangerId = ({ data, id }) => {
    setViewId(id);
    const res = data?.find((val) => val._id === id);
    setPrice({
      expertRate: res?.expertRate,
      currency: res?.currency ? res?.currency : [],
      show_price: res?.show_price,
    });
  };

  // const constructUrl = (options) => {
  //   const { key } = options;

  //   const projectParam = Array.isArray(key) ? key.join(", ") : key;
  //   const sanitizedProject = projectParam
  //     ? projectParam.replace(/%20/g, " ")
  //     : "";

  //   const urlParams = [`key=${encodeURIComponent(sanitizedProject)}`]; // Remove quotes and properly encode

  //   return history({
  //     pathname: `/dashboard/clients`,
  //     search: `?${urlParams.join("&")}`,
  //   });
  // };

  const GetRoleList = useCallback(async () => {
    try {
      const res = await fetch(`${API_PATH.CLIENT_ROLE}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
      });

      const result = await res.json();
      if (result) {
        setRoleList(result?.data);
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);
      console.log("Error while fetching role", error);
      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  }, [userData?.token]);

  const callOnce = useRef(false);
  useEffect(() => {
    if (!callOnce.current) {
      callOnce.current = true;
      GetRoleList();
      GetClientList(currentPage, selectedOptions);
      // const url = constructUrl(selectedOptions);
      // ViewClient();
    }
  }, [GetClientList, GetRoleList, currentPage, selectedOptions]);

  // const menu = (_id, record) => (
  //   <Menu>
  //     <Menu.Item
  //       onClick={() => {
  //         viewClientModal(record)
  //         setModal1Open(true);
  //       }}
  //       style={{
  //         display: "flex",
  //         alignItems: "center",
  //         justifyContent: "center",
  //       }}
  //     >
  //       <Space align="center" size={8} >
  //         <EyeOutlined />
  //         <span> View</span>
  //       </Space>
  //     </Menu.Item>
  //     <Menu.Item
  //       // onClick={() => {
  //       //   setModal1Open(true);
  //       // }}
  //       style={{
  //         display: "flex",
  //         alignItems: "center",
  //         justifyContent: "center",
  //       }}
  //     >
  //       <Space align="center" size={8} onClick={() => moveToMynt(record)}>
  //         <BiTransfer />
  //         <span> {record?.registrationType === "client" ? "Move to Mynt" : "Move to Client"}</span>
  //       </Space>
  //     </Menu.Item>
  //     {record?.team_count > 0 ? (
  //       <Menu.Item
  //         onClick={() => setModal2Open(true)}
  //         style={{
  //           display: "flex",
  //           alignItems: "center",
  //           justifyContent: "center",
  //         }}
  //       >
  //         <Space align="center" size={8}>
  //           {price?.expertRate !== "" && price?.currency?.length > 0 ? (
  //             <>
  //               <EditOutlined />
  //               <span>Edit Price</span>
  //             </>
  //           ) : (
  //             <>
  //               <PlusOutlined />
  //               <span>Add Price</span>
  //             </>
  //           )}
  //         </Space>
  //       </Menu.Item>
  //     ) : null}
  //   </Menu>
  // );

  const columns = [
    {
      title: "#",
      key: "#",
      render: (text, record, rowIndex) => rowIndex + 1,
    },
    {
      title: "Company Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Domain Name",
      dataIndex: "domain",
      key: "domain",
    },
    {
      title: "Registration Type",
      dataIndex: "registrationType",
      key: "registrationType",
      render: (text, record) => {
        console.log("record: ", record);
        return record?.registrationType ? (
          <Tag color={record?.registrationType === "client" ? "blue" : "green"}>
            {" "}
            {record?.registrationType === "cloud" ? "Mynt" : "Client"}
          </Tag>
        ) : (
          "-"
        );
      },
    },
    {
      title: "Price",
      dataIndex: ["currency", "expertRate"],
      key: "name",
      render: (text, record) =>
        record.currency && record.expertRate
          ? `${record.currency} ${record.expertRate}`
          : "-",
    },
    {
      title: "Team Members",
      dataIndex: "team_count",
      key: "team_count",
    },
    {
      title: "Action",
      dataIndex: "_id",
      key: "_id",
      render: (_id, record) => (
        // <div onClick={() => getMangerId({ data: filteredClientList, id: _id })}>
        //   <Dropdown overlay={() => menu(_id, record)} trigger={["click"]}>
        //     <a onClick={(e) => e.preventDefault()}>
        //       <MoreOutlined style={{ fontSize: "20px" }} />
        //     </a>
        //   </Dropdown>
        // </div>
        <div className="d-flex expertActions align-items-center gap-1">
          <Tooltip placement="top" title={"View Profile"} color="#5746EB">
            <span>
              <button
                size="large"
                className="viewBttnExpert"
                onClick={async () => {
                  getMangerId({ data: filteredClientList, id: _id });

                  await viewClientModal(_id);
                  setModal1Open(true);
                }}
              >
                <FaEye />
              </button>
            </span>
          </Tooltip>
          <Tooltip
            placement="top"
            title={
              record?.registrationType === "client"
                ? "Move to Mynt"
                : "Move to Client"
            }
            color="#5746EB"
          >
            <span>
              <button
                size="large"
                className="viewBttnExpert"
                onClick={() => {
                  getMangerId({ data: filteredClientList, id: _id });
                  setSelectedClient(record);
                  setOpen(true);
                  // moveToMynt(record)
                }}
              >
                <BiTransfer />
              </button>
            </span>
          </Tooltip>
          {record?.team_count > 0 ? (
            <Tooltip
              placement="top"
              title={
                price?.expertRate !== "" && price?.currency?.length > 0
                  ? "Edit Price"
                  : "Add Price"
              }
              color="#5746EB"
            >
              <button
                size="large"
                className="viewBttnExpert"
                onClick={() => {
                  getMangerId({ data: filteredClientList, id: _id });
                  setModal2Open(true);
                }}
              >
                {price?.expertRate !== "" && price?.currency?.length > 0 ? (
                  <>
                    <EditOutlined />
                  </>
                ) : (
                  <>
                    <PlusOutlined />
                  </>
                )}
              </button>
            </Tooltip>
          ) : null}
        </div>
      ),
    },
  ];

  const columns10 = [
    {
      title: "S.No.",
      dataIndex: "1.",
      key: "1.",
      render: (text, record, rowIndex) => rowIndex + 1,
      width: "50px",
    },
    {
      title: "Image",
      dataIndex: "name",
      key: "name",
      width: "40px",
      render: (name) => (
        <>
          <Avatar alt={name}>{name?.charAt(0)?.toUpperCase()}</Avatar>
        </>
      ),
    },
    {
      title: "Name",
      dataIndex: ["firstNmae", "lastName"],
      key: "name",
      render: (text, record) => (
        <>
          <Tooltip title={record?.name} color="#5746ec">
            {_.truncate(record?.name, { length: 20, omission: "..." })}
          </Tooltip>
        </>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text, record) => (
        <Tooltip title={record?.email} color="#5746ec">
          {_.truncate(record?.email, { length: 35, omission: "..." })}
        </Tooltip>
      ),
    },
    {
      title: "Phone",
      dataIndex: "name",
      key: "name",
      render: (text, record) =>
        record?.contact_code && record?.contact_number
          ? !record?.contact_code?.includes("+")
            ? `+${record?.contact_code} ${record?.contact_number}`
            : `${record?.contact_code} ${record?.contact_number}`
          : "-",
    },

    {
      title: "Action",
      dataIndex: "_id",
      key: "action",
      render: (_id) => (
        <>
          {/* <Space size="middle">
            <MdDelete
              style={{ fontSize: "20px", cursor: "pointer" }}
              onClick={() => {
                setModal3Open(true);
                DeleteExpertID(_id);
              }}
            />
            <PlusOutlined
              style={{ fontSize: "20px" }}
              onClick={() => {
                setCreateModal(true);
              }}
            />
          </Space> */}
          <div className="d-flex expertActions align-items-center gap-1">
            <Tooltip placement="top" title="Delete" color="#5746EB">
              <button
                size="large"
                className="viewBttnExpert"
                onClick={() => {
                  setModal3Open(true);
                  DeleteExpertID(_id);
                }}
              >
                <MdDelete />
              </button>
            </Tooltip>
          </div>
        </>
      ),
    },
  ];

  // useEffect(() => {
  //   // const filteredData = clientList?.filter((record) =>
  //   //   Object.values(record)
  //   //     .join("")
  //   //     .toLowerCase()
  //   //     .includes(searchText.toLowerCase())
  //   // );
  //   // setFilteredClientList(filteredData);
  //   // setPagination({
  //   //   ...pagination,
  //   //   current: 1,
  //   //   total: filteredData.length,
  //   // });
  // }, [clientList, pagination, searchText]);

  // const handleSearch = (value) => {
  //   setSearchText(value);
  // };

  const handleTableChange = (pagination, filters, sorter) => {
    setPagination(pagination);
  };

  const handleInputs = (value) => {
    const selectValue = value;
    setPrice({ ...price, expertRate: selectValue });
  };

  const handleChange = (values) => {
    setPrice((prevPrice) => ({
      ...prevPrice,
      currency: values, // Store the selected currencies as an array
    }));
  };

  function handleSwitchChange(checked) {
    const selectValue = checked;
    setPrice({ ...price, show_price: selectValue });
  }

  const AddClientData = async () => {
    setLoading(true);

    try {
      const res = await fetch(`${API_PATH.CLIENT_CREATE}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
        body: JSON.stringify(clienData),
      });

      const result = await res.json();
      if (result && (result.status === 200 || result.status === true)) {
        GetClientList();
        notification.success({
          message: "Client created",
          icon: <CheckCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#2ecc71",
            color: "#fff",
            border: "1px solid #52c41a",
          },
          duration: 5,
          placement: "topRight",
        });
      } else if (result && result.message === "Email is already exists.") {
        notification.error({
          message: "Email is already exists.",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      console.log("Error when creating client", error);
      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  };

  const footer = (
    <div>
      <button
        className="secondaryBttn btn"
        key="cancel"
        onClick={() => setModal3Open(false)}
      >
        No
      </button>
      <button
        key="submit"
        className="viewButton btn ms-2"
        onClick={() => {
          DeleteExpert();
          setModal3Open(false);
        }}
      >
        Yes
      </button>
    </div>
  );

  // const customPagination = {
  //   ...pagination,
  //   showSizeChanger: false, // Set showSizeChanger to false to remove the Page Size dropdown
  // };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setSelectedOptions({
      key: inputValue,
    });

    setSearchText(inputValue);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (callOnce.current) {
      let handler = setTimeout(async () => {
        GetClientList(1, { key: searchText });
      }, 1000);
      return () => {
        clearTimeout(handler);
      };
    }
  }, [GetClientList, searchText]);

  const handleInputsClient = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setClienData({ ...clienData, [name]: value });
  };

  // function handleSelectChange(value) {
  //   const selectValue = value;
  //   setClienData({ ...clienData, role_id: selectValue });
  // }

  function handleSelectChangeMoveTo(value) {
    const selectValue = value;
    setClienData({ ...clienData, registrationType: selectValue });
  }

  const handlePhoneChange = (value, country) => {
    setPhone(value);

    const selectedCountry = countryOptions?.find(
      (option) => option?.code === `+${country?.dialCode}`
    );

    // const contact_code = country?.dialCode;
    // const contact_number = value?.replace(country?.dialCode, "");

    // const isValidContact = contact_code && contact_number;

    if (selectedCountry) {
      setClienData((prevStep3) => ({
        ...prevStep3,
        contact_code: country?.dialCode,
        contact_number: value?.replace(country?.dialCode, ""),
        country: selectedCountry?.name,
      }));

      // setContactValid(isValidContact);
    } else {
      console.log("No matching country found for dial code:", country.dialCode);
    }
  };

  return (
    <>
      <div>
        <div className="bk-div float-start w-100">
          {" "}
          {/* <hr className="sp-hr" /> */}
          <div className="body-expart-div">
            <div className="d-flex justify-content-between align-items-center">
              <h2>Clients</h2>
              <button
                className="btn viewButton"
                onClick={() => setCreateModal(true)}
                size="large"
              >
                Create Client
              </button>
            </div>

            <Input
              placeholder="Search"
              value={selectedOptions?.key}
              onChange={handleInputChange}
              prefix={<SearchOutlined />}
              style={{ marginBottom: 16 }}
              size="large"
            />
            <Table
              dataSource={filteredClientList}
              columns={columns}
              pagination={false}
              loading={loading}
              onChange={handleTableChange}
              scroll={{ x: 800 }}
              size="large"
            />
            <Pagination
              {...paginationSettings}
              showSizeChanger={false}
              style={{ marginTop: 16, marginBottom: 16, textAlign: "right" }}
            />
          </div>
        </div>
      </div>

      <Modal
        title={false}
        style={{
          top: 20,
        }}
        open={modal1Open}
        onOk={() => setModal1Open(false)}
        onCancel={() => setModal1Open(false)}
        width={1000}
        visible={true}
        footer={null}
        centered
      >
        <div className="mt-5">
          <div className="row m-b-10 ">
            <div className="col-12 col-md-6">
              <p
                className="d-flex me-2"
                style={{ fontSize: "12px", color: "#000" }}
              >
                <strong className=" fw-bold me-1 modalHeader">
                  Company Name:{" "}
                </strong>{" "}
                {<span> {viewCompany}</span>}
              </p>
            </div>

            <div className="col-12 col-md-6 text-end">
              <p style={{ fontSize: "14px", color: "#000" }} className="">
                <span className="px-2">
                  <strong className="col-indigo fw-bold modalHeader">
                    Total Members:
                  </strong>{" "}
                  <span>{clientView?.length}</span>
                </span>
                <span className="ps-4">
                  <Button
                    style={{
                      color: "#5746EB",
                      borderColor: "#5746EB",
                    }}
                    onClick={() => {
                      setUserModal(true);
                    }}
                  >
                    Add New User
                  </Button>
                </span>
              </p>
            </div>
          </div>
          <Table
            columns={columns10}
            dataSource={clientView}
            pagination={false}
          />
        </div>
      </Modal>

      <Modal
        title={false}
        style={{
          top: 20,
        }}
        open={modal2Open}
        onOk={() => setModal2Open(false)}
        onCancel={() => setModal2Open(false)}
        width={800}
        visible={true}
        footer={null}
        centered
      >
        <div className="mt-5">
          <h4 className="modalHeader">Price &amp; Currency</h4>
          <Form name="form_item_path" layout="vertical" onFinish={UpdatePrice}>
            <MyFormItemGroup prefix={["user"]}>
              <MyFormItemGroup prefix={["name"]}>
                <MyFormItem label="Customise Price">
                  <InputNumber
                    size="large"
                    type="number"
                    // name="expertRate"
                    value={price?.expertRate}
                    onChange={handleInputs}
                    style={{
                      width: "100%",
                    }}
                  />
                </MyFormItem>
                <MyFormItem label="Currency ">
                  <Select
                    // mode="multiple"
                    size="large"
                    name="currency"
                    placeholder="Select Currency"
                    onChange={handleChange}
                    value={price?.currency}
                    options={[
                      {
                        value: "GBP - British Pound Sterling - £",
                        label: "GBP - British Pound Sterling - £",
                      },
                      {
                        value: "EURO - Euro -  £",
                        label: "EURO - Euro -  £",
                      },
                      {
                        value: "INR - Indian Rupee - ₹",
                        label: "INR - Indian Rupee - ₹",
                      },
                      {
                        value: "USD - US Dollar - $",
                        label: "USD - US Dollar - $",
                      },
                    ]}
                  />
                </MyFormItem>
              </MyFormItemGroup>

              <div className="d-flex">
                <MyFormItem
                  name="show_price"
                  label="Show Currency"
                  className="me-3"
                />
                <Switch
                  checked={price?.show_price}
                  value={price?.show_price}
                  onChange={handleSwitchChange}
                />{" "}
              </div>
            </MyFormItemGroup>
            <div className="d-flex justify-content-end align-items-center">
              <Button
                type="primary"
                htmlType="submit"
                onClick={() => setModal2Open(false)}
              >
                Update
              </Button>
            </div>
          </Form>
        </div>
      </Modal>

      <Modal
        title={<span className="modalHeader">Delete.</span>}
        open={modal3Open}
        onOk={() => setModal3Open(false)}
        onCancel={() => setModal3Open(false)}
        visible={true}
        footer={footer}
        centered
      >
        <div className="mt-4" style={{ width: "100%", textAlign: "left" }}>
          <p>
            Are you sure, do you want to delete this client? If you proceed it
            will not undone.
          </p>{" "}
        </div>
      </Modal>

      <BootstrapModal
        show={showCreateModal}
        onHide={() => setCreateModal(false)}
        backdrop="static"
        keyboard={false}
        centered
        size={"lg"}
        scrollable={true}
        dialogClassName={"bootStrapModal"}
        contentClassName={"bootStrapModalContent"}
      >
        <BootstrapModal.Body className="position-relative">
          <MdOutlineClose
            className="position-absolute"
            style={{ right: "15px", top: "15px", fontSize: "25px" }}
            onClick={() => setCreateModal(false)}
          />
          <div className="text-left">
            <h4 className="modalHeader">Create Client</h4>
          </div>
          <div>
            <Form form={form} layout="vertical">
              <div className="row">
                <div className="col-md-6">
                  <Form.Item label="Name">
                    <Input
                      size="large"
                      name="name"
                      value={clienData?.name}
                      onChange={handleInputsClient}
                    />
                  </Form.Item>
                </div>

                <div className="col-md-6">
                  <Form.Item label="Email">
                    <Input
                      size="large"
                      name="email"
                      value={clienData?.email}
                      onChange={handleInputsClient}
                    />
                  </Form.Item>
                </div>

                <div className="col-md-6">
                  <Form.Item label="Company Name">
                    <Input
                      size="large"
                      name="companyName"
                      value={clienData?.companyName}
                      onChange={handleInputsClient}
                    />
                  </Form.Item>
                </div>

                <div className="col-md-6">
                  <Form.Item label="Contact Number">
                    <PhoneInput
                      country={"us"}
                      enableSearch={true}
                      name="contact_number"
                      placeholder="Contact Number"
                      onChange={(value, country) => {
                        handlePhoneChange(value, country);
                      }}
                      inputStyle={{
                        width: "100%",
                      }}
                      value={phone}
                    />
                  </Form.Item>
                </div>

                {/* <div className="col-md-6">
                  <Form.Item label="Role">
                    <Select
                      size="large"
                      style={{
                        width: "100%",
                      }}
                      value={clienData?.role_id}
                      onChange={handleSelectChange}
                    >
                      {roleList?.map((option, index) => (
                        <Select.Option value={option?._id} key={index}>
                          {option?.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div> */}

                <div className="col-md-6">
                  <Form.Item label="Move to">
                    <Select
                      size="large"
                      style={{
                        width: "100%",
                      }}
                      value={clienData?.registrationType}
                      onChange={handleSelectChangeMoveTo}
                    >
                      <Select.Option value={"cloud"}>
                        {/* Cloud */}
                        Mynt
                      </Select.Option>
                      <Select.Option value={"client"}>Client</Select.Option>
                    </Select>
                  </Form.Item>
                </div>
              </div>
            </Form>
          </div>
          <div className="d-flex justify-content-end mt-3">
            <button
              className="secondaryBttn btn"
              onClick={() => setCreateModal(false)}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                setCreateModal(false);
                AddClientData();
              }}
              className="viewButton btn ms-2"
            >
              Submit
            </button>
          </div>
        </BootstrapModal.Body>
      </BootstrapModal>

      <AddUserModal
        viewClientModal={viewClientModal}
        clientView={clientView}
        userModal={userModal}
        setUserModal={setUserModal}
        GetClientList={GetClientList}
      />
      <ConfirmationModal
        open={open}
        setOpen={setOpen}
        message={"Are you sure you want to move this client?"}
        title={`Move to ${
          selectedClient?.registrationType === "client" ? "Mynt" : "Client"
        }`}
        okFunction={async () => {
          await moveToMynt(selectedClient);
          setOpen(false);
        }}
        cancelFunction={() => {
          setOpen(false);
          setSelectedClient({});
        }}
      />
    </>
  );
};

export default ClientTable;
